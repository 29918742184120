import React, {useEffect, useState, useRef} from "react";
import {Link, useNavigate} from "react-router-dom";
import {Popover, Checkbox, DatePicker, Select, Tooltip, Drawer, Modal} from 'antd';
import {useAuth} from "../../context/AuthContext";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import config from "../../config";
import searchIcon from "../../assets/images/ai-search/search-icon.svg";
import crossBlackIcon from "../../assets/images/ai-search/cross-black-icon.svg";
import infoWhiteIcon from "../../assets/images/ai-search/info-white-icon.svg";
import fileIcon from "../../assets/images/ai-search/file-icon.svg";
import backArrowIcon from "../../assets/images/icons/back-arrow-black-icon.svg";
import aiCopyIcon from "../../assets/images/icons/ai-copy-icon.svg";
import clipsIcon from "../../assets/images/ai-search/clips-icon.svg";
import taggingIcon from "../../assets/images/ai-search/tagging-icon.svg";
import fileInfoIcon from "../../assets/images/ai-search/file-info-icon.svg";
import commnetIcon from "../../assets/images/ai-search/commnet-icon.svg";
import videoImg from "../../assets/images/ai-search/video-plyer-img.png";
import playIcon from "../../assets/images/ai-search/play-circle-icon.svg";
import globeIcon from "../../assets/images/ai-search/globe-gray-icon.svg";
import globeBlackIcon from "../../assets/images/ai-search/globe-black-icon.svg";
import convertIcon from "../../assets/images/ai-search/convert-icon.svg";
import rightDrawerIcon from "../../assets/images/ai-search/open-drawer-right-icon.svg";
import plusWhiteIcon from "../../assets/images/ai-search/plus-white-icon.svg";
import playBlackIcon from "../../assets/images/ai-search/play-black-icon.svg";
import trashBlackIcon from "../../assets/images/ai-search/trash-black-icon.svg";
import downloadBlackIcon from "../../assets/images/ai-search/download-black-icon.svg";
import editBlackIcon from "../../assets/images/ai-search/edit-black-icon.svg";
import gitCutImg from "../../assets/images/ai-search/gif-cut-img.png";
import identifiedImg1 from "../../assets/images/ai-search/identified-object-img-1.png";
import identifiedImg2 from "../../assets/images/ai-search/identified-object-img-2.png";
import identifiedImg3 from "../../assets/images/ai-search/identified-object-img-3.png";
import identifiedImg4 from "../../assets/images/ai-search/identified-object-img-4.png";
import identifiedImg5 from "../../assets/images/ai-search/identified-object-img-5.png";
import identifiedImg6 from "../../assets/images/ai-search/identified-object-img-6.png";
import identifiedImg7 from "../../assets/images/ai-search/identified-object-img-7.png";
import identifiedImg8 from "../../assets/images/ai-search/identified-object-img-8.png";
import identifiedImg9 from "../../assets/images/ai-search/identified-object-img-9.png";
import identifiedImg10 from "../../assets/images/ai-search/identified-object-img-10.png";
import identifiedImg11 from "../../assets/images/ai-search/identified-object-img-11.png";
import identifiedImg12 from "../../assets/images/ai-search/image 45.jpg";
import crossRedCircleIcon from "../../assets/images/ai-search/cross-red-circle-icon.svg";
import userImg1 from "../../assets/images/ai-search/comment-user-img.png";
import zoomIn from "../../assets/images/ai-search/Zoomin.svg";
import zoomOut from "../../assets/images/ai-search/ZoomOut.svg";
import download from "../../assets/images/ai-search/Download.svg";
import fullscreen from "../../assets/images/ai-search/Full.svg";
import minimized from "../../assets/images/ai-search/Minimazed.svg";

import searchGrayIcon from "../../assets/images/ai-search/search-gray-icon.svg";
import quillArrowLeftIcon from "../../assets/images/ai-search/arrow-left-gray-icon.svg";
import quillArrowRightIcon from "../../assets/images/ai-search/arrow-right-gray-icon.svg";
import zoomOutIcon from "../../assets/images/ai-search/zoom-out-icon.svg";
import zoomINIcon from "../../assets/images/ai-search/zoom-in-icon.svg";
import minimizeIcon from "../../assets/images/ai-search/minimize-icon.svg";

import Viewer from 'react-viewer';
import {tr} from "react-date-range/dist/locale";
import fileUserImg1 from "../../assets/images/ai-search/file-user-img-1.png";
import fileUserImg2 from "../../assets/images/ai-search/file-user-img-2.png";
import fileUserImg3 from "../../assets/images/ai-search/file-user-img-3.png";
import fileUserImg4 from "../../assets/images/ai-search/file-user-img-4.png";
import fileUserImg5 from "../../assets/images/ai-search/file-user-img-5.png";

const {RangePicker} = DatePicker;

const modules = {
    toolbar: [
        [{ 'font': [] }, { 'size': [] }],
        [{ 'bold': true, 'italic': true, 'underline': true, 'strike': true }],
        [{ 'color': [] }, { 'background': [] }],
        [{ 'script': 'sub'}, { 'script': 'super' }],
        [{ 'header': '1' }, { 'header': '2' }, 'blockquote', 'code-block'],
        [{ 'list': 'ordered'}, { 'list': 'bullet' }, { 'indent': '-1'}, { 'indent': '+1' }],
        [{ 'direction': 'rtl' }, { 'align': [] }],
        ['link', 'image', 'video', 'formula'],
        ['clean']                                         // remove formatting button
    ]
};
const TextDetail = ({open, callback}) => {
    const {
        postDataValere,
        getDataValere,
        logout,
        setPopup500,
        setError499,
        setPopup499,
        user,
        getFormatedDate,
        setSearchResults,
        searchResults
    } = useAuth();
    const viewerContainer = useRef(null); // Create a ref to the container
    const [fileSidebar, setFileSidebar] = useState(false);
    const [fileInfo, setFileInfo] = useState(false);
    const [aiComment, setAiComment] = useState(false);
    // const [aiTagging, setEditText] = useState(false);
    const [editText, setEditText] = useState(false);
    const [containerReady, setContainerReady] = useState(false);

    const [isRightSidebar, setisRightSidebar] = useState(false);
    const [editClipModal, setEditClipModal] = useState(false);
    const [showImagePreview, setShowImagePreview] = useState(false);
    const [quillValue, setQuillValue] = useState('');


    useEffect(() => {
        if (fileSidebar) {
            document.body.classList.add('file-sidebar-open');
        } else {
            document.body.classList.remove('file-sidebar-open');
        }
    }, [fileSidebar]);

    const onCloseDrawer = (e) => {
        e.preventDefault();
        callback()
    };
    const onEditClipClose = (e) => {
        e.preventDefault();
        setFileInfo(false);
        setisRightSidebar(false);
        setAiComment(false);
    };

    const onEditText = (e) => {
        e.preventDefault()
        // setEditText(true);
        setEditText(!editText);
        setFileInfo(false);
        setAiComment(false);
        setisRightSidebar(false)
    };

    const onFileInfo = (e) => {
        e.preventDefault();
        setFileInfo(true);
        setEditText(false);
        setAiComment(false);
        setisRightSidebar(true);
    };
    const onAiComment = (e) => {
        e.preventDefault();
        setAiComment(true);
        setFileInfo(false);
        setEditText(false);
        setisRightSidebar(true);
    };
    const toggleFullscreen = () => {
        if (document.fullscreenElement) {
            document.exitFullscreen();
        } else if (viewerContainer.current) {
            viewerContainer.current.requestFullscreen();
        }
    };
    const customToolbar = (config) => {
        return config.concat([
            {
                key: 'customDownload',
                render: <img src={download} alt="img"/>,
                onClick: () => {
                    // alert('Download Button Clicked');
                }
            },
            {
                key: 'fullscreen',
                render: <img src={document.fullscreenElement ? minimized : fullscreen} alt="img"/>,
                onClick: toggleFullscreen
            }
        ]).map((item) => {
            if (item.key === 'zoomIn') {
                return { ...item, render: <img src={zoomIn} alt="img"/>};
            }
            if (item.key === 'zoomOut') {
                return { ...item, render: <img src={zoomOut} alt="img"/>};
            }
            return item;
        });
    };

    const videoPlayer = useRef(null);
    const [videoControls, setVideoControls] = useState(false);
    const [fileURL, setFileURL] = useState("");


    return (
        <>
            <Drawer
                title="Basic Drawer"
                placement={'bottom'}
                closable={false}
                onClose={onCloseDrawer}
                open={open}
                afterOpenChange={(o)=>{
                    setShowImagePreview(o)
                }}
            >
                <div className="ai-drawer-main">
                    <div className={`ai-left-sidebar ${!isRightSidebar ? 'active' : ''}`}>
                        <a href="#" className="open-drawer" onClick={(e) => {
                            e.preventDefault();
                            setisRightSidebar(false)
                        }}>
                            <img src={rightDrawerIcon} alt="icon"/>
                        </a>
                        <div className="ai-sidebar-wrapper">
                            <div className="title-wrapper">
                                <a href="#" className="back-icon" onClick={onCloseDrawer}><img src={backArrowIcon} alt="icon"/></a>
                                <h3>Project_Meeting.docs</h3>
                            </div>
                            <div className="form ai-search">
                                <div className="form-group">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter your search"
                                    />
                                    <img src={searchIcon} alt="icon" className="icon"/>
                                </div>
                            </div>
                            <div className="ai-tags-wrapper">
                                <p className="title">Auto-tags</p>
                                <ul className="ai-tags">
                                    <li><a href="#">Vel mattis</a></li>
                                    <li><a href="#">Laculis</a></li>
                                    <li><a href="#">Quam</a></li>
                                    <li><a href="#">Elementum</a></li>
                                    <li><a href="#">Praesent</a></li>
                                    <li><a href="#">Ligula cursus</a></li>
                                    <li><a href="#">Quam</a></li>
                                    <li><a href="#">Molestie</a></li>
                                </ul>
                            </div>
                            <div className="ai-copy-wrapper mb-0">
                                <div className="copy-title">
                                    <p className="title">Keywords</p>
                                    <a href="#" className="copy-icon"><img src={aiCopyIcon} alt="icon"/></a>
                                </div>
                                <div className="copy-body">
                                    <p>Lorem ipsum, dolor sit amet, consectetur adipiscind, sed tempor ante,
                                        eget tincidunt, nullam sed, nisi, vestibulum interdum. </p>
                                </div>
                            </div>
                            <div className="ai-copy-wrapper mb-0">
                                <div className="copy-title">
                                    <p className="title">Summary</p>
                                    <a href="#" className="copy-icon"><img src={aiCopyIcon} alt="icon"/></a>
                                </div>
                                <div className="copy-body">
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                        Sed tempor ante eget tincidunt efficitur. Nullam ac est
                                        sed nisi vestibulum interdum. Cras porta quam id nisl
                                        volutpat, vel cursus libero cursus. Integer ultricies auctor
                                        turpis, sed dictum justo faucibus at. Phasellus pretium tortor
                                        in urna fermentum, nec suscipit velit hendrerit. Lorem ipsum
                                        dolor sit amet, consectetur adipiscing elit. Sed tempor ante
                                        eget tincidunt efficitur. Cras porta quam id nisl volutpat,
                                        vel cursus libero cursus. Integer ultricies auctor turpis,
                                        sed dictum justo faucibus at. </p>
                                </div>
                            </div>
                            <div className="ai-copy-wrapper mb-0">
                                <div className="copy-title">
                                    <p className="title">Main Ideas</p>
                                    <a href="#" className="copy-icon"><img src={aiCopyIcon} alt="icon"/></a>
                                </div>
                                <div className="copy-body">
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed tempor ante eget tincidunt efficitur. Nullam ac est sed nisi vestibulum interdum.
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed tempor ante eget tincidunt efficitur. Nullam ac est sed nisi vestibulum interdum. </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={`ai-right-sidebar ${isRightSidebar ? 'active' : ''}`}>
                        {fileInfo && (
                            <div className="ai-sidebar-wrapper">
                                <div className="file-name-wrapper">
                                    <div className="title-wrapper">
                                        <h3 className="h3">File Name</h3>
                                        <a href="#" className="cross-icon" onClick={onEditClipClose}><img src={crossBlackIcon} alt="icon"/></a>
                                    </div>
                                    <div className="file-name-body">
                                        <ul className="btn-list">
                                            <li><a href="#" className="btn-style icon"><img src={infoWhiteIcon} alt="img"/>About the file</a></li>
                                            <li><a href="#" className="btn-style icon text"><img src={fileIcon} alt="img"/>All files</a></li>
                                        </ul>
                                        <div className="access-wrapper">
                                            <div className="access-header">
                                                <p className="p bold title">Who has access</p>
                                                <p className="small orange">Manage access</p>
                                            </div>
                                            <ul className="users">
                                                <li><a href="#"><img src={fileUserImg1} alt="img"/></a></li>
                                                <li><a href="#"><img src={fileUserImg2} alt="img"/></a></li>
                                                <li><a href="#"><img src={fileUserImg3} alt="img"/></a></li>
                                                <li><a href="#"><img src={fileUserImg4} alt="img"/></a></li>
                                                <li><a href="#"><img src={fileUserImg5} alt="img"/></a></li>
                                                <li><a href="#"><span className="count">+1</span></a></li>
                                            </ul>
                                            <p className="small">Owned by Patrick Amove. Shared with xxx, xxxx, xxxx, xxxx, and 1 other.</p>
                                        </div>
                                        <div className="information-wrapper">
                                            <p className="p bold title">General information</p>
                                            <ul className="info">
                                                <li>
                                                    <p className="small semi">Size:</p>
                                                    <p className="small">500 bytes</p>
                                                </li>
                                                <li>
                                                    <p className="small semi">Cloud:</p>
                                                    <p className="small">AWS</p>
                                                </li>
                                                <li>
                                                    <p className="small semi">Bucket:</p>
                                                    <p className="small">Bucket name</p>
                                                </li>
                                                <li>
                                                    <p className="small semi">Created:</p>
                                                    <p className="small">may 11th, 2023 at 09:14</p>
                                                </li>
                                                <li>
                                                    <p className="small semi">Modified:</p>
                                                    <p className="small">24 feb 2024 at 13:45 by Barry Allen</p>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="tags-wrapper">
                                            <p className="p bold title">Auto-tags</p>
                                            <ul className="tags">
                                                <li><a href="#">Quam</a></li>
                                                <li><a href="#">Quam</a></li>
                                                <li><a href="#">Quam</a></li>
                                                <li><a href="#">Quam</a></li>
                                                <li><a href="#">Quam</a></li>
                                                <li><a href="#">Quam</a></li>
                                                <li><a href="#">Quam</a></li>
                                                <li><a href="#">Quam</a></li>
                                                <li><a href="#">Quam</a></li>
                                                <li><a href="#">Quam</a></li>
                                            </ul>
                                        </div>
                                        <div className="tags-wrapper">
                                            <p className="p bold title">Tags</p>
                                            <div className="form">
                                                <div className="form-group">
                                                    <input type="text" className="form-control" placeholder="Enter your email"/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="tags-wrapper">
                                            <p className="p bold title">Comments</p>
                                            <div className="form">
                                                <div className="form-group">
                                                    <input type="text" className="form-control" placeholder="Add some comments"/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                        {aiComment && (
                            <div className="ai-sidebar-wrapper">
                                <div className="file-name-wrapper">
                                    <div className="title-wrapper">
                                        <h3 className="h3">Comments</h3>
                                        <a href="#" className="cross-icon" onClick={onEditClipClose}><img src={crossBlackIcon} alt="icon"/></a>
                                    </div>
                                    <div className="file-name-body">
                                        <div className="tags-wrapper">
                                            <div className="select-wrapper">
                                                <Select
                                                    defaultValue="Frame Time"
                                                    options={[
                                                        {value: 'All', label: 'All'},
                                                        {value: 'Start (0:00 to 2:00)', label: 'Start (0:00 to 2:00)'},
                                                        {value: 'Quarter (2:01 to 4:00)', label: 'Quarter (2:01 to 4:00)'},
                                                        {value: 'Middle (4:01 to 6:00)', label: 'Middle (4:01 to 6:00)'},
                                                        {value: 'Three-quarters (6:01 to 8:00)', label: 'Three-quarters (6:01 to 8:00)'},
                                                        {value: 'End (8:01: to 10:00)', label: 'End (8:01: to 10:00)'},
                                                    ]}
                                                />
                                            </div>
                                            <div className="comment-wrapper">
                                                <div className="comment-card">
                                                    <div className="img-wrapper">
                                                        <img src={userImg1} alt="icon"/>
                                                    </div>
                                                    <div className="content">
                                                        <p><strong>Isaac F.</strong> commented</p>
                                                        <ul className="option">
                                                            <li><span className="count">05:13</span></li>
                                                            <li><p>Good shot, I love it!</p></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="comment-card">
                                                    <div className="img-wrapper">
                                                        <img src={userImg1} alt="icon"/>
                                                    </div>
                                                    <div className="content">
                                                        <p><strong>Isaac F.</strong> commented</p>
                                                        <ul className="option">
                                                            <li><span className="count">05:13</span></li>
                                                            <li><p>Good shot, I love it!</p></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="comment-card">
                                                    <div className="img-wrapper">
                                                        <img src={userImg1} alt="icon"/>
                                                    </div>
                                                    <div className="content">
                                                        <p><strong>Isaac F.</strong> commented</p>
                                                        <ul className="option">
                                                            <li><span className="count">05:13</span></li>
                                                            <li><p>Good shot, I love it!</p></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                    <div id="scrollable" className={`ai-main-content padding-bottom-0  ${isRightSidebar ? 'right-active' : 'left-active'}`}>
                        <ul className="tool-menu">
                            <li><a href="#" onClick={onFileInfo} className={`${fileInfo ? 'active' : ''}`}><img src={fileInfoIcon} alt="icon"/>File information</a></li>
                            <li><a href="#" onClick={onEditText}><img src={editBlackIcon} style={{width: 14}} alt="icon"/>Edit</a></li>
                            <li><a href="#"><img src={globeBlackIcon}  alt="icon"/>Translate</a></li>
                            <li><a href="#"><img src={convertIcon}  alt="icon"/>Convert</a></li>
                        </ul>

                        <div className="docs-transcript">
                            <ReactQuill theme="snow" className={`${editText ? 'show-editor-toolbar' : ''}`} modules={modules} value={quillValue} onChange={setQuillValue} />
                            <ul className="custom-quill-toolbar">
                                <li className="m-8"><a href="#"><img src={searchGrayIcon} alt="icon"/></a></li>
                                <li><a href="#"><img src={quillArrowLeftIcon} alt="icon"/></a></li>
                                <li className="m-8"><p className="p">Page</p></li>
                                <li className="m-8"><p className="page-count">1</p></li>
                                <li className="m-8"><p className="p">of</p></li>
                                <li><p className="p">4</p></li>
                                <li><a href="#"><img src={quillArrowRightIcon} alt="icon"/></a></li>
                                <li><a href="#"><img src={zoomOutIcon} alt="icon"/></a></li>
                                <li><a href="#" className="zoom-progress"><span className="current-zoom"></span></a></li>
                                <li className="m-8"><a href="#"><img src={zoomINIcon} alt="icon"/></a></li>
                                <li><a href="#"><img src={minimizeIcon} alt="icon"/></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </Drawer>
        </>
    );
};

export default TextDetail;



