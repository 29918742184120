import React, {useState, useEffect} from "react";
import pageLogo from "../assets/images/logo.svg";
import pageLogoWhite from "../assets/images/logo-dark.svg";
import popupImage from "../assets/images/popup-image.jpg";
import {Modal} from 'antd';
import {useAuth} from "../context/AuthContext";
import config from "../config";

const AgentOffPopup = () => {
    const {agentIsRunning, token, isDark, checkAgent, agentPopupOpen, setAgentPopupOpen,
        setShowAgentOnce,
        isWindows,
        sendDataToMixPanel,
        user
    } = useAuth();
    const [showSingleButton, setShowSingleButton] = useState(false);

    useEffect(() => {
        if (!agentIsRunning) {
            setTimeout(function () {
                setAgentPopupOpen(true);
            }, 400)
        } else {
            setAgentPopupOpen(false);
        }
    }, [agentIsRunning]);
    const handleOk = () => {
        setAgentPopupOpen(false);
        setShowAgentOnce(false)
    };

    const handleCancel = () => {
        setAgentPopupOpen(false);
        setShowAgentOnce(false)
    };

    return (
        <>
            <Modal
                open={agentPopupOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                centered
                className="download-client-popup"
                footer={null}
                title={null}
            >
                <div className="cloud-drive-modal-wrapper">
                    <div className="row row-eq-height">
                        <div className="col-md-12 ">
                            <div className="left-side">
                                <div className="logo-img-wrapper">
                                    <img src={isDark ? pageLogoWhite : pageLogo} alt="logo"/>
                                </div>
                                <div className="content-wrapper text-center new-popup">
                                    <h3>Almost ready to start solving your storage problems?</h3>
                                    <p>If you have not install the CLICK desktop app please click <a
                                        href="https://www.amove.io/download/" target='_blank'>download</a></p>
                                    <p>Simply Launch CLICK to start connecting existing or provisioning Amove Perform,
                                        Global or Scale storage buckets.</p>
                                    <p>Share all you connections and storage as Drives direct to the desktop. <br/> Enable
                                        User, Teams and Projects to access content instantly, from anywhere.</p>
                                </div>
                                <div className="text-center launch-amove-btn">

                                    {config.environment === 'STG' && !showSingleButton &&
                                        <>
                                            {!isWindows &&
                                                <a href='#' onClick={(e) => {
                                                    e.preventDefault();
                                                    checkAgent(true)
                                                    setShowSingleButton(true)
                                                    sendDataToMixPanel('Amove-intel-STG downloaded.', user.username, {
                                                        'App': 'Web App'
                                                    })

                                                    // Create a temporary link element
                                                    const link = document.createElement('a');
                                                    link.href = "https://d2h7a876wzviri.cloudfront.net/Amove-intel-STG.pkg";
                                                    link.download = "Amove-Mac-latest.pkg";
                                                    link.target = "_blank";

                                                    // Append the link to the body
                                                    document.body.appendChild(link);

                                                    // Trigger the download by clicking the link
                                                    link.click();

                                                    // Remove the link from the document
                                                    document.body.removeChild(link);
                                                }} className="btn-style btn-30 invert">Download Amove</a>
                                            }
                                            {isWindows &&
                                                <a href='#' onClick={(e) => {
                                                    e.preventDefault();
                                                    checkAgent(true)
                                                    setShowSingleButton(true)
                                                    sendDataToMixPanel('Amove-64bit-STG downloaded.', user.username, {
                                                        'App': 'Web App'
                                                    })
                                                    // Create a temporary link element
                                                    const link = document.createElement('a');
                                                    link.href = "https://d2h7a876wzviri.cloudfront.net/Amove-64bit-STG.exe";
                                                    link.download = "Amove-Mac-latest.pkg";
                                                    link.target = "_blank";

                                                    // Append the link to the body
                                                    document.body.appendChild(link);

                                                    // Trigger the download by clicking the link
                                                    link.click();

                                                    // Remove the link from the document
                                                    document.body.removeChild(link);
                                                }} className="btn-style btn-30 invert">Download Amove</a>
                                            }
                                        </>
                                    }
                                    {config.environment !== 'STG' && !showSingleButton &&
                                        <>
                                            {!isWindows &&
                                                <a href='#' onClick={(e) => {
                                                    e.preventDefault();
                                                    checkAgent(true)
                                                    setShowSingleButton(true)
                                                    sendDataToMixPanel('Amove-Mac-latest downloaded.', user.username, {
                                                        'App': 'Web App'
                                                    })

                                                    // Create a temporary link element
                                                    const link = document.createElement('a');
                                                    link.href = "https://d2h7a876wzviri.cloudfront.net/Amove-Mac-latest.pkg";
                                                    link.download = "Amove-Mac-latest.pkg";
                                                    link.target = "_blank";

                                                    // Append the link to the body
                                                    document.body.appendChild(link);

                                                    // Trigger the download by clicking the link
                                                    link.click();

                                                    // Remove the link from the document
                                                    document.body.removeChild(link);
                                                }} className="btn-style btn-30 invert">Download Amove</a>
                                            }
                                            {isWindows &&
                                                <a href='#' onClick={(e) => {
                                                    e.preventDefault();
                                                    checkAgent(true)
                                                    setShowSingleButton(true)
                                                    sendDataToMixPanel('Amove-Win-latest downloaded.', user.username, {
                                                        'App': 'Web App'
                                                    })
                                                    // Create a temporary link element
                                                    const link = document.createElement('a');
                                                    link.href = "https://d2h7a876wzviri.cloudfront.net/Amove-Win-latest.exe";
                                                    link.download = "Amove-Mac-latest.pkg";
                                                    link.target = "_blank";

                                                    // Append the link to the body
                                                    document.body.appendChild(link);

                                                    // Trigger the download by clicking the link
                                                    link.click();

                                                    // Remove the link from the document
                                                    document.body.removeChild(link);



                                                }} className="btn-style btn-30 invert">Download Amove</a>
                                            }
                                        </>
                                    }

                                    <a href='#' onClick={(e) => {
                                        e.preventDefault();
                                        checkAgent(true)
                                        sendDataToMixPanel('Launch Amove', user.username, {
                                            'App': 'Web App'
                                        })
                                        window.location.href = `amoveapp://open?token=${token}`
                                    }} className={`btn-style btn-30 blue ${showSingleButton ? "large-btn" : ""}`}>Launch Amove</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default AgentOffPopup;
