import React, {useEffect, useState, useRef} from "react";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {LoadingOutlined} from '@ant-design/icons';
import {Popover, Checkbox, DatePicker, Select, Tooltip, Drawer, Modal, Spin} from 'antd';
import {useAuth} from "../context/AuthContext";
import orderByIcon from "../assets/images/ai-search/order-by-icon.svg";
import infoIcon from "../assets/images/ai-search/info-icon.svg";
import verticleDotsIcon from "../assets/images/ai-search/vertical-dot-icon.svg";
import previewIcon from "../assets/images/ai-search/preview-icon.svg";
import renameIcon from "../assets/images/ai-search/rename-icon.svg";
import editIcon from "../assets/images/ai-search/edit-icon.svg";
import copyIcon from "../assets/images/ai-search/copy-icon.svg";
import moveIcon from "../assets/images/ai-search/move-icon.svg";
import showDetailIcon from "../assets/images/ai-search/show-detail-icon.svg";
import showActivityIcon from "../assets/images/ai-search/show-activity-icon.svg";
import downloadIcon from "../assets/images/ai-search/download-icon.svg";
import deleteIcon from "../assets/images/ai-search/delete-icon.svg";
import rowIcon from "../assets/images/ai-search/row-icon.svg";
import gridIcon from "../assets/images/ai-search/grid-icon.svg";
import crossBlackIcon from "../assets/images/ai-search/cross-black-icon.svg";
import infoWhiteIcon from "../assets/images/ai-search/info-white-icon.svg";
import AiSearch from "../components/AiSearch/AiSearch";
import VideoDetail from "../components/AiSearch/VideoDetail";
import PhotoDetail from "../components/AiSearch/PhotoDetail";
import AudioDetail from "../components/AiSearch/AudioDetail";
import JSONView from "../components/AiSearch/JSONView";
import GifDetail from "../components/AiSearch/GifDetail";
import TextDetail from "../components/AiSearch/TextDetail";
import Comments from "../components/AiSearch/CommentsSection";

const SearchResult = () => {
    const {
        postDataValere,
        getDataValere,
        logout,
        setPopup500,
        setError499,
        setPopup499,
        user,
        getFormatedDate,
        setSearchResults,
        getFormatedDateFiles,
        searchResults,
        groupByValue,
        setGroupByValue,
        orderByValue,
        setOrderByValue
    } = useAuth();
    const location = useLocation();
    const [gridType, setGridType] = useState('grid');
    const [fileSidebar, setFileSidebar] = useState(false);
    const [tooltipVisible, setTooltipVisible] = useState(false);
    const [showVideoPreview, setShowVideoPreview] = useState(false);
    const [showPhotoPreview, setShowPhotoPreview] = useState(false);
    const [showAudioPreview, setShowAudioPreview] = useState(false);
    const [showGifPreview, setShowGifPreview] = useState(false);
    const [showTextPreview, setShowTextPreview] = useState(false);
    const [showJSONView, setShowJSONView] = useState(false);
    const [checkedValues, setCheckedValues] = useState([]);
    const [dataGroups, setDataGroups] = useState([]);
    const [gifShow, setGifShow] = useState(false);
    const [previewItem, setPreviewItem] = useState({});
    const [fileInfoLoaded, setFileInfoLoaded] = useState(false);
    useEffect(() => {
        if (fileSidebar) {
            document.body.classList.add('file-sidebar-open');
        } else {
            document.body.classList.remove('file-sidebar-open');
        }
    }, [fileSidebar]);
    useEffect(() => {
        console.log("searchResults Changed")
        console.log(groupByValue)
        console.log(searchResults)
      /*  if (searchResults.length > 0) {
            // Parse the query string to get data
            const queryParams = new URLSearchParams(window.location.search);
            console.log('Page was reloaded');
            console.log(queryParams);
            const fileId = queryParams.get("fileId");
            alert(fileId)
            if (fileId !== null
            ) {
               // alert(fileId)
            }
            console.log(fileId);
        }*/
        if (groupByValue !== "none" && searchResults?.data?.length > 0) {
            setDataGroups(groupCounts(searchResults.data, 'group'))
        } else {
            setDataGroups([])
        }
    }, [searchResults]);

    function updateQueryStringParameter(key, value) {
        const url = new URL(window.location.href);
        const params = new URLSearchParams(url.search);

        if (value !== "") {
            params.set(key, value); // Add or update parameter
        } else {
            params.delete(key); // Remove parameter
        }

        url.search = params.toString();
        window.history.pushState({}, '', url);
    }

    const onOpenSidebar = (e) => {
        e.preventDefault();
        getSelectedFileInfo()
        setFileSidebar(!fileSidebar)
    }

    const callbackVideoPreview = () => {
        setShowVideoPreview(false);
        updateQueryStringParameter("fileId", "")
    }

    const callbackPhotoPreview = () => {
        setShowPhotoPreview(false);
        updateQueryStringParameter("fileId", "")
    }
    const callbackAudioPreview = () => {
        setShowAudioPreview(false);
        updateQueryStringParameter("fileId", "")
    }
    const callbackGifPreview = () => {
        setShowGifPreview(false);
        updateQueryStringParameter("fileId", "")
    }
    const callbackTextPreview = () => {
        setShowTextPreview(false);
        updateQueryStringParameter("fileId", "")
    }
    const callbackJSONView = () => {
        setShowJSONView(false);
        updateQueryStringParameter("fileId", "")
    }
    const onSelectFile = (values) => {
        setCheckedValues(document.querySelectorAll('input[name="selectedFiles"]:checked').length);
        console.log(document.querySelectorAll('input[name="selectedFiles"]:checked').length)
        console.log(values)
    };

    const groupCounts = (array, key) => {
        // First, count occurrences using reduce
        const counts = array.reduce((acc, obj) => {
            const value = obj[key];
            acc[value] = (acc[value] || 0) + 1;
            return acc;
        }, {});

        // Convert the counts object into an array of objects
        return Object.keys(counts).map(value => ({
            value: value,
            count: counts[value]
        }));
    };
    const [fileType, setFileType] = useState("");
    const [fileSize, setFileSize] = useState("");
    const [fileResolution, setFileResolution] = useState("");
    const [fileCreated_at, setFileCreated_at] = useState("");
    const [fileUpdated_at, setFileUpdated_at] = useState("");
    const [fileModifiedBy, setFileModifiedBy] = useState("");
    const [autoTags, setAutoTags] = useState([]);
    const [comments, setComments] = useState([]);
    const [tags, setTags] = useState([]);
    const [speakers, setSpeakers] = useState([]);
    const [keywords, setKeywords] = useState("");
    const [transcript, setTranscript] = useState([]);
    const [commentsOnVideo, setCommentsOnVideo] = useState([]);
    const [showAddCommentsOnVideo, setShowAddCommentsOnVideo] = useState(false);
    const [currentCommentPosition, setCurrentCommentPosition] = useState("");
    const [fileId, setFileId] = useState("");
    const [fileName, setFileName] = useState("");
    const [fileURL, setFileURL] = useState("");
    const [fileDuration, setFileDuration] = useState("");
    const [fileSummary, setFileSummary] = useState("");
    const [fileBucketName, setFileBucketName] = useState("");
    const [fileCloudName, setFileCloudName] = useState("");


    const getSelectedFileInfo = () => {
        console.log(checkedValues)
        console.log(document.querySelector('input[name="selectedFiles"]:checked').value)
        var id = document.querySelector('input[name="selectedFiles"]:checked').value;
        getDataValere(`/file?file_id=${id}`)
            .then((res) => Promise.all([res.status, res.json()]))
            .then(([status, data]) => {
                console.log(data)
                if (status === 401) {
                    logout()
                    return;
                }
                if (status === 500) {
                    setPopup500(true)
                    return;
                }
                if (status === 499) {
                    var dataNew = JSON.parse(data)
                    var errorType = dataNew.type;
                    setError499(dataNew.errors[errorType][0])
                    setPopup499(true)
                    return;
                }

                setKeywords(data.keywords)
                setTranscript(data.transcriptions)
                setFileId(data.id)
                setFileName(data.path)
                setFileURL(data.url)
                setFileDuration(data.duration)
                setFileSize(data.size)
                setFileUpdated_at(data.updated_at)
                setFileResolution(data.resolution)
                setFileCreated_at(data.created_at)
                setFileModifiedBy(data.modified_by)
                setFileType(data.type)
                setFileBucketName(data.bucket_name)
                setFileInfoLoaded(true)

            })
        getDataValere(`/tags?file_id=${id}`)
            .then((res) => Promise.all([res.status, res.json()]))
            .then(([status, data]) => {
                console.log(data)
                if (status === 401) {
                    logout()
                    return;
                }
                if (status === 500) {
                    setPopup500(true)
                    return;
                }
                if (status === 499) {
                    var dataNew = JSON.parse(data)
                    var errorType = dataNew.type;
                    setError499(dataNew.errors[errorType][0])
                    setPopup499(true)
                    return;
                }
                setAutoTags(data)
            })
        getDataValere(`/comments?file_id=${id}`)
            .then((res) => Promise.all([res.status, res.json()]))
            .then(([status, data]) => {
                console.log(data)
                if (status === 401) {
                    logout()
                    return;
                }
                if (status === 500) {
                    setPopup500(true)
                    return;
                }
                if (status === 499) {
                    var dataNew = JSON.parse(data)
                    var errorType = dataNew.type;
                    setError499(dataNew.errors[errorType][0])
                    setPopup499(true)
                    return;
                }
                setComments(data)
            })
    }

    return (
        <>
            {showVideoPreview &&
                <VideoDetail open={showVideoPreview} callback={callbackVideoPreview} previewItem={previewItem}/>
            }
            {showJSONView &&
                <JSONView open={showJSONView} callback={callbackJSONView}/>
            }
            {showPhotoPreview &&
                <PhotoDetail open={showPhotoPreview} callback={callbackPhotoPreview} previewItem={previewItem}/>
            }
            {showAudioPreview &&
                <AudioDetail open={showAudioPreview} callback={callbackAudioPreview}/>
            }
            {showGifPreview &&
                <GifDetail open={showGifPreview} callback={callbackGifPreview}/>
            }
            {showTextPreview &&
                <TextDetail open={showTextPreview} callback={callbackTextPreview}/>
            }
            {/*<div className={`file-name-sidebar ${fileSidebar ? 'active' : ''}`}>
                <div className="file-name-wrapper">
                    <div className="title-wrapper">
                        <h3 className="h3">{fileName}</h3>
                        <a href="#" className="cross-icon" onClick={(e) => {
                            e.preventDefault();
                            setFileSidebar(false)
                        }}><img src={crossBlackIcon} alt="icon"/></a>
                    </div>
                    <div className="file-name-body">
                         <ul className="btn-list">
                            <li><a href="#" className="btn-style icon"><img src={infoWhiteIcon} alt="img"/>About the file</a></li>
                            <li><a href="#" className="btn-style icon text"><img src={fileIcon} alt="img"/>All files</a></li>
                        </ul>
                        <div className="access-wrapper">
                            <div className="access-header">
                                <p className="p bold title">Who has access</p>
                                <p className="small orange">Manage access</p>
                            </div>
                            <ul className="users">
                                <li><a href="#"><img src={fileUserImg1} alt="img"/></a></li>
                                <li><a href="#"><img src={fileUserImg2} alt="img"/></a></li>
                                <li><a href="#"><img src={fileUserImg3} alt="img"/></a></li>
                                <li><a href="#"><img src={fileUserImg4} alt="img"/></a></li>
                                <li><a href="#"><img src={fileUserImg5} alt="img"/></a></li>
                                <li><a href="#"><span className="count">+1</span></a></li>
                            </ul>
                            <p className="small">Owned by Patrick Amove. Shared with xxx, xxxx, xxxx, xxxx, and 1 other.</p>
                        </div>
                        <div className="information-wrapper">
                            <p className="p bold title">General information</p>
                            <ul className="info">
                                <li>
                                    <p className="small semi">Type:</p>
                                    <p className="small">{fileType}</p>
                                </li>
                                <li>
                                    <p className="small semi">Size:</p>
                                    <p className="small">{fileSize}</p>
                                </li>
                                <li>
                                    <p className="small semi">Resolution:</p>
                                    <p className="small">{fileResolution}</p>
                                </li>
                                <li>
                                    <p className="small semi">Cloud:</p>
                                    <p className="small">AWS</p>
                                </li>
                                <li>
                                    <p className="small semi">Bucket:</p>
                                    <p className="small">{fileBucketName}</p>
                                </li>
                                <li>
                                    <p className="small semi">Created:</p>
                                    <p className="small">{getFormatedDateFiles(fileCreated_at)}</p>
                                </li>
                                <li>
                                    <p className="small semi">Modified:</p>
                                    <p className="small">{getFormatedDateFiles(fileUpdated_at)} {fileModifiedBy !== "" ? ` by ${fileModifiedBy}` : ""}</p>
                                </li>
                            </ul>
                        </div>
                        <div className="tags-wrapper">
                            <p className="p bold title">Auto-tags</p>
                            <ul className="tags">
                                {autoTags.map((item) => (
                                    <li><a href="#" id={item.id}>{item.name}</a></li>
                                ))}
                            </ul>
                        </div>
                        <Comments data={comments}/>
                    </div>
                </div>
            </div>*/}

            <div className={`ai-right-sidebar ${fileSidebar ? 'active' : ''}`}>
                <div className="ai-sidebar-wrapper">
                    {fileInfoLoaded &&
                        <div className="file-name-wrapper">
                            <div className="title-wrapper">
                                <h3 className="h3">{fileName}</h3>
                                <a href="#" className="cross-icon" onClick={(e) => {
                                    e.preventDefault();
                                    setFileSidebar(false);
                                    setKeywords("")
                                    setTranscript("")
                                    setFileId("")
                                    setFileName("")
                                    setFileURL("")
                                    setFileDuration("")
                                    setFileSize("")
                                    setFileUpdated_at("")
                                    setFileResolution("")
                                    setFileCreated_at("")
                                    setFileModifiedBy("")
                                    setFileType("")
                                    setFileBucketName("")
                                    setFileInfoLoaded(false)
                                }}><img src={crossBlackIcon} alt="icon"/></a>
                            </div>
                            <div className="file-name-body">
                                <ul className="btn-list">
                                    <li><a href="#" className="btn-style icon"><img src={infoWhiteIcon} alt="img"/>About the file</a></li>
                                    {/*<li><a href="#" className="btn-style icon text"><img src={fileIcon} alt="img"/>All files</a></li>*/}
                                </ul>
                                <div className="information-wrapper">
                                    <p className="p bold title">General information</p>
                                    <ul className="info">
                                        <li>
                                            <p className="small semi">Type:</p>
                                            <p className="small">{fileType}</p>
                                        </li>
                                        <li>
                                            <p className="small semi">Size:</p>
                                            <p className="small">{fileSize}</p>
                                        </li>
                                        <li>
                                            <p className="small semi">Resolution:</p>
                                            <p className="small">{fileResolution}</p>
                                        </li>
                                        <li>
                                            <p className="small semi">Cloud:</p>
                                            <p className="small">AWS----</p>
                                        </li>
                                        <li>
                                            <p className="small semi">Bucket:</p>
                                            <p className="small">{fileBucketName}</p>
                                        </li>
                                        <li>
                                            <p className="small semi">Created:</p>
                                            <p className="small">{getFormatedDateFiles(fileCreated_at)}</p>
                                        </li>
                                        <li>
                                            <p className="small semi">Modified:</p>
                                            <p className="small">{getFormatedDateFiles(fileUpdated_at)} {fileModifiedBy !== "" ? ` by ${fileModifiedBy}` : ""}</p>
                                        </li>
                                    </ul>
                                </div>
                                <div className="tags-wrapper">
                                    <p className="p bold title">Tags</p>
                                    <ul className="tags">
                                        {autoTags.map((item) => (
                                            <li><a href="#" id={item.id}>{item.name}</a></li>
                                        ))}
                                    </ul>
                                </div>
                                <Comments data={comments}/>
                            </div>
                        </div>
                    }

                    {!fileInfoLoaded &&
                        <Spin className={"sideBar-loading"} indicator={<LoadingOutlined style={{fontSize: 22}} spin/>}/>
                    }
                </div>
            </div>
            <div id="main-content" className="no-header">
                <div className="container-fluid">
                    <div className="search-result-main">
                        <div className="search-result-wrapper">
                            <AiSearch location="results"/>
                            <div className="search-result-header">
                                <div className="row">
                                    <div className="col-md-3 align-self-center">
                                        <h3 className="h3 title">Search results</h3>
                                    </div>
                                    <div className="col-md-9 align-self-center">
                                        <ul className="options">
                                            {document.querySelectorAll('input[name="selectedFiles"]:checked').length === 1 &&
                                                <li><a href="#" onClick={onOpenSidebar}><img src={infoIcon} alt="icon"/></a></li>
                                            }

                                            <li>
                                                <div className="select-card">
                                                    <p>Group by</p>
                                                    <div className="select-wrapper">
                                                        <Select
                                                            onChange={(value) => {
                                                                setSearchResults([])
                                                                setDataGroups([])
                                                                setGroupByValue(value)
                                                            }}
                                                            value={groupByValue}
                                                            options={[
                                                                {value: 'none', label: 'None'},
                                                                {value: 'date', label: 'Modification date'},
                                                                /*{value: 'name', label: 'Name'},*/
                                                                {value: 'type', label: 'Type'},
                                                                /*{value: 'size', label: 'Size'},*/
                                                            ]}
                                                        />
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="select-card">
                                                    <p>Order by</p>
                                                    <div className="select-wrapper">
                                                        <img src={orderByIcon} className="icon" alt="icon"/>
                                                        <Select
                                                            className="icon-select"
                                                            onChange={(value) => {
                                                                setOrderByValue(value)
                                                            }}
                                                            value={orderByValue}
                                                            options={[
                                                                {value: 'none', label: 'None'},
                                                                {value: 'date', label: 'Modification date'},
                                                                {value: 'name', label: 'Name'},
                                                                {value: 'type', label: 'Type'},
                                                                {value: 'size', label: 'Size'},
                                                            ]}
                                                        />
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="ai-tooltip-v1">
                                                    <Tooltip
                                                        getPopupContainer={trigger => trigger.parentNode}
                                                        trigger="click"
                                                        placement="bottomLeft"
                                                        visible={tooltipVisible}
                                                        onVisibleChange={(visible) => setTooltipVisible(visible)}
                                                        title={
                                                            <div className="popup-options">
                                                                <p className="title">Design</p>
                                                                <ul className="view-list">
                                                                    <li><a href="#" onClick={(e) => {
                                                                        e.preventDefault();
                                                                        setGridType('row');
                                                                        setTooltipVisible(false);
                                                                    }}><img src={rowIcon} alt="icon"/>Rows</a></li>
                                                                    <li><a href="#" onClick={(e) => {
                                                                        e.preventDefault();
                                                                        setGridType('grid');
                                                                        setTooltipVisible(false);
                                                                    }}><img src={gridIcon} alt="icon"/>Grid</a></li>
                                                                </ul>
                                                            </div>
                                                        }
                                                    >
                                                        <a href="#"><img src={gridType === 'row' ? gridIcon : rowIcon} alt="icon"/></a>
                                                    </Tooltip>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            {(gridType === 'grid') &&
                                <div className="search-result-body grid-body">
                                    <div className="result-body-card">
                                        {groupByValue === "none" &&
                                            /*<h3 className="title fk-font">Last Week</h3>*/
                                            <div className="result-card-wrapper">
                                                {searchResults?.data?.length > 0 && searchResults?.data?.map((item, index) => (
                                                    <div className="result-card-main">
                                                        {/* <input type="checkbox" value={item.id} name="selectedFiles" onChange={() => onSelectFile(item.id)} id={`result-card-${index}`}/>
                                                       <label htmlFor={`result-card-${index}`} className="result-card">
                                                       */}
                                                        {/*<div className="result-options">
                                                            <div className="ai-tooltip-v1">
                                                                <Tooltip
                                                                    getPopupContainer={trigger => trigger.parentNode}
                                                                    trigger="click"
                                                                    placement="bottomLeft"
                                                                    title={
                                                                        <div className="popup-options">
                                                                            <p className="title">{item.type}</p>
                                                                            <ul className="view-list">
                                                                                <li><a href="#" onClick={(e) => {
                                                                                    e.preventDefault()
                                                                                    setPreviewItem(item)
                                                                                    updateQueryStringParameter("fileId", item.id)
                                                                                    if (item.type === "video") {
                                                                                        setShowVideoPreview(true)
                                                                                    }
                                                                                    if (item.type === "image") {
                                                                                        setShowPhotoPreview(true);
                                                                                    }
                                                                                    if (item.type === "audio") {
                                                                                        setShowAudioPreview(true);
                                                                                    }
                                                                                    if (item.type === "code") {
                                                                                        setShowJSONView(true);
                                                                                    }
                                                                                    if (item.type === "image") {
                                                                                        // setShowGifPreview(true);
                                                                                    }
                                                                                    if (item.type === "docs") {
                                                                                        setShowTextPreview(true);
                                                                                    }
                                                                                }}><img src={previewIcon} alt="icon"/>Preview</a>
                                                                                </li>
                                                                                <li className="disabled"><a
                                                                                    href="#"><img src={renameIcon}
                                                                                                  alt="icon"/>Rename</a>
                                                                                </li>
                                                                                <li><a href="#"><img src={editIcon}
                                                                                                     alt="icon"/>Edit</a>
                                                                                </li>
                                                                                <li><a href="#"><img src={copyIcon}
                                                                                                     alt="icon"/>Make a
                                                                                    copy</a></li>
                                                                                <li><a href="#"><img src={moveIcon}
                                                                                                     alt="icon"/>Move</a>
                                                                                </li>
                                                                                <li className=""><a href="#"
                                                                                                    onClick={onOpenSidebar}><img
                                                                                    src={showDetailIcon} alt="icon"/>Show
                                                                                    details</a></li>
                                                                                <li className="disabled"><a
                                                                                    href="#"><img src={showActivityIcon}
                                                                                                  alt="icon"/>Show
                                                                                    activity log</a></li>
                                                                                <li><a href="#"><img src={downloadIcon}
                                                                                                     alt="icon"/>Download</a>
                                                                                </li>
                                                                                <li><a href="#"><img src={deleteIcon}
                                                                                                     className="orange"
                                                                                                     alt="icon"/>Delete</a>
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                    }
                                                                >
                                                                    <a href="#" className={"result-card-option"}><img
                                                                        src={verticleDotsIcon} alt="icon"/></a>
                                                                </Tooltip>
                                                            </div>
                                                        </div>*/}
                                                        <a href="#" id={`result-card-${index}`} className="result-card"
                                                           onClick={(e) => {
                                                               e.preventDefault();
                                                               setPreviewItem(item)
                                                               updateQueryStringParameter("fileId", item.id)
                                                               if (item.type === "video") {
                                                                   setShowVideoPreview(true)
                                                               }
                                                               if (item.type === "image") {
                                                                   setShowPhotoPreview(true);
                                                               }
                                                               if (item.type === "audio") {
                                                                   setShowAudioPreview(true);
                                                               }
                                                               if (item.type === "code") {
                                                                   setShowJSONView(true);
                                                               }
                                                               if (item.type === "image") {
                                                                   // setShowGifPreview(true);
                                                               }
                                                               if (item.type === "docs") {
                                                                   setShowTextPreview(true);
                                                               }
                                                           }}>
                                                            <div className="img-wrapper">
                                                                <img src={item.thumbnail} alt={item.name}/>
                                                            </div>
                                                            <div className="content-wrapper">
                                                                <p>{item.name}</p>
                                                            </div>
                                                        </a>
                                                    </div>
                                                ))
                                                }
                                            </div>
                                        }
                                        {groupByValue !== "none" &&
                                            <>
                                                {dataGroups.map(group => (
                                                    <>
                                                        <h3 className="title fk-font">{group.value}</h3>
                                                        <div className="result-card-wrapper">
                                                            {searchResults?.data?.length > 0 && searchResults?.data?.filter((item) => item.group === group.value).map((item) => item.content.map((item, index) => (
                                                                <div className="result-card-main">
                                                                    {/*<div className="result-options">
                                                            <div className="ai-tooltip-v1">
                                                                <Tooltip
                                                                    getPopupContainer={trigger => trigger.parentNode}
                                                                    trigger="click"
                                                                    placement="bottomLeft"
                                                                    title={
                                                                        <div className="popup-options">
                                                                            <p className="title">{item.type}</p>
                                                                            <ul className="view-list">
                                                                                <li><a href="#" onClick={(e) => {
                                                                                    e.preventDefault()
                                                                                    setPreviewItem(item)
                                                                                    updateQueryStringParameter("fileId", item.id)
                                                                                    if (item.type === "video") {
                                                                                        setShowVideoPreview(true)
                                                                                    }
                                                                                    if (item.type === "image") {
                                                                                        setShowPhotoPreview(true);
                                                                                    }
                                                                                    if (item.type === "audio") {
                                                                                        setShowAudioPreview(true);
                                                                                    }
                                                                                    if (item.type === "code") {
                                                                                        setShowJSONView(true);
                                                                                    }
                                                                                    if (item.type === "image") {
                                                                                        // setShowGifPreview(true);
                                                                                    }
                                                                                    if (item.type === "docs") {
                                                                                        setShowTextPreview(true);
                                                                                    }
                                                                                }}><img src={previewIcon} alt="icon"/>Preview</a>
                                                                                </li>
                                                                                <li className="disabled"><a
                                                                                    href="#"><img src={renameIcon}
                                                                                                  alt="icon"/>Rename</a>
                                                                                </li>
                                                                                <li><a href="#"><img src={editIcon}
                                                                                                     alt="icon"/>Edit</a>
                                                                                </li>
                                                                                <li><a href="#"><img src={copyIcon}
                                                                                                     alt="icon"/>Make a
                                                                                    copy</a></li>
                                                                                <li><a href="#"><img src={moveIcon}
                                                                                                     alt="icon"/>Move</a>
                                                                                </li>
                                                                                <li className=""><a href="#"
                                                                                                    onClick={onOpenSidebar}><img
                                                                                    src={showDetailIcon} alt="icon"/>Show
                                                                                    details</a></li>
                                                                                <li className="disabled"><a
                                                                                    href="#"><img src={showActivityIcon}
                                                                                                  alt="icon"/>Show
                                                                                    activity log</a></li>
                                                                                <li><a href="#"><img src={downloadIcon}
                                                                                                     alt="icon"/>Download</a>
                                                                                </li>
                                                                                <li><a href="#"><img src={deleteIcon}
                                                                                                     className="orange"
                                                                                                     alt="icon"/>Delete</a>
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                    }
                                                                >
                                                                    <a href="#" className={"result-card-option"}><img
                                                                        src={verticleDotsIcon} alt="icon"/></a>
                                                                </Tooltip>
                                                            </div>
                                                        </div>*/}
                                                                    <a href="#" id={`result-card-${index}`} className="result-card"
                                                                       onClick={(e) => {
                                                                           e.preventDefault();
                                                                           setPreviewItem(item)
                                                                           updateQueryStringParameter("fileId", item.id)
                                                                           if (item.type === "video") {
                                                                               setShowVideoPreview(true)
                                                                           }
                                                                           if (item.type === "image") {
                                                                               setShowPhotoPreview(true);
                                                                           }
                                                                           if (item.type === "audio") {
                                                                               setShowAudioPreview(true);
                                                                           }
                                                                           if (item.type === "code") {
                                                                               setShowJSONView(true);
                                                                           }
                                                                           if (item.type === "image") {
                                                                               // setShowGifPreview(true);
                                                                           }
                                                                           if (item.type === "docs") {
                                                                               setShowTextPreview(true);
                                                                           }
                                                                       }}>
                                                                        <div className="img-wrapper">
                                                                            <img src={item.thumbnail} alt={item.name}/>
                                                                        </div>
                                                                        <div className="content-wrapper">
                                                                            <p>{item.name}</p>
                                                                        </div>
                                                                    </a>
                                                                </div>
                                                            )))
                                                            }
                                                        </div>
                                                    </>
                                                ))
                                                }
                                            </>
                                        }
                                    </div>
                                </div>
                            }
                            {(gridType === 'row') &&
                                <div className="search-result-body row-body">
                                    <div className={`result-table-main ${fileSidebar ? 'sidebar-opened' : ''}`}>
                                        <h3 className="title">Last Week</h3>
                                        {searchResults?.data?.length > 0 &&
                                            <div className="table-wrapper">
                                                <div className="table-header grid-4">
                                                    <p>Name</p>
                                                    <p>Type</p>
                                                    <p>File size</p>
                                                    <p></p>
                                                </div>
                                                <div className="table-body position-relative">
                                                    {searchResults.data.map((item, index) => (
                                                        <div className="table-row grid-4">
                                                            <div className="table-item">
                                                                <a href="#" className="name-wrapper"  onClick={(e) => {
                                                                    e.preventDefault()
                                                                    setPreviewItem(item)
                                                                    if (item.type === "video") {
                                                                        setShowVideoPreview(true)
                                                                    }
                                                                    if (item.type === "image") {
                                                                        setShowPhotoPreview(true);
                                                                    }
                                                                    if (item.type === "audio") {
                                                                        setShowAudioPreview(true);
                                                                    }
                                                                    if (item.type === "code") {
                                                                        setShowJSONView(true);
                                                                    }
                                                                    if (item.type === "image") {
                                                                        // setShowGifPreview(true);
                                                                    }
                                                                    if (item.type === "docs") {
                                                                        setShowTextPreview(true);
                                                                    }
                                                                }}>
                                                                    <img src={item.thumbnail} className="bucket-icon" alt={item.name}/>
                                                                    <p>{item.name}</p>
                                                                </a>
                                                            </div>

                                                            <div className="table-item">
                                                                <p>{item.type}</p>
                                                            </div>
                                                            <div className="table-item">
                                                               {/* <p>10 MB</p>*/}
                                                            </div>
                                                            <div className="table-item">
                                                               {/* <ul className="options">
                                                                    <li>
                                                                        <div className="ai-tooltip-v1">

                                                                            <Tooltip
                                                                                getPopupContainer={trigger => trigger.parentNode}
                                                                                trigger="click"
                                                                                placement="bottomLeft"
                                                                                title={
                                                                                    <div className="popup-options">
                                                                                        <p className="title">{item.type}</p>
                                                                                        <ul className="view-list">
                                                                                            <li><a href="#" onClick={(e) => {
                                                                                                e.preventDefault()
                                                                                                setPreviewItem(item)
                                                                                                if (item.type === "video") {
                                                                                                    setShowVideoPreview(true)
                                                                                                }
                                                                                                if (item.type === "image") {
                                                                                                    setShowPhotoPreview(true);
                                                                                                }
                                                                                                if (item.type === "audio") {
                                                                                                    setShowAudioPreview(true);
                                                                                                }
                                                                                                if (item.type === "code") {
                                                                                                    setShowJSONView(true);
                                                                                                }
                                                                                                if (item.type === "image") {
                                                                                                    // setShowGifPreview(true);
                                                                                                }
                                                                                                if (item.type === "docs") {
                                                                                                    setShowTextPreview(true);
                                                                                                }
                                                                                            }}><img src={previewIcon} alt="icon"/>Preview</a></li>
                                                                                            <li className="disabled"><a href="#"><img src={renameIcon} alt="icon"/>Rename</a></li>
                                                                                            <li><a href="#"><img src={editIcon} alt="icon"/>Edit</a></li>
                                                                                            <li><a href="#"><img src={copyIcon} alt="icon"/>Make a copy</a></li>
                                                                                            <li><a href="#"><img src={moveIcon} alt="icon"/>Move</a></li>
                                                                                            <li className=""><a href="#" onClick={onOpenSidebar}><img src={showDetailIcon} alt="icon"/>Show details</a></li>
                                                                                            <li className="disabled"><a href="#"><img src={showActivityIcon} alt="icon"/>Show activity log</a></li>
                                                                                            <li><a href="#"><img src={downloadIcon} alt="icon"/>Download</a></li>
                                                                                            <li><a href="#"><img src={deleteIcon} className="orange" alt="icon"/>Delete</a></li>
                                                                                        </ul>
                                                                                    </div>
                                                                                }
                                                                            >
                                                                                <a href="#" className={"result-card-option"}><img src={verticleDotsIcon} alt="icon"/></a>
                                                                            </Tooltip>
                                                                        </div>
                                                                    </li>
                                                                </ul>*/}
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        }
                                    </div>
                                    {/* <div className="result-table-main">
                                        <h3 className="title">Last Mont</h3>
                                        <div className="table-wrapper">
                                            <div className="table-header grid-4">
                                                <p>Name</p>
                                                <p>Type</p>
                                                <p>File size</p>
                                                <p></p>
                                            </div>
                                            <div className="table-body position-relative">
                                                <div className="table-row grid-4">
                                                    <div className="table-item">
                                                        <div className="name-wrapper">
                                                            <img src={resultImg2} className="bucket-icon" alt="icon"/>
                                                            <p>riley-photo.jpg</p>
                                                        </div>
                                                    </div>
                                                    <div className="table-item">
                                                        <p>Type name</p>
                                                    </div>
                                                    <div className="table-item">
                                                        <p>10 MB</p>
                                                    </div>
                                                    <div className="table-item">
                                                        <ul className="options">
                                                            <li>
                                                                <div className="ai-tooltip-v1">
                                                                    <Tooltip
                                                                        getPopupContainer={trigger => trigger.parentNode}
                                                                        trigger="click"
                                                                        placement="bottomLeft"
                                                                        title={
                                                                            <div className="popup-options">
                                                                                <p className="title">Design</p>
                                                                                <ul className="view-list">
                                                                                    <li className="disabled"><a href="#"><img src={previewIcon} alt="icon"/>Preview</a></li>
                                                                                    <li className="disabled"><a href="#"><img src={renameIcon} alt="icon"/>Rename</a></li>
                                                                                    <li><a href="#"><img src={editIcon} alt="icon"/>Edit</a></li>
                                                                                    <li><a href="#"><img src={copyIcon} alt="icon"/>Make a copy</a></li>
                                                                                    <li><a href="#"><img src={moveIcon} alt="icon"/>Move</a></li>
                                                                                    <li className="disabled"><a href="#"><img src={showDetailIcon} alt="icon"/>Show details</a></li>
                                                                                    <li className="disabled"><a href="#"><img src={showActivityIcon} alt="icon"/>Show activity log</a></li>
                                                                                    <li><a href="#"><img src={downloadIcon} alt="icon"/>Download</a></li>
                                                                                    <li><a href="#"><img src={deleteIcon} className="orange" alt="icon"/>Delete</a></li>
                                                                                </ul>
                                                                            </div>
                                                                        }
                                                                    >
                                                                        <a href="#"><img src={verticleDotsIcon} alt="icon"/></a>
                                                                    </Tooltip>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="table-row grid-4">
                                                    <div className="table-item">
                                                        <div className="name-wrapper">
                                                            <img src={resultImg2} className="bucket-icon" alt="icon"/>
                                                            <p>riley-photo.jpg</p>
                                                        </div>
                                                    </div>
                                                    <div className="table-item">
                                                        <p>Type name</p>
                                                    </div>
                                                    <div className="table-item">
                                                        <p>10 MB</p>
                                                    </div>
                                                    <div className="table-item">
                                                        <ul className="options">
                                                            <li>
                                                                <div className="ai-tooltip-v1">
                                                                    <Tooltip
                                                                        getPopupContainer={trigger => trigger.parentNode}
                                                                        trigger="click"
                                                                        placement="bottomLeft"
                                                                        title={
                                                                            <div className="popup-options">
                                                                                <p className="title">Design</p>
                                                                                <ul className="view-list">
                                                                                    <li className="disabled"><a href="#"><img src={previewIcon} alt="icon"/>Preview</a></li>
                                                                                    <li className="disabled"><a href="#"><img src={renameIcon} alt="icon"/>Rename</a></li>
                                                                                    <li><a href="#"><img src={editIcon} alt="icon"/>Edit</a></li>
                                                                                    <li><a href="#"><img src={copyIcon} alt="icon"/>Make a copy</a></li>
                                                                                    <li><a href="#"><img src={moveIcon} alt="icon"/>Move</a></li>
                                                                                    <li className="disabled"><a href="#"><img src={showDetailIcon} alt="icon"/>Show details</a></li>
                                                                                    <li className="disabled"><a href="#"><img src={showActivityIcon} alt="icon"/>Show activity log</a></li>
                                                                                    <li><a href="#"><img src={downloadIcon} alt="icon"/>Download</a></li>
                                                                                    <li><a href="#"><img src={deleteIcon} className="orange" alt="icon"/>Delete</a></li>
                                                                                </ul>
                                                                            </div>
                                                                        }
                                                                    >
                                                                        <a href="#"><img src={verticleDotsIcon} alt="icon"/></a>
                                                                    </Tooltip>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="table-row grid-4">
                                                    <div className="table-item">
                                                        <div className="name-wrapper">
                                                            <img src={resultImg2} className="bucket-icon" alt="icon"/>
                                                            <p>riley-photo.jpg</p>
                                                        </div>
                                                    </div>
                                                    <div className="table-item">
                                                        <p>Type name</p>
                                                    </div>
                                                    <div className="table-item">
                                                        <p>10 MB</p>
                                                    </div>
                                                    <div className="table-item">
                                                        <ul className="options">
                                                            <li>
                                                                <div className="ai-tooltip-v1">
                                                                    <Tooltip
                                                                        getPopupContainer={trigger => trigger.parentNode}
                                                                        trigger="click"
                                                                        placement="bottomLeft"
                                                                        title={
                                                                            <div className="popup-options">
                                                                                <p className="title">Design</p>
                                                                                <ul className="view-list">
                                                                                    <li className="disabled"><a href="#"><img src={previewIcon} alt="icon"/>Preview</a></li>
                                                                                    <li className="disabled"><a href="#"><img src={renameIcon} alt="icon"/>Rename</a></li>
                                                                                    <li><a href="#"><img src={editIcon} alt="icon"/>Edit</a></li>
                                                                                    <li><a href="#"><img src={copyIcon} alt="icon"/>Make a copy</a></li>
                                                                                    <li><a href="#"><img src={moveIcon} alt="icon"/>Move</a></li>
                                                                                    <li className="disabled"><a href="#"><img src={showDetailIcon} alt="icon"/>Show details</a></li>
                                                                                    <li className="disabled"><a href="#"><img src={showActivityIcon} alt="icon"/>Show activity log</a></li>
                                                                                    <li><a href="#"><img src={downloadIcon} alt="icon"/>Download</a></li>
                                                                                    <li><a href="#"><img src={deleteIcon} className="orange" alt="icon"/>Delete</a></li>
                                                                                </ul>
                                                                            </div>
                                                                        }
                                                                    >
                                                                        <a href="#"><img src={verticleDotsIcon} alt="icon"/></a>
                                                                    </Tooltip>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="table-row grid-4">
                                                    <div className="table-item">
                                                        <div className="name-wrapper">
                                                            <img src={resultImg2} className="bucket-icon" alt="icon"/>
                                                            <p>riley-photo.jpg</p>
                                                        </div>
                                                    </div>
                                                    <div className="table-item">
                                                        <p>Type name</p>
                                                    </div>
                                                    <div className="table-item">
                                                        <p>10 MB</p>
                                                    </div>
                                                    <div className="table-item">
                                                        <ul className="options">
                                                            <li>
                                                                <div className="ai-tooltip-v1">
                                                                    <Tooltip
                                                                        getPopupContainer={trigger => trigger.parentNode}
                                                                        trigger="click"
                                                                        placement="bottomLeft"
                                                                        title={
                                                                            <div className="popup-options">
                                                                                <p className="title">Design</p>
                                                                                <ul className="view-list">
                                                                                    <li className="disabled"><a href="#"><img src={previewIcon} alt="icon"/>Preview</a></li>
                                                                                    <li className="disabled"><a href="#"><img src={renameIcon} alt="icon"/>Rename</a></li>
                                                                                    <li><a href="#"><img src={editIcon} alt="icon"/>Edit</a></li>
                                                                                    <li><a href="#"><img src={copyIcon} alt="icon"/>Make a copy</a></li>
                                                                                    <li><a href="#"><img src={moveIcon} alt="icon"/>Move</a></li>
                                                                                    <li className="disabled"><a href="#"><img src={showDetailIcon} alt="icon"/>Show details</a></li>
                                                                                    <li className="disabled"><a href="#"><img src={showActivityIcon} alt="icon"/>Show activity log</a></li>
                                                                                    <li><a href="#"><img src={downloadIcon} alt="icon"/>Download</a></li>
                                                                                    <li><a href="#"><img src={deleteIcon} className="orange" alt="icon"/>Delete</a></li>
                                                                                </ul>
                                                                            </div>
                                                                        }
                                                                    >
                                                                        <a href="#"><img src={verticleDotsIcon} alt="icon"/></a>
                                                                    </Tooltip>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>

                                                <div className="table-row grid-4">
                                                    <div className="table-item">
                                                        <div className="name-wrapper">
                                                            <img src={resultImg2} className="bucket-icon" alt="icon"/>
                                                            <p>riley-photo.jpg</p>
                                                        </div>
                                                    </div>
                                                    <div className="table-item">
                                                        <p>Type name</p>
                                                    </div>
                                                    <div className="table-item">
                                                        <p>10 MB</p>
                                                    </div>
                                                    <div className="table-item">
                                                        <ul className="options">
                                                            <li>
                                                                <div className="ai-tooltip-v1">
                                                                    <Tooltip
                                                                        getPopupContainer={trigger => trigger.parentNode}
                                                                        trigger="click"
                                                                        placement="bottomLeft"
                                                                        title={
                                                                            <div className="popup-options">
                                                                                <p className="title">Design</p>
                                                                                <ul className="view-list">
                                                                                    <li className="disabled"><a href="#"><img src={previewIcon} alt="icon"/>Preview</a></li>
                                                                                    <li className="disabled"><a href="#"><img src={renameIcon} alt="icon"/>Rename</a></li>
                                                                                    <li><a href="#"><img src={editIcon} alt="icon"/>Edit</a></li>
                                                                                    <li><a href="#"><img src={copyIcon} alt="icon"/>Make a copy</a></li>
                                                                                    <li><a href="#"><img src={moveIcon} alt="icon"/>Move</a></li>
                                                                                    <li className="disabled"><a href="#"><img src={showDetailIcon} alt="icon"/>Show details</a></li>
                                                                                    <li className="disabled"><a href="#"><img src={showActivityIcon} alt="icon"/>Show activity log</a></li>
                                                                                    <li><a href="#"><img src={downloadIcon} alt="icon"/>Download</a></li>
                                                                                    <li><a href="#"><img src={deleteIcon} className="orange" alt="icon"/>Delete</a></li>
                                                                                </ul>
                                                                            </div>
                                                                        }
                                                                    >
                                                                        <a href="#"><img src={verticleDotsIcon} alt="icon"/></a>
                                                                    </Tooltip>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="table-row grid-4">
                                                    <div className="table-item">
                                                        <div className="name-wrapper">
                                                            <img src={resultImg2} className="bucket-icon" alt="icon"/>
                                                            <p>riley-photo.jpg</p>
                                                        </div>
                                                    </div>
                                                    <div className="table-item">
                                                        <p>Type name</p>
                                                    </div>
                                                    <div className="table-item">
                                                        <p>10 MB</p>
                                                    </div>
                                                    <div className="table-item">
                                                        <ul className="options">
                                                            <li>
                                                                <div className="ai-tooltip-v1">
                                                                    <Tooltip
                                                                        getPopupContainer={trigger => trigger.parentNode}
                                                                        trigger="click"
                                                                        placement="bottomLeft"
                                                                        title={
                                                                            <div className="popup-options">
                                                                                <p className="title">Design</p>
                                                                                <ul className="view-list">
                                                                                    <li className="disabled"><a href="#"><img src={previewIcon} alt="icon"/>Preview</a></li>
                                                                                    <li className="disabled"><a href="#"><img src={renameIcon} alt="icon"/>Rename</a></li>
                                                                                    <li><a href="#"><img src={editIcon} alt="icon"/>Edit</a></li>
                                                                                    <li><a href="#"><img src={copyIcon} alt="icon"/>Make a copy</a></li>
                                                                                    <li><a href="#"><img src={moveIcon} alt="icon"/>Move</a></li>
                                                                                    <li className="disabled"><a href="#"><img src={showDetailIcon} alt="icon"/>Show details</a></li>
                                                                                    <li className="disabled"><a href="#"><img src={showActivityIcon} alt="icon"/>Show activity log</a></li>
                                                                                    <li><a href="#"><img src={downloadIcon} alt="icon"/>Download</a></li>
                                                                                    <li><a href="#"><img src={deleteIcon} className="orange" alt="icon"/>Delete</a></li>
                                                                                </ul>
                                                                            </div>
                                                                        }
                                                                    >
                                                                        <a href="#"><img src={verticleDotsIcon} alt="icon"/></a>
                                                                    </Tooltip>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="table-row grid-4">
                                                    <div className="table-item">
                                                        <div className="name-wrapper">
                                                            <img src={resultImg2} className="bucket-icon" alt="icon"/>
                                                            <p>riley-photo.jpg</p>
                                                        </div>
                                                    </div>
                                                    <div className="table-item">
                                                        <p>Type name</p>
                                                    </div>
                                                    <div className="table-item">
                                                        <p>10 MB</p>
                                                    </div>
                                                    <div className="table-item">
                                                        <ul className="options">
                                                            <li>
                                                                <div className="ai-tooltip-v1">
                                                                    <Tooltip
                                                                        getPopupContainer={trigger => trigger.parentNode}
                                                                        trigger="click"
                                                                        placement="bottomLeft"
                                                                        title={
                                                                            <div className="popup-options">
                                                                                <p className="title">Design</p>
                                                                                <ul className="view-list">
                                                                                    <li className="disabled"><a href="#"><img src={previewIcon} alt="icon"/>Preview</a></li>
                                                                                    <li className="disabled"><a href="#"><img src={renameIcon} alt="icon"/>Rename</a></li>
                                                                                    <li><a href="#"><img src={editIcon} alt="icon"/>Edit</a></li>
                                                                                    <li><a href="#"><img src={copyIcon} alt="icon"/>Make a copy</a></li>
                                                                                    <li><a href="#"><img src={moveIcon} alt="icon"/>Move</a></li>
                                                                                    <li className="disabled"><a href="#"><img src={showDetailIcon} alt="icon"/>Show details</a></li>
                                                                                    <li className="disabled"><a href="#"><img src={showActivityIcon} alt="icon"/>Show activity log</a></li>
                                                                                    <li><a href="#"><img src={downloadIcon} alt="icon"/>Download</a></li>
                                                                                    <li><a href="#"><img src={deleteIcon} className="orange" alt="icon"/>Delete</a></li>
                                                                                </ul>
                                                                            </div>
                                                                        }
                                                                    >
                                                                        <a href="#"><img src={verticleDotsIcon} alt="icon"/></a>
                                                                    </Tooltip>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="table-row grid-4">
                                                    <div className="table-item">
                                                        <div className="name-wrapper">
                                                            <img src={resultImg2} className="bucket-icon" alt="icon"/>
                                                            <p>riley-photo.jpg</p>
                                                        </div>
                                                    </div>
                                                    <div className="table-item">
                                                        <p>Type name</p>
                                                    </div>
                                                    <div className="table-item">
                                                        <p>10 MB</p>
                                                    </div>
                                                    <div className="table-item">
                                                        <ul className="options">
                                                            <li>
                                                                <div className="ai-tooltip-v1">
                                                                    <Tooltip
                                                                        getPopupContainer={trigger => trigger.parentNode}
                                                                        trigger="click"
                                                                        placement="bottomLeft"
                                                                        title={
                                                                            <div className="popup-options">
                                                                                <p className="title">Design</p>
                                                                                <ul className="view-list">
                                                                                    <li className="disabled"><a href="#"><img src={previewIcon} alt="icon"/>Preview</a></li>
                                                                                    <li className="disabled"><a href="#"><img src={renameIcon} alt="icon"/>Rename</a></li>
                                                                                    <li><a href="#"><img src={editIcon} alt="icon"/>Edit</a></li>
                                                                                    <li><a href="#"><img src={copyIcon} alt="icon"/>Make a copy</a></li>
                                                                                    <li><a href="#"><img src={moveIcon} alt="icon"/>Move</a></li>
                                                                                    <li className="disabled"><a href="#"><img src={showDetailIcon} alt="icon"/>Show details</a></li>
                                                                                    <li className="disabled"><a href="#"><img src={showActivityIcon} alt="icon"/>Show activity log</a></li>
                                                                                    <li><a href="#"><img src={downloadIcon} alt="icon"/>Download</a></li>
                                                                                    <li><a href="#"><img src={deleteIcon} className="orange" alt="icon"/>Delete</a></li>
                                                                                </ul>
                                                                            </div>
                                                                        }
                                                                    >
                                                                        <a href="#"><img src={verticleDotsIcon} alt="icon"/></a>
                                                                    </Tooltip>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>

                                                <div className="table-row grid-4">
                                                    <div className="table-item">
                                                        <div className="name-wrapper">
                                                            <img src={resultImg2} className="bucket-icon" alt="icon"/>
                                                            <p>riley-photo.jpg</p>
                                                        </div>
                                                    </div>
                                                    <div className="table-item">
                                                        <p>Type name</p>
                                                    </div>
                                                    <div className="table-item">
                                                        <p>10 MB</p>
                                                    </div>
                                                    <div className="table-item">
                                                        <ul className="options">
                                                            <li>
                                                                <div className="ai-tooltip-v1">
                                                                    <Tooltip
                                                                        getPopupContainer={trigger => trigger.parentNode}
                                                                        trigger="click"
                                                                        placement="bottomLeft"
                                                                        title={
                                                                            <div className="popup-options">
                                                                                <p className="title">Design</p>
                                                                                <ul className="view-list">
                                                                                    <li className="disabled"><a href="#"><img src={previewIcon} alt="icon"/>Preview</a></li>
                                                                                    <li className="disabled"><a href="#"><img src={renameIcon} alt="icon"/>Rename</a></li>
                                                                                    <li><a href="#"><img src={editIcon} alt="icon"/>Edit</a></li>
                                                                                    <li><a href="#"><img src={copyIcon} alt="icon"/>Make a copy</a></li>
                                                                                    <li><a href="#"><img src={moveIcon} alt="icon"/>Move</a></li>
                                                                                    <li className="disabled"><a href="#"><img src={showDetailIcon} alt="icon"/>Show details</a></li>
                                                                                    <li className="disabled"><a href="#"><img src={showActivityIcon} alt="icon"/>Show activity log</a></li>
                                                                                    <li><a href="#"><img src={downloadIcon} alt="icon"/>Download</a></li>
                                                                                    <li><a href="#"><img src={deleteIcon} className="orange" alt="icon"/>Delete</a></li>
                                                                                </ul>
                                                                            </div>
                                                                        }
                                                                    >
                                                                        <a href="#"><img src={verticleDotsIcon} alt="icon"/></a>
                                                                    </Tooltip>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="table-row grid-4">
                                                    <div className="table-item">
                                                        <div className="name-wrapper">
                                                            <img src={resultImg2} className="bucket-icon" alt="icon"/>
                                                            <p>riley-photo.jpg</p>
                                                        </div>
                                                    </div>
                                                    <div className="table-item">
                                                        <p>Type name</p>
                                                    </div>
                                                    <div className="table-item">
                                                        <p>10 MB</p>
                                                    </div>
                                                    <div className="table-item">
                                                        <ul className="options">
                                                            <li>
                                                                <div className="ai-tooltip-v1">
                                                                    <Tooltip
                                                                        getPopupContainer={trigger => trigger.parentNode}
                                                                        trigger="click"
                                                                        placement="bottomLeft"
                                                                        title={
                                                                            <div className="popup-options">
                                                                                <p className="title">Design</p>
                                                                                <ul className="view-list">
                                                                                    <li className="disabled"><a href="#"><img src={previewIcon} alt="icon"/>Preview</a></li>
                                                                                    <li className="disabled"><a href="#"><img src={renameIcon} alt="icon"/>Rename</a></li>
                                                                                    <li><a href="#"><img src={editIcon} alt="icon"/>Edit</a></li>
                                                                                    <li><a href="#"><img src={copyIcon} alt="icon"/>Make a copy</a></li>
                                                                                    <li><a href="#"><img src={moveIcon} alt="icon"/>Move</a></li>
                                                                                    <li className="disabled"><a href="#"><img src={showDetailIcon} alt="icon"/>Show details</a></li>
                                                                                    <li className="disabled"><a href="#"><img src={showActivityIcon} alt="icon"/>Show activity log</a></li>
                                                                                    <li><a href="#"><img src={downloadIcon} alt="icon"/>Download</a></li>
                                                                                    <li><a href="#"><img src={deleteIcon} className="orange" alt="icon"/>Delete</a></li>
                                                                                </ul>
                                                                            </div>
                                                                        }
                                                                    >
                                                                        <a href="#"><img src={verticleDotsIcon} alt="icon"/></a>
                                                                    </Tooltip>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="table-row grid-4">
                                                    <div className="table-item">
                                                        <div className="name-wrapper">
                                                            <img src={resultImg2} className="bucket-icon" alt="icon"/>
                                                            <p>riley-photo.jpg</p>
                                                        </div>
                                                    </div>
                                                    <div className="table-item">
                                                        <p>Type name</p>
                                                    </div>
                                                    <div className="table-item">
                                                        <p>10 MB</p>
                                                    </div>
                                                    <div className="table-item">
                                                        <ul className="options">
                                                            <li>
                                                                <div className="ai-tooltip-v1">
                                                                    <Tooltip
                                                                        getPopupContainer={trigger => trigger.parentNode}
                                                                        trigger="click"
                                                                        placement="bottomLeft"
                                                                        title={
                                                                            <div className="popup-options">
                                                                                <p className="title">Design</p>
                                                                                <ul className="view-list">
                                                                                    <li className="disabled"><a href="#"><img src={previewIcon} alt="icon"/>Preview</a></li>
                                                                                    <li className="disabled"><a href="#"><img src={renameIcon} alt="icon"/>Rename</a></li>
                                                                                    <li><a href="#"><img src={editIcon} alt="icon"/>Edit</a></li>
                                                                                    <li><a href="#"><img src={copyIcon} alt="icon"/>Make a copy</a></li>
                                                                                    <li><a href="#"><img src={moveIcon} alt="icon"/>Move</a></li>
                                                                                    <li className="disabled"><a href="#"><img src={showDetailIcon} alt="icon"/>Show details</a></li>
                                                                                    <li className="disabled"><a href="#"><img src={showActivityIcon} alt="icon"/>Show activity log</a></li>
                                                                                    <li><a href="#"><img src={downloadIcon} alt="icon"/>Download</a></li>
                                                                                    <li><a href="#"><img src={deleteIcon} className="orange" alt="icon"/>Delete</a></li>
                                                                                </ul>
                                                                            </div>
                                                                        }
                                                                    >
                                                                        <a href="#"><img src={verticleDotsIcon} alt="icon"/></a>
                                                                    </Tooltip>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="table-row grid-4">
                                                    <div className="table-item">
                                                        <div className="name-wrapper">
                                                            <img src={resultImg2} className="bucket-icon" alt="icon"/>
                                                            <p>riley-photo.jpg</p>
                                                        </div>
                                                    </div>
                                                    <div className="table-item">
                                                        <p>Type name</p>
                                                    </div>
                                                    <div className="table-item">
                                                        <p>10 MB</p>
                                                    </div>
                                                    <div className="table-item">
                                                        <ul className="options">
                                                            <li>
                                                                <div className="ai-tooltip-v1">
                                                                    <Tooltip
                                                                        getPopupContainer={trigger => trigger.parentNode}
                                                                        trigger="click"
                                                                        placement="bottomLeft"
                                                                        title={
                                                                            <div className="popup-options">
                                                                                <p className="title">Design</p>
                                                                                <ul className="view-list">
                                                                                    <li className="disabled"><a href="#"><img src={previewIcon} alt="icon"/>Preview</a></li>
                                                                                    <li className="disabled"><a href="#"><img src={renameIcon} alt="icon"/>Rename</a></li>
                                                                                    <li><a href="#"><img src={editIcon} alt="icon"/>Edit</a></li>
                                                                                    <li><a href="#"><img src={copyIcon} alt="icon"/>Make a copy</a></li>
                                                                                    <li><a href="#"><img src={moveIcon} alt="icon"/>Move</a></li>
                                                                                    <li className="disabled"><a href="#"><img src={showDetailIcon} alt="icon"/>Show details</a></li>
                                                                                    <li className="disabled"><a href="#"><img src={showActivityIcon} alt="icon"/>Show activity log</a></li>
                                                                                    <li><a href="#"><img src={downloadIcon} alt="icon"/>Download</a></li>
                                                                                    <li><a href="#"><img src={deleteIcon} className="orange" alt="icon"/>Delete</a></li>
                                                                                </ul>
                                                                            </div>
                                                                        }
                                                                    >
                                                                        <a href="#"><img src={verticleDotsIcon} alt="icon"/></a>
                                                                    </Tooltip>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>*/}
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default SearchResult;



