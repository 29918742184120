import React, {useEffect, useState, useRef} from 'react';
import {Button, InputNumber, Modal, notification, Popconfirm, Radio, Select, Switch, Tooltip} from "antd";
import folderBlackIcon from "../assets/images/icons/folder-black-icon.svg";
import fileIconFile from "../assets/images/icons/file-icon-file.svg";
import {useAuth} from "../context/AuthContext";
import {useNavigate} from "react-router-dom";
import toastIcon from "../assets/images/icons/toast-icon.svg";
import config from "../config";

const SelectSharedDrive = ({isManualGroup = false, setManualGroup, getAllDrives, selectedCloud = "", selectedB = ""}) => {
    const {
        token,
        getDataWithToken,
        updateDataWithToken,
        logout,
        cancelRequests,
        cloudAccounts,
        getDataWithTokenPOST,
        user,
        userStatus,
        agentIsRunning,
        setBillingPopupOpen,
        reloadSharedDrives,
        setReloadSharedDrives,
        sendDataToWebsocket,
        postDataWithToken,
        showAgentOnce,
        setShowAgentOnce,
        sync,
        setPopup500,
        deleteDataWeb,
        sendDataToMixPanel,
        setError499,
        setPopup499,
        checkLength,
        postDataNewFolderWeb,
        limitExceed,
        isWindows,
        setDefaultOpenCloudDrive,
        postDataNoResponse
    } = useAuth();
    const dataFetchedRef = useRef(false);
    const [localCachePath, setLocalCachePath] = useState("");
    const navigate = useNavigate();
    const [api, contextHolder] = notification.useNotification();
    const [isSelectGroup, setSelectGroup] = useState(false);
    const [isOktaGroup, setOktaGroup] = useState(false);
    const [isEditGroup, setEditGroup] = useState(false);
    const [isAssignShare, setAssignProject] = useState(false);
    const [isAssignGroup, setAssignGroup] = useState(false);
    const [isAssignDelete, setAssignDelete] = useState(false);
    const [groupIdToDelete, setGroupIdToDelete] = useState('');
    const [isEditUser, setEditUser] = useState(false);
    const [loading, setLoading] = useState(false);
    const [allowDelete, setAllowDelete] = useState(true);
    const [isChecked, setisChecked] = useState(false);
    const [Objects, setObjects] = useState(10000);
    const [totalGroups, setTotalGroups] = useState("");
    const [cloudDrives, setCloudDrives] = useState([]);
    const [projectsList, setProjectsList] = useState([]);
    const [newProjectsList, setNewProjectsList] = useState([]);
    const [selectedProject, setSelectedProject] = useState("");
    const [selectedGroup, setSelectedGroup] = useState("");
    const [selectedDrive, setSelectedDrive] = useState("");
    const [projectsData, setProjectsData] = useState([]);
    const [usersData, setUsersData] = useState([]);
    const [groupsData, setGroupsData] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [selectedCloudDrive, setSelectedCloudDrive] = useState([]);
    const [descending, setDescending] = useState(false);
    const [searchValForSearch, setSearchValForSearch] = useState("");
    const [searchVal, setSearchVal] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(20);
    const [selectedCloudAccount, setSelectedCloudAccount] = useState('');
    const [selectedCloudAccountID, setSelectedCloudAccountID] = useState('');
    const [buckets, setBuckets] = useState([]);
    const [bucket, setBucket] = useState("");
    const [sharedCloudDriveName, setSharedCloudDriveName] = useState("");
    const [newUserPermissionValue, setNewUserPermissionValue] = useState(2);
    const [newUsersList, setNewUsersList] = useState([]);
    const [usersList, setUsersList] = useState([]);
    const [newGroupPermissionValue, setNewGroupPermissionValue] = useState(2);
    const [newGroupList, setNewGroupList] = useState([]);
    const [groupList, setGroupList] = useState([]);
    const [dataLoaded, setDataLoaded] = useState(false);
    const [localCacheEncrypted, setLocalCacheEncrypted] = useState(true);
    const [showCloudDriveNameLengthError, setShowCloudDriveNameLengthError] = useState(false);

    const [isActive, setActive] = useState(null);
    const [prefixOptions, setPrefixOptions] = useState("0");
    const [prefixFolderName, setPrefixFolderName] = useState("");
    const [openChooseFolder, setOpenChooseFolder] = useState(false);
    const [activatedBuckets, setActivatedBuckets] = useState([]);
    const [filteredBuckets, setFilteredBuckets] = useState([]);
    const [filteredCloudAccounts, setFilteredCloudAccounts] = useState([]);
    const [listView, setListView] = useState(true);
    const [showPrefix, setShowPrefix] = useState(false);
    const [showData, setShowData] = useState(false);
    const [loadingMore, setLoadingMore] = useState(true);
    const [prefix, setPrefix] = useState("");
    const [path, setPath] = useState("");
    const [continuationToken, setContinuationToken] = useState("");
    const [previousPathFiles, setPreviousPathFiles] = useState([]);
    const [previousPathIds, setPreviousPathIds] = useState([]);
    const [prevFolderID, setPrevFolderID] = useState("");
    const [result, setResult] = useState([]);
    const [file, setFile] = useState(null);
    const [selectedBucketId, setSelectedBucketId] = useState("");
    const [selectedFolderName, setSelectedFolderName] = useState("");
    const [selectedFolderId, setSelectedFolderId] = useState("");
    const [parentID, setParentID] = useState("");
    const [local, setlocal] = useState("drive");
    const [showCloudDriveNameError, setShowCloudDriveNameError] = useState(false);
    const [showBucketNameError, setBucketNameError] = useState(false);
    const [openPreCache, setOpenPreCache] = useState(false);
    const [localDrives, setLocalDrives] = useState([]);
    const [selectedBuckets, setSelectedBuckets] = useState([]);
    const [localDir, setLocalDir] = useState([]);
    const [previousPath, setPreviousPath] = useState("");
    const [selectedPath, setSelectedPath] = useState("");
    const [localDrivesPreCache, setLocalDrivesPreCache] = useState([]);
    const [localDirPreCache, setLocalDirPreCache] = useState([]);
    const [previousPathPreCache, setPreviousPathPreCache] = useState("");
    const [availableLocalDrives, setAvailableLocalDrives] = useState([]);
    const [selectedAvailableDrive, setSelectedAvailableDrive] = useState("Z");
    const [yourCloudDriveName, setYourCloudDriveName] = useState("");
    const [folderName, setFolderName] = useState("");
    const [loadingCreate, setLoadingCreate] = useState(false);
    const [errorText, setErrorText] = useState('');
    const [backupInstance, setBackupInstance] = useState([]);
    const [cacheSettings, setcacheSettings] = useState('stream');
    const [directoryType, setDirectoryType] = useState('automatic');
    const [cacheSize, setcacheSize] = useState(50);
    const [objects, setobjects] = useState(10000);
    const [cacheSettingsGBTB, setcacheSettingsGBTB] = useState('GB');
    const [newBucketName, setNewBucketName] = useState("");
    const [newBucketCreated, setNewBucketCreated] = useState(false);
    const [rotationalCache, setRotationalCache] = useState(true);
    const [showSingleButton, setShowSingleButton] = useState(false);
    const [showCreateCloudDrive, setChowCreateCloudDrive] = useState(false);
    const [activeKey, setActiveKey] = useState(0);


    var allcloudDrives = [];
    var assignedProjectsCount = 0;
    var assignedUsersCount = 0;


    useEffect(() => {
        if (isManualGroup) {
            if (selectedCloud !== "") {
                let indexAccount = cloudAccounts.findIndex(x => x.id == selectedCloud);
                setSelectedCloudAccount(cloudAccounts[indexAccount]);
                setSelectedCloudAccountID(selectedCloud)
                getBuckets(selectedCloud)

            }
            if (selectedB !== "") {
                setBucket(selectedB)
            }
        }
    }, [isManualGroup]);
    const selectManualCancel = () => {
        setManualGroup(false);
        setSelectedCloudDrive("")
        setAllowDelete(true)
        setSharedCloudDriveName("")
        setBucket("")
        setSelectedCloudAccount('');
        setSelectedCloudAccountID('');
        setObjects(10000);
    };

    const createSharedCloudDrive = () => {

        var selectedBucket = buckets.filter((item) => (item.name === bucket))[0];

        var prefix = "";
        var prefixId = "";

        if (prefixOptions === "1") {
            if (prefixFolderName) {
                prefix = prefixFolderName.trim();
            }
        } else if (prefixOptions === "3") {
            prefixId = selectedFolderId;
            if (path.length > 1) {
                prefix = path;
            } else {
                prefix = selectedFolderName;
            }
        }

        var dataToPost = {
            "cloudAccountId": selectedCloudAccountID,
            "name": sharedCloudDriveName.trim(),
            "objectsPerFolder": Objects,
            "allowDelete": allowDelete,
            "storageId": selectedBucket.id,
            "storageName": bucket,
            "localCacheEncrypted": localCacheEncrypted,
            "cloudObjectsEncrypted": false,
            "prefix": prefix.endsWith('/') ? prefix.slice(0, -1) : prefix,
            "prefixId": prefixId,
        }


        //.endsWith('/') ? prefix.slice(0, -1) : prefix
        if (prefixOptions === "1") {
            createNewFolder(prefix, dataToPost)
        } else if (prefixOptions === "3") {
            createSharedCloudDrivePost(dataToPost)
        } else {
            createSharedCloudDrivePost(dataToPost)
        }
    };

    const createNewFolder = (folderName, dataToPostDrive) => {
        if (folderName) {
            var dataToPost = {
                "cloudAccountId": selectedCloudAccountID,
                "storageName": bucket,
                "path": "",
                "folderName": folderName,
                "id": "",
            };
            postDataNewFolderWeb("/api/desktop/create_folder", dataToPost)
                .then((res) => Promise.all([res.status, res.text()]))
                .then(([status, data]) => {
                    setLoading(false)
                    if (status === 401) {
                        logout()
                        return;
                    }
                    if (status === 500) {
                        setPopup500(true)
                        return;
                    }
                    if (status === 499) {
                        var res = JSON.parse(data)
                        var errorType = res.type;
                        setError499(res.errors[errorType][0])
                        setPopup499(true)
                        return;
                    }


                    if (user.userType !== 32) {
                        createSharedCloudDrivePost(dataToPostDrive)
                    } else {
                        setupCloudDrivePost(dataToPostDrive)
                    }
                });
        }

    };


    const createSharedCloudDrivePost = (dataToPost) => {
        if (sharedCloudDriveName.trim().length > 0) {
            setLoading(true)
            postData("/api/sharedclouddrive/insert", dataToPost)
                .then((res) => Promise.all([res.status, res.json()]))
                .then(([status, response]) => {
                    if (status === 401) {
                        logout()
                        return;
                    }
                    if (status === 500) {
                        setPopup500(true);
                        setLoading(false);
                        return;
                    }
                    if (status === 499) {
                        var errorType = response.type;
                        setError499(response.errors[errorType][0])
                        setPopup499(true)
                        return;
                    }
                    setLoading(false)
                    setManualGroup(false);
                    api.open({
                        message: sharedCloudDriveName.trim() + ' Drive has been successfully created.',
                        icon: <img src={toastIcon} alt="toastIcon"/>,
                        duration: 5,
                    });
                    sendDataToMixPanel('Drive has been successfully created.', user.username, {
                        'App': 'Web App',
                        'Drive Name': sharedCloudDriveName.trim()
                    })
                    setSelectedCloudDrive("")
                    setAllowDelete(true)
                    setSharedCloudDriveName("")
                    setBucket("")
                    setSelectedCloudAccount('');
                    setSelectedCloudAccountID('');
                    setObjects(10000);
                    AssignCurrentUserToDrive(response.id)
                    setDefaultOpenCloudDrive(true)
                })
        }
    }


    const setupCloudDrive = () => {
        var driveType = 1;
        var cSetting = 1;
        var cachePath = localCachePath;

        var cSize = (cacheSize * 1024 * 1024 * 1024);
        if (local === 'folder') {
            driveType = 2;
            cSize = 0;
        }
        if (cacheSettingsGBTB === "TB") {
            cSize = cSize * 1024;
        }
        if (cacheSettings === "stream") {
            cSetting = 1;
        }
        if (cacheSettings === "mirror") {
            cSetting = 2;
        }
        var localPath = '';

        if (driveType === 1) {
            localPath = selectedAvailableDrive;
        }
        if (driveType === 2) {
            localPath = selectedPath;
            cachePath = selectedPath;
        }

        if (directoryType === 'automatic') {
            cachePath = "$$$";
        }

        if (!isWindows) {
            localPath = "A";
            cachePath = "/";
        }

        var selectedBucket = buckets.filter((item) => (item.name === bucket))[0];


        var dataToPost = {
            "name": sharedCloudDriveName.trim(),
            "driveName": localPath,
            "storageId": selectedBucket.id,
            "storageName": bucket,
            "permanentLocalPath": cachePath,
            "driveType": driveType,
            "syncType": cSetting,
            "rotationalCache": rotationalCache,
            "maximumCacheSize": cSize,
            "objectsPerFolder": objects,
            "localCacheEncrypted": localCacheEncrypted,
            "cloudObjectsEncrypted": false,
            "account": {
                "id": selectedCloudAccount.id,
                "accountId": selectedCloudAccount.accountId,
                "cloudType": selectedCloudAccount.cloudType,
                "name": selectedCloudAccount.name,
                "accessKey": selectedCloudAccount.accessKey,
                "secretKey": selectedCloudAccount.secretKey,
                "credentialsData": selectedCloudAccount.credentialsData,
                "serviceUrl": selectedCloudAccount.serviceUrl,
                "active": selectedCloudAccount.active,
                "internalStorage": selectedCloudAccount.internalStorage,
                "deleted": selectedCloudAccount.deleted
            },
        }

        if (selectedCloudAccount.cloudIconType === 'storj' || selectedCloudAccount.cloudIconType === 'Amove') {
            dataToPost.transferOptions = {
                "chunkSize": 64 * 1024 * 1024,
            }
        }
        //.endsWith('/') ? prefix.slice(0, -1) : prefix
        if (prefixOptions === "1") {
            createNewFolder(prefix, dataToPost)
        } else if (prefixOptions === "3") {
            setupCloudDrivePost(dataToPost)
        } else {
            setupCloudDrivePost(dataToPost)
        }
    };
    const setupCloudDrivePost = (dataToPost) => {
        if (!limitExceed) {
            postDataNoResponse('/Management/clouddrive', dataToPost)
                .then((res) => Promise.all([res.status, res.json()]))
                .then(([status, response]) => {
                    setLoading(false)
                    if (status === 401) {
                        logout()
                        return;
                    }
                    if (status === 500) {
                        setPopup500(true)
                        return;
                    }
                    setLoading(false)
                    setManualGroup(false);
                    api.open({
                        message: sharedCloudDriveName.trim() + ' Drive has been successfully created.',
                        icon: <img src={toastIcon} alt="toastIcon"/>,
                        duration: 5,
                    });
                    sendDataToMixPanel('Drive has been successfully created.', user.username, {
                        'App': 'Web App',
                        'Drive Name': sharedCloudDriveName.trim()
                    })
                    setSelectedCloudDrive("")
                    setAllowDelete(false)
                    setSharedCloudDriveName("")
                    setBucket("")
                    setSelectedCloudAccount('');
                    setSelectedCloudAccountID('');
                    setObjects(10000);
                    AssignCurrentUserToDrive(response.id)
                });
        }
    }

    async function postDataResponseText(url = "", data = {}) {
        const response = await fetch(config.apiURLWeb + url, {
            method: "POST", headers: {
                "Content-Type": "application/json", 'Authorization': 'Bearer ' + token,
                "X-App-Origin": "WebApp"
            }, body: JSON.stringify(data),
        });
        sendDataToWebsocket(config.apiURLWeb + url, "POST")
        return response;
    }

    const AssignCurrentUserToDrive = (sharedCloudDriveId) => {
        var dataToPost = [{
            "userId": user.userId,
            "sharedCloudDriveId": sharedCloudDriveId,
            "permissionType": 2
        }]
        postDataResponseText("/api/permission/users_sharedclouddrive_permision", dataToPost)
            .then((res) => Promise.all([res.status, res.text()]))
            .then(([status, bucket]) => {
                if (status === 401) {
                    logout()
                    return;
                }
                if (status === 500) {
                    setPopup500(true);
                    setLoading(false);
                    return;
                }
                if (status === 499) {
                    var data = JSON.parse(bucket)
                    var errorType = data.type;
                    setError499(data.errors[errorType][0])
                    setPopup499(true)
                    return;
                }
                sendDataToMixPanel('Assign user to Drive.', user.username, {
                    'App': 'Web App'
                })

                sync('/Management/sync_shareddrive')
                    .then((res) => Promise.all([res.status, res.text()]))
                    .then(([status, data]) => {
                        if (status === 401) {
                            logout()
                            return;
                        }
                        if (status === 500) {
                            setPopup500(true)
                            return;
                        }
                        if (status === 499) {
                            var dataNew = JSON.parse(data)
                            var errorType = dataNew.type;
                            setError499(dataNew.errors[errorType][0])
                            setPopup499(true)
                            return;
                        }
                            setTimeout(()=>{
                                getAllDrives();
                            },2000)
                    });
            })
    };

    async function postData(url = "", data = {}) {
        const response = await fetch(config.apiURLWeb + url, {
            method: "POST", headers: {
                "Content-Type": "application/json", 'Authorization': 'Bearer ' + token,
                "X-App-Origin": "WebApp"
            }, body: JSON.stringify(data),
        });
        sendDataToWebsocket(config.apiURLWeb + url, "POST")
        return response;
    }

    const onChangeCloud = (value) => {
        setBucket('');
        setSelectedCloudAccount(value)
        setSelectedCloudAccountID(value)
        getBuckets(value)
    };

    function getListObjects(loadmore = false) {

        var pathName = '/'
        if (prefix !== '' && showPrefix) {
            pathName = prefix;
        }

        if (path !== '') {
            pathName = path;
        }
        var ct = ''
        if (continuationToken !== '' && loadmore) {
            ct = continuationToken;
            setLoadingMore(true)
        } else {
            setLoading(true);
        }
        var id = ""
        if (pathName === '/') {
            id = selectedBucketId;
        } else {
            id = selectedFolderId;
        }
        if (prevFolderID !== "" && prevFolderID !== null) {
            if (previousPathIds.length > 0) {
                id = previousPathIds[previousPathIds.length - 1];
            }
            setPrevFolderID("")
        }
        setParentID(id)
        /* if (selectedCloudAccount.cloudIconType === 32) {
             if (pathName === '/') {
                 pathName = selectedBucketId;
             }
             pathName = selectedBucketId;
             if (path !== '') {
                 pathName = path;
             }
             if (pathName === '/' && path === '/') {
                 pathName = selectedBucketId;
             }
         }*/

        if (bucket !== '') {
            var dataToPost = {
                "cloudAccountId": selectedCloudAccountID,
                "bucketName": bucket,
                "id": id,
                "path": pathName,
                "continuationToken": ct,
                "count": 50
            }
            console.log(dataToPost)
            getDataWithTokenPOST('/api/desktop/list_objects', dataToPost)
                .then((res) => Promise.all([res.status, res.json()]))
                .then(([status, data]) => {
                    setLoading(false);
                    if (status === 401) {
                        logout()
                        return;
                    }
                    if (status === 500) {
                        setPopup500(true)
                        return;
                    }
                    if (status === 499) {
                        var errorType = data.type;
                        setError499(data.errors[errorType][0])
                        setPopup499(true)
                        return;
                    }

                    data.data.sort((a, b) => {
                        return b.type - a.type;
                    })
                    if (ct !== '' && ct !== null) {
                        // setResult(old => [...old, ...data.data]);

                    } else {
                        setResult(data.data);
                    }
                    setContinuationToken(data.continuationToken);
                    setLoadingMore(false)
                    setShowData(true)
                });
        } else {
            setLoading(false);
            setLoadingMore(false)
        }
    }

    const onChangeBucket = (value) => {
        setContinuationToken("");
        setPath('');
        setPreviousPathFiles('');
        setPreviousPathIds('');
        setBucket(value);
        console.log(value)
        setSelectedBucketId(buckets.filter(item => item.name === value)[0].id);
    };
    const onChangeObjects = (newValue) => {
        setObjects(newValue);
    };
    useEffect(() => {
        if (path !== "") {
            getListObjects()
        }
    }, [path])
    const onChangeAllowDelete = (e) => {
        setAllowDelete(false)
    };

    const findCloudAccount = cloudAccounts?.find(
        (item) => item.id === selectedCloudAccountID
    );

    useEffect(() => {
        if (findCloudAccount?.storageTier !== 4) {
            // Set localCacheEncrypted to false
            setLocalCacheEncrypted(false);
        }
    }, [findCloudAccount]);

    const onCheckLocalCacheEncrypted = (e) => {
        setLocalCacheEncrypted(e);
    };
    const confirm = (e) => {
        setisChecked(true)
        setAllowDelete(true)
    };
    const cancel = (e) => {
        setisChecked(false)
    };

    function getBuckets(selectedAccount) {
        getDataWithTokenPOST("/api/desktop/list_buckets", {
            "cloudAccountId": selectedAccount, "includeRegion": true
        }).then((res) => Promise.all([res.status, res.json()]))
            .then(([status, bucket]) => {
                if (status === 401) {
                    //logout()
                    return;
                }
                if (status === 500) {
                    setPopup500(true);
                    setLoading(false);
                    return;
                }
                if (status === 499) {
                    var errorType = bucket.type;
                    setError499(bucket.errors[errorType][0])
                    setPopup499(true)
                    return;
                }
                setBuckets(bucket);
            });
    }

    function goBack() {
        setContinuationToken("")
        setPreviousPathIds((previousArr) => (previousArr.slice(0, -1)));
        setPrevFolderID(previousPathIds[previousPathIds.length - 1])
        setPath(previousPathFiles[previousPathFiles.length - 1])
        setPreviousPathFiles((previousArr) => (previousArr.slice(0, -1)));
    }

    function changeObject(e) {
        const val = e.target.getAttribute('data-value');
        const type = e.target.getAttribute('data-folder');
        const folderName = e.target.getAttribute('data-name');
        const folderId = e.target.getAttribute('data-id');

        console.log(e.detail)
        if (e.detail == 2) {
            if (type == 2) {
                if (path === '') {
                    setPreviousPathFiles(oldArray => [...oldArray, '/']);
                } else {
                    setPreviousPathFiles(oldArray => [...oldArray, path]);
                }
                setPreviousPathIds(oldArray => [...oldArray, folderId]);
                setPath(val);
                setSelectedFolderName(folderName);
                setSelectedFolderId(folderId);
            }
        } else {
            const elements = document.querySelectorAll('.name-wrapper'); // Replace '.myClass' with your desired selector
            elements.forEach(element => {
                element.classList.remove("selected")
            });
            e.target.classList.add("selected")
            setSelectedFolderName(folderName);
        }
    }

    const handleCancelChooseFolder = () => {
        setOpenChooseFolder(false);
    };
    return (
        <>
            <Modal title="Create new group" className="manual-user-type" centered open={isManualGroup}
                   onOk={selectManualCancel} onCancel={selectManualCancel}>
                <div className="manual-user-modal-wrapper">
                    <div className="header">
                        <h3>Create new drive</h3>
                    </div>
                    <div className="body">
                        <div className="search-wrapper">
                            <div className="form">
                                <div className="search-wrapper">
                                    <div className="search-group">
                                        <div className="form-group">
                                            <p className="label">Drive name</p>
                                            <input type="text" className="form-control" onChange={(e) => {
                                                setSharedCloudDriveName(e.target.value);

                                                if (!checkLength(e.target.value, 32)) {
                                                    setShowCloudDriveNameLengthError(true)
                                                } else {
                                                    setShowCloudDriveNameLengthError(false)
                                                }
                                            }} value={sharedCloudDriveName} placeholder="Enter Drive Name"/>

                                            {showCloudDriveNameLengthError && <span className="error">
                                                                32 characters max allowed
                                                            </span>}
                                        </div>
                                        <div className="form-group">
                                            <p className="label">Select Cloud Account</p>
                                            <Select
                                                showSearch
                                                id="select-cloud-1"
                                                placeholder="Select Cloud"
                                                optionFilterProp="children"
                                                onChange={onChangeCloud}
                                                filterOption={(input, option) =>
                                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                                }
                                                value={selectedCloudAccountID || undefined}
                                                options={cloudAccounts
                                                    ?.filter(
                                                        (item) =>
                                                            item.cloudType !== 256 &&
                                                            item.cloudType !== 512 &&
                                                            item.cloudType !== 1024
                                                    )
                                                    .filter((item) => !item.disabled)
                                                    .map((item) => ({
                                                        label: item.name,
                                                        value: item.id,
                                                    }))}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <p className="label">Select Bucket</p>
                                            <Select
                                                showSearch
                                                id="select-bucket"
                                                placeholder="Select Bucket"
                                                optionFilterProp="children"
                                                onChange={onChangeBucket}
                                                value={bucket || undefined}
                                                filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                                options={buckets.map((item) => ({
                                                    label: item.name, value: item.name,
                                                }))}
                                            />
                                        </div>


                                        <div className={`row  ${bucket === '' || bucket === null ? "disabled" : ""}`}>
                                            <div className="col-md-12 align-self-center">
                                                <div className={`mb-3 account-detail radio radio-button`}>
                                                    <Radio.Group onChange={(e) => {
                                                        setPrefixOptions(e.target.value)
                                                        if (e.target.value === "3") {
                                                            getListObjects();
                                                            setOpenChooseFolder(true)
                                                        }
                                                    }} value={prefixOptions}>
                                                        <Radio value="0">Root Bucket</Radio>
                                                        <Radio value="1">Create New Folder</Radio>
                                                        <Radio value="3">Choose Existing Folder</Radio>
                                                    </Radio.Group>
                                                </div>

                                                {prefixOptions === "1" &&
                                                    <div className={`form-group ${isActive ? "help-circle" : null}`}>
                                                        <input type="text" className="form-control" value={prefixFolderName} placeholder="Folder Name" onChange={(e) => {
                                                            const result = e.target.value.replace(/[^a-z0-9.!*)(_'/-]/gi, '');
                                                            setPrefixFolderName(result);
                                                        }}/>
                                                    </div>
                                                }
                                                {prefixOptions === "3" &&
                                                    <div className={`form-group ${isActive ? "help-circle" : null}`}>
                                                        <input type="text" className="form-control" value={selectedFolderName} onClick={(e) => {
                                                            setOpenChooseFolder(true)
                                                        }}/>
                                                    </div>
                                                }
                                            </div>
                                        </div>


                                        <div className="row">
                                            <div className="col-md-6 align-self-center">
                                                <div className="form-group">
                                                    <label htmlFor="filesPerFolder">Number of files per folder</label>
                                                </div>
                                            </div>
                                            <div className="col-md-6 align-self-center">
                                                <div className="form-group slider">
                                                    <div className="slider-wrapper">
                                                        <InputNumber
                                                            className="form-control"
                                                            min={5000}
                                                            max={100000}
                                                            style={{width: 90}}
                                                            value={Objects}
                                                            onChange={onChangeObjects}/>
                                                    </div>
                                                    <strong>Max: 100K</strong>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-7">
                                                <div className="form-group">
                                                    <label htmlFor="deletion">Enable file deletion in cloud
                                                        account</label>
                                                </div>
                                            </div>
                                            <div className="col-md-5 align-self-center">
                                                <div className="form-group switch-btn allow-delete-wrapper">
                                                    <div className="switch-wrapper shared-drive-switch">
                                                        {!allowDelete && <Popconfirm className="switch-confirm"
                                                                                     placement="topLeft"
                                                                                     title={<div>IMPORTANT: If you
                                                                                         enable File
                                                                                         Deletion
                                                                                         for <br/> this local shared
                                                                                         drive,
                                                                                         any objects deleted <br/> will
                                                                                         also be
                                                                                         deleted in
                                                                                         your corresponding cloud
                                                                                         bucket. <br/>
                                                                                         As agreed in the Amove Terms.
                                                                                     </div>}
                                                                                     okText="Yes"
                                                                                     cancelText="No"
                                                                                     getPopupContainer={trigger => trigger.parentNode}
                                                                                     onConfirm={confirm}
                                                                                     onCancel={cancel}
                                                        >
                                                            <Switch checked={allowDelete}/>
                                                        </Popconfirm>}
                                                        {allowDelete && <Switch checked={allowDelete}
                                                                                onChange={onChangeAllowDelete}/>}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {findCloudAccount?.storageTier !== 4 && (
                                            <div className="row">
                                                <div className="col-md-7">
                                                    <div className="form-group">
                                                        <label htmlFor="deletion">Encrypt Local Cache</label>
                                                    </div>
                                                </div>
                                                <div className="col-md-5 align-self-center">
                                                    <div className="form-group switch-btn allow-delete-wrapper">
                                                        <div className="switch-wrapper">
                                                            <Tooltip
                                                                placement="topLeft"
                                                                title={`Once ${
                                                                    localCacheEncrypted ? "disabled" : "enabled"
                                                                }, this setting cannot be changed later.`}
                                                                getPopupContainer={(trigger) =>
                                                                    trigger.parentNode
                                                                }
                                                            >
                                                                <Switch
                                                                    checked={localCacheEncrypted}
                                                                    onChange={onCheckLocalCacheEncrypted}
                                                                />
                                                            </Tooltip>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="footer">
                        <a href="#" className="btn-style transparent btn-30" onClick={selectManualCancel}>Cancel</a>
                        <Button
                            className={`btn-style blue btn-30 ${sharedCloudDriveName.length < 1 || showCloudDriveNameLengthError || sharedCloudDriveName.trim() === "" || (selectedCloudAccountID.length < 1 && bucket.length < 1) || bucket === "" ? 'disabled' : ''}`}
                            loading={loading} onClick={() => {
                            if (user.userType !== 32) {
                                createSharedCloudDrive()
                            } else {
                                setupCloudDrive()
                            }
                        }}>Create Drive</Button>
                    </div>
                </div>

            </Modal>
            <Modal
                open={openChooseFolder}
                title="Choose Folder"
                onOk={handleCancelChooseFolder}
                onCancel={handleCancelChooseFolder}
                className="file-path-modal file-path-modal-wrapper choose-folder"
                footer={[/*<Popconfirm
                title="Create a folder"
                description={<input type='text' placeholder='Enter your folder name' value={folderName} className='form-control' onChange={newFolderName}/>}
                onConfirm={confirm}
                cancelButtonProps={false}
                icon={false}
                okText="Create"
                placement="topLeft"
                getPopupContainer={trigger => trigger.parentNode}
                className={`new-folder-wrapper`}
            >
                <button className={`btn-style float-start`}>
                    New Folder
                </button>
            </Popconfirm>,*/
                    <span className={`selected-folder-name ${selectedFolderName === "" ? "d-none" : ""}`}>Selected Folder: {selectedFolderName}</span>,
                    <button className="btn-style btn-30" onClick={handleCancelChooseFolder}>
                        Select Folder
                    </button>,
                    <button className="btn-style margin-left btn-30" onClick={() => {
                        setPrefixOptions("0")
                        setContinuationToken("");
                        setPath('');
                        setPreviousPathFiles('');
                        setPreviousPathIds('');
                        setSelectedFolderName('');
                        handleCancelChooseFolder()
                    }}>
                        Cancel
                    </button>]}
            >
                <div className="form dir-path-wrapper">
                    <div className="choose-your-backup-title">
                        <h3>
                            Choose your folder
                        </h3>
                    </div>
                </div>
                <div className="modal-body-wrapper">
                    <div className="select-drive-wrapper choose-folder">
                        <div className="activate-cloud-main">
                            {(previousPathFiles.length > 0 || path.length > 10) &&
                                <div className="sync-back">
                                    {previousPathFiles.length > 0 &&
                                        <button className="btn-style" type="button" onClick={goBack}><i
                                            className="fa fa-arrow-left"></i></button>
                                    }
                                    {path.length > 1 &&
                                        <b className='searchPath'>Path: <span title={path}>{path}</span></b>
                                    }
                                </div>
                            }
                            <div className="activate-cloud-wrapper">
                                <div className="cloud-item header">
                                    <p>Folder Name</p>
                                </div>

                                <div className="cloud-item-body-wrapper">
                                    {!loading && result.filter((item) => item.type === 2).map((item, index) => (
                                        <div className="cloud-item">
                                            <p><a href="#" className="name-wrapper" data-value={item.path}
                                                  data-folder={item.type} data-name={item.name} data-id={item.id}
                                                  role="button" onClick={changeObject}>
                                                <div className="icon-wrapper">
                                                    {item.type === 2 &&
                                                        <img src={`${folderBlackIcon}`} alt="folderIcon"/>
                                                    }
                                                    {item.type === 1 &&
                                                        <img src={`${fileIconFile}`} alt="fileIcon"/>
                                                    }
                                                </div>
                                                {item.name}
                                            </a></p>
                                        </div>
                                    ))}
                                    {result.filter((item) => item.type === 2).length < 1 &&
                                        <div className="no-data-found">
                                            <p className="p">No folder found</p>
                                        </div>
                                    }

                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}

export {SelectSharedDrive}
