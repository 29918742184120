import React, {useEffect, useState, useRef} from "react";
import userImg from "../../assets/images/user-circle.svg";
import {useAuth} from "../../context/AuthContext";

const Comments = ({data}) => {
    const {
        formatTime
    } = useAuth();
    return (
        <>
            {data.length > 0 &&
                <div className="tags-wrapper">
                    <p className="p bold title">Comments</p>
                    <div className="comment-wrapper">
                        {data.map(item => (
                            <div className="comment-card" id={item.id}>
                                <div className="img-wrapper" id={item.user?.id}>
                                    <img src={item.user?.photo === "string" ? userImg : item.user?.photo} alt={item.user?.name}/>
                                </div>
                                <div className="content">
                                    <p><strong>{item.user?.name}</strong> commented</p>
                                    <ul className="option">
                                        <li><span className="count">{formatTime(item.timestamp)}</span></li>
                                        <li><p>{item.comment}</p></li>
                                    </ul>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            }
        </>
    );
};

export default Comments;



