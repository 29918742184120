import React, {useEffect, useState, useRef} from "react";
import {Button, Dropdown, Modal, Tooltip, notification} from "antd";
import {Sidebar} from "./LeftSidebar";
import bellIcon from "../assets/images/icons/bell-icon.svg";
import plusIcon from "../assets/images/icons/plus-white-icon.svg";
import {useAuth} from "../context/AuthContext";
import {Link, NavLink, useLocation, useNavigate} from "react-router-dom";
import toastIcon from "../assets/images/icons/toast-icon.svg";
import warning from "../assets/images/icons/warning-sign.svg";
import administratorIcon from "../assets/images/icons/administrator-icon.svg";
import homeIcon from "../assets/images/icons/home-icon-new.svg";
import pageLogoWhite from "../assets/images/logo-dark.svg";
import pageLogo from "../assets/images/logo.svg";

const DashboardHeader = (props) => {
    const {
        user,
        logout,
        userStatus,
        showHeaderAndSidebar,
        isAuthenticated,
        notifications,
        setNotifications,
        loading,
        setLoading,
        drivesPopup,
        hideBTNs,
        setHideBTNs,
        popup500,
        setPopup500,
        popupSupport,
        setPopupSupport,
        sendEmailDataToZapier,
        endpoint,
        showPopupSupport,
        setShowPopupSupport,
        removeAllNotification,
        removeNotification,
        setShowTourDashboard,
        popup499,
        setPopup499,
        error499,
        setError499,
        setDefaultOpenCloudDrive,
        isDark
    } = useAuth();
    const [api, contextHolder] = notification.useNotification();
    const [mobileOptions, setMobileOptions] = useState(false);
    const [unreadCount, setUnreadCount] = useState(0);
    const [items, setItems] = useState([]);
    const location = useLocation();
    const [showError, setShowError] = useState(false);
    const [errorText, setErrorText] = useState("");
    const [userForm, setUserForm] = useState({
        fname: "",
        lname: "",
        message: "",
    });
    const navigate = useNavigate();
    const dataFetchedRef = useRef(false);

    const checkEmail = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

    const inputEvent = (event) => {
        setShowError(false);
        const name = event.target.name;
        const value = event.target.value;
        setUserForm((lastValue) => {
            return {
                ...lastValue,
                [name]: value,
            };
        });
    };
    const handleCancel = () => {
        setPopup500(false);
    };

    const handleCancelSupport = () => {
        setPopupSupport(false);
    };
    const sendMessage = () => {
        if (userForm.message !== "") {
            setLoading(true)
            var dataToPost = {
                firstName: userForm.fname,
                lastName: userForm.lname,
                email: user.username,
                message: userForm.message,
                endPoint: endpoint
            }
            sendEmailDataToZapier(dataToPost).then((response) => {
                api.open({
                    message: 'Your message has been sent.',
                    icon: <img src={toastIcon} alt="toastIcon"/>,
                    duration: 3,
                });
                setPopupSupport(false);
            })
        } else {
            setErrorText("Please Explain your issue.");
            setShowError(true);
        }
    };

    useEffect(() => {
        setMobileOptions(false);
        const urlParams = new URLSearchParams(window.location.search);
        const hide = urlParams.get('hide')
        if (hide === "true") {
            setHideBTNs(true)
        }
    }, [location, drivesPopup]);
    useEffect(() => {
        if (popup500 && localStorage.getItem('isLoggedInTimer') !== null) {
            api.open({
                message: 'Sorry, something went wrong there.',
                icon: <img src={warning} alt="warning"/>,
                duration: 3,
            });
            setPopup500(false)
        }
    }, [popup500]);
    useEffect(() => {
        if (popup499 && localStorage.getItem('isLoggedInTimer') !== null) {
            api.open({
                message: <div className="custon-pop-499">{error499}</div>,
                icon: <img src={warning} alt="warning"/>,
                duration: 3,
            });
            setPopup499(false);
            setError499("")
        }
    }, [popup499]);


    useEffect(() => {
        if (showPopupSupport) {
            api.open({
                message: 'Something went wrong.',
                description: <div className="des">Please try again and if the issue continues, <br/> please click <a href="#" onClick={(e) => {
                    e.preventDefault();
                    api.destroy("mypopup")
                    setPopupSupport(true)
                }}>here</a>.</div>,
                icon: <img src={warning} alt="warning"/>,
                duration: 5,
                key: "mypopup"
            });
        }
    }, [showPopupSupport]);

    useEffect(() => {
        setUnreadCount(notifications.filter(item => item.msgStatus === 'unread').length);
    }, [notifications]);
    const readNotification = (i) => {
        let newArr = [...notifications];
        if (newArr.length > 0) {
            newArr[i].msgStatus = 'read';
            setNotifications(newArr)
        }
    }
    useEffect(() => {
        var itemsMenu = [
            {
                key: "3",
                label: (
                    <a
                        href="#"
                        onClick={(e) => {
                            e.preventDefault()
                            logout();
                        }}
                    >
                        Log out
                    </a>
                ),
            }
        ];
       /* if (user.owner) {
            itemsMenu.splice(1, 0,
                {
                    key: "1",
                    label: (
                        <Link
                            to="/billing-status/"
                        >
                            Billing
                        </Link>
                    ),
                }
            )
        }*/
        setItems(itemsMenu)
    }, [userStatus, user]);

    const onMobileOptions = () => {
        if (mobileOptions === true) {
            setMobileOptions(false);
        } else {
            setMobileOptions(true);
        }
    }

    return (
        <>
            {contextHolder}
            {showHeaderAndSidebar && isAuthenticated && (
                <>
                    <div id="dashboard-header" className={`force-show-on-mobile ${mobileOptions ? 'active' : ''} ${(location.pathname !== '/' && location.pathname !== '/dashboard') ? "" : ""} `}>
                        <div className="container-fluid">
                            <div className="row min-height">
                                <div className="col-md-4 col-4 align-self-center">
                                    <div className="mobile-logo">
                                        <Link to="/dashboard" onClick={(e)=>{
                                            if(location.pathname === '/dashboard' || location.pathname === '/'){
                                                e.preventDefault();
                                            }
                                        }} className="logo">
                                            <img src={isDark ? pageLogoWhite : pageLogo} alt="logo"/>
                                        </Link>
                                    </div>
                                    {(location.pathname !== '/onboarding') &&
                                        <div className="dashboard-mobile-options">
                                            {user.userType !== 64 && user.userType !== undefined &&
                                                <>
                                                    <div className={`menu-bg-layer ${mobileOptions ? 'active' : ''}`} onClick={onMobileOptions}></div>
                                                    <div className={`mobile-options-navbar ${mobileOptions ? 'active' : ''}`}>
                                                        <div className="mobile-logo">
                                                            <Link to="/dashboard" className="logo">
                                                                <img src={isDark ? pageLogoWhite : pageLogo} alt="logo"/>
                                                            </Link>
                                                        </div>
                                                        <ul className="mobile-options-menu-v2">
                                                            <li>
                                                                <NavLink to="/dashboard" onClick={onMobileOptions}>Home</NavLink>
                                                            </li>
                                                            <li>
                                                                <NavLink to="/add-cloud-account" onClick={onMobileOptions}>
                                                                    <img src={`${plusIcon}`} alt="logo"/>
                                                                    Connection
                                                                </NavLink>
                                                            </li>
                                                            <li>
                                                                <NavLink to="/file-manager-new" onClick={onMobileOptions}>Files</NavLink>
                                                            </li>
                                                            {user.userType === 16 &&
                                                            <li>
                                                                <NavLink to="/storage-dashboard" onClick={onMobileOptions}>Storage</NavLink>
                                                            </li>
                                                            }
                                                            {user.userType === 16 &&
                                                            <li>
                                                                <NavLink to="/buckets" onClick={onMobileOptions}>Buckets</NavLink>
                                                            </li>
                                                            }
                                                            {user.userType === 16 &&
                                                            <li>
                                                                <NavLink to="/access-key" onClick={onMobileOptions}>Access Key</NavLink>
                                                            </li>
                                                            }
                                                            <li>
                                                                <NavLink to="/account" onClick={onMobileOptions}>Account</NavLink>
                                                            </li>
                                                            {user.userType === 16 &&
                                                                <li>
                                                                    <NavLink to="/users" onClick={onMobileOptions}>Administrator</NavLink>
                                                                </li>
                                                            }
                                                            <li>
                                                                <a  href="#" onClick={(e)=>{
                                                                    e.preventDefault()
                                                                    logout();}}>Log out</a>
                                                            </li>
                                                        </ul>
                                                        <div className="company-detail-wrapper">
                                                            <span className="user-name">{user.firstname} {user.lastname}</span>
                                                            {user.firstname !== user.accountName && user.username !== user.accountName && !checkEmail.test(user.accountName.trim()) &&
                                                                <span className="company-name">{user.accountName}</span>
                                                            }
                                                        </div>
                                                        <ul className="mobile-options-menu d-none">
                                                            <li>
                                                                <div className="company-detail">
                                                                    <span className="user-name">{user.firstname} {user.lastname}</span>
                                                                    {user.firstname !== user.accountName && user.username !== user.accountName && !checkEmail.test(user.accountName.trim()) &&
                                                                        <span className="company-name">{user.accountName}</span>
                                                                    }
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <Link to="/dashboard" onClick={onMobileOptions} className="btn-style btn-30 small brown">
                                                                    Home</Link>
                                                            </li>
                                                            <li>
                                                                <Link to="/add-cloud-account" onClick={onMobileOptions} className="btn-style btn-30 small purple">
                                                                    <img
                                                                        src={`${plusIcon}`} alt="logo"/>Connection</Link>
                                                            </li>
                                                            <li>
                                                                <NavLink to="/file-manager-new" onClick={onMobileOptions}
                                                                         className="btn-style btn-30 brown small">Files</NavLink>
                                                            </li>

                                                            {user.userType === 16 &&
                                                            <li>
                                                                <Link to="/storage-dashboard" onClick={onMobileOptions}
                                                                      className={`btn-style btn-30 yellow-brown small`}>Storage</Link>
                                                            </li>
                                                            }
                                                            {user.userType === 16 &&
                                                            <li>
                                                                <Link to="/buckets" onClick={onMobileOptions}
                                                                      className={`btn-style btn-30 yellow-brown small`}>Buckets</Link>
                                                            </li>
                                                            }
                                                            {user.userType === 16 &&
                                                            <li>
                                                                <Link to="/access-key" onClick={onMobileOptions}
                                                                      className={`btn-style btn-30 yellow-brown small`}>Access Key</Link>
                                                            </li>
                                                            }
                                                            <li>
                                                                <Link to="/account" onClick={onMobileOptions}
                                                                      className={`btn-style btn-30 yellow-brown small`}>Account</Link>
                                                            </li>


                                                            {user.userType === 16 &&
                                                                <li>
                                                                    <Link to="/users" onClick={onMobileOptions}
                                                                          className={`btn-style btn-30 yellow-brown small`}>Administrator</Link>
                                                                </li>
                                                            }
                                                            <li>
                                                                <a  href="#" onClick={(e)=>{
                                                                    e.preventDefault()
                                                                    logout();
                                                                }} className={`btn-style btn-30 yellow-brown small`}>Log out</a>
                                                            </li>
                                                            {/*<li>
                                                                <Link to="/setup-cloud-drive" onClick={onMobileOptions} className="btn-style btn-30 small orange">
                                                                    <img src={`${plusIcon}`} alt="logo"/>Drive</Link>
                                                            </li>*/}
                                                            {/*<li>
                                                                <Link to="/new-backup-instance" onClick={onMobileOptions} className="btn-style btn-30 small blue">
                                                                    <img src={`${plusIcon}`} alt="logo"/>Sync</Link>
                                                            </li>*/}
                                                        </ul>
                                                    </div>
                                                </>
                                            }
                                        </div>
                                    }
                                    {(location.pathname !== '/onboarding' && !hideBTNs) &&
                                        <ul className="dashboard-left-menu">
                                            {user.userType !== 64 && user.userType !== undefined &&
                                                <li>
                                                    <NavLink to="/file-manager-new"
                                                             className={`btn-style brown small btn-30 ${(location.pathname !== '/file-manager-new') ? '' : 'disabled opacity'}`}>Files</NavLink>
                                                </li>
                                            }
                                            {user.userType === 16 && user.userType !== undefined &&
                                                <li>
                                                    <NavLink to="/storage-dashboard"
                                                             className={`btn-style brown small btn-30 ${(location.pathname !== '/storage-dashboard') ? '' : 'disabled opacity'}`}>Storage</NavLink>
                                                </li>
                                            }
                                        </ul>
                                    }
                                </div>
                                <div className="col-md-8 col-8 align-self-center">
                                    <ul className="dashboard-options">
                                        {(location.pathname !== '/onboarding' && !hideBTNs) && user.userType !== 64 && user.userType !== undefined &&
                                            <>
                                                <li>
                                                    <Link to="/add-cloud-account" className={`btn-style small purple ${(location.pathname !== '/add-cloud-account') ? '' : 'disabled opacity'}`}>
                                                        <img
                                                            src={`${plusIcon}`} alt="logo"/>Connection</Link>
                                                </li>
                                                <li id="drive-popup" className="new-pop-wrapper">
                                                    <a href="#" className={`btn-style small orange move-left-15 ${(location.pathname !== '/setup-cloud-drive') ? '' : 'disabled opacity'}`} onClick={(e) => {
                                                        e.preventDefault();
                                                        setDefaultOpenCloudDrive(false)
                                                        navigate("/setup-cloud-drive");
                                                    }}>
                                                        <img src={`${plusIcon}`} alt="logo"/>Drive</a>
                                                </li>
                                                <li>
                                                    <Link to="/new-backup-instance" className={`btn-style small blue move-left-16 ${(location.pathname !== '/new-backup-instance') ? '' : 'disabled opacity'}`}>
                                                        <img src={`${plusIcon}`} alt="logo"/>Sync</Link>
                                                </li>
                                                <li className="notification-wrapper mobile-visible">
                                                    <Tooltip
                                                        getPopupContainer={trigger => trigger.parentNode}
                                                        trigger="click"
                                                        placement="bottomLeft"
                                                        title={
                                                            <ul className="notifications">
                                                                {notifications.length > 0 &&
                                                                    <>{notifications.map((item, i) => (
                                                                        <li className={item.msgStatus === 'unread' ? 'unread' : 'read'}>
                                                                            <a href="#" className="notification-text" onClick={(e) => {
                                                                                e.preventDefault();
                                                                                readNotification(i);
                                                                            }}>{item.Operation === 1 ? item.User + ' uploaded ' + item.Key + ' for ' + item.SharedDriveName : item.User + ' deleted ' + item.Key + ' for ' + item.SharedDriveName}</a>
                                                                            <i onClick={() => {
                                                                                removeNotification(i)
                                                                            }} className="fa fa-xmark"></i>
                                                                        </li>
                                                                    ))
                                                                    }
                                                                        <li className="remove-all-notifications">
                                                                            <a href="#" onClick={(e) => {
                                                                                e.preventDefault();
                                                                                removeAllNotification()
                                                                            }}> Clear all notifications
                                                                            </a>
                                                                        </li>
                                                                    </>
                                                                }
                                                                {notifications.length < 1 &&
                                                                    <li className="no-notifications">
                                                                        <img src={bellIcon} alt="logo"/>
                                                                        No Notifications</li>
                                                                }
                                                            </ul>
                                                        }
                                                    >
                                                        <a href="#" className="bell-wrapper">
                                                            {unreadCount > 0 &&
                                                                <span>{unreadCount}</span>
                                                            }
                                                            <img src={bellIcon} alt="logo"/>
                                                        </a>
                                                    </Tooltip>
                                                </li>
                                                <li className="mobile-visible">
                                                    <div className="hamburger-menu">
                                                        <i className="fa-solid fa-bars" onClick={onMobileOptions}></i>
                                                    </div>
                                                </li>
                                            </>
                                        }
                                        <li className="user-account-list">
                                            <div className="user-account-dropdown">
                                                {location.pathname !== '/onboarding' && !hideBTNs &&
                                                    <Dropdown
                                                        menu={{
                                                            items,
                                                        }}
                                                        placement="bottomRight"
                                                        arrow
                                                        getPopupContainer={(trigger) => trigger.parentNode}
                                                    >
                                                        <div className="user-account">
                                                            <div className="company-detail">
                                                                <span className="user-name">{user.firstname} {user.lastname}</span>
                                                                {user.firstname !== user.accountName && user.username !== user.accountName && !checkEmail.test(user.accountName.trim()) &&
                                                                    <span className="company-name">{user.accountName}</span>
                                                                }
                                                            </div>
                                                            <svg viewBox="64 64 896 896" focusable="false" data-icon="right"
                                                                 width="1em" height="1em" fill="#000" aria-hidden="true">
                                                                <path
                                                                    d="M765.7 486.8L314.9 134.7A7.97 7.97 0 00302 141v77.3c0 4.9 2.3 9.6 6.1 12.6l360 281.1-360 281.1c-3.9 3-6.1 7.7-6.1 12.6V883c0 6.7 7.7 10.4 12.9 6.3l450.8-352.1a31.96 31.96 0 000-50.4z"></path>
                                                            </svg>
                                                        </div>
                                                    </Dropdown>
                                                }
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <Sidebar/>
                </>
            )}
            {/*     <Modal title="500 Amove" className="manual-user-type agent-popup" centered open={popup500} onOk={handleCancel} onCancel={handleCancel}>
                <div className="manual-user-modal-wrapper">
                    <div className="header text-center">
                        <h2>Something went wrong.</h2>
                        <h3 className="justify-content-center not-bold">Sorry, something went wrong there.</h3>
                    </div>
                    <div className="footer text-center justify-content-center">
                        <Button className={`btn-style blue`} loading={loading} onClick={() => {
                            setPopup500(false)
                        }}>Close</Button>
                    </div>
                </div>
            </Modal>*/}
            <Modal title="Support Amove" className="manual-user-type support-popup" centered open={popupSupport} onOk={handleCancelSupport} onCancel={handleCancelSupport}>
                <div className="manual-user-modal-wrapper">
                    <div className="header text-center">
                        <h2>Amove Support</h2>
                        <p>Please fill out this form and we will be in touch soon.</p>
                    </div>
                    <div className="body">
                        <div className="search-wrapper">
                            <div className="form">
                                <div className="row">
                                    <div className="col-6">
                                        <div className="form-group">
                                            <input type="text" className="form-control" placeholder="First Name"
                                                   value={userForm.fname}
                                                   onChange={inputEvent}
                                                   name="fname"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="form-group">
                                            <input type="text" className="form-control" placeholder="Last Name"
                                                   value={userForm.lname}
                                                   onChange={inputEvent}
                                                   name="lname"/>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <textarea className="form-control" placeholder="How can we help?"
                                              value={userForm.message}
                                              onChange={inputEvent}
                                              name="message"></textarea>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="footer text-center justify-content-center">
                        <Button className={`btn-style btn-30 blue`} loading={loading} onClick={() => {
                            sendMessage()
                        }}>Send</Button>
                    </div>
                </div>
            </Modal>
        </>
    );
};

export {
    DashboardHeader
};
