import React, {useEffect, useState} from 'react';
import editIcon from "../assets/images/icons/edit-red-icon.svg";
import info from "../assets/images/info.svg";
import {Collapse, notification, Popconfirm, Radio, Tooltip, Switch, Select, InputNumber, Input} from 'antd';
import {useAuth} from "../context/AuthContext";
import warning from "../assets/images/icons/warning-sign.svg";
import toastIcon from "../assets/images/icons/toast-icon.svg";
import {Button} from 'antd';
import axios from 'axios';
import config from '../config';
import {LoadingOutlined} from '@ant-design/icons';
import {Spin} from 'antd';
import {Tabs} from 'antd';

const {TabPane} = Tabs;
const {Panel} = Collapse;
const DrivesSettings = (props) => {
    const {
        getData,
        deleteData,
        updateData,
        logout,
        getIcon,
        isWindows,
        isCMDDone,
        defaultOpenCloudDrive,
        setDefaultOpenCloudDrive,
        token,
        reloadDrivesFromAccounts,
        setReloadDrivesFromAccounts,
        userStatus,
        getDataWithToken,
        limitExceeded,
        setDrivesPopup,
        agentIsRunning,
        openCloudDriveAccordion,
        setOpenCloudDriveAccordion,
        user,
        sendDataToWebsocket,
        deleteDataWeb,
        setPopup500,
        sendDataToMixPanel,
        setError499,
        setPopup499
    } = useAuth();
    const [cacheSettings, setcacheSettings] = useState('stream');
    const [cacheSettingsGBTB, setcacheSettingsGBTB] = useState('GB');
    const [api, contextHolder] = notification.useNotification();
    const [cloudDrives, setCloudDrives] = useState([]);
    const [cloudCounts, setcloudCounts] = useState([])
    const [backupInstance, setBackupInstance] = useState([]);
    const [modalData, setModalData] = useState({})
    const [allowDelete, setAllowDelete] = useState(false);
    const [cache, setCache] = useState(50);
    const [Objects, setObjects] = useState(10000);
    const [syncInterval, setSyncInterval] = useState(21600);
    const [speedNetwork, setSpeedNetwork] = useState('Home/Office');
    const [GBMB, setGBMB] = useState('MB');
    const [Threads, setThreads] = useState(5);
    const [selectedTab, setSelectedTab] = useState('1');
    const [chunkSize, setChunkSize] = useState();
    const [chunkObjects, setChunkObjects] = useState();
    const [descending, setDescending] = useState(false);
    const [searchValForSearch, setSearchValForSearch] = useState("");
    const [editDriveName, setEditDriveName] = useState(false);
    const [updatedDriveName, setUpdatedDriveName] = useState(false);
    const [showCloudDriveNameError, setShowCloudDriveNameError] = useState(false);
    const [defaultActive, setDefaultActive] = useState("")
    const [rotationalCache, setRotationalCache] = useState(false);
    const [encryption, setEncryption] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loadingDel, setLoadingDel] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(20);
    const [showNoDriveError, setShowNoDriveError] = useState(false);
    const [keepFileAfterUpload, setKeepFileAfterUploade] = useState(false);

    const [inputsDisabled, setInputsDisabled] = useState(false);
    const onChangeCache = (newValue) => {
        setCache(newValue);
    };
    const onChangeObjects = (newValue) => {
        setObjects(newValue);
    };

    useEffect(() => {
        if (userStatus.status === "trial" || userStatus.status === "active") {
            if (backupInstance.length === 0) {
                getData('/Management/cloudbackup')
                    .then((res) => Promise.all([res.status, res.json()]))
                    .then(([status, data]) => {
                        if (status === 401) {
                            logout()
                            return;
                        }
                        if (status === 500) {
                            setPopup500(true)
                            return;
                        }
                        if (status === 499) {
                            var errorType = data.type;
                            setError499(data.errors[errorType][0])
                            setPopup499(true)
                            return;
                        }
                        setBackupInstance(data);
                    });
            }
            if (defaultOpenCloudDrive) {
                setDefaultActive(1);
                setDefaultOpenCloudDrive(false);
            }
        }
    }, []);
    useEffect(() => {
        if (userStatus.status === "trial" || userStatus.status === "active") {
            if (reloadDrivesFromAccounts) {
                setReloadDrivesFromAccounts(false)
                loadCloudDrives(true)
            }
        }
    }, [reloadDrivesFromAccounts]);
    const onChangeDriveName = (e) => {
        var name = e.target.value;
        setUpdatedDriveName(e.target.value)
        setShowCloudDriveNameError(false)
        if (name.length > 1) {
            var result1 = cloudDrives.filter(item => item.name.toLowerCase() === name.toLowerCase());
            var result2 = backupInstance.filter(item => item.name.toLowerCase() === name.toLowerCase());
            if (result1.length > 0 || result2.length > 0) {
                setShowCloudDriveNameError(true)
            } else {
                setModalData((lastValue) => {
                    return {
                        ...lastValue,
                        ['name']: e.target.value,
                    };
                });
            }
        }
    };

    const onChangeChunkObjects = (newValue) => {
        setChunkObjects(newValue);
    };
    const onChangeThreads = (newValue) => {
        setThreads(newValue);
    };
    const handleChange = (value) => {
        setSyncInterval(value)
    };
    const onChangeNetwork = (value) => {
        setSpeedNetwork(value);
        if (value === '1') { // Home/Office
            setThreads(5);
            setChunkObjects(5);
            setChunkSize(5);
            setInputsDisabled(false);
        } else if (value === '2') { // Mobile
            setThreads(1);
            setChunkObjects(1);
            setChunkSize(1);
            setInputsDisabled(false);
        } else if (value === '3') { // Public Wifi
            setThreads(2);
            setChunkObjects(2);
            setChunkSize(5);
            setInputsDisabled(false);
        } else if (value === '4') { // Advance
            setThreads(5);
            setChunkObjects(5);
            setChunkSize(5);
            setInputsDisabled(true);
        }
    };
    const changeGBMB = (e) => {
        setGBMB(e.target.value)
        if (e.target.value === "GB") {
            setChunkSize(1)
        } else {
            setChunkSize(5)
        }
    };
    const onChangeAllowDelete = (e) => {
        setAllowDelete(false)
    };

    function cloudWithCounts(data) {
        var items = [];
        for (const [key, value] of Object.entries(data)) {
            var obj = {};
            obj.cloudType = key;
            obj.count = value;
            items.push(obj)
        }
        setcloudCounts(items);
    }

    const onChange = (key) => {
        if (cloudDrives.length > 0) {
            setDefaultActive(key);
            if (key !== undefined) {
                showModal(cloudDrives[key]);
            }
        }
    };

    function getUniqueDataCount(objArr, propName) {
        var data = [];
        if (Array.isArray(propName)) {
            propName.forEach(prop => {
                objArr.forEach(function (d, index) {
                    if (d[prop]) {
                        data.push(d[prop]);
                    }
                });
            });
        } else {
            objArr.forEach(function (d, index) {
                if (d[propName]) {
                    data.push(d[propName]);
                }
            });
        }

        var uniqueList = [...new Set(data)];

        var dataSet = {};
        for (var i = 0; i < uniqueList.length; i++) {
            dataSet[uniqueList[i]] = data.filter(x => x == uniqueList[i]).length;
        }

        return dataSet;
    }

    useEffect(() => {
        if (userStatus.status === "trial" || userStatus.status === "active") {
            if (cloudDrives.length === 0) {
                if (agentIsRunning) {
                    loadCloudDrives()
                }
            }
        }
    }, [isCMDDone, agentIsRunning]);
    useEffect(() => {
        if (limitExceeded !== "") {
            if (agentIsRunning) {
                loadCloudDrives()
            }
        }
    }, [limitExceeded]);
    useEffect(() => {
        if (openCloudDriveAccordion) {
            setOpenCloudDriveAccordion(false)
            setDefaultActive(1);
        }
    }, [openCloudDriveAccordion]);

    function loadCloudDrives(refresh = false) {
        getData('/Management/clouddrive')
            .then((res) => Promise.all([res.status, res.json()]))
            .then(([status, response]) => {
                setLoading(false);
                if (status === 401) {
                    logout()
                    return;
                }
                if (status === 500) {
                    setPopup500(true);
                    return;
                }
                if (status === 499) {
                    var errorType = response.type;
                    setError499(response.errors[errorType][0])
                    setPopup499(true)
                    return;
                }

                var clouddrives = response;
                var data = clouddrives;
                if (user.userType === 16) {
                    console.log("/api/sharedclouddrive/get_all")
                    console.log(user)
                    getDataWithToken(`/api/sharedclouddrive/get_all?page=${currentPage}&pagesize=${perPage}&sortfield=Name&descending=${descending}&deleted=false&name=${searchValForSearch}`)
                        .then((res) => Promise.all([res.status, res.json()]))
                        .then(([status, response]) => {
                            if (status === 401) {
                                logout()
                                return;
                            }
                            if (status === 500) {
                                setPopup500(true)
                                return;
                            }
                            if (status === 499) {
                                var errorType = response.type;
                                setError499(response.errors[errorType][0])
                                setPopup499(true)
                                return;
                            }

                            var sharedDrives = response.data;
                            let sharedDriveIds = new Set(clouddrives.map(d => d.sharedDriveId));
                            let notExistsDrives = sharedDrives.filter(drive => !sharedDriveIds.has(drive.id));


                            if (notExistsDrives.length > 0) {
                                for (var i = 0; i < notExistsDrives.length; i++) {
                                    notExistsDrives[i]["account"] = notExistsDrives[i]["cloudAccount"];
                                    notExistsDrives[i]["notassigned"] = true;
                                    notExistsDrives[i]["sharedDriveId"] = notExistsDrives[i]["id"];
                                    data.push(notExistsDrives[i])
                                }
                            }
                            updateCloudDrivesData(data)
                        })
                } else {
                    updateCloudDrivesData(data)
                }

            });
    }


    const updateCloudDrivesData = (data) => {

        if (data.length > 0) {
            setDrivesPopup("false")
            var get_cloud_info = false;
            for (var j = 0; j < data.length; j++) {
                data[j]["cloudIconType"] = data[j].account.cloudType;
                data[j]["cloudType"] = data[j].account.cloudType;
                data[j]["disabled"] = false;
                if (data[j].account.id === limitExceeded) {
                    data[j]["disabled"] = true;
                    data[j]["active"] = false;
                    actionSettingUpdate(false, data[j], false)
                }
                if (data[j].account.internalStorage) {
                    if(data[j].account.storageTier === 1){
                        data[j]["cloudIconType"] = "Perform";
                    }
                    if(data[j].account.storageTier === 2){
                        data[j]["cloudIconType"] = "Global";
                    }
                    if(data[j].account.storageTier === 4){
                        data[j]["cloudIconType"] = "Scale";
                    }
                    get_cloud_info = true
                } else {
                    if (data[j].account.serviceUrl !== null && data[j].account.serviceUrl !== undefined) {
                        if (data[j].account.serviceUrl.indexOf('storjshare.io') > -1) {
                            data[j]["cloudIconType"] = 'storj';
                        }
                        if (data[j].account.serviceUrl.indexOf('appdomain.cloud') > -1) {
                            data[j]["cloudIconType"] = 'IBM';
                        }
                        if (data[j].account.serviceUrl.indexOf('ovh.us') > -1) {
                            data[j]["cloudIconType"] = 'OVH';
                        }
                        if (data[j].account.serviceUrl.indexOf('seagate.com') > -1) {
                            data[j]["cloudIconType"] = 'Live';
                        }
                        if (data[j].account.serviceUrl.indexOf('oraclecloud.com') > -1) {
                            data[j]["cloudIconType"] = 'Oracle';
                        }
                        if (data[j].account.serviceUrl.indexOf('digitaloceanspaces.com') > -1) {
                            data[j]["cloudIconType"] = 'Ocean';
                        }
                        if (data[j].account.serviceUrl.indexOf('backblazeb2.com') > -1) {
                            data[j]["cloudIconType"] = 'Backblaze';
                        }
                        if (data[j].account.serviceUrl.indexOf("object-matrix.com") > -1) {
                            data[j]["cloudIconType"] = "Perifery";
                        }
                        if (data[j].account.serviceUrl.indexOf("idrivee2") > -1) {
                            data[j]["cloudIconType"] = "IDrive";
                        }
                        if (data[j].account.serviceUrl.indexOf("symply") > -1) {
                            data[j]["cloudIconType"] = "symply";
                        }
                    }
                }
            }

            setCloudDrives(data);
            setShowNoDriveError(false)
        } else {
            setDrivesPopup("true")
            setShowNoDriveError(true)
        }
        var dataToCheck = [];
        for (var i = 0; i < data.length; i++) {
            dataToCheck.push(data[i])
        }
        cloudWithCounts(getUniqueDataCount(dataToCheck, ['cloudIconType']))
    }


    const showModal = (data) => {
        setSelectedTab('1');
        setModalData(data)
        setObjects(data.objectsPerFolder)
        setThreads(data.transferOptions.concurrentFiles)
        setcacheSettingsGBTB('GB')
        setAllowDelete(data.allowDelete)
        setChunkObjects(data.transferOptions.concurrentChunks)
        setRotationalCache(data.rotationalCache)
        setKeepFileAfterUploade(data.keepFileAfterUpload)
        setEncryption(data.localCacheEncrypted)

        setGBMB("MB")
        if (data.maximumCacheSize > 0) {
            setCache(((data.maximumCacheSize / 1024) / 1024) / 1024)
        } else {
            setCache(data.maximumCacheSize)
        }

        setChunkSize((data.transferOptions.chunkSize / 1024) / 1024)
        setSyncInterval((data.syncIntervalSeconds / 60) / 60 + " hour")

        if (data.syncType === 1) {
            setcacheSettings('stream')
        }
        if (data.syncType === 2) {
            setcacheSettings('mirror')
        }
    };

    function saveSettings(e) {
        setLoading(true)
        var finalChunkSize = chunkSize * 1024 * 1024;
        var ThreadsVal = Threads;
        var chunkObjectsVal = chunkObjects;
        var cSize = cache;
        if (GBMB === "GB") {
            finalChunkSize = 1024 * 1024 * 1024;
        }
        var cSetting = 1;
        if (cacheSettings === "stream") {
            cSetting = 1;
        }
        if (cacheSettings === "mirror") {
            cSetting = 2;
        }
        if (ThreadsVal === '' || ThreadsVal === undefined || ThreadsVal === null) {
            ThreadsVal = 1;
        }
        if (chunkObjectsVal === '' || chunkObjectsVal === undefined || chunkObjectsVal === null) {
            chunkObjectsVal = 1;
        }

        if (cSize === '' || cSize === undefined || cSize === null) {
            cSize = 15;

            if (cacheSettingsGBTB === "TB") {
                cSize = 1;
            }
        }

        cSize = (cSize * 1024 * 1024 * 1024);

        if (cacheSettingsGBTB === "TB") {
            cSize = cSize * 1024;
        }

        var dataToPost = {
            "id": modalData.id,
            "active": modalData.active,
            "name": modalData.name,
            "permanentLocalPath": modalData.permanentLocalPath,
            "prefix": modalData.prefix,
            "storageName": modalData.storageName,
            "driveName": modalData.driveName,
            "syncIntervalSeconds": (parseInt(syncInterval) * 60 * 60),
            "objectsPerFolder": Objects,
            "maximumCacheSize": cSize,
            "allowDelete": allowDelete,
            "driveType": modalData.driveType,
            "rotationalCache": rotationalCache,
            "keepFileAfterUpload": keepFileAfterUpload,
            "syncType": cSetting,
            "permissionType": modalData.permissionType,
            "sharedDrive": modalData.sharedDrive,
            "cloudType": modalData.cloudType,
            "cloudIconType": modalData.cloudIconType,
            "transferOptions": {
                "chunkSize": finalChunkSize,
                "retryCount": modalData.transferOptions.retryCount,
                "concurrentChunks": chunkObjectsVal,
                "concurrentFiles": ThreadsVal
            },
            "account": modalData.account
        }

        updateData('/Management/clouddrive/' + modalData.id, dataToPost)
            .then((res) => Promise.all([res.status, res.json()]))
            .then(([status, response]) => {
                if (status === 401) {
                    logout()
                    return;
                }
                if (status === 500) {
                    setPopup500(true);
                    setLoading(false);
                    return;
                }
                if (status === 499) {
                    var errorType = response.type;
                    setError499(response.errors[errorType][0])
                    setPopup499(true)
                    return;
                }

                setLoading(false)
                if (status === 200) {
                    let newArr = [...cloudDrives];
                    dataToPost.cloudType = modalData.cloudType;
                    dataToPost.cloudIconType = modalData.cloudIconType;
                    for (var i = 0; i < newArr.length; i++) {
                        if (newArr[i].id === modalData.id) {
                            newArr[i] = dataToPost;
                        }
                    }
                    setCloudDrives(newArr)
                    api.open({
                        message: 'Your ' + modalData.name + ' Drive has been updated.',
                        icon: <img src={toastIcon} alt="toastIcon"/>,
                        duration: 5
                    });
                    sendDataToMixPanel('Your ' + modalData.name + ' Drive has been updated.', user.username, {
                        'App': 'Web App'
                    })
                    setModalData({})
                    setShowCloudDriveNameError(false)
                    setEditDriveName(false);
                    setUpdatedDriveName('')
                }
            });

        e.preventDefault();
    }

    function actionSettingUpdate(e, data, updateCData = true) {

        var msg = 'Your ' + data.name + ' Drive has been deactivated.'
        if (e) {
            msg = 'Your ' + data.name + ' Drive has been activated.'
        }
        var dataToPost = {
            "id": data.id,
            "active": e,
            "name": data.name,
            "permanentLocalPath": data.permanentLocalPath,
            "prefix": data.prefix,
            "storageName": data.storageName,
            "driveName": data.driveName,
            "syncIntervalSeconds": data.syncIntervalSeconds,
            "objectsPerFolder": data.objectsPerFolder,
            "maximumCacheSize": data.maximumCacheSize,
            "allowDelete": data.allowDelete,
            "rotationalCache": data.rotationalCache,
            "keepFileAfterUpload": keepFileAfterUpload,
            "driveType": data.driveType,
            "syncType": data.syncType,
            "permissionType": data.permissionType,
            "sharedDrive": data.sharedDrive,
            "cloudType": data.cloudType,
            "cloudIconType": data.cloudIconType,
            "transferOptions": {
                "chunkSize": data.transferOptions.chunkSize,
                "retryCount": data.transferOptions.retryCount,
                "concurrentChunks": data.transferOptions.concurrentChunks,
                "concurrentFiles": data.transferOptions.concurrentFiles
            },
            "account": data.account
        }

        updateData('/Management/clouddrive/' + data.id, dataToPost)
            .then((res) => Promise.all([res.status, res.json()]))
            .then(([status, response]) => {
                if (status === 401) {
                    logout()
                    return;
                }
                if (status === 500) {
                    setPopup500(true);
                    setLoading(false);
                    return;
                }
                if (status === 499) {
                    var errorType = response.type;
                    setError499(response.errors[errorType][0])
                    setPopup499(true)
                    return;
                }
                if (status === 200) {
                    if (updateCData) {
                        let newArr = [...cloudDrives];
                        dataToPost.cloudType = data.cloudType;
                        dataToPost.cloudIconType = data.cloudIconType;

                        for (var i = 0; i < newArr.length; i++) {
                            if (newArr[i].id === dataToPost.id) {
                                newArr[i] = dataToPost;
                            }
                        }
                        setCloudDrives(newArr);

                        api.open({
                            message: msg,
                            icon: <img src={toastIcon} alt="toastIcon"/>,
                            duration: 5
                        });
                        setModalData({})
                    }
                }
            });
    }

    function deleteCloudDrive(id, type, name, sharedDriveId) {
        setLoadingDel(true)
        if (user.userType !== 32 && user.userType !== 64) {
            deleteDataWeb('/api/sharedclouddrive/delete?id=' + sharedDriveId)
                .then((res) => Promise.all([res.status, res.text()]))
                .then(([status, data]) => {
                    if (status === 401) {
                        logout()
                        return;
                    }
                    if (status === 500) {
                        setPopup500(true)
                        return;
                    }
                    if (status === 499) {
                        var response = JSON.parse(data)
                        var errorType = response.type;
                        setError499(response.errors[errorType][0])
                        setPopup499(true)
                        return;
                    }
                    if (status === 200) {
                        deleteCloudDriveFromAgent(id, type, name)
                    }
                });
        } else {
            deleteCloudDriveFromAgent(id, type, name)
        }
        sendDataToMixPanel('Drive Deleted.', user.username, {
            'App': 'Web App',
            'Drive Name': name
        })
    }

    function deleteCloudDriveFromAgent(id, type, name) {
        deleteData('/Management/clouddrive/' + id)
            .then((res) => Promise.all([res.status, res.text()]))
            .then(([status, data]) => {
                if (status === 401) {
                    logout()
                    return;
                }
                if (status === 500) {
                    setPopup500(true);
                    setLoading(false);
                    return;
                }
                if (status === 499) {
                    var response = JSON.parse(data)
                    var errorType = response.type;
                    setError499(response.errors[errorType][0])
                    setPopup499(true)
                    return;
                }
                if (status === 200) {
                    let cDrives = [...cloudDrives];
                    if (cDrives.length < 2) {
                        if (agentIsRunning) {
                            loadCloudDrives()
                        }
                        setDefaultActive(0)
                    }


                    setCloudDrives((current) =>
                        current.filter(
                            (item) => item.id !== id
                        )
                    );

                    api.open({
                        message: 'Your ' + name + ' Drive has been deleted.',
                        icon: <img src={toastIcon} alt="toastIcon"/>,
                        duration: 3
                    });

                    let newArr = [...cloudCounts];
                    for (var i = 0; i < newArr.length; i++) {
                        if (newArr[i].cloudType == type) {
                            newArr[i].count--;
                        }
                    }
                    setcloudCounts(newArr)
                }
                setLoadingDel(false)
            });
    }

    const onChangeCacheSettings = (e) => {
        setcacheSettings(e.target.value)
    };
    const onChangeCacheSettingsGBTB = (e) => {
        setcacheSettingsGBTB(e.target.value)
        if (e.target.value === 'GB') {
            setCache(15)
        } else {
            setCache(1)
        }
    };
    const chunkSizeChange = (value) => {
        setChunkSize(value)
    };

    const confirm = (e) => {
        setAllowDelete(true)
    };
    const cancel = (e) => {
    };
    const onChangeTab = (key) => {
        setSelectedTab(key);
    };
    const onEditDriveName = (key) => {
        setEditDriveName(true);
        setUpdatedDriveName(modalData.name)
    };

    return (

        <> {contextHolder}
            <div className="setting-connected-accounts">
                {cloudDrives.length > 0 && (<>
                        <div className="table-header">
                            <div className="row">
                                <div className="col-6 col-md-4">
                                    <p>Cloud Account</p>
                                </div>
                                <div className="col-6 col-md-4 text-center">
                                    Bucket
                                </div>
                            </div>
                        </div>
                        <div className="connected-accounts-body">
                            <div className="table-body">
                                <Collapse accordion={true} defaultActiveKey={[defaultActive]} className="connected-accounts-body-collapse" activeKey={defaultActive} onChange={onChange}>
                                    {cloudDrives.map((item, index) => (
                                        <Panel header={(
                                            <div className="row">
                                                <div className="col-6 col-md-4 align-self-center">
                                                    <div className="account-detail">
                                                        <div className="img-wrapper width-25">
                                                            {getIcon(item.cloudType, item.cloudIconType)}
                                                        </div>
                                                        <div className="content-wrapper">
                                                            <p className="medium">{item.account.name}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-6 col-md-4 align-self-center text-center">
                                                    {item.storageName}
                                                </div>
                                            </div>
                                        )} key={index} data-item={item}>
                                            <div className="table-body-card" key={item.id}>
                                                <div className="cloud-drive-modal-wrapper">
                                                    <h3>{modalData.name} | Drive Settings</h3>
                                                    <Tabs type="card" onChange={onChangeTab} activeKey={selectedTab}>
                                                        <TabPane tab="General" key="1" className={modalData.notassigned ? "not-assigned" : ""}>
                                                            <div className="cloud-account-popup-detail">
                                                                <ul>
                                                                    <li>
                                                                        <strong>Cloud Account:</strong>
                                                                        {modalData.account !== undefined &&
                                                                            <p>{modalData.account.name}</p>
                                                                        }
                                                                    </li>
                                                                    <li>
                                                                        <strong>Drive:</strong>
                                                                        {(editDriveName === false) &&
                                                                            <div className="flex-gap">
                                                                                <p>{modalData.name}</p>
                                                                                {!modalData.notassigned && !modalData.sharedDrive &&
                                                                                    <div className="edit-drive-name" onClick={onEditDriveName}>
                                                                                        <img src={editIcon} alt="icon"/>
                                                                                    </div>
                                                                                }
                                                                            </div>
                                                                        }
                                                                        {(editDriveName === true) &&
                                                                            <div className="form edit-cloud-drive-name">
                                                                                <div className="form-group">
                                                                                    <Input
                                                                                        className="form-control"
                                                                                        value={updatedDriveName}
                                                                                        onChange={onChangeDriveName}/>
                                                                                </div>
                                                                                {showCloudDriveNameError &&
                                                                                    <span className="red-error cloud-name">
                                                                That drive name has already been used.
                                                            </span>
                                                                                }
                                                                            </div>
                                                                        }
                                                                    </li>
                                                                    <li>
                                                                        <strong>Bucket Name:</strong>
                                                                        <p>{modalData.storageName}</p>
                                                                    </li>

                                                                    {!modalData.notassigned && isWindows &&
                                                                        <>
                                                                            <li className='path-text'>
                                                                                <strong>Local Cache Folder:</strong>
                                                                                <p title={modalData.permanentLocalPath}>{modalData.permanentLocalPath}</p>
                                                                            </li>
                                                                        </>
                                                                    }
                                                                    {modalData.prefix !== "" && modalData.prefix !== null &&
                                                                        <>
                                                                            <li className='path-text'>
                                                                                <strong>Prefix:</strong>
                                                                                <p title={modalData.prefix}>{modalData.prefix}</p>
                                                                            </li>
                                                                        </>
                                                                    }
                                                                    {modalData.notassigned && isWindows &&
                                                                        <>
                                                                            <li className='path-text'>
                                                                                <strong>Number of files per folder:</strong>
                                                                                <p>{Objects}</p>
                                                                            </li>
                                                                            <li className='path-text'>
                                                                                <strong>File deletion in cloud account:</strong>
                                                                                <p>{allowDelete ? "Enabled" : "Disabled"}</p>
                                                                            </li>
                                                                            <li className='path-text'>
                                                                                <strong>Encryption:</strong>
                                                                                <p className="switch-wrapper info-wrapper">
                                                                                    {encryption ? "Enabled" : "Disabled"}
                                                                                </p>
                                                                            </li>
                                                                        </>
                                                                    }
                                                                </ul>
                                                            </div>
                                                        </TabPane>
                                                        {!modalData.notassigned &&
                                                            <TabPane tab="Control" key="2">
                                                                <div className="form form-spacing-fixed">
                                                                    <div className="row">
                                                                        <div className="col-md-7 align-self-center">
                                                                            <div className="form-group info-wrapper stream-file-popup-wrapper">
                                                                                <label>Drive Type
                                                                                    <Tooltip
                                                                                        getPopupContainer={trigger => trigger.parentNode}
                                                                                        placement="topLeft" title={<div className="stream-detail-text">
                                                                                        <span>Stream files</span>
                                                                                        <ul>
                                                                                            <li><p>Store all files in the cloud</p></li>
                                                                                            <li><p>Access files from the Amove Drive on your desktop</p></li>
                                                                                            <li><p>Choose files and folders to make available offline</p></li>
                                                                                        </ul>
                                                                                        <span>Mirror files</span>
                                                                                        <ul>
                                                                                            <li><p>Access files in the cloud and from the desktop</p></li>
                                                                                            <li><p>All files are available offline</p></li>
                                                                                            <li><p>See thumbnails of your files from the Amove Drive</p></li>
                                                                                        </ul>
                                                                                    </div>}
                                                                                    >
                                                                                        <img src={info} alt="Info"/>
                                                                                    </Tooltip>
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-5 align-self-center">
                                                                            <div
                                                                                className={`form-group cache-radio-wrapper radio-btn radio-info-wrapper cloud-drive-type-wrapper  ${modalData?.account?.internalStorage && modalData?.account?.storageTier === 4 ? "disabled" : ""}`}>
                                                                                <Radio.Group onChange={onChangeCacheSettings} value={cacheSettings}>
                                                                                    <Radio value="stream">
                                                                                        <Tooltip placement="top"
                                                                                                 title="Stream Cloud files to a virtual drive on your computer.">
                                                                                            Stream
                                                                                        </Tooltip>
                                                                                    </Radio>
                                                                                    <Radio value="mirror">
                                                                                        <Tooltip placement="top"
                                                                                                 title="Mirror automatically downloads from your cloud bucket to this Amove drive on your computer.">
                                                                                            Mirror
                                                                                        </Tooltip>
                                                                                    </Radio>
                                                                                </Radio.Group>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-md-7 align-self-center">
                                                                            <div className="form-group info-wrapper">
                                                                                <label htmlFor="threads">Cloud to Local Sync
                                                                                    <Tooltip
                                                                                        getPopupContainer={trigger => trigger.parentNode}
                                                                                        placement="topLeft"
                                                                                        title="If there are any changes to a file in the cloud bucket (new version) or if there’s a new file, it will sync to the drive in the saved intervals. Learn more.">
                                                                                        <img src={info} alt="Info"/>
                                                                                    </Tooltip></label>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-5 align-self-center">
                                                                            <div className="form-group">
                                                                                <Select
                                                                                    defaultValue={syncInterval}
                                                                                    value={syncInterval}
                                                                                    onChange={handleChange}
                                                                                    style={{width: 90}}
                                                                                    options={[
                                                                                        {
                                                                                            value: '1',
                                                                                            label: '1 hour',
                                                                                        },
                                                                                        {
                                                                                            value: '3',
                                                                                            label: '3 hour',
                                                                                        },
                                                                                        {
                                                                                            value: '4',
                                                                                            label: '4 hour',
                                                                                        },
                                                                                        {
                                                                                            value: '5',
                                                                                            label: '5 hour',
                                                                                        },
                                                                                        {
                                                                                            value: '6',
                                                                                            label: '6 hour',
                                                                                        },
                                                                                        {
                                                                                            value: '12',
                                                                                            label: '12 hour',
                                                                                        },
                                                                                        {
                                                                                            value: '18',
                                                                                            label: '18 hour',
                                                                                        },
                                                                                        {
                                                                                            value: '24',
                                                                                            label: '24 hour',
                                                                                        }
                                                                                    ]}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-md-7 align-self-center">
                                                                            <div className="form-group">
                                                                                <label htmlFor="cacheSize">Cache Size</label>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-5 align-self-center">
                                                                            <div className="form-group slider">
                                                                                <div className="slider-wrapper">
                                                                                    <InputNumber
                                                                                        className="form-control"
                                                                                        min={cacheSettingsGBTB === 'GB' ? 15 : 1}
                                                                                        style={{width: 90}}
                                                                                        value={cache}
                                                                                        onChange={onChangeCache}/>
                                                                                </div>
                                                                                <div className="form-group no-margin-b">
                                                                                    <Radio.Group onChange={onChangeCacheSettingsGBTB}
                                                                                                 value={cacheSettingsGBTB}>
                                                                                        <Radio value="GB">
                                                                                            GB
                                                                                        </Radio>
                                                                                        <Radio value="TB">
                                                                                            TB
                                                                                        </Radio>
                                                                                    </Radio.Group>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className={`row ${modalData.sharedDrive ? "disabled" : ""}`}>
                                                                        <div className="col-md-7 align-self-center">
                                                                            <div className="form-group">
                                                                                <label htmlFor="filesPerFolder">Number of files per folder</label>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-5 align-self-center">
                                                                            <div className="form-group slider">
                                                                                <div className="slider-wrapper">
                                                                                    <InputNumber
                                                                                        className="form-control"
                                                                                        min={5000}
                                                                                        max={100000}
                                                                                        style={{width: 90}}
                                                                                        value={Objects}
                                                                                        onChange={onChangeObjects}/>
                                                                                </div>
                                                                                <strong>Max: 100K</strong>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className={`row`}>
                                                                        <div className="col-md-7 align-self-center">
                                                                            <div className="form-group  info-wrapper">
                                                                                <label htmlFor="deletion"><span className={modalData.sharedDrive ? "disabled" : ""}>Enable file deletion in cloud account</span>
                                                                                    <Tooltip
                                                                                        getPopupContainer={trigger => trigger.parentNode}
                                                                                        placement="topLeft"
                                                                                        title="Enabling file deletion cannot be changed after the Drive has been set up. You can set up a new Drive at anytime.">
                                                                                        <img src={info} alt="Info"/>
                                                                                    </Tooltip>
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                        <div className={`col-md-5 align-self-center ${modalData.sharedDrive ? "disabled" : ""}`}>
                                                                            <div className="form-group switch-btn allow-delete-wrapper">
                                                                                <div className="switch-wrapper">
                                                                                    {!allowDelete &&
                                                                                        <Popconfirm className="switch-confirm"
                                                                                                    placement="topLeft"
                                                                                                    title={<div>IMPORTANT: If you enable File Deletion
                                                                                                        for <br/> this local drive,
                                                                                                        any objects deleted <br/> will also be deleted in
                                                                                                        your corresponding cloud bucket. <br/>
                                                                                                        As agreed in the Amove Terms.</div>}
                                                                                                    okText="Yes"
                                                                                                    cancelText="No"
                                                                                                    getPopupContainer={trigger => trigger.parentNode}
                                                                                                    onConfirm={confirm}
                                                                                                    onCancel={cancel}
                                                                                        >
                                                                                            <Switch checked={allowDelete}/>
                                                                                        </Popconfirm>
                                                                                    }
                                                                                    {allowDelete &&
                                                                                        <Switch checked={allowDelete} onChange={onChangeAllowDelete}/>
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className={`row`}>
                                                                        <div className="col-md-7">
                                                                            <div className="form-group">
                                                                                <label htmlFor="deletion">Rotational Cache</label>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-5 align-self-center">
                                                                            <div className="form-group switch-btn allow-delete-wrapper">
                                                                                <div className="switch-wrapper">
                                                                                    <Tooltip placement="topLeft"
                                                                                             title={`${rotationalCache ? "Disable" : "Enable"}, Rotational Cache.`}
                                                                                             getPopupContainer={trigger => trigger.parentNode}>
                                                                                        <Switch checked={rotationalCache}
                                                                                                onChange={(e) => {
                                                                                                    setRotationalCache(e)
                                                                                                }}/>
                                                                                    </Tooltip>

                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className={`row`}>
                                                                        <div className="col-md-7">
                                                                            <div className="form-group">
                                                                                <label htmlFor="deletion">Keep file in local cache after upload</label>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-5 align-self-center">
                                                                            <div className="form-group switch-btn allow-delete-wrapper">
                                                                                <div className="switch-wrapper">
                                                                                    <Switch checked={keepFileAfterUpload}
                                                                                            onChange={(e) => {
                                                                                                setKeepFileAfterUploade(e)
                                                                                            }}/>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className={`row`}>
                                                                        <div className="col-md-7">
                                                                            <div className="form-group">
                                                                                <label htmlFor="deletion">Encryption</label>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-5 align-self-center">
                                                                            <div className="form-group switch-btn allow-delete-wrapper">
                                                                                <div className="switch-wrapper info-wrapper">
                                                                                    {encryption ? "Enabled" : "Disabled"}
                                                                                    <Tooltip
                                                                                        getPopupContainer={trigger => trigger.parentNode}
                                                                                        placement="topLeft"
                                                                                        title={<div>Encryption setting can't be <br/> changed after the Drive is set up.</div>}>
                                                                                        <img src={info} alt="Info"/>
                                                                                    </Tooltip>

                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </TabPane>
                                                        }

                                                        {!modalData.notassigned &&
                                                            <TabPane tab="Speed" key="3">
                                                                <div className="form form-spacing-fixed">
                                                                    <div className="row">
                                                                        <div className="col-md-7 align-self-center">
                                                                            <div className="form-group info-wrapper">
                                                <label htmlFor="network">Network</label>
                                            </div>
                                        </div>
                                        <div className="col-md-5 align-self-center">
                                            <div className="form-group">
                                                <Select
                                                    defaultValue={speedNetwork}
                                                    value={speedNetwork}
                                                    onChange={onChangeNetwork}
                                                    style={{width: 120}}
                                                    options={[
                                                        {
                                                            value: '1',
                                                            label: 'Home/Office',
                                                        },
                                                        {
                                                            value: '2',
                                                            label: 'Mobile',
                                                        },
                                                        {
                                                            value: '3',
                                                            label: 'Public Wifi',
                                                        },
                                                        {
                                                            value: '4',
                                                            label: 'Advance',
                                                        },
                                                    ]}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-7 align-self-center">
                                            <div className="form-group info-wrapper">
                                                                                <label htmlFor="threads">Number of concurrent threads
                                                                                    <Tooltip
                                                                                        getPopupContainer={trigger => trigger.parentNode}
                                                                                        placement="topLeft"
                                                                                        title="The number of objects that are transferred in parallel across the network simultaneously.">
                                                                                        <img src={info} alt="Info"/>
                                                                                    </Tooltip></label>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-5 align-self-center">
                                            <div className={`form-group slider concurrent-threads-slider ${inputsDisabled ? '':'disabled'}`}>
                                                                                <div className="slider-wrapper">
                                                                                    <InputNumber
                                                                                        className="form-control"
                                                                                        min={1}
                                                                                        max={10}
                                                                                        style={{width: 90}}
                                                                                        value={Threads}
                                                                                        onChange={onChangeThreads}/>
                                                                                </div>
                                                                                <strong>Max: 10</strong>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-md-7 align-self-center">
                                                                            <div className="form-group info-wrapper">
                                                                                <label htmlFor="threads">Number of chunks per object
                                                                                    <Tooltip
                                                                                        getPopupContainer={(trigger) => trigger.parentNode}
                                                                                        placement="topLeft"
                                                                                        title="The number of parts divided for each object that are transferred in parallel across the network.">
                                                                                        <img src={info} alt="Info"/>
                                                                                    </Tooltip>
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-5 align-self-center">
                                            <div className={`form-group slider concurrent-threads-slider ${inputsDisabled ? '':'disabled'}`}>
                                                                                <div className="slider-wrapper">
                                                                                    <InputNumber
                                                                                        className="form-control"
                                                                                        min={1}
                                                                                        max={modalData.cloudIconType === 'storj' ? 20 : 5}
                                                                                        style={{width: 90}}
                                                                                        value={chunkObjects}
                                                                                        onChange={onChangeChunkObjects}/>
                                                                                </div>
                                                                                <strong>Max: {modalData.cloudIconType === 'storj' && 20}{modalData.cloudIconType !== 'storj' && 5}</strong>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-md-7 align-self-center">
                                                                            <div className="form-group info-wrapper">
                                                                                <label htmlFor="chunkSize">
                                                                                    Chunk Size
                                                                                </label>
                                                                                <Tooltip
                                                                                    getPopupContainer={(trigger) => trigger.parentNode}
                                                                                    placement="topLeft"
                                                                                    title={<div>For most providers, chunk size <br/> is regulated to a
                                                                                        minimum of <br/> 5MB up to 4GB.</div>}>
                                                                                    <img src={info} alt="Info"/>
                                                                                </Tooltip>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-5 align-self-center">

                                            <div className={`form-group slider ${inputsDisabled ? '':'disabled'}`}>
                                                                                <div className="slider-wrapper">
                                                                                    <div
                                                                                        className={`form-group no-margin-b ${chunkSize === '' ? "hide-input-arrows" : ""}`}>
                                                                                        {modalData.cloudIconType !== 'storj' &&
                                                                                            <InputNumber placeholder="5MB to 4GB"
                                                                                                         style={{width: 90}} className="form-control"
                                                                                                         min={GBMB === 'GB' ? 1 : 5}
                                                                         max={GBMB === 'GB' ? 4 : 4096}
                                                                         value={chunkSize}
                                                                                                         onChange={chunkSizeChange}/>

                                                                                        }
                                                                                        {modalData.cloudIconType === 'storj' &&
                                                                                            <Select
                                                                                                defaultValue="64"
                                                                                                style={{width: 90}}
                                                                                                onChange={chunkSizeChange}
                                                                                                value={chunkSize}
                                                                                                options={[
                                                                                                    {
                                                                                                        value: '64',
                                                                                                        label: '64',
                                                                                                    },
                                                                                                    {
                                                                                                        value: '128',
                                                                                                        label: '128',
                                                                                                    },
                                                                                                    {
                                                                                                        value: '192',
                                                                                                        label: '192',
                                                                                                    },
                                                                                                    {
                                                                                                        value: '256',
                                                                                                        label: '256'
                                                                                                    },
                                                                                                    {
                                                                                                        value: '320',
                                                                                                        label: '320'
                                                                                                    },
                                                                                                    {
                                                                                                        value: '384',
                                                                                                        label: '384'
                                                                                                    },
                                                                                                    {
                                                                                                        value: '448',
                                                                                                        label: '448'
                                                                                                    },
                                                                                                    {
                                                                                                        value: '512',
                                                                                                        label: '512'
                                                                                                    },
                                                                                                    {
                                                                                                        value: '576',
                                                                                                        label: '576'
                                                                                                    },
                                                                                                    {
                                                                                                        value: '640',
                                                                                                        label: '640'
                                                                                                    },
                                                                                                    {
                                                                                                        value: '704',
                                                                                                        label: '704'
                                                                                                    },
                                                                                                    {
                                                                                                        value: '768',
                                                                                                        label: '768'
                                                                                                    },
                                                                                                    {
                                                                                                        value: '832',
                                                                                                        label: '832'
                                                                                                    },
                                                                                                    {
                                                                                                        value: '896',
                                                                                                        label: '896'
                                                                                                    },
                                                                                                    {
                                                                                                        value: '960',
                                                                                                        label: '960'
                                                                                                    }
                                                                                                ]}
                                                                                            />
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                                <div className="form-group no-margin-b">
                                                                                    <Radio.Group onChange={changeGBMB} value={GBMB}>
                                                                                        <Radio value="MB">
                                                                                            MB
                                                                                        </Radio>
                                                                                        {modalData.cloudIconType !== 'storj' &&
                                                                                            <Radio value="GB">
                                                                                                GB
                                                                                            </Radio>
                                                                                        }
                                                                                    </Radio.Group>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </TabPane>
                                                        }
                                                    </Tabs>
                                                    <div className="form form-footer">
                                                        <div className="row">
                                                            <div className="col-md-6 align-self-end delete-wrapper">
                                                                <div className="form-group">
                                                                    {(selectedTab == 2 || modalData.notassigned) &&
                                                                        <label>
                                                                            <Popconfirm
                                                                                placement="topLeft"
                                                                                title="Are you sure you want to delete this drive?"
                                                                                okText="Yes"
                                                                                cancelText="No"
                                                                                getPopupContainer={trigger => trigger.parentNode}
                                                                                onConfirm={() => deleteCloudDrive(modalData.id, modalData.cloudIconType, modalData.name, modalData.sharedDriveId)}
                                                                            >
                                                                                Delete {modalData.name} Drive
                                                                            </Popconfirm>
                                                                            {loadingDel &&
                                                                                <Spin
                                                                                    indicator={
                                                                                        <LoadingOutlined
                                                                                            style={{
                                                                                                fontSize: 20,
                                                                                            }}
                                                                                            spin
                                                                                        />
                                                                                    }
                                                                                />
                                                                            }
                                                                        </label>
                                                                    }
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div className="form-group submit">
                                                                    {!modalData.notassigned &&
                                                                        <Button loading={loading} className="btn-style btn-30 small-btn"
                                                                                onClick={saveSettings}>Save</Button>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Panel>
                                    ))}
                                </Collapse>
                            </div>
                        </div>
                    </>
                )}

                {showNoDriveError && (
                    <>
                        <h3 className="text-center mt-100">No Drive Available</h3>
                    </>
                )}
            </div>
        </>
    )
}

export {DrivesSettings}
