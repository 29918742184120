import React, {useEffect, useState, useRef} from "react";
import {Link, useNavigate} from "react-router-dom";
import {
    Popover,
    Checkbox,
    DatePicker,
    Select,
    Tooltip,
    Drawer,
    Modal,
    notification,
    Button,
    Popconfirm,
    Switch
} from 'antd';
import {useAuth} from "../../context/AuthContext";
import config from "../../config";
import searchIcon from "../../assets/images/ai-search/search-icon.svg";
import crossBlackIcon from "../../assets/images/ai-search/cross-black-icon.svg";
import infoWhiteIcon from "../../assets/images/ai-search/info-white-icon.svg";
import fileIcon from "../../assets/images/ai-search/file-icon.svg";
import backArrowIcon from "../../assets/images/icons/back-arrow-black-icon.svg";
import addicon from "../../assets/images/icons/add-user-plus-icon.svg";
import aiCopyIcon from "../../assets/images/icons/ai-copy-icon.svg";
import clipsIcon from "../../assets/images/ai-search/clips-icon.svg";
import taggingIcon from "../../assets/images/ai-search/tagging-icon.svg";
import fileInfoIcon from "../../assets/images/ai-search/file-info-icon.svg";
import commnetIcon from "../../assets/images/ai-search/commnet-icon.svg";
import videoImg from "../../assets/images/ai-search/video-plyer-img.png";
import playIcon from "../../assets/images/ai-search/play-circle-icon.svg";
import globeIcon from "../../assets/images/ai-search/globe-gray-icon.svg";
import rightDrawerIcon from "../../assets/images/ai-search/open-drawer-right-icon.svg";
import plusWhiteIcon from "../../assets/images/ai-search/plus-white-icon.svg";
import playBlackIcon from "../../assets/images/ai-search/play-black-icon.svg";
import trashBlackIcon from "../../assets/images/ai-search/trash-black-icon.svg";
import downloadBlackIcon from "../../assets/images/ai-search/download-black-icon.svg";
import editBlackIcon from "../../assets/images/ai-search/edit-black-icon.svg";
import identifiedImg1 from "../../assets/images/ai-search/identified-object-img-1.png";
import identifiedImg2 from "../../assets/images/ai-search/identified-object-img-2.png";
import identifiedImg3 from "../../assets/images/ai-search/identified-object-img-3.png";
import identifiedImg4 from "../../assets/images/ai-search/identified-object-img-4.png";
import identifiedImg5 from "../../assets/images/ai-search/identified-object-img-5.png";
import identifiedImg6 from "../../assets/images/ai-search/identified-object-img-6.png";
import identifiedImg7 from "../../assets/images/ai-search/identified-object-img-7.png";
import identifiedImg8 from "../../assets/images/ai-search/identified-object-img-8.png";
import identifiedImg9 from "../../assets/images/ai-search/identified-object-img-9.png";
import identifiedImg10 from "../../assets/images/ai-search/identified-object-img-10.png";
import identifiedImg11 from "../../assets/images/ai-search/identified-object-img-11.png";
import crossRedCircleIcon from "../../assets/images/ai-search/cross-red-circle-icon.svg";
import userImg from "../../assets/images/user-circle.svg";
import toastIcon from "../../assets/images/icons/toast-icon.svg";

import playWhiteIcon from "../../assets/images/ai-search/video-play-icon.svg";
import audioNextIcon from "../../assets/images/ai-search/audio-next-seconds-icon.svg";
import audioPreviousIcon from "../../assets/images/ai-search/audio-previous-seconds-icon.svg";
import commnetVideoIcon from "../../assets/images/ai-search/commnet-icon.svg";
import speakerIcon from "../../assets/images/ai-search/audio-speaker-icon.svg";
import settingIcon from "../../assets/images/ai-search/audio-setting-icon.svg";
import minimizeIcon from "../../assets/images/ai-search/minimize-video-icon.svg";
import largeIcon from "../../assets/images/ai-search/large-video-icon.svg";
import starIcon from "../../assets/images/ai-search/ai-star-icon.svg";
import saveIcon from "../../assets/images/ai-search/Save.svg";
import writeCommentIcon from "../../assets/images/ai-search/write-comment-icon.svg";
import tagCommentIcon from "../../assets/images/ai-search/comment-tag-icon.svg";
import crossOrangeIcon from "../../assets/images/ai-search/cross-orange-icon.svg";
import pauseIcon from "../../assets/images/ai-search/pause-white-icon.svg";
import muteIcon from "../../assets/images/ai-search/mute-white-icon.svg";
import rangeRightIcon from "../../assets/images/ai-search/range-right-icon.svg";
import rangeLeftIcon from "../../assets/images/ai-search/range-left-icon.svg";
import Comments from "./CommentsSection";
import CreateClip from "./CreateClip";
import verticleDotsIcon from "../../assets/images/icons/verticle-dots-black-icon.svg";
import {document} from "mixpanel-browser/src/utils";

const {RangePicker} = DatePicker;
const VideoDetail = ({open, callback, previewItem}) => {
    const {
        postDataValere,
        getDataValere,
        logout,
        setPopup500,
        setError499,
        setPopup499,
        user,
        getFormatedDate,
        setSearchResults,
        searchResults,
        getFormatedDateFiles,
        searchedBucketName,
        searchedCloudName,
        formatTime,
        formatTimeHours,
        deleteDataValere,
        getCloudAccountName,
        updateDataValere
    } = useAuth();
    const [fileSidebar, setFileSidebar] = useState(false);
    const [selectFile, setSelectFile] = useState(false);
    const [isCalendarOpen, setIsCalendarOpen] = useState(false);
    const [cloudFilterOpen, setCloudFilterOpen] = useState(false);
    const [fileInfo, setFileInfo] = useState(false);
    const [aiComment, setAiComment] = useState(false);
    const [metaFocused, setMetaFocused] = useState(false);
    const [aiTagging, setAiTagging] = useState(false);
    const searchInputRef = useRef(null);
    const metaSearchRef = useRef(null);
    const videoPlayer = useRef(null);
    const commentInput = useRef(null);
    const aiTaggingWrapper = useRef(null);
    const callResize = useRef(null);
    const thumbPreviewWrapper = useRef(null);
    const newTagName = useRef(null);
    const newKeyword = useRef(null);
    const manualTagging = useRef(false);
    const maxChars = 80; // Maximum character limit
    const [videoPosition, setVideoPosition] = useState("");
    const [getMetaDataDone, setGetMetaDataDone] = useState(false);
    const [videoControls, setVideoControls] = useState(false);
    const [openDrawer, setOpenDrawer] = useState(false);
    const [isEditClip, setIsEditClip] = useState(false);
    const [isRightSidebar, setisRightSidebar] = useState(false);
    const [editClipModal, setEditClipModal] = useState(false);
    const [showSmallVideo, setShowSmallVideo] = useState(false);
    const [tags, setTags] = useState([]);
    const [autoTags, setAutoTags] = useState([]);
    const [manualKeywords, setManualKeywords] = useState([]);
    const [speakers, setSpeakers] = useState([]);
    const [keywords, setKeywords] = useState("");
    const [transcript, setTranscript] = useState([]);
    const [comments, setComments] = useState([]);
    const [clips, setClips] = useState([]);
    const [commentsOnVideo, setCommentsOnVideo] = useState([]);
    const [showAddCommentsOnVideo, setShowAddCommentsOnVideo] = useState(false);
    const [currentCommentPosition, setCurrentCommentPosition] = useState("");
    const [fileId, setFileId] = useState("");
    const [fileName, setFileName] = useState("");
    const [fileURL, setFileURL] = useState("");
    const [fileDuration, setFileDuration] = useState("");
    const [fileSize, setFileSize] = useState("");
    const [fileUpdated_at, setFileUpdated_at] = useState("");
    const [fileSummary, setFileSummary] = useState("");
    const [fileResolution, setFileResolution] = useState("");
    const [fileResolutionHeight, setFileResolutionHeight] = useState("");
    const [fileResolutionWidth, setFileResolutionWidth] = useState("");
    const [fileCreated_at, setFileCreated_at] = useState("");
    const [fileModifiedBy, setFileModifiedBy] = useState("");
    const [fileType, setFileType] = useState("");
    const [fileBucketName, setFileBucketName] = useState("");
    const [fileCloudName, setFileCloudName] = useState("");
    const [fileMetaDate, setFileMetaDate] = useState([]);
    const [fileThumbs, setFileThumbs] = useState([]);
    const [selectedMetaDate, setSelectedMetaDate] = useState({});
    const [api, contextHolder] = notification.useNotification();
    const [parentWidth, setParentWidth] = useState(897); // Initial width of the container
    const [parentHeight, setParentHeight] = useState(509); // Initial height of the container
    //const [scaleFactorX, setScaleFactorX] = useState(1); // Scale factor for width
    //const [scaleFactorY, setScaleFactorY] = useState(1); // Scale factor for height
    const scaleFactorX = useRef(1);
    const scaleFactorY = useRef(1);
    const metaDataRef = useRef([]);


    const startSlider = useRef(null);
    const endSlider = useRef(null);
    const videoClip = useRef(null);
    const sliderTrack = useRef(null);
    const sliderTrackGray = useRef(null);
    const [startClip, setStartClip] = useState(0);
    const [endClip, setEndClip] = useState(0);
    const [clipDuration, setClipDuration] = useState(0);
    const [loading, setLoading] = useState(false);
    const [loadingSave, setLoadingSave] = useState(false);

    const [isPlaying, setIsPlaying] = useState(false);
    const [isMuted, setIsMuted] = useState(false);
    const [showAddTag, setShowAddTag] = useState(false);
    const [showAddKeyword, setShowAddKeyword] = useState(false);
    const [editObjects, setEditObjects] = useState(false);
    const [isLoadingVideo, setIsLoadingVideo] = useState(true);
    const [showSaveButton, setShowSaveButton] = useState(false);

    // Update the scale factors based on the parent container size
    useEffect(() => {
        if (aiTaggingWrapper.current !== null) {
            const updateDimensions = () => {
                if (aiTaggingWrapper.current) {
                    const newWidth = aiTaggingWrapper.current.offsetWidth;
                    const newHeight = aiTaggingWrapper.current.offsetHeight;
                    // Assuming original design dimensions
                    const originalWidth = fileResolutionWidth;
                    const originalHeight = fileResolutionHeight;
                    setParentWidth(newWidth);
                    setParentHeight(newHeight);
                    scaleFactorX.current = newWidth / originalWidth;
                    scaleFactorY.current = newHeight / originalHeight;
                }
            };
            // Initial update
            updateDimensions();
            // Add event listener for window resize
            window.addEventListener('resize', updateDimensions);
            // Cleanup event listener on component unmount
            return () => {
                window.removeEventListener('resize', updateDimensions);
            };
        }
    }, [aiTaggingWrapper.current]);

    // Update the scale factors based on the parent container size
    useEffect(() => {
        if (thumbPreviewWrapper.current !== null) {
            if (callResize.current) return;
            callResize.current = true;
            const updateDimensions = () => {
                console.log(document.getElementById('custom-video-player').offsetHeight)
                console.log((document.getElementById('custom-video-player').offsetHeight + 45) + "px")
                thumbPreviewWrapper.current.style.minHeight = (document.getElementById('custom-video-player').offsetHeight + 45) + "px";
            };
            // Initial update
            updateDimensions();
            // Add event listener for window resize
            window.addEventListener('resize', updateDimensions);
            // Cleanup event listener on component unmount
            return () => {
                window.removeEventListener('resize', updateDimensions);
            };
        }
    }, [thumbPreviewWrapper.current]);
    useEffect(() => {
        if (commentInput.current !== null) {
            const handleInput = () => {
                const currentLength = commentInput.current.value.length;
                // Trim input if it exceeds max character limit
                if (currentLength > maxChars) {
                    commentInput.current.value = commentInput.current.value.substring(0, maxChars);
                }
            };
            const inputElement = commentInput.current;
            inputElement.addEventListener('input', handleInput);
            // Cleanup event listener on component unmount
            return () => inputElement.removeEventListener('input', handleInput);
        }
    }, [commentInput.current]); // Run this effect only once, after initial render

    useEffect(() => {
        if (fileSidebar) {
            document.body.classList.add('file-sidebar-open');
        } else {
            document.body.classList.remove('file-sidebar-open');
        }
    }, [fileSidebar]);
    useEffect(() => {
        if (open) {
            setOpenDrawer(true)
        } else {
            setOpenDrawer(false)
        }
    }, [open]);

    useEffect(() => {
        getDataValere(`/file?file_id=${previewItem.id}`)
            .then((res) => Promise.all([res.status, res.json()]))
            .then(([status, data]) => {
                if (status === 401) {
                    logout()
                    return;
                }
                if (status === 500) {
                    setPopup500(true)
                    return;
                }
                if (status === 499) {
                    var dataNew = JSON.parse(data)
                    var errorType = dataNew.type;
                    setError499(dataNew.errors[errorType][0])
                    setPopup499(true)
                    return;
                }

                const updatedDataTranscriptions = data.transcriptions.map((item, index) => {
                    return {
                        ...item,   // Spread the existing properties
                        index: index // Add new key 'index'
                    };
                });

                setKeywords(data.keywords)
                setTranscript(updatedDataTranscriptions)
                setFileId(data.id)
                setFileName(data.path)
                setFileURL(data.url)
                setFileDuration(data.duration)
                setFileSize(data.size)
                setFileUpdated_at(data.updated_at)
                setFileResolution(data.resolution)
                var res = data.resolution.replace("(", "").replace(")", "").split(",")
                var height = parseInt(res[1]);
                var width = parseInt(res[0]);
                setFileResolutionWidth(width)
                setFileResolutionHeight(height)

                setFileSummary(data.summary)
                setSpeakers(data.speakers)
                setFileCreated_at(data.created_at)
                setFileModifiedBy(data.modify_by)
                setFileType(data.type)
                setFileBucketName(data.bucket_name)
                setFileCloudName(getCloudAccountName(data.cloud_account_id))

            })
        getMetaData();
        getTags();
        getKeywords();
        getComments();
        getClips();
        getThumbs();
    }, []);

    useEffect(() => {
        if (openDrawer) {
            document.getElementById('scrollable').addEventListener('scroll', function () {
                var scroll = document.getElementById('scrollable').scrollTop;
                var TranscriptTop = document.getElementById('TranscriptTop').offsetTop;
                if (scroll > TranscriptTop) {
                    document.getElementById('custom-video-player').classList.add("fixed")
                } else {
                    document.getElementById('custom-video-player').classList.remove("fixed")
                }
            });
        }
    }, [openDrawer]);

    const getMetaData = (draw = false, selectedMetaDate) => {
        getDataValere(`/metadata?file_id=${previewItem.id}`)
            .then((res) => Promise.all([res.status, res.json()]))
            .then(([status, data]) => {
                if (status === 401) {
                    logout()
                    return;
                }
                if (status === 500) {
                    setPopup500(true)
                    return;
                }
                if (status === 499) {
                    var dataNew = JSON.parse(data)
                    var errorType = dataNew.type;
                    setError499(dataNew.errors[errorType][0])
                    setPopup499(true)
                    return;
                }
                setFileMetaDate(data)
                metaDataRef.current = data;
                setGetMetaDataDone(true)
                if (draw) {
                    aiTaggingWrapper.current.innerHTML = '';
                    drawRect(selectedMetaDate)
                }
            })
    };
    const getTags = () => {
        getDataValere(`/tags?file_id=${previewItem.id}`)
            .then((res) => Promise.all([res.status, res.json()]))
            .then(([status, data]) => {
                if (status === 401) {
                    logout()
                    return;
                }
                if (status === 500) {
                    setPopup500(true)
                    return;
                }
                if (status === 499) {
                    var dataNew = JSON.parse(data)
                    var errorType = dataNew.type;
                    setError499(dataNew.errors[errorType][0])
                    setPopup499(true)
                    return;
                }
                setAutoTags(data)
            })
    };
    const getKeywords = () => {
        getDataValere(`/keywords?file_id=${previewItem.id}`)
            .then((res) => Promise.all([res.status, res.json()]))
            .then(([status, data]) => {
                if (status === 401) {
                    logout()
                    return;
                }
                if (status === 500) {
                    setPopup500(true)
                    return;
                }
                if (status === 499) {
                    var dataNew = JSON.parse(data)
                    var errorType = dataNew.type;
                    setError499(dataNew.errors[errorType][0])
                    setPopup499(true)
                    return;
                }
                setManualKeywords(data)
            })
    };
    const getComments = () => {
        getDataValere(`/comments?file_id=${previewItem.id}`)
            .then((res) => Promise.all([res.status, res.json()]))
            .then(([status, data]) => {
                if (status === 401) {
                    logout()
                    return;
                }
                if (status === 500) {
                    setPopup500(true)
                    return;
                }
                if (status === 499) {
                    var dataNew = JSON.parse(data)
                    var errorType = dataNew.type;
                    setError499(dataNew.errors[errorType][0])
                    setPopup499(true)
                    return;
                }
                setComments(data)
            })
    };
    const closeClip = (v) => {
        setEditClipModal(v)
    };
    const getClips = () => {
        getDataValere(`/clip?file_id=${previewItem.id}`)
            .then((res) => Promise.all([res.status, res.json()]))
            .then(([status, data]) => {
                if (status === 401) {
                    logout()
                    return;
                }
                if (status === 500) {
                    setPopup500(true)
                    return;
                }
                if (status === 499) {
                    var dataNew = JSON.parse(data)
                    var errorType = dataNew.type;
                    setError499(dataNew.errors[errorType][0])
                    setPopup499(true)
                    return;
                }
                setClips(data)
            })
    };
    const getThumbs = () => {
        getDataValere(`/category?file_id=${previewItem.id}`)
            .then((res) => Promise.all([res.status, res.json()]))
            .then(([status, data]) => {
                if (status === 401) {
                    logout()
                    return;
                }
                if (status === 500) {
                    setPopup500(true)
                    return;
                }
                if (status === 499) {
                    var dataNew = JSON.parse(data)
                    var errorType = dataNew.type;
                    setError499(dataNew.errors[errorType][0])
                    setPopup499(true)
                    return;
                }
                setFileThumbs(data)
            })
    };
    const deleteClips = (id) => {
        deleteDataValere(`/clip?clip_id=${id}`)
            .then((res) => Promise.all([res.status, res.text()]))
            .then(([status, data]) => {
                if (status === 401) {
                    logout()
                    return;
                }
                if (status === 500) {
                    setPopup500(true)
                    return;
                }
                if (status === 499) {
                    var dataNew = JSON.parse(data)
                    var errorType = dataNew.type;
                    setError499(dataNew.errors[errorType][0])
                    setPopup499(true)
                    return;
                }
                getClips()
            })
    };
    const deleteTags = (id) => {
        deleteDataValere(`/tags?id=${id}`)
            .then((res) => Promise.all([res.status, res.text()]))
            .then(([status, data]) => {
                if (status === 401) {
                    logout()
                    return;
                }
                if (status === 500) {
                    setPopup500(true)
                    return;
                }
                if (status === 499) {
                    var dataNew = JSON.parse(data)
                    var errorType = dataNew.type;
                    setError499(dataNew.errors[errorType][0])
                    setPopup499(true)
                    return;
                }
                getTags()
            })
    };
    const deleteKeyword = (id) => {
        deleteDataValere(`/keywords?id=${id}`)
            .then((res) => Promise.all([res.status, res.text()]))
            .then(([status, data]) => {
                if (status === 401) {
                    logout()
                    return;
                }
                if (status === 500) {
                    setPopup500(true)
                    return;
                }
                if (status === 499) {
                    var dataNew = JSON.parse(data)
                    var errorType = dataNew.type;
                    setError499(dataNew.errors[errorType][0])
                    setPopup499(true)
                    return;
                }
                getKeywords()
            })
    };
    const onCloseDrawer = (e) => {
        e.preventDefault();
        setOpenDrawer(false);
        callback()
    };
    const onEditClip = (e) => {
        e.preventDefault();
        setIsEditClip(true);
        setisRightSidebar(true);
        setAiComment(false);
        setFileInfo(false);
        setAiTagging(false);
    };
    const onEditClipClose = (e) => {
        e.preventDefault();
        setIsEditClip(false);
        setFileInfo(false);
        setisRightSidebar(false);
        setAiComment(false);
    };

    const onAiTagging = () => {
        if (aiTagging) {
            manualTagging.current = false
        }
        setAiTagging(!aiTagging);
        setIsEditClip(false);
        setFileInfo(false);
        setAiComment(false);
        setisRightSidebar(false)
    };

    const onFileInfo = (e) => {
        e.preventDefault();
        setFileInfo(true);
        setIsEditClip(false);
        setAiTagging(false);
        setAiComment(false);
        setisRightSidebar(true);
    };
    const onAiComment = (e) => {
        e.preventDefault();
        setAiComment(true);
        setFileInfo(false);
        setIsEditClip(false);
        setAiTagging(false);
        setisRightSidebar(true);
    };
    const addComment = (e) => {
        e.preventDefault();
        if (commentInput.current.value !== "") {

            var dataToPost = {
                "comment": commentInput.current.value,
                "timestamp": videoPlayer.current?.currentTime.toString(),
                "user": {
                    "id": user.userId,
                    "name": user.username,
                    "photo": "string"
                },
                "file_id": previewItem.id
            }
            postDataValere("/comments", dataToPost)
                .then((res) => Promise.all([res.status, res.json()]))
                .then(([status, data]) => {
                    if (status === 401) {
                        logout()
                        return;
                    }
                    if (status === 500) {
                        setPopup500(true)
                        return;
                    }
                    if (status === 499) {
                        var dataNew = JSON.parse(data)
                        var errorType = dataNew.type;
                        setError499(dataNew.errors[errorType][0])
                        setPopup499(true)
                        return;
                    }
                    getComments()
                })
        }
    };
    const addTag = (e) => {
        e.preventDefault();
        if (newTagName.current.value !== "") {
            var dataToPost =
                {
                    "file_id": previewItem.id,
                    "name": newTagName.current.value
                }
            postDataValere("/tags", dataToPost)
                .then((res) => Promise.all([res.status, res.json()]))
                .then(([status, data]) => {
                    if (status === 401) {
                        logout()
                        return;
                    }
                    if (status === 500) {
                        setPopup500(true)
                        return;
                    }
                    if (status === 499) {
                        var dataNew = JSON.parse(data)
                        var errorType = dataNew.type;
                        setError499(dataNew.errors[errorType][0])
                        setPopup499(true)
                        return;
                    }
                    newTagName.current.value = "";
                    setShowAddTag(false)
                    getTags();
                })
        }
    };

    const addKeyword = (e) => {
        e.preventDefault();
        if (newKeyword.current.value !== "") {
            var dataToPost =
                {
                    "file_id": previewItem.id,
                    "name": newKeyword.current.value
                }
            postDataValere("/keywords", dataToPost)
                .then((res) => Promise.all([res.status, res.json()]))
                .then(([status, data]) => {
                    if (status === 401) {
                        logout()
                        return;
                    }
                    if (status === 500) {
                        setPopup500(true)
                        return;
                    }
                    if (status === 499) {
                        var dataNew = JSON.parse(data)
                        var errorType = dataNew.type;
                        setError499(dataNew.errors[errorType][0])
                        setPopup499(true)
                        return;
                    }
                    newKeyword.current.value = "";
                    setShowAddKeyword(false)
                    getKeywords();
                })
        }
    };


    const togglePlayPause = () => {
        if (videoPlayer.current.paused) {
            videoPlayer.current.play();
            setIsPlaying(true);
            manualTagging.current = false;
        } else {
            videoPlayer.current.pause();
            setIsPlaying(false);
        }
    };

    const skipTime = (seconds) => {
        videoPlayer.current.currentTime += seconds;
    };

    const toggleMute = () => {
        videoPlayer.current.muted = !videoPlayer.current.muted;
        setIsMuted(videoPlayer.current.muted);
    };
    const closeClipModal = () => {
        setEditClipModal(false)
        removeVideoClipEvents()
    };

    const handlePlayClip = (clip) => {
        const startTime = timeStringToSeconds(clip.start_timestamp);
        videoPlayer.current.currentTime = startTime; // Set video to start at the start timestamp
        videoPlayer.current.play(); // Start the video
        setIsPlaying(true);
    };
    const handleProgressClick = (e) => {
        const progressLine = e.currentTarget;
        const rect = progressLine.getBoundingClientRect();
        const clickX = e.clientX - rect.left;
        const width = rect.width;
        const clickPositionPercentage = clickX / width;
        videoPlayer.current.currentTime = clickPositionPercentage * videoPlayer.current.duration;
    };

    useEffect(() => {
        if (videoPlayer.current !== undefined && videoPlayer.current !== null && getMetaDataDone) {

            const player = videoPlayer.current;
            if (player) {
                const updateProgress = () => {
                    if (player.duration) {
                        const progress = (player.currentTime / player.duration) * 100;
                        setVideoPosition(player.currentTime)
                        const watchLine = document.querySelector('.watch-line');
                        if (watchLine) {
                            watchLine.style.width = `${progress}%`;
                            watchLine.style.backgroundColor = '#FC6524'; // Ensure it's visible
                        }
                        loadAiTagging()

                    }
                };

                const handleLoadedMetadata = () => {
                    updateProgress();
                };

                player.addEventListener('timeupdate', updateProgress);
                player.addEventListener('loadedmetadata', handleLoadedMetadata);


                return () => {
                    player.removeEventListener('timeupdate', updateProgress);
                    player.removeEventListener('loadedmetadata', handleLoadedMetadata);
                };
            } else {
                console.error('videoPlayer.current is not defined');
            }
        }
    }, [videoPlayer, getMetaDataDone, scaleFactorX, scaleFactorY]);
    useEffect(() => {
        if (videoPlayer.current !== undefined && comments.length > 0) {
            if (videoPlayer.current.duration) {
                let newArr = [...comments];
                for (var i = 0; i < newArr.length; i++) {
                    newArr[i].positionLeft = ((newArr[i].timestamp / videoPlayer.current.duration) * 100) + "%";
                }
                setCommentsOnVideo(newArr);
            }
        }
    }, [videoPlayer, comments, videoPlayer.current?.duration]);

    useEffect(() => {
        if (aiTagging) {
            loadAiTagging()
        }
    }, [aiTagging]);

    function calculateArea(box) {
        return (box.x2 - box.x1) * (box.y2 - box.y1);
    }

    const loadAiTagging = () => {
        if (aiTaggingWrapper.current !== null) {
            const player = videoPlayer.current;
            aiTaggingWrapper.current.innerHTML = '';

            // fileMetaDate.filter(item => (item.timestamp - player.currentTime) < 0.01).map((item) => {
            metaDataRef.current.filter(item => Math.abs(parseFloat(item.timestamp) - parseFloat(player.currentTime)) < 0.4).map((item) => {
                setSelectedMetaDate(item)
                drawRect(item)
            })

        }
    };
    const drawRect = (item) => {
        console.log(item)
        if (item.detections.length > 0) {
            if (manualTagging.current === true) {
                videoPlayer.current.pause();
                setIsPlaying(false);
            }

            item.detections.sort((a, b) => calculateArea(b) - calculateArea(a)).forEach((detection, index) => {
                const x1 = parseFloat(detection.x1) * scaleFactorX.current;
                const y1 = parseFloat(detection.y1) * scaleFactorY.current;
                const width = (parseFloat(detection.x2) - parseFloat(detection.x1)) * scaleFactorX.current;
                const height = (parseFloat(detection.y2) - parseFloat(detection.y1)) * scaleFactorY.current;
                const rect = document.createElement('div');
                rect.id = "tagging-rect-" + detection.id;

                rect.className = 'find-object-card';
                rect.style.left = `${x1}px`;
                rect.style.top = `${y1}px`;
                rect.style.width = `${width}px`;
                rect.style.height = `${height}px`;
                rect.style.zIndex = `${index}`;
                const span = document.createElement('span');
                span.textContent = detection.label;
                const input = document.createElement('input');
                input.value = detection.label;
                input.dataset.frameid = detection.id;
                input.className = 'edit-tag-field';
                input.type = 'text';

                // Create the anchor element
                const anchor = document.createElement('a');
                anchor.href = '#';
                anchor.className = 'cross-black-icon';
                anchor.dataset.frameid = detection.id;
                anchor.innerHTML = '<svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">\n    <path fill-rule="evenodd" clip-rule="evenodd" d="M19.1182 5.46967C19.4111 5.76256 19.4111 6.23744 19.1182 6.53033L7.11822 18.5303C6.82533 18.8232 6.35045 18.8232 6.05756 18.5303C5.76467 18.2374 5.76467 17.7626 6.05756 17.4697L18.0576 5.46967C18.3505 5.17678 18.8253 5.17678 19.1182 5.46967Z" fill="#373843"/>\n    <path fill-rule="evenodd" clip-rule="evenodd" d="M6.05756 5.46967C5.76467 5.76256 5.76467 6.23744 6.05756 6.53033L18.0576 18.5303C18.3505 18.8232 18.8253 18.8232 19.1182 18.5303C19.4111 18.2374 19.4111 17.7626 19.1182 17.4697L7.11822 5.46967C6.82533 5.17678 6.35045 5.17678 6.05756 5.46967Z" fill="#373843"/>\n</svg>';

                // Append the span and anchor to the outer div
                rect.appendChild(span);
                rect.appendChild(input);
                rect.appendChild(anchor);


                // Add event listener to the cross-black-icon
                input.addEventListener('keyup', function (event) {
                    event.preventDefault(); // Prevent default anchor behavior
                    const frameId = this.dataset.frameid;
                    updateAiTagBox(frameId, this.value)
                });

                // Add event listener to the cross-black-icon
                anchor.addEventListener('click', function (event) {
                    event.preventDefault(); // Prevent default anchor behavior
                    const frameId = this.dataset.frameid;
                    deleteAiTagBox(frameId)
                    document.getElementById("tagging-rect-" + frameId).remove();
                });

                aiTaggingWrapper.current.appendChild(rect);

            });
        }
    }


// Function to update a label based on detection ID
    const updateAiTagBox = (id, newLabel) => {
        setSelectedMetaDate(prevState => ({
            ...prevState, // Spread the previous state
            detections: prevState.detections?.map(detection =>
                detection.id === id ? {...detection, label: newLabel} : detection
            )
        }));
        setShowSaveButton(true)
    };

    const deleteAiTagBox = (frameId) => {
        setSelectedMetaDate(prevState => ({
            ...prevState, // Spread the previous state
            detections: prevState.detections.filter(i => i.id !== frameId)
        }));
        setShowSaveButton(true)
    };
    const handleLoadedMetadata = () => {
        const player = videoClip.current;
        // Update slider max value to video duration in seconds
        startSlider.current.max = player.duration;
        endSlider.current.max = player.duration;
        setStartClip(0)
        setEndClip(5)
        setClipDuration(player.duration)
    };
    const removeVideoClipEvents = () => {
        const player = videoClip.current;
        player.removeEventListener('timeupdate', handleLoadedMetadata);
        player.removeEventListener('loadedmetadata', handleLoadedMetadata);
        setStartClip(0)
        setEndClip(5)
        setClipDuration(0)
        videoClip.current = null
        startSlider.current = null
        endSlider.current = null
    };

    useEffect(() => {
        if (videoClip.current !== undefined && videoClip.current !== null) {
            const player = videoClip.current;
            if (player) {
                player.removeEventListener('timeupdate', handleLoadedMetadata);
                player.addEventListener('loadedmetadata', handleLoadedMetadata);
                return () => {
                    player.removeEventListener('timeupdate', handleLoadedMetadata);
                    player.removeEventListener('loadedmetadata', handleLoadedMetadata);
                };
            }
        } else {
            setStartClip(0)
            setEndClip(5)
            setClipDuration(0)
            startSlider.current = null
            endSlider.current = null
        }
    }, [videoClip.current]);

// Function to convert hh:mm:ss to seconds
    function timeStringToSeconds(timeString) {
        const [hours, minutes, seconds] = timeString.split(':').map(Number);
        return hours * 3600 + minutes * 60 + seconds;
    }

    function manualTaggingClick() {
        manualTagging.current = true;
        if (aiTagging) {
            loadAiTagging()
        }
        setAiTagging(true);
        setIsEditClip(false);
        setFileInfo(false);
        setAiComment(false);
        setisRightSidebar(false)
        aiTaggingWrapper.current.classList.add("manual-tagging")
    }

    function updateSlider() {
        // Update the slider track fill
        const minPercent = (startClip / startSlider.current.max) * 100;
        const maxPercent = (endClip / endSlider.current.max) * 100;
        sliderTrack.current.style.background = `linear-gradient(to right, transparent ${minPercent}%, #FC6524 ${minPercent}%, #FC6524 ${maxPercent}%, transparent ${maxPercent}%)`;
        sliderTrackGray.current.style.background = `linear-gradient(to right, transparent ${minPercent}%, #E2E3E9 ${minPercent}%, #E2E3E9 ${maxPercent}%, transparent ${maxPercent}%)`;

    }


    useEffect(() => {
        if (startSlider.current !== undefined && startSlider.current !== null) {
            updateSlider()
        }
    }, [startClip, endClip]);

    const [isDesktop, setIsDesktop] = useState(false);
    useEffect(() => {
        // Function to check screen size
        const checkScreenSize = () => {
            setIsDesktop(window.innerWidth >= 1024);
        };

        // Initial check
        checkScreenSize();

        // Add event listener to handle screen resize
        window.addEventListener('resize', checkScreenSize);

        // Cleanup event listener on component unmount
        return () => window.removeEventListener('resize', checkScreenSize);
    }, []);

    // Handling loading state
    const handleLoadStart = () => {
        setIsLoadingVideo(true);
    };

    const handleLoadedData = () => {
        setIsLoadingVideo(false);
    };
    return (
        <>
            {contextHolder}
            {editClipModal &&
                <CreateClip
                    open={editClipModal}
                    callback={closeClip}
                    fileBucketName={fileBucketName}
                    fileId={fileId}
                    fileName={fileName}
                    fileURL={fileURL}
                    getClips={getClips}
                    currentProgress={videoPlayer.current?.currentTime}
                />
            }
            <Drawer
                title="Video Drawer"
                placement={'bottom'}
                closable={false}
                onClose={onCloseDrawer}
                open={openDrawer}
            >
                <div className="ai-drawer-main">
                    {/*<div className={`ai-left-sidebar ${!isRightSidebar ? 'active' : ''}`}>*/}
                    <div className={`ai-left-sidebar ${!isRightSidebar && isDesktop ? 'active' : ''}`}>
                        <a href="#" className="open-drawer" onClick={(e) => {
                            e.preventDefault();
                            setIsEditClip(false);
                            setisRightSidebar(false);
                            setAiComment(false);
                            setFileInfo(false);
                            setAiTagging(false);
                            setIsDesktop(true);
                        }}>
                            <img src={rightDrawerIcon} alt="icon"/>
                        </a>
                        <div className="ai-sidebar-wrapper">
                            <div className="title-wrapper-main">
                                <div className="row">
                                    <div className="col-md-12 d-block d-md-block col-10 align-self-center">
                                        <div className="title-wrapper">
                                            <a href="#" className="back-icon" onClick={onCloseDrawer}><img
                                                src={backArrowIcon} alt="icon"/></a>
                                            <h3>{fileName}</h3>
                                        </div>
                                    </div>
                                    <div className="col-md-12 d-block d-md-none col-2 align-self-center">
                                        <div className="cross-icon">
                                            <a href="#" onClick={(e) => {
                                                e.preventDefault();
                                                setIsDesktop(false);
                                            }}><i className="fa-solid fa-x"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                                <div className="form ai-search"  onClick={(e) => {
                                    e.preventDefault()
                                    setAiTagging(false);
                                    setIsEditClip(false);
                                    setFileInfo(false);
                                    setAiComment(false);
                                    setisRightSidebar(false)
                                    manualTagging.current = false
                                }}>
                                    <div className={`form-group ${aiTagging ? "disabled" : ""}`}>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Enter your search"
                                            onChange={(e) => {
                                                var element;
                                                var filteredData = transcript.filter((item) => (item.text.toLowerCase().indexOf(e.target.value.toLowerCase()) > -1));
                                                var elements = document.querySelectorAll('.highLight');
                                                // Iterate over the NodeList and remove the class
                                                elements.forEach(element => {
                                                    element.classList.remove('highLight');
                                                });
                                                if (filteredData.length > 0) {
                                                    element = document.getElementById("transcript-item-" + filteredData[0].index);
                                                    filteredData.map((item) => {
                                                        document.getElementById("transcript-item-" + item.index).classList.add("highLight");
                                                    })
                                                }
                                                if (e.target.value === "") {
                                                    element = document.getElementById("TranscriptTop");
                                                    elements = document.querySelectorAll('.highLight');
                                                    elements.forEach(element => {
                                                        element.classList.remove('highLight');
                                                    });
                                                }

                                                if (element !== undefined) {
                                                    element.scrollIntoView({behavior: "smooth"}); // Smoothly scrolls to the element
                                                }
                                            }}
                                        />
                                        <img src={searchIcon} alt="icon" className="icon"/>
                                    </div>
                                </div>
                            <div className="ai-tags-wrapper">
                                <div className="copy-title">
                                    <p className="title">Auto-tags</p>
                                    <a href="#" onClick={(e) => {
                                        e.preventDefault();
                                        setShowAddTag(true)
                                    }} className="add-user-plus-icon"><img src={addicon} alt="icon"/></a>
                                </div>
                                <ul className="ai-tags">
                                    {autoTags.map((item) => (
                                        <li><a href="#" onClick={(e) => {
                                            e.preventDefault();
                                        }}>{item.name}</a>
                                            <a href="#" onClick={(e) => {
                                                e.preventDefault();
                                                deleteTags(item.id)
                                            }} className="cross-black-icon"><img src={crossBlackIcon} alt="icon"/></a>
                                        </li>
                                    ))}
                                </ul>
                                {showAddTag &&
                                    <div className="form ai-search add-new-tag">
                                        <div className="form-group">
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Tag Name"
                                                ref={newTagName}
                                            />
                                            <a href="#" onClick={addTag} className="">Add</a>
                                        </div>
                                    </div>
                                }
                            </div>

                            {speakers?.length > 0 &&
                                <div className="ai-tags-wrapper">
                                    <p className="title">Speakers</p>
                                    <ul className="ai-tags">
                                        {speakers.map((item) => (
                                            <li><a href="#" id={item}>{item}</a></li>
                                        ))}
                                    </ul>
                                </div>
                            }
                            {keywords !== "" &&
                                <div className="ai-copy-wrapper">
                                    <div className="copy-title">
                                        <p className="title">Keywords </p>
                                        <a href="#" onClick={(e) => {
                                            e.preventDefault();
                                            navigator.clipboard.writeText(keywords);
                                            api.open({
                                                message: 'Keywords Copied.',
                                                icon: <img src={toastIcon} alt="toastIcon"/>,
                                                duration: 3,
                                            });
                                        }} className="copy-icon"><img src={aiCopyIcon} alt="icon"/></a>
                                    </div>
                                    <div className="copy-body">
                                        <p>{keywords}</p>
                                    </div>
                                </div>
                            }

                            <div className="ai-tags-wrapper">
                                <div className="copy-title">
                                    <p className="title">Manual Keywords</p>
                                    <a href="#" onClick={(e) => {
                                        e.preventDefault();
                                        setShowAddKeyword(true)
                                    }} className="add-user-plus-icon"><img src={addicon} alt="icon"/></a>
                                </div>
                                <ul className="ai-tags">
                                    {manualKeywords.map((item) => (
                                        <li><a href="#" onClick={(e) => {
                                            e.preventDefault();
                                        }}>{item.name}</a>
                                            <a href="#" onClick={(e) => {
                                                e.preventDefault();
                                                deleteKeyword(item.id)
                                            }} className="cross-black-icon"><img src={crossBlackIcon} alt="icon"/></a>
                                        </li>
                                    ))}
                                </ul>
                                {showAddKeyword &&
                                    <div className="form ai-search add-new-tag">
                                        <div className="form-group">
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Keyword"
                                                ref={newKeyword}
                                            />
                                            <a href="#" onClick={addKeyword} className="">Add</a>
                                        </div>
                                    </div>
                                }
                            </div>
                            {fileSummary !== "" &&
                                <div className="ai-copy-wrapper mb-0">
                                    <div className="copy-title">
                                        <p className="title">Summary</p>
                                        <a href="#" onClick={(e) => {
                                            e.preventDefault();
                                            navigator.clipboard.writeText(fileSummary);
                                            api.open({
                                                message: 'Summary Copied.',
                                                icon: <img src={toastIcon} alt="toastIcon"/>,
                                                duration: 3,
                                            });
                                        }} className="copy-icon"><img src={aiCopyIcon} alt="icon"/></a>
                                    </div>
                                    <div className="copy-body">
                                        <p>{fileSummary}</p>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                    <div className={`ai-right-sidebar ${isRightSidebar ? 'active' : ''}`}>
                        {isEditClip && (
                            <div className="ai-sidebar-wrapper">
                                <div className="title-header">
                                    <h3 className="title">Clips</h3>
                                    <a href="#" className="cross-icon" onClick={onEditClipClose}><img
                                        src={crossBlackIcon} alt="icon"/></a>
                                </div>
                                {clips.length === 0 && (
                                    <div className="no-clip">
                                        <div className="no-clip-wrapper">
                                            <h3>No clips <strong>created</strong> yet.</h3>
                                            <p>Turn your empty space into a thriving oasis.</p>
                                            <a href="#" className="btn-style icon" onClick={(e) => {
                                                e.preventDefault();
                                                videoPlayer.current.pause();
                                                setIsPlaying(false);
                                                setEditClipModal(true)
                                            }}><img src={plusWhiteIcon} alt="icon"/>Create a clip</a>
                                        </div>
                                    </div>
                                )}
                                {clips.length > 0 && (
                                    <div className="cutted-clipped">
                                        <div className="create-clip">
                                            <a href="#" onClick={(e) => {
                                                e.preventDefault()
                                                videoPlayer.current.pause();
                                                setIsPlaying(false);
                                                setEditClipModal(true)
                                            }}><img src={plusWhiteIcon} alt="icon"/>Create a clip</a>
                                        </div>
                                        <div className="cutted-clipped-wrapper">
                                            {clips.map((item) => (
                                                <div className="clipped-card">
                                                    <div className="clip-detail">
                                                        <div className="img-wrapper"
                                                             onClick={() => handlePlayClip(item)}>
                                                            <img src={playBlackIcon} alt="icon" className="play-icon"/>
                                                        </div>
                                                        <div className="content">
                                                            <p>{item.clip_name}</p>
                                                            <span
                                                                className="count">{formatTime(timeStringToSeconds(item.end_timestamp) - timeStringToSeconds(item.start_timestamp))}</span>
                                                        </div>
                                                    </div>
                                                    <div className="clip-options delete-wrapper">
                                                        <Popconfirm
                                                            placement="topLeft"
                                                            title="Are you sure you want to delete this clip?"
                                                            okText="Yes"
                                                            cancelText="No"
                                                            getPopupContainer={trigger => trigger.parentNode}
                                                            onConfirm={() => {
                                                                deleteClips(item.id)
                                                            }}
                                                        ><a href="#" onClick={(e) => {
                                                            e.preventDefault()
                                                        }}><img src={trashBlackIcon} alt="icon"/></a>
                                                        </Popconfirm>

                                                        <a href={item.clip_url} target="_blank"><img
                                                            src={downloadBlackIcon} alt="icon"/></a>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                )}
                            </div>
                        )}
                        {fileInfo && (
                            <div className="ai-sidebar-wrapper">
                                <div className="file-name-wrapper">
                                    <div className="title-wrapper">
                                        <h3 className="h3">{fileName}</h3>
                                        <a href="#" className="cross-icon" onClick={onEditClipClose}><img
                                            src={crossBlackIcon} alt="icon"/></a>
                                    </div>
                                    <div className="file-name-body">
                                        <ul className="btn-list">
                                            <li><a href="#" className="btn-style icon"><img src={infoWhiteIcon}
                                                                                            alt="img"/>About the
                                                file</a></li>
                                            {/*<li><a href="#" className="btn-style icon text"><img src={fileIcon} alt="img"/>All files</a></li>*/}
                                        </ul>
                                        <div className="information-wrapper">
                                            <p className="p bold title">General information</p>
                                            <ul className="info">
                                                <li>
                                                    <p className="small semi">Type:</p>
                                                    <p className="small">{fileType}</p>
                                                </li>
                                                <li>
                                                    <p className="small semi">Size:</p>
                                                    <p className="small">{fileSize}</p>
                                                </li>
                                                <li>
                                                    <p className="small semi">Resolution:</p>
                                                    <p className="small">{fileResolution}</p>
                                                </li>
                                                <li>
                                                    <p className="small semi">Cloud:</p>
                                                    <p className="small">{fileCloudName}</p>
                                                </li>
                                                <li>
                                                    <p className="small semi">Bucket:</p>
                                                    <p className="small">{fileBucketName}</p>
                                                </li>
                                                <li>
                                                    <p className="small semi">Created:</p>
                                                    <p className="small">{getFormatedDateFiles(fileCreated_at)}</p>
                                                </li>
                                                <li>
                                                    <p className="small semi">Modified:</p>
                                                    <p className="small">{getFormatedDateFiles(fileUpdated_at)} {fileModifiedBy !== "" ? ` by ${fileModifiedBy}` : ""}</p>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="tags-wrapper">
                                            <p className="p bold title">Tags</p>
                                            <ul className="tags">
                                                {autoTags.map((item) => (
                                                    <li><a href="#" id={item.id}>{item.name}</a></li>
                                                ))}
                                            </ul>
                                        </div>
                                        <Comments data={comments}/>
                                    </div>
                                </div>
                            </div>
                        )}
                        {aiComment && (
                            <div className="ai-sidebar-wrapper">
                                <div className="file-name-wrapper">
                                    <div className="title-wrapper">
                                        <h3 className="h3">Comments</h3>
                                        <a href="#" className="cross-icon" onClick={onEditClipClose}><img
                                            src={crossBlackIcon} alt="icon"/></a>
                                    </div>
                                    <div className="file-name-body">
                                        <div className="tags-wrapper">
                                            {/*<div className="select-wrapper">
                                                <Select
                                                    defaultValue="Frame Time"
                                                    options={[
                                                        {value: 'All', label: 'All'},
                                                        {value: 'Start (0:00 to 2:00)', label: 'Start (0:00 to 2:00)'},
                                                        {value: 'Quarter (2:01 to 4:00)', label: 'Quarter (2:01 to 4:00)'},
                                                        {value: 'Middle (4:01 to 6:00)', label: 'Middle (4:01 to 6:00)'},
                                                        {value: 'Three-quarters (6:01 to 8:00)', label: 'Three-quarters (6:01 to 8:00)'},
                                                        {value: 'End (8:01: to 10:00)', label: 'End (8:01: to 10:00)'},
                                                    ]}
                                                />
                                            </div>*/}
                                            <Comments data={comments}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                    <div id="scrollable"
                         className={`ai-main-content  ${isRightSidebar ? 'right-active' : 'left-active'}`}>
                        <div className="tool-menu-wrapper">
                            {/*<div className="tool-hamburger">
                                <a href="#" className="ai-icon">
                                    <i className="fa-solid fa-bars"></i>
                                </a>
                            </div>*/}
                            <ul className="tool-menu">
                                <li><a href="#" onClick={onEditClip} className={`${isEditClip ? 'active' : ''}`}><img
                                    src={clipsIcon} alt="icon"/>Clips</a></li>
                                <li>
                                    <Tooltip
                                        getPopupContainer={trigger => trigger.parentNode}
                                        trigger="hover"
                                        placement="bottomLeft"
                                        title={<ul className="view-list">
                                            <li><a href="#">
                                                <Switch onChange={onAiTagging} checked={aiTagging}/> AI tagging</a></li>
                                            <li><a href="#" onClick={(e) => {
                                                e.preventDefault();
                                                manualTaggingClick()
                                            }}>Manual tagging</a></li>
                                        </ul>}
                                    >
                                        {!aiTagging &&
                                            <a href="#" className={`${aiTagging ? 'active' : ''}`}><img
                                                src={taggingIcon} alt="icon"/>AI tagging</a>

                                        }
                                        {aiTagging &&

                                            <a href="#" onClick={(e) => {
                                                e.preventDefault()
                                                setAiTagging(false);
                                                setIsEditClip(false);
                                                setFileInfo(false);
                                                setAiComment(false);
                                                setisRightSidebar(false)
                                                manualTagging.current = false
                                            }} className={`${aiTagging ? 'active' : ''}`}><img src={taggingIcon}
                                                                                               alt="icon"/>Hide tagging</a>
                                        }
                                    </Tooltip>
                                </li>
                                <li><a href="#" onClick={onFileInfo} className={`${fileInfo ? 'active' : ''}`}><img
                                    src={fileInfoIcon} alt="icon"/>File information</a></li>
                                <li><a href="#" onClick={onAiComment} className={`${aiComment ? 'active' : ''}`}><img
                                    src={commnetIcon} alt="icon"/>Comments</a></li>
                            </ul>
                        </div>
                        <div id="thumbnail-preview-wrapper" ref={thumbPreviewWrapper}>
                            <div className="thumbnail-preview thumbnail-video-preview" id="custom-video-player">
                                {isLoadingVideo &&
                                    <div className="loading-wrapper">
                                        <div className="lds-roller">
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                        </div>
                                    </div>
                                }
                                <video ref={videoPlayer}
                                       className="video"
                                       controls={false}
                                       src={fileURL}
                                       onLoadStart={handleLoadStart}
                                       onLoadedData={handleLoadedData}
                                ></video>
                                {/*{!videoControls &&*/}
                                {!videoControls && videoPlayer.current !== undefined &&
                                    <a href="#" className="play-icon" onClick={(e) => {
                                        e.preventDefault()
                                        setVideoControls(true);
                                        manualTagging.current = false;
                                        videoPlayer.current.play();
                                        setIsPlaying(true);
                                    }}><img src={playIcon} alt="icon"/></a>
                                }

                                {aiTagging &&
                                    <a href="#" className="btn-style orange-btn icon manually-tagging" onClick={(e) => {
                                        e.preventDefault();
                                        manualTaggingClick()
                                    }}>Manually Tag <img src={starIcon} alt="icon"/></a>
                                }
                                {aiTagging && manualTagging.current === true && showSaveButton &&
                                    <Button loading={loadingSave} className="btn-style orange-btn icon save-btn-tagging"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setLoadingSave(true)
                                                updateDataValere("/metadata", selectedMetaDate)
                                                    .then((res) => Promise.all([res.status, res.text()]))
                                                    .then(([status, data]) => {
                                                        setLoadingSave(false)
                                                        setShowSaveButton(false)

                                                        getMetaData(true, selectedMetaDate);
                                                        /*let newArr = [...fileMetaDate];
                                                        for (var i = 0; i < newArr.length; i++) {
                                                            if (newArr[i].image_id === selectedMetaDate.image_id) {
                                                                newArr[i] = selectedMetaDate;
                                                            }
                                                        }
                                                        setFileMetaDate(newArr)*/

                                                        aiTaggingWrapper.current.innerHTML = '';
                                                        drawRect(selectedMetaDate)
                                                        if (status === 204) {
                                                            api.open({
                                                                message: 'Tags Saved.',
                                                                icon: <img src={toastIcon} alt="toastIcon"/>,
                                                                duration: 3,
                                                            });
                                                            manualTagging.current = false;
                                                            return;
                                                        }
                                                        if (status === 401) {
                                                            logout()
                                                            return;
                                                        }
                                                        if (status === 500) {
                                                            setPopup500(true)
                                                            return;
                                                        }
                                                        if (status === 499) {
                                                            var dataNew = JSON.parse(data)
                                                            var errorType = dataNew.type;
                                                            setError499(dataNew.errors[errorType][0])
                                                            setPopup499(true)
                                                            return;
                                                        }
                                                    }).catch(() => {
                                                    setLoadingSave(false)
                                                })
                                            }}>Save <img src={saveIcon} alt="icon"/></Button>
                                }
                                {aiTagging &&
                                    <div
                                        className={`video-tagging-wrapper ${manualTagging.current === true ? "manual-tagging" : ""}`}
                                        ref={aiTaggingWrapper}></div>
                                }

                                {videoControls && videoPlayer.current !== undefined &&
                                    <div className="video-tool-wrapper">
                                        {!aiTagging &&
                                            <div className="write-comment-wrapper hide-for-fixed">
                                                {showAddCommentsOnVideo &&

                                                    <div className="write-comment-card"
                                                         style={{left: currentCommentPosition}}>
                                                        <a href="#" className="cross-icon" onClick={(e) => {
                                                            e.preventDefault();
                                                            setShowAddCommentsOnVideo(false)
                                                        }}>
                                                            <img src={crossOrangeIcon} alt="icon"/>
                                                        </a>
                                                        <img src={writeCommentIcon} alt="img"
                                                             className={`comment-icon ${videoPlayer.current.currentTime > videoPlayer.current?.duration / 2 ? "flip-image" : ""}`}/>
                                                        <input type="text" ref={commentInput} onKeyUp={(event) => {
                                                            if (event.key === 'Enter') {
                                                                addComment(event);
                                                                commentInput.current.value = "";
                                                                setShowAddCommentsOnVideo(false)
                                                            }
                                                        }}
                                                               placeholder={`Write your comment at  ${formatTime(videoPlayer.current.currentTime)}`}/>
                                                    </div>
                                                }

                                                {commentsOnVideo.length > 0 && commentsOnVideo.map(item => (
                                                    <a href="#" onClick={onAiComment} style={{left: item.positionLeft}}
                                                       className="tag-comment-card tag-1" id={item.id}>
                                                        <img src={tagCommentIcon} alt="img" className="comment-icon"/>
                                                    </a>
                                                ))}
                                            </div>
                                        }

                                        <span className="progress-line" onClick={handleProgressClick}>
                                    <span className="watch-line"></span>
                                  </span>
                                        <div className="video-tools">
                                            <div className="row">
                                                <div className="col-7 align-self-center">
                                                    <ul className="tools">
                                                        <li>
                                                            <a href="#" onClick={togglePlayPause}>
                                                                <img src={isPlaying ? pauseIcon : playWhiteIcon}
                                                                     className={isPlaying ? 'w-18' : ''} alt="icon"/>
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="#" onClick={() => skipTime(10)}>
                                                                <img src={audioNextIcon} alt="icon"/>
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="#" onClick={() => skipTime(-10)}>
                                                                <img src={audioPreviousIcon} alt="icon"/>
                                                            </a>
                                                        </li>
                                                        {videoPosition !== "" &&
                                                            <li className="hide-for-fixed">
                                                                <p>{formatTime(videoPosition)} / {formatTime(videoPlayer?.current?.duration)}</p>
                                                            </li>
                                                        }
                                                        {/*   <li className="hide-for-fixed">
                                                                <p>{videoPlayer?.current?.currentTime.toFixed(2)} --- {formatTime(videoPlayer?.current?.currentTime)}</p>
                                                            </li>*/}
                                                        {/*   <li className="hide-for-fixed">
                                                                <p onClick={()=>{
                                                                    videoPlayer.current.playbackRate = .5;
                                                                }}>Slow</p>
                                                            </li>*/}
                                                    </ul>
                                                </div>
                                                <div className="col-5 align-self-center">
                                                    <ul className="tools right">
                                                        <li className="hide-for-fixed">
                                                            <a href="#" onClick={(e) => {
                                                                e.preventDefault();
                                                                videoPlayer.current.pause();
                                                                setIsPlaying(false);
                                                                var percet = (videoPosition / videoPlayer.current?.duration) * 100;
                                                                var middleOfVideo = videoPlayer.current?.duration / 2
                                                                if (videoPosition > middleOfVideo) {
                                                                    setCurrentCommentPosition(`calc(${percet}% - 425px)`)
                                                                } else {
                                                                    setCurrentCommentPosition(`calc(${percet}% - 15px)`)

                                                                }
                                                                setShowAddCommentsOnVideo(true)
                                                            }}>
                                                                <img src={commnetVideoIcon} alt="icon"/>
                                                            </a>
                                                        </li>
                                                        <li className="hide-for-fixed">
                                                            <a href="#" onClick={toggleMute}>
                                                                <img src={isMuted ? muteIcon : speakerIcon}
                                                                     className={isMuted ? 'w-18' : ''} alt="icon"/>
                                                            </a>
                                                        </li>
                                                        <li className="hide-for-fixed">
                                                            <a href="#" onClick={(e) => {
                                                                e.preventDefault();

                                                                setAiTagging(false);
                                                                setIsEditClip(false);
                                                                setFileInfo(false);
                                                                setAiComment(false);
                                                                setisRightSidebar(false)
                                                                manualTagging.current = false
                                                                videoPlayer.current.requestPictureInPicture();
                                                            }} id="requestPictureInPicture">
                                                                <img src={minimizeIcon} alt="icon"/>
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="#" onClick={(e) => {
                                                                e.preventDefault();
                                                                videoPlayer.current.requestFullscreen();
                                                            }}>
                                                                <img src={largeIcon} alt="icon"/>
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="transcript-wrapper" id="TranscriptTop">
                            <div className="transcript-header">
                                {!aiTagging && (
                                    <div className="row">
                                        <div className="col-6 align-self-center">
                                            <div className="content">
                                                <p className="title">Transcript</p>
                                                <span> Audio to Text</span>
                                            </div>
                                        </div>
                                        <div className="col-6 align-self-center">
                                            <a href="#" className="translate"><img src={globeIcon} alt="icon"/>Translate</a>
                                        </div>
                                    </div>
                                )}
                                {aiTagging && (
                                    <div className="row">
                                        <div className="col-6 align-self-center">
                                            <div className="content">
                                                <p className="title">Identified</p>
                                            </div>
                                        </div>
                                        <div className="col-6 align-self-center text-end">
                                            {!editObjects &&
                                                <a href="#" className="edit-icon" onClick={(e) => {
                                                    e.preventDefault();
                                                    setEditObjects(true)
                                                }}><img src={editBlackIcon} alt="icon"/></a>
                                            }
                                            {editObjects &&
                                                <Button loading={loading} className="btn-style orange-btn done-btn"
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            setEditObjects(false)
                                                        }}>Done</Button>
                                            }
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className="transcript-body">
                                {!aiTagging && (
                                    <ul className="transcript">
                                        {transcript?.map((item) => (
                                            <li id={`transcript-item-${item.index}`}>
                                                <p className="time-speaker">{item.speaker}</p>
                                                <p className="time-tag">{formatTime(item.start)}</p>
                                                <p className="script">{item.text}</p>
                                            </li>
                                        ))}
                                    </ul>
                                )}
                                {aiTagging && (
                                    <ul className="identified">
                                        {fileThumbs.map(item => (
                                            <li>
                                                <div className={`identified-card ${editObjects ? 'active' : ''}`}>
                                                    {editObjects &&
                                                        <a href="#" className="cross-icon" onClick={(e) => {
                                                            e.preventDefault();
                                                            setFileThumbs(fileThumbs.filter(i => i.id !== item.id));

                                                        }}><img src={crossRedCircleIcon} alt="icon"/></a>
                                                    }
                                                    <div className="img-wrapper">
                                                        <img src={item.url} alt="img"/>
                                                    </div>
                                                    <div className="content">
                                                        <input type="text" value={item.label} onKeyUp={(event) => {
                                                            if (editObjects) {
                                                                // Call the function if Enter key is pressed
                                                                if (event.key === 'Enter') {
                                                                    console.log("save")
                                                                    console.log(item);

                                                                    var dataToPost = {
                                                                        "id": item.id,
                                                                        "new_label": event.target.value
                                                                    }
                                                                    updateDataValere("/category", dataToPost)
                                                                        .then((res) => Promise.all([res.status, res.text()]))
                                                                        .then(([status, data]) => {
                                                                            if (status === 204) {
                                                                                api.open({
                                                                                    message: 'Thumbnail Title Saved.',
                                                                                    icon: <img src={toastIcon}
                                                                                               alt="toastIcon"/>,
                                                                                    duration: 3,
                                                                                });
                                                                                return;
                                                                            }
                                                                            if (status === 401) {
                                                                                logout()
                                                                                return;
                                                                            }
                                                                            if (status === 500) {
                                                                                setPopup500(true)
                                                                                return;
                                                                            }
                                                                            if (status === 499) {
                                                                                var dataNew = JSON.parse(data)
                                                                                var errorType = dataNew.type;
                                                                                setError499(dataNew.errors[errorType][0])
                                                                                setPopup499(true)
                                                                                return;
                                                                            }
                                                                        })

                                                                }
                                                            }
                                                        }} onChange={(event) => {
                                                            if (editObjects) {
                                                                let newArr = [...fileThumbs];
                                                                for (var i = 0; i < newArr.length; i++) {
                                                                    if (newArr[i].id === item.id) {
                                                                        newArr[i].label = event.target.value;
                                                                    }
                                                                }
                                                                setFileThumbs(newArr);
                                                            }
                                                        }}/>
                                                        {/*<p>{item.label}</p>*/}
                                                    </div>
                                                </div>
                                            </li>
                                        ))
                                        }
                                    </ul>
                                )}

                            </div>
                        </div>
                    </div>
                </div>
            </Drawer>
        </>
    );
};

export default VideoDetail;



