import React, {useEffect, useState, useRef} from "react";
import {Link, useNavigate} from "react-router-dom";
import {CheckCircleFilled} from '@ant-design/icons';
import pageLogo from "../assets/images/page-logo.svg";
import {Button, notification, Tooltip} from "antd";
import warning from "../assets/images/icons/warning-sign.svg";
import {useAuth} from "../context/AuthContext";
import config from "../config";
import {GoogleLogin} from "@react-oauth/google";
import darkLogo from "../assets/images/logo-dark.svg";
import pageLogoWhite from "../assets/images/logo-dark.svg";

const Signup = () => {
    const [userDetail, setuserDetail] = useState({
        name: "",
        lastName: "",
        email: "",
        password: ""
    });
    const {isDark, getToken, setPopup500, omnisend, checkLength} = useAuth();
    const dataFetchedRef = useRef(false);
    const [errorText, setErrorText] = useState('');
    const [errorTextPassword, setErrorTextPassword] = useState('');
    const [eye, seteye] = useState(true);
    const navigate = useNavigate();
    const {setIsAuthenticated, setJWTToken, setLoginSession, setError499, setPopup499, sendDataToMixPanel, setSignUpWithGoogle, logoutONSignup} = useAuth();
    const [loading, setLoading] = useState(false);
    const [api, contextHolder] = notification.useNotification();
    const [password, setpassword] = useState("password");
    const [showError, setShowError] = useState(false);
    const [signupStep, setSignupStep] = useState(1);
    const [showErrorAll, setShowErrorAll] = useState(false);
    const [showErrorEmailWrong, setShowErrorEmailWrong] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const eightCharsOrMore = /.{8,}/g; // eight characters or more
    const atLeastOneUppercase = /[A-Z]/g; // capital letters from A to Z
    const atLeastOneLowercase = /[a-z]/g; // small letters from a to z
    const atLeastOneNumeric = /[0-9]/g; // numbers from 0 to 9
    const atLeastOneSpecialChar = /[#?!@$%^&*-]/g; // any of the special characters within the square brackets
    const [meter, setMeter] = useState(false);
    const [loadingGoogle, setLoadingGoogle] = useState(false);
    const [googleBTN, setGoogleBTN] = useState(false);
    const passwordTracker = {
        uppercase: userDetail.password.match(atLeastOneUppercase),
        lowercase: userDetail.password.match(atLeastOneLowercase),
        number: userDetail.password.match(atLeastOneNumeric),
        specialChar: userDetail.password.match(atLeastOneSpecialChar),
        eightCharsOrGreater: userDetail.password.match(eightCharsOrMore),
    }
    const passwordStrength = Object.values(passwordTracker).filter(value => value).length;
    const passwordStrengthValues = Object.values(passwordTracker).filter(value => value);

    const inputEvent = (event) => {
        setShowError(false)
        setEmailError(false);
        setShowErrorAll(false);
        setShowErrorEmailWrong(false);
        setErrorTextPassword("");
        const name = event.target.name;
        const value = event.target.value;
        setuserDetail((lastValue) => {
            return {
                ...lastValue,
                [name]: value
            }
        });
    }

    const Eye = () => {
        if (password == "password") {
            setpassword("text");
            seteye(false);
        } else {
            setpassword("password");
            seteye(true);
        }
    }


    async function postData(url = '', data) {
        try {
            const response = await fetch(config.apiURLWeb + url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    "X-App-Origin": "WebApp"
                },
                body: JSON.stringify(data)
            });
            return response;
        } catch (error) {
            console.error(error);
        }
    }

    async function postDataGoogle(url = '', data) {
        try {
            const response = await fetch(config.apiURLWeb + url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    "X-App-Origin": "WebApp"
                },
                body: JSON.stringify(data)
            });
            return response;
        } catch (error) {
            console.error(error);
        }
    }

    async function postDataZapier(email) {
        if (email !== "" && email !== undefined && email !== null) {
            try {
                var dataTosend = {
                    email: email
                }

                if(config.environment === "STG"){
                    dataTosend.environment = "STG";
                }
                if(config.environment === "Dev"){
                    dataTosend.environment = "Dev";
                }
                if(config.environment === "Prod"){
                    dataTosend.environment = "Prod";
                }

                const params = new URLSearchParams(dataTosend);
                var requestOptions = {
                    method: 'POST',
                    redirect: 'follow',
                    headers: {
                        "X-App-Origin": "WebApp"
                    },
                    mode: "no-cors"
                };
                const response = await fetch(config.zapURL + params.toString(), requestOptions);
                return response;
            } catch (error) {
                console.error(`Could not signup: ${error}`);
            }
        }
    }

    function SendZap(email) {
        postDataZapier(email).then(response => response.text())
            .then(result => {
            })
            .catch(error => console.error(error));
    }

    useEffect(() => {
        const url = window.location.search;
        const token = url.split("token=")[1];

        if (dataFetchedRef.current) return;
        dataFetchedRef.current = true;
        const urlParams = new URLSearchParams(window.location.search);
        const email = urlParams.get('email');
        if (email !== null) {
            setuserDetail((lastValue) => {
                return {
                    ...lastValue,
                    ["email"]: email
                }
            });
            var checkEmail = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
            checkEmail.test(email);
            if (checkEmail.test(email) === true) {
                setSignupStep(2);
                setShowErrorEmailWrong(false)
            } else {
                setShowErrorEmailWrong(true)
            }
        }
        if (token !== '' && token !== null && token !== undefined) {
            signupWithGoogle(token)
        }
        setTimeout(function () {
            setGoogleBTN(true)
        }, 1500)
    }, []);


    const signup = (event) => {
        // event.preventDefault();
        if (
            //userDetail.name !== "" &&
            //userDetail.lastName !== "" &&
            userDetail.email !== "" &&
            userDetail.password !== "") {

            if (!checkLength(userDetail.password, 256)) {
                setErrorTextPassword("256 characters max allowed");
                return;
            }
            if (passwordStrength === 5) {
                setLoading(true);
                var userName = userDetail.name;
                if (userDetail.lastName !== '') {
                    //userName += " " + userDetail.lastName;
                }

                getToken(config.auth + "/api/authentication/request_token")

                    .then((res) => Promise.all([res.status, res.text()]))
                    .then(([status, token]) => {
                        if (status === 500) {
                            setPopup500(true)
                            return;
                        }
                        if (status === 499) {
                            var data = JSON.parse(token)
                            var errorType = data.type;
                            setError499(data.errors[errorType][0])
                            setPopup499(true)
                            return;
                        }
                        postData('/api/desktop/signup', {
                            "name": userDetail.email,
                            "email": userDetail.email,
                            "password": userDetail.password,
                            "requestToken": token,
                        })
                            .then(res => Promise.all([res, res.text()])).then(([res, data]) => {
                            if (res.status === 400 || res.status === 404) {
                                setShowErrorEmailWrong(true)
                                setLoading(false);
                            } else if (res.status === 499) {
                                var d = JSON.parse(data)
                                if (d.type === 'AUTH') {
                                    sendDataToMixPanel(d.errors.AUTH[0], userDetail.email)
                                    setErrorText(d.errors.AUTH[0])
                                }
                                if (d.type === 'INACTIVE_USER') {
                                    sendDataToMixPanel(d.errors.INACTIVE_USER[0], userDetail.email)
                                    setErrorText(d.errors.INACTIVE_USER[0])
                                }
                                if (d.type === 'SESSION') {
                                    sendDataToMixPanel(d.errors.SESSION[0], userDetail.email)
                                    setErrorText(d.errors.SESSION[0])
                                }

                                if (d.type === 'SESSION_MAXOUT') {
                                    sendDataToMixPanel(d.errors.SESSION_MAXOUT[0], userDetail.email)
                                    setErrorText(d.errors.SESSION_MAXOUT[0])
                                }

                                if (d.type === 'PENDING') {
                                    sendDataToMixPanel(d.errors.PENDING[0], userDetail.email)
                                    //setErrorText(d.errors.PENDING[0])
                                    setErrorText((<>This email is already in use. <br/> Resend activation email.</>))
                                }
                                if (d.type === 'DUPLICATE') {
                                    sendDataToMixPanel(d.errors.DUPLICATE[0], userDetail.email)
                                    setErrorText(d.errors.DUPLICATE[0])
                                }

                                if (d.type === 'COGNITO') {
                                    sendDataToMixPanel(d.errors.COGNITO[0], userDetail.email)
                                    setErrorText(d.errors.COGNITO[0])
                                }

                                if (d.type === 'INVALID_EMAIL') {
                                    sendDataToMixPanel(d.errors.INVALID_EMAIL[0], userDetail.email)
                                    setErrorText(d.errors.INVALID_EMAIL[0])
                                }
                                if (d.type === 'INTERNAL_ERROR') {
                                    sendDataToMixPanel(d.errors.INTERNAL_ERROR[0], userDetail.email)
                                    setErrorText(d.errors.INTERNAL_ERROR[0])
                                }

                                setLoading(false);
                                setShowErrorAll(false);
                                setEmailError(true);
                                setSignupStep(1)
                            } else if (res.status === 200) {
                                localStorage.setItem("signupEmail", userDetail.email);
                                setLoginSession(data.session);
                                omnisend(userDetail.email)
                                SendZap(userDetail.email);
                                sendDataToMixPanel("Email Verification Sent", userDetail.email)
                                navigate("/email-verification")
                            } else if (data.jwtToken !== undefined) {
                                sendDataToMixPanel("Signup Success With Email", userDetail.email)
                                omnisend(userDetail.email)
                                SendZap(userDetail.email);
                                setJWTToken(data.jwtToken)
                                setIsAuthenticated(true);
                            }
                        });
                    })
            }
        } else {
            setShowError(true);
        }
    }
    const signupWithGoogle = (token) => {

        getToken(config.auth + "/api/authentication/request_token")

            .then((res) => Promise.all([res.status, res.text()]))
            .then(([status, requestToken]) => {
                if (status === 500) {
                    setPopup500(true);
                    setLoading(false);
                    return;
                }
                if (status === 499) {
                    var data = JSON.parse(requestToken)
                    var errorType = data.type;
                    setError499(data.errors[errorType][0])
                    setPopup499(true)
                    return;
                }
                setLoadingGoogle(true);
                postDataGoogle('/api/desktop/google_signup', {
                    "idToken": token,
                    requestToken: requestToken
                })
                    .then(res => Promise.all([res.status, res.text()])).then(([status, textData]) => {

                    if (status === 400 || status === 404) {
                        api.open({
                            message: 'Error',
                            description:
                                `Something went wrong`,
                            icon: <img src={warning} alt="warning"/>,
                            duration: 5,
                            placement: "top"
                        });
                        setLoadingGoogle(false);
                    } else if (status === 499) {
                        var d = JSON.parse(textData)
                        if (d.type === 'AUTH') {
                            sendDataToMixPanel(d.errors.AUTH[0], userDetail.email)
                            setErrorText(d.errors.AUTH[0])
                        }
                        if (d.type === 'INACTIVE_USER') {
                            sendDataToMixPanel(d.errors.INACTIVE_USER[0], userDetail.email)
                            setErrorText(d.errors.INACTIVE_USER[0])
                        }
                        if (d.type === 'SESSION') {
                            sendDataToMixPanel(d.errors.SESSION[0], userDetail.email)
                            setErrorText(d.errors.SESSION[0])
                        }
                        if (d.type === 'SESSION_MAXOUT') {
                            sendDataToMixPanel(d.errors.SESSION_MAXOUT[0], userDetail.email)
                            setErrorText(d.errors.SESSION_MAXOUT[0])
                        }
                        if (d.type === 'DUPLICATE') {
                            sendDataToMixPanel(d.errors.DUPLICATE[0], userDetail.email)
                            setErrorText(d.errors.DUPLICATE[0])
                        }
                        if (d.type === 'COGNITO') {
                            sendDataToMixPanel(d.errors.COGNITO[0], userDetail.email)
                            setErrorText(d.errors.COGNITO[0])
                        }
                        if (d.type === 'INVALID_EMAIL') {
                            sendDataToMixPanel(d.errors.INVALID_EMAIL[0], userDetail.email)
                            setErrorText(d.errors.INVALID_EMAIL[0])
                        }
                        if (d.type === 'INTERNAL_ERROR') {
                            sendDataToMixPanel(d.errors.INTERNAL_ERROR[0], userDetail.email)
                            setErrorText(d.errors.INTERNAL_ERROR[0])
                        }
                        setShowErrorAll(true)
                        setEmailError(false);
                        setLoadingGoogle(false);
                        setSignupStep(1)
                    } else if (status === 200) {
                        logoutONSignup()
                        setLoadingGoogle(true);
                        setSignUpWithGoogle(true);
                        omnisend(userDetail.email)
                        SendZap(userDetail.email);
                        sendDataToMixPanel("Signup Success With Google", userDetail.email)
                        postDataGmail("/api/desktop/google_signin", {
                            "idToken": token,
                            requestToken: requestToken
                        })
                            .then((res) => Promise.all([res.status, res.text()]))
                            .then(([status, textData]) => {

                                if (status === 499 || status === 401) {
                                    var d = JSON.parse(textData);
                                    if (d.type === "AUTH") {
                                        setErrorText(d.errors.AUTH[0]);
                                    }

                                    if (d.type === "INACTIVE_USER") {
                                        setErrorText(d.errors.INACTIVE_USER[0]);
                                    }

                                    if (d.type === "SESSION") {
                                        setErrorText(d.errors.SESSION[0]);
                                    }

                                    if (d.type === "SESSION_MAXOUT") {
                                        setErrorText(d.errors.SESSION_MAXOUT[0]);
                                    }

                                    if (d.type === "DUPLICATE") {
                                        setErrorText(d.errors.DUPLICATE[0]);
                                    }

                                    if (d.type === "COGNITO") {
                                        setErrorText(d.errors.COGNITO[0]);
                                    }

                                    if (d.type === "INVALID_EMAIL") {
                                        setErrorText(d.errors.INVALID_EMAIL[0]);
                                    }
                                    if (d.type === "INTERNAL_ERROR") {
                                        setErrorText(d.errors.INTERNAL_ERROR[0]);
                                    }
                                    setShowError(true);
                                    setLoadingGoogle(false);
                                }
                                if (status === 200) {
                                    setJWTToken(textData);
                                    setIsAuthenticated(true);
                                    setLoadingGoogle(false);
                                }
                                setLoadingGoogle(false);
                            });
                    }
                });
            })

    }


    async function postDataGmail(url = "", data = {}) {
        const response = await fetch(config.apiURLWeb + url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "X-App-Origin": "WebApp"
            },
            body: JSON.stringify(data),
        });
        return response;
    }

    const signupKeyup = (event) => {
        if (event.key === 'Enter') {
            signup();
        }

    }
    const signupKeyup1 = (event) => {
        if (event.key === 'Enter') {
            setSignupStep(2)
        }

    }

    return (
        <>
            {contextHolder}
            <div className="login">
                <div className="login-wrapper">
                    <div className="text-center">
                        <a href="https://www.amove.io/" className="logo" target='_blank'>
                            <img src={isDark ? pageLogoWhite : pageLogo} alt="logo"/>
                        </a>
                    </div>
                    <div className="main-form-wrapper">
                        <div className={`form ${signupStep === 2 ? 'signup-setup-2' : 'signup-setup-2'}`}>
                            <div className='fields-wrapper'>
                                <div className={`form-group  ${showError || emailError ? "" : ""}`}>
                                    <input type="text" id="email" className="form-control" value={userDetail.email}
                                           onChange={inputEvent} placeholder="Email" name="email" onKeyDown={signupKeyup1}/>
                                    <i className="fa-solid fa-envelope fa"></i>
                                </div>
                                {emailError &&
                                    <div className="form-group">
                                        <span className="red-error">{errorText}</span>
                                    </div>
                                }

                                {showErrorAll &&
                                    <div className="form-group error-after-metter">
                                        <span className="red-error">{errorText}</span>
                                    </div>
                                }

                                {showErrorEmailWrong &&
                                    <div className="form-group">
                                        <span className="red-error">Incorrect email address</span>
                                    </div>
                                }
                                <div className={`form-group  ${showError || (passwordStrength < 5 && userDetail.password !== '') ? "" : ""}`}>
                                    <input type={password} className="form-control padding-right" id="password"
                                           placeholder="Choose password" value={userDetail.password} onChange={inputEvent}
                                           onFocus={() => setMeter(true)} name="password" onKeyDown={signupKeyup}/>
                                    <i onClick={Eye} className={`fa ${eye ? "fa-eye-slash" : "fa-eye"}`}></i>
                                </div>
                                <div className="form-group sign-up-page">
                                    <div className="position-relative">

                                        <Tooltip placement="bottom"
                                                 trigger='hover'
                                                 title={
                                                     <div className="security-page error-after-metter">
                                                         <span>Your password must contain:</span>
                                                         <ul className="errors-list">
                                                             <li className={userDetail.password.match(eightCharsOrMore) ? 'active' : ''}><CheckCircleFilled/> Minimum 8 characters</li>
                                                             <li className={userDetail.password.match(atLeastOneUppercase) ? 'active' : ''}><CheckCircleFilled/> 1 upper case letter</li>
                                                             <li className={userDetail.password.match(atLeastOneLowercase) ? 'active' : ''}><CheckCircleFilled/> 1 lower case letter</li>
                                                             <li className={userDetail.password.match(atLeastOneNumeric) ? 'active' : ''}><CheckCircleFilled/> 1 number</li>
                                                             <li className={userDetail.password.match(atLeastOneSpecialChar) ? 'active' : ''}><CheckCircleFilled/> 1 special character (! # $)</li>
                                                         </ul>
                                                     </div>
                                                 }
                                                 getPopupContainer={trigger => trigger.parentNode}>
                                            <div className="security-meter">
                                                <span className={passwordStrength > 0 ? "active" : ""}></span>
                                                <span className={passwordStrength > 1 ? "active" : ""}></span>
                                                <span className={passwordStrength > 2 ? "active" : ""}></span>
                                                <span className={passwordStrength > 3 ? "active" : ""}></span>
                                                <span className={passwordStrength > 4 ? "active" : ""}></span>
                                            </div>
                                        </Tooltip>
                                    </div>
                                </div>

                                {showError &&
                                    <div className="form-group error-after-metter">
                                        <span className="red-error">Must fill out all fields for signup.</span>
                                    </div>
                                }
                                {errorTextPassword !== "" &&
                                    <div className="form-group">{errorTextPassword}
                                    </div>
                                }

                                <div className="form-group text-center submit get-started">
                                    <Button className="btn-style full" onClick={signup} loading={loading}>
                                        Sign up
                                    </Button>
                                </div>

                                <div className="or-wrapper mb-3"><span>or</span></div>

                                <div className="google-wrapper">
                                    <div className="form-group submit google-btn-new-wrapper">
                                        <Button className={`btn-style full google-btn-new ${!googleBTN ? "not-visible" : ""}`} tabIndex='-1' loading={loadingGoogle}>
                                            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" className="LgbsSe-Bz112c">
                                                <g>
                                                    <path fill="#EA4335" d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"></path>
                                                    <path fill="#4285F4" d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"></path>
                                                    <path fill="#FBBC05" d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"></path>
                                                    <path fill="#34A853" d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"></path>
                                                    <path fill="none" d="M0 0h48v48H0z"></path>
                                                </g>
                                            </svg>
                                            Sign up with Google
                                        </Button>

                                        <GoogleLogin
                                            width='290px'
                                            onSuccess={credentialResponse => {
                                                signupWithGoogle(credentialResponse.credential)
                                            }}
                                            text='signup_with'
                                            size='large'
                                        />
                                    </div>
                                </div>

                                <p className="text-center">By signing up, I accept Amove's <br/>
                                    <a href="#">Terms of Use</a> and&nbsp;
                                    <a href="#">Privacy Policy</a>.
                                </p>
                            </div>
                        </div>
                        <p className="text-center bottom-text mb-0">I already have an account. <Link to="/sign-in" className="bold">Sign In</Link></p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Signup;
