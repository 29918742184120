import React, {useEffect, useState, useRef} from "react";
import {Link, useNavigate} from "react-router-dom";
import {Button, notification, Checkbox, DatePicker} from 'antd';
import {useAuth} from "../context/AuthContext";
import {Tooltip, Popconfirm} from 'antd';
import config from "../config";
import toastIcon from "../assets/images/icons/toast-icon.svg";
import SettingHeader from "../components/Setting/SettingHeader";
import {
    DeleteFilled
} from '@ant-design/icons';
import info from "../assets/images/info.svg";

const ApiToken = () => {
    const {
        logout,
        setPopup500,
        setError499,
        setPopup499,
        sendDataToMixPanel,
        user,
        postDataWebNoResponse,
        getDataWithToken,
        deleteDataWeb,
        getFormatedDate
    } = useAuth();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [api, contextHolder] = notification.useNotification();
    const [isEncrypted, setIsEncrypted] = useState(false);
    const [tokenLifetime, setTokenLifetime] = useState(true);
    const [expirationDate, setExpirationDate] = useState('');
    const [apiTitle, setApiTitle] = useState('');
    const [encryptionValue, setEncryptionValue] = useState('');
    const [errorMsg, setErrorMsg] = useState('');
    const [showAddToken, setShowAddToken] = useState(true);
    const [viewToken, setViewToken] = useState(false);
    const [tokens, setTokens] = useState([]);
    const [tokenToCopy, setTokenToCopy] = useState('');
    const [tokenToCopyHalf, setTokenToCopyHalf] = useState('');
    const [showKeyError, setShowKeyError] = useState(false);
    const base64Pattern = /^[A-Za-z0-9+/=]{32}$/;

    var now = new Date();
    useEffect(() => {
        loadToken();
    }, []);

    const onChangeEncryption = (e) => {
        setIsEncrypted(e.target.checked)
        if (e.target.checked) {
            setErrorMsg('');
        }
    };
    const onChangeLifetime = (e) => {
        const isChecked = e.target.checked;
        setTokenLifetime(isChecked);
    };
    const onDateChange = (e) => {
        console.log(e.target.value);
        setExpirationDate(e.target.value);
    };

    function getDate100YearsFromNow() {
        const date = new Date();
        date.setFullYear(date.getFullYear() + 100);
        return date.toISOString(); // format as YYYY-MM-DDTHH:mm:ss.sssZ
    }

    const inputEncryption = (event) => {
        var value = event.target.value;
        console.log(value)
        var max_chars = 32;
        setShowKeyError(false)
        if (value.length > max_chars) {
            value = value.substring(0, max_chars);
        }
        setEncryptionValue(value)
    }
    const checkKeyValidation = () => {
        if (base64Pattern.test(encryptionValue)) {
            setShowKeyError(false)
        } else {
            setShowKeyError(true)
        }

    }
    const inputTitle = (event) => {
        const value = event.target.value;
        console.log(value)
        setApiTitle(value)
    }

    const saveToken = () => {
        var expiryDate = getDate100YearsFromNow();
        if (!tokenLifetime) {
            expiryDate = expirationDate
        }
        var dataToPost = {
            "title": apiTitle,
            "encryptionKey": encryptionValue,
            "isEncrypted": isEncrypted,
            "expirationDate": expiryDate,
        }

        console.log(expirationDate)
        //setLoading(true)
        console.log(dataToPost, 'object');
        postDataWebNoResponse("/api/apitoken/insert", dataToPost)
            .then((res) => Promise.all([res.status, res.json()]))
            .then(([status, data]) => {
                console.log(data, 'data')
                console.log(status, 'status')
                setLoading(false);
                if (status === 401) {
                    logout()
                    return;
                }
                if (status === 500) {
                    setPopup500(true);
                    setLoading(false);
                    return;
                }
                if (status === 499) {
                    setError499(data.detail)
                    setPopup499(true);
                    return;
                }
                if (status === 200) {
                    console.log(data)
                    setTokenToCopy(data.token)
                    setTokenToCopyHalf(data.token.substring(0, 30) + "**********************")
                    setIsEncrypted(false)
                    setTokenLifetime(true)
                    setExpirationDate("")
                    setApiTitle("")
                    setEncryptionValue("")

                }
                sendDataToMixPanel('Api Token Created', user.username, {
                    'App': 'Web App'
                })
            })
    }

    const loadToken = () => {
        getDataWithToken("/api/apitoken/get_all")
            .then((res) => Promise.all([res.status, res.json()]))
            .then(([status, response]) => {
                if (status === 401) {
                    logout()
                    return;
                }
                if (status === 500) {
                    setPopup500(true);
                    setLoading(false);
                    return;
                }

                if (status === 499) {
                    var errorType = response.type;
                    setError499(response.errors[errorType][0])
                    setPopup499(true)
                    return;
                }
                if (status === 200) {
                    console.log('response')
                    console.log(response)
                    setTokens(response)
                    if (response.length > 0) {
                        setShowAddToken(false)
                        setTokenToCopy("")
                    }
                }
            })
    }

    const removeEncryption = (id) => {
        deleteDataWeb('/api/apitoken/delete?id=' + id)
            .then((res) => Promise.all([res.status, res.text()]))
            .then(([status, data]) => {
                console.log(data, 'del data')
                console.log(status, 'del status')
                if (status === 401) {
                    logout()
                    return;
                }
                if (status === 500) {
                    setPopup500(true)
                    return;
                }
                if (status === 499) {
                    var response = JSON.parse(data)
                    var errorType = response.type;
                    setError499(response.errors[errorType][0])
                    setPopup499(true)
                    return;
                }
                if (status === 200) {
                    api.open({
                        message: 'Token removed successfully.',
                        icon: <img src={toastIcon} alt="toastIcon"/>,
                        duration: 3
                    });
                    if (tokens.length === 1) {
                        setShowAddToken(true)
                    }
                    setTokens((current) =>
                        current.filter(
                            (item) => item.id !== id
                        )
                    );
                }
            });
    }
    return (
        <>
            {contextHolder}
            <div id="main-content" className={``}>
                <div className="container-fluid">

                    <div className="account-detail-wrapper">
                        <SettingHeader/>
                    </div>
                    <div className="main-content-body dashboard-home log-page  add-cloud-account-page">
                        <div className="cloud-brands-wrapper choose-cloud-wrapper">
                            {showAddToken &&
                                <div className="left-content">
                                    <div className="row">
                                        <div className="col-7">
                                            <h3 className="mb-41">Add API Token</h3>
                                        </div>
                                        <div className="col-5 text-end">
                                            {(tokens.length > 0 || tokenToCopy !== "") &&
                                                <h3 className="mb-20">
                                                    <a className="btn-style btn-30 small-btn" href="#" onClick={() => {
                                                        loadToken()
                                                    }}>View All Tokens</a></h3>
                                            }
                                        </div>
                                    </div>
                                    {tokenToCopy === "" &&
                                        <div className="setup-cloud-drive">
                                            <div className="form token-form">
                                                <div className={`row`}>
                                                    <div className="col-md-4 align-self-center">
                                                        <p className="medium">Title</p>
                                                    </div>
                                                    <div className="col-md-8 align-self-center">
                                                        <div className="account-detail">
                                                            <div className={`form-group max-width-50-percent-large`}>
                                                                <input type="text" className="form-control" placeholder=""
                                                                       value={apiTitle} onChange={inputTitle}/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={`row`}>
                                                    <div className="col-md-4 align-self-center">
                                                        <p className="medium"></p>
                                                    </div>
                                                    <div className="col-md-8 align-self-center">
                                                        <div className="account-detail">
                                                            <div className={`form-group`}>
                                                                <Checkbox checked={isEncrypted} onChange={onChangeEncryption}>
                                                                    <div className="detail">
                                                                        Enable encryption to secure sensitive data
                                                                    </div>
                                                                </Checkbox>
                                                                <span className="api-error">{errorMsg}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {(isEncrypted) &&
                                                    <>
                                                        <div className={`row`}>
                                                            <div className="col-md-4 align-self-center">
                                                                <p className="medium  info-wrapper">Encryption Key

                                                                    <Tooltip placement="topLeft" title={
                                                                        <div>
                                                                            <ul>
                                                                                <li>Needs to be exactly 32 characters</li>
                                                                                <li>Valid base64 characters<br/> (A-Z, a-z, 0-9, +, /, =)</li>
                                                                            </ul>
                                                                        </div>
                                                                    }>
                                                                        <img src={info} alt="Info"/>
                                                                    </Tooltip>

                                                                </p>
                                                            </div>
                                                            <div className="col-md-8 align-self-center">
                                                                <div className="account-detail">
                                                                    <div className={`form-group max-width-50-percent-large`}>
                                                                        <input type="text" className={`form-control ${showKeyError ? "red-border" : ""}`}
                                                                               value={encryptionValue} onChange={inputEncryption} onBlur={checkKeyValidation}/>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                        {showKeyError &&
                                                            <div className={`row`}>
                                                                <div className="col-md-4 align-self-center">
                                                                </div>
                                                                <div className="col-md-8 align-self-center">
                                                                    <div className="errors-wrapper">
                                                                        <span>Needs to be exactly 32 characters</span>
                                                                        <span>Valid base64 characters<br/> (A-Z, a-z, 0-9, +, /, =)</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }
                                                    </>
                                                }
                                                <div className={`row`}>
                                                    <div className="col-md-4 align-self-center">
                                                        <p className="medium"></p>
                                                    </div>
                                                    <div className="col-md-8 align-self-center">
                                                        <div className="account-detail">
                                                            <div className={`form-group`}>
                                                                <Checkbox checked={tokenLifetime} onChange={onChangeLifetime}>
                                                                    <div className="detail">
                                                                        Token is Lifetime
                                                                    </div>
                                                                </Checkbox>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {
                                                    (!tokenLifetime) &&
                                                    <div className={`row`}>
                                                        <div className="col-md-4 align-self-center">
                                                            <p className="medium">Token Expiration Date</p>
                                                        </div>
                                                        <div className="col-md-8 align-self-center">
                                                            <div className="account-detail">
                                                                <div className={`form-group max-width-50-percent-large`}>
                                                                    <input
                                                                        type="date"
                                                                        className="form-control"
                                                                        min={now.toISOString().substring(0, 10)}
                                                                        value={expirationDate}
                                                                        onChange={onDateChange}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    }
                                    {tokenToCopy !== "" &&
                                        <div className="setup-cloud-drive">
                                            <div className="form">
                                                <div className={`row`}>
                                                    <div className="col-md-4">
                                                        <p className="medium">Token</p>
                                                    </div>
                                                    <div className="col-md-8 align-self-center">
                                                        <div className="account-detail">
                                                            <div className={`form-group`}>
                                                                {viewToken &&
                                                                    <textarea className="form-control height-200">{tokenToCopy}</textarea>
                                                                }
                                                                {!viewToken &&
                                                                    <textarea className="form-control height-200 disabled">{tokenToCopyHalf}</textarea>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    <div className={`row setup-drive-footer`}>
                                        <div className="col-md-4">
                                        </div>
                                        <div className="col-md-8">
                                            {tokenToCopy !== "" &&
                                                <>
                                                    <Button className={`btn-style btn-30 orange-btn transparent mr-2 `} onClick={() => {
                                                        navigator.clipboard.writeText(tokenToCopy);
                                                        api.open({
                                                            message: 'Token Copied.',
                                                            icon: <img src={toastIcon} alt="toastIcon"/>,
                                                            duration: 3,
                                                        });
                                                    }}>Copy Token</Button>
                                                    <Button className={`btn-style btn-30 orange-btn transparent mr-2 `} onClick={() => {
                                                        setViewToken(!viewToken)
                                                        console.log(viewToken)
                                                    }}>{viewToken ? "Hide" : "Show"} Token</Button>
                                                </>
                                            }
                                            {tokenToCopy === "" &&
                                                <Button className={`btn-style btn-30 orange-btn transparent mr-2 ${apiTitle === "" || (isEncrypted && (encryptionValue === "" || !base64Pattern.test(encryptionValue))) || (!tokenLifetime && (expirationDate === undefined || expirationDate === "")) ? 'disabled' : ''}`} loading={loading} onClick={saveToken}>Generate Token</Button>
                                            }
                                        </div>
                                    </div>
                                </div>
                            }
                            {!showAddToken &&
                                <div className="left-content">
                                    <div className="row">
                                        <div className="col-7">
                                            <h3 className="mb-20">API Tokens</h3>
                                        </div>
                                        <div className="col-5 text-end">
                                            <h3 className="mb-20"><a className="btn-style btn-30 small-btn" href="#" onClick={() => {
                                                setShowAddToken(true);
                                                setTokenToCopy("")
                                            }}>Add API Token</a></h3>
                                        </div>
                                    </div>
                                    <div className="setup-cloud-drive apiToken-page">
                                        <div className="form connected-accounts">

                                            <div className="connected-accounts-body">
                                                {tokens.length > 0 && (<>
                                                        <div className="table-header">
                                                            <div className="row">
                                                                <div className="col-md-6 col-2">
                                                                    <p>Title</p>
                                                                </div>
                                                                <div className="col-md-4 col-6 text-center">
                                                                    <p>Token Expiration Date</p>
                                                                </div>
                                                                <div className="col-md-2 col-4 text-end">
                                                                    <p>Delete</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="table-body">
                                                            <ul>
                                                                {tokens.map((item) => (
                                                                    <div className="table-body-card" key={item.id}>
                                                                        <div className="row cursor-pointer">
                                                                            <div className="col-md-6 col-3 align-self-center">
                                                                                <div className="account-detail">
                                                                                    <div className="content-wrapper">
                                                                                        <p className="medium">{item.title}</p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-4 col-2 align-self-center  text-center">
                                                                                <p className="medium">{getFormatedDate(item.expirationDate, false)}</p>
                                                                            </div>
                                                                            <div className="col-md-2 col-2 align-self-center">
                                                                                <ul className="icons">
                                                                                    <li className="delete-wrapper">
                                                                                        <Popconfirm
                                                                                            placement="topLeft"
                                                                                            title={<>
                                                                                                Are you sure you want to delete it?
                                                                                            </>}
                                                                                            okText="Yes"
                                                                                            cancelText="No"
                                                                                            getPopupContainer={trigger => trigger.parentNode}
                                                                                            onConfirm={() => removeEncryption(item.id)}
                                                                                        ><a className="" href="#" onClick={(e) => {
                                                                                            e.preventDefault();
                                                                                        }}><DeleteFilled/></a>
                                                                                        </Popconfirm></li>
                                                                                </ul>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                ))}
                                                            </ul>
                                                        </div>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ApiToken;

