import React, {useEffect, useState, useRef} from "react";
import {useNavigate} from "react-router-dom";
import toastIcon from "../assets/images/icons/toast-icon.svg";
import {Button, Checkbox, Modal, notification, Select, Tooltip, Radio, Switch} from 'antd';
import {useAuth} from "../context/AuthContext";
import pageLogo from "../assets/images/logo.svg";
import awsLogo from "../assets/images/icons/onboard-aws-icon.svg";
import AmoveStorage from "../components/SetupAmoveStorage";
import warning from "../assets/images/icons/warning-sign.svg";
import pageLogoWhite from "../assets/images/logo-dark.svg";
import info from "../assets/images/info.svg";
import config from "../config";

const Onboarding = () => {
    const {
        getDataWithToken,
        cloudAccounts,
        isCMDDone,
        userStatus,
        setHideBTNs,
        getDataWithTokenPOST,
        setShowAgentOnce,
        setPopup500,
        setPopupSupport,
        logout,
        setEndpoint,
        setShowPopupSupport,
        setShowTourDashboard,
        sendDataToMixPanel,
        user,
        setError499,
        setPopup499,
        isDark,
        regions,
        setRegions,
        getRegions
    } = useAuth();
    const navigate = useNavigate();
    const dataFetchedRef = useRef(false);
    const [loading, setLoading] = useState(false);
    const [showMessage, setShowMessage] = useState(false);
    const [connectedCloudDrives, setConnectedCloudDrives] = useState(0);
    const [hasError, setHasError] = useState(false);
    const [options, setOptions] = useState(0);
    const [step, setStep] = useState(1);
    const [errorText, setErrorText] = useState('');
    const [selectedRegions, setSelectedRegions] = useState("Select a Region");
    const [api, contextHolder] = notification.useNotification();
    const [selectedOptionTitle, setSelectedOptionTitle] = useState("step-1");


    useEffect(() => {
        localStorage.setItem('onboarding', true);
        setHideBTNs(true)
        getRegions()
    }, []);

    useEffect(() => {
        if (Object.keys(user).length !== 0 && user.constructor === Object) {
            const url = window.location.search;
            const activation = url.split("activation=")[1];
            if (activation === "true") {
                SendZap(user.username)
                window.history.pushState({}, null, "/onboarding");
            }
        }
    }, [user]);
    useEffect(() => {
        setTimeout(function () {
            setShowMessage(true);
        }, 500)
    }, [cloudAccounts]);


    async function postDataZapier(email) {
        if (email !== "" && email !== undefined && email !== null) {
            try {
                var dataTosend = {
                    email: email
                }

                if (config.environment === "STG") {
                    dataTosend.environment = "STG";
                }
                if (config.environment === "Dev") {
                    dataTosend.environment = "Dev";
                }
                if (config.environment === "Prod") {
                    dataTosend.environment = "Prod";
                }

                const params = new URLSearchParams(dataTosend);
                var requestOptions = {
                    method: 'POST',
                    redirect: 'follow',
                    headers: {
                        "X-App-Origin": "WebApp"
                    },
                    mode: "no-cors"
                };
                const response = await fetch(config.zapURLActivate + params.toString(), requestOptions);
                return response;
            } catch (error) {
                console.error(`Could not signup: ${error}`);
            }
        }
    }

    function SendZap(email) {
        postDataZapier(email).then(response => response.text())
            .then(result => {
            })
            .catch(error => console.error(error));
    }

    const onChange = async (value) => {
        setSelectedRegions(value)
    };

    const setUpNewAmoveStorage = () => {
        var dataToPost = {
            "cloudAccountName": "Amove",
            "bucketName": "amovebucket",
            "shared": false
        }
        setLoading(true)
        getDataWithTokenPOST("/api/cloudaccount/add_storage", dataToPost)
            .then((res) => Promise.all([res.status, res.json()]))
            .then(([status, response]) => {
                if (status === 401) {
                    setEndpoint("/api/cloudaccount/add_storage")
                    setShowPopupSupport(true)
                    // logout()
                    setLoading(false);
                    return;
                }
                if (status === 500) {
                    setPopup500(true);
                    setLoading(false);
                    return;
                }
                if (status === 499) {
                    var errorType = response.type;
                    setError499(response.errors[errorType][0])
                    setPopup499(true)
                    return;
                }
                if (status === 499) {
                    if (response.type === 'DEFAULT') {
                        setErrorText(response.errors.DEFAULT[0])
                    }
                    setLoading(false);
                } else {
                    api.open({
                        message: `New Amove Storage Created.`,
                        icon: <img src={toastIcon} alt="toastIcon"/>,
                        duration: 5
                    });
                    setLoading(false);
                    localStorage.setItem("dashboardTourStep", 0)
                    setShowTourDashboard(false)
                    setShowAgentOnce(true)

                    sendDataToMixPanel('Log settings updated successfully.', user.username, {
                        'App': 'Web App'
                    })
                    navigate(`/dashboard?amove=true`);
                }
            })

    };
    const setUpNewIDrive = () => {

        var dataToPost =
            {
                "firstname": "",
                "userEmail": "",
                "cloudAccountName": "Amove",
                "bucketTitle": "amovebucket",
                "region": selectedRegions,
                "defaultPassword": "",
                "shared": false,
                "storageTier": options
            }
        setLoading(true)
        getDataWithTokenPOST("/api/cloudaccount/idrive_add_storage", dataToPost)
            .then((res) => Promise.all([res.status, res.json()]))
            .then(([status, response]) => {
                setLoading(false);
                if (status === 401) {
                    setEndpoint("/api/cloudaccount/idrive_add_storage")
                    setShowPopupSupport(true)
                    // logout()
                    setLoading(false);
                    return;
                }
                if (status === 500) {
                    setPopup500(true);
                    setLoading(false);
                    return;
                }
                if (status === 499) {
                    var errorType = response.type;
                    setError499(response.errors[errorType][0])
                    setPopup499(true)
                    setLoading(false);
                    return;
                }
                if (status === 499) {
                    if (response.type === 'DEFAULT') {
                        setErrorText(response.errors.DEFAULT[0])
                    }
                    setLoading(false);
                } else {
                    api.open({
                        message: `New Amove Storage Created.`,
                        icon: <img src={toastIcon} alt="toastIcon"/>,
                        duration: 5
                    });

                    sendDataToMixPanel('New Amove Storage Created.', user.username, {
                        'App': 'Web App'
                    })
                    navigate(`/dashboard?amove=true`);
                }
            })

    };

    return (
        <>
            {contextHolder}
            <div id="main-content">
                <div className="container-fluid">
                    {(userStatus.status === "trial" || userStatus.status === "active") &&
                        <div className="main-content-body home">
                            <div className="onboarding-screen-main">
                                {step === 1111 &&
                                    <div className="onboarding-step onboarding-step-1">
                                        <div className="logo-wrapper mb-4">
                                            <img src={isDark ? pageLogoWhite : pageLogo} alt="logo"/>
                                        </div>
                                        <h2>Welcome to <strong>Click</strong></h2>
                                        <h3 className="mb-32">Get Started with your <strong> 15-Day Free Trial</strong></h3>
                                        <div className="pricing-wrapper">
                                            <div className="pricing-card">
                                                <p className="p bold title">Click for Everyone</p>
                                                <p className="discount"></p>
                                                <p className="p bold price">Features Include:</p>
                                                <ul className="feature">
                                                    <li>3 Admin Users</li>
                                                    <li>1 TB of Storage</li>
                                                    <li>Instant Collaboration</li>
                                                    <li>3 Cloud Connections <br/> (Bring Your Own Storage)</li>
                                                    <li>Unlimited Cloud Drives</li>
                                                    <li>Unlimited Backups to Any Cloud</li>
                                                    <li>Unlimited transfers between Cloud Drives</li>
                                                    <li>High Speed File Access & Share</li>
                                                    <li>Sync between any points</li>
                                                    <li>File management</li>
                                                    <li>Cloudflyer Access <br/> (Migrations between clouds)</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="btn-footer">
                                            <a href="#" className="btn-style" onClick={(e) => {
                                                e.preventDefault();
                                                setStep(2)
                                            }}>Let's Get Started</a>
                                        </div>
                                    </div>
                                }
                                {step === 1 &&
                                    <div className="onboarding-step onboarding-step-2">
                                        <div className="logo-wrapper mb-4">
                                            <img src={isDark ? pageLogoWhite : pageLogo} alt="logo"/>
                                        </div>
                                        <h2>Welcome to <strong>Click</strong></h2>
                                        <h3 className="mb-45">Let's Start Your 15 Day Trial With Your Storage</h3>
                                        <div className="choose-cloud-wrapper">

                                            <Radio.Group onChange={(e) => {
                                                setOptions(e.target.value)
                                            }}>
                                                <div className="row">
                                                    <div className="col-xl-3 col-md-6">
                                                        <div className="ant-radio-wrapper">
                                                            <div className="cloud-card">
                                                                <div className="title-wrapper">
                                                                    <p className="title p bold">Create Amove<br/>
                                                                        <strong className="sky-blue-color">Perform Storage </strong></p>
                                                                </div>
                                                                <p className="small">Experience instant access to large video files from anywhere.
                                                                    Built for creative workflows and real-time collaboration.</p>
                                                                {/* <a href="#" className="btn-style">Sign Up For Early Access</a>*/}
                                                                <strong className="pricing">(Coming Soon)</strong>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-3 col-md-6">
                                                        <Radio value={2}>
                                                            <div className="cloud-card">
                                                                <div className="title-wrapper">
                                                                    <p className="title p bold">Create Amove <br/>
                                                                        <strong className="light-blue-color">Global Storage</strong></p>
                                                                    <span className="circle"></span>
                                                                </div>
                                                                <p className="small">Ideal for teams needing high-availability global storage. Dynamic caching and folder level sharing.</p>

                                                               <strong className="pricing">$15 TB/Month</strong>
                                                            </div>
                                                        </Radio>
                                                    </div>
                                                    <div className="col-xl-3 col-md-6">
                                                        <Radio value={4}>
                                                            <div className="cloud-card">
                                                                <div className="title-wrapper">
                                                                    <p className="title p bold">Create Amove <br/>
                                                                        <strong className="dark-blue-color">Scale Storage</strong></p>
                                                                    <span className="circle"></span>
                                                                </div>
                                                                <p className="small">Suited to cost-conscious teams needing
                                                                    synchronised access and sharing for document
                                                                    based workflows.</p>

                                                                <strong className="pricing">$5 TB/Month</strong>

                                                            </div>
                                                        </Radio>
                                                    </div>
                                                    <div className="col-xl-3 col-md-6">
                                                        <Radio value={3}>{/*
                                                            <p className="cloud-name text-center">Add Existing Storage</p>*/}
                                                            <div className="cloud-card">
                                                                <div className="title-wrapper">
                                                                    <p className="title p bold">Bring Your <br/>
                                                                        <strong>Own Storage</strong></p>
                                                                    <span className="circle"></span>
                                                                </div>
                                                                <p className="small">Connect existing storage from over 50 providers. Create shared drives + gain faster performance from the desktop.</p>
                                                            </div>
                                                        </Radio>
                                                    </div>
                                                </div>
                                            </Radio.Group>
                                        </div>
                                        <div className="btn-footer">
                                            <Button loading={loading} className={`btn-style mb-3 ${options === 0 ? "disabled" : ""}`} onClick={(e) => {
                                                e.preventDefault();
                                                if (options === 1) {
                                                    setSelectedOptionTitle('step-1')
                                                } else if (options === 2) {
                                                    setSelectedOptionTitle('step-2')
                                                } else if (options === 4) {
                                                    setSelectedOptionTitle('step-3')
                                                }
                                                if (options === 1 || options === 2 || options === 4) {
                                                    setErrorText('')
                                                    setStep(2)
                                                } else if (options === 3) {
                                                    sendDataToMixPanel('Connect to your existing cloud account From Onboarding.', user.username, {
                                                        'App': 'Web App'
                                                    })
                                                    navigate("/add-cloud-account?hide=true&startTour=true");
                                                }
                                            }}>Continue</Button>
                                            <p>25GB Free To Start</p>
                                        </div>
                                    </div>
                                }
                                {step === 2 &&
                                    <div className="onboarding-step onboarding-step-3">
                                        <div className="logo-wrapper mb-4">
                                            <img src={isDark ? pageLogoWhite : pageLogo} alt="logo"/>
                                            <strong>Click</strong>
                                        </div>
                                        {(selectedOptionTitle === 'step-1') &&
                                            <h2>Choose an <strong className="sky-blue-color">Amove Perform Storage Region</strong> <br/>
                                                and experience amazing performance.</h2>
                                        }
                                        {(selectedOptionTitle === 'step-2') &&
                                            <h2>Choose an <strong className="light-blue-color">Amove Global Storage Region</strong> <br/>
                                                and experience amazing performance.</h2>
                                        }
                                        {(selectedOptionTitle === 'step-3') &&
                                            <h2>Choose an <strong className="dark-blue-color">Amove Scale Storage Region</strong> <br/>
                                                and experience amazing performance.</h2>
                                        }

                                        <p className="gray mb-32">You will have the option to choose multiple
                                            regions after getting started.</p>
                                        <div className="choose-region-wrapper">
                                            <div className="region-card">
                                                <p className="small">Choose Your Region</p>
                                                <Select
                                                    className={` ${regions !== '' && regions !== null ? "selected" : ''}`}
                                                    showSearch
                                                    optionFilterProp="children"
                                                    onChange={onChange}
                                                    getPopupContainer={trigger => trigger.parentNode}
                                                    placeholder="Select a Region"
                                                    value={selectedRegions}
                                                    filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                                    options={regions.filter((item) => {
                                                        if (options === 4 && item.name === "Virginia") {
                                                            return item
                                                        } else if (options !== 4) {
                                                            return item
                                                        }
                                                    }).map((item) => ({
                                                        label: item.name, value: item.code,
                                                    }))}
                                                />
                                            </div>
                                        </div>
                                        <div className="btn-footer grid-two">
                                            <Button className={`btn-style transparent`} onClick={(e) => {
                                                e.preventDefault();
                                                setErrorText('')
                                                setOptions(0)
                                                setLoading(false)
                                                setStep(1)
                                            }}>Back</Button>
                                            <Button loading={loading} className={`btn-style ${selectedRegions === "" || selectedRegions === "Select a Region" ? "disabled" : ""}`} onClick={(e) => {
                                                e.preventDefault();
                                                setUpNewIDrive();
                                            }}>Continue</Button>
                                        </div>
                                        <div className="provisioning-wrapper">
                                            {loading &&
                                                <>
                                                    <div className={`loader`}></div>
                                                    <span className="loading-text">Amove Global Storage is provisioning.</span>
                                                </>
                                            }

                                            {!loading &&
                                                <div className="red-error fix-3-lines-desktop" title={errorText}>{errorText}</div>
                                            }
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    }
                </div>
            </div>
        </>
    )
}

export default Onboarding;

