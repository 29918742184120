import React, {useEffect, useState, useRef} from "react";
import {Link, useNavigate} from "react-router-dom";
import {Popover, Checkbox, DatePicker, Select, Tooltip, Drawer, Modal, notification, Button, Popconfirm} from 'antd';
import {useAuth} from "../../context/AuthContext";
import crossBlackIcon from "../../assets/images/ai-search/cross-black-icon.svg";
import playWhiteIcon from "../../assets/images/ai-search/video-play-icon.svg";
import audioNextIcon from "../../assets/images/ai-search/audio-next-seconds-icon.svg";
import audioPreviousIcon from "../../assets/images/ai-search/audio-previous-seconds-icon.svg";
import speakerIcon from "../../assets/images/ai-search/audio-speaker-icon.svg";
import pauseIcon from "../../assets/images/ai-search/pause-white-icon.svg";
import muteIcon from "../../assets/images/ai-search/mute-white-icon.svg";

const CreateClip = ({open, callback, fileBucketName, fileId, fileName, fileURL, getClips, currentProgress = 0}) => {
    const {
        postDataValere,
        getDataValere,
        logout,
        setPopup500,
        setError499,
        setPopup499,
        user,
        getFormatedDate,
        setSearchResults,
        searchResults,
        getFormatedDateFiles,
        searchedBucketName,
        searchedCloudName,
        formatTime,
        formatTimeHours,
        deleteDataValere,
        getCloudAccountName
    } = useAuth();
    const videoPlayer = useRef(null);
    const [videoPosition, setVideoPosition] = useState("");
    const [api, contextHolder] = notification.useNotification();
    const startSlider = useRef(null);
    const endSlider = useRef(null);
    const sliderTrack = useRef(null);
    const sliderTrackGray = useRef(null);
    const firstLoad = useRef(false);
    // const [startClip, setStartClip] = useState(0);
    // const [endClip, setEndClip] = useState(5);
    const [startClip, setStartClip] = useState(currentProgress);
    const [endClip, setEndClip] = useState(currentProgress + 5);
    const [clipDuration, setClipDuration] = useState(0);
    const [loading, setLoading] = useState(false);

    const [isPlaying, setIsPlaying] = useState(false);
    const [isMuted, setIsMuted] = useState(false);
    const [isLoadingVideo, setIsLoadingVideo] = useState(true);

    // Handling loading state
    const handleLoadStart = () => {
        setIsLoadingVideo(true);
    };

    const handleLoadedData = () => {
        setIsLoadingVideo(false);
    };
    const saveClip = () => {
        var dataToPost = {
            "bucket_id": fileBucketName,
            "end_timestamp": formatTimeHours(endClip) + "Z",
            "file_id": fileId.toString(),
            "start_timestamp": formatTimeHours(startClip) + "Z",
            "user_id": user.userId
        }
        setLoading(true)
        console.log(dataToPost)
        postDataValere("/clip", dataToPost)
            .then((res) => Promise.all([res.status, res.json()]))
            .then(([status, data]) => {
                console.log(data)
                setLoading(false)
                if (status === 401) {
                    logout()
                    return;
                }
                if (status === 500) {
                    setPopup500(true)
                    return;
                }
                if (status === 499) {
                    var dataNew = JSON.parse(data)
                    var errorType = dataNew.type;
                    setError499(dataNew.errors[errorType][0])
                    setPopup499(true)
                    return;
                }
                getClips();
                callback();
            }).catch(()=>{
            setLoading(false)
        })

    };
    useEffect(() => {
        if (videoPlayer.current) {
            videoPlayer.current.currentTime = currentProgress;
        }
        updateSlider();
    }, [currentProgress, endClip]);
    const togglePlayPause = () => {
        if (videoPlayer.current.paused) {
            videoPlayer.current.play();
            setIsPlaying(true);
        } else {
            videoPlayer.current.pause();
            setIsPlaying(false);
        }
    };

    const skipTime = (seconds) => {
        videoPlayer.current.currentTime += seconds;
    };

    const toggleMute = () => {
        videoPlayer.current.muted = !videoPlayer.current.muted;
        setIsMuted(videoPlayer.current.muted);
    };
    const closeClipModal = () => {
        callback(false)
    };

    const handleProgressClick = (e) => {
        const progressLine = e.currentTarget;
        const rect = progressLine.getBoundingClientRect();
        const clickX = e.clientX - rect.left;
        const width = rect.width;
        const clickPositionPercentage = clickX / width;
        videoPlayer.current.currentTime = clickPositionPercentage * videoPlayer.current.duration;
    };

    useEffect(() => {
        if (videoPlayer.current !== null) {
            console.log("All Loaded ")
            const player = videoPlayer.current;
            if (player) {
                const updateProgress = () => {
                    if (player.duration) {
                        const progress = (player.currentTime / player.duration) * 100;
                        setVideoPosition(player.currentTime)
                        if (startSlider.current !== null) {
                            startSlider.current.max = player.duration;
                            endSlider.current.max = player.duration;
                            if(!firstLoad.current){
                                firstLoad.current = true;
                                updateSlider();
                            }
                        }
                        setClipDuration(player.duration)
                        const watchLine = document.getElementById('watch-line-line-clip');
                        if (watchLine) {
                            watchLine.style.width = `${progress}%`;
                            watchLine.style.backgroundColor = '#EA4903'; // Ensure it's visible
                        } else {
                        }
                    }
                };

                const handleLoadedMetadata = () => {
                    updateProgress();
                };

                player.addEventListener('timeupdate', updateProgress);
                player.addEventListener('loadedmetadata', handleLoadedMetadata);

                return () => {
                    player.removeEventListener('timeupdate', updateProgress);
                    player.removeEventListener('loadedmetadata', handleLoadedMetadata);
                };
            }
        }
    }, [videoPlayer]);


    function updateSlider() {
        // Update the slider track fill
        console.log("updateSlider called")
        console.log(startClip)
        console.log(startSlider.current.max)
        console.log(endClip)
        console.log(endSlider.current.max)
        const minPercent = (startClip / startSlider.current.max) * 100;
        const maxPercent = (endClip / endSlider.current.max) * 100;
        sliderTrack.current.style.background = `linear-gradient(to right, transparent ${minPercent}%, #FC6524 ${minPercent}%, #FC6524 ${maxPercent}%, transparent ${maxPercent}%)`;
        sliderTrackGray.current.style.background = `linear-gradient(to right, transparent ${minPercent}%, #E2E3E9 ${minPercent}%, #E2E3E9 ${maxPercent}%, transparent ${maxPercent}%)`;
       /* if (!isFinite(minPercent)) {
           // console.log(isFinite(minPercent))
            updateSlider()
        }*/
    }

    useEffect(() => {
        if (startSlider.current !== undefined && startSlider.current !== null) {
            updateSlider()
        }
    }, [startClip, endClip]);

    return (
        <>
            {contextHolder}
            {open &&
                <Modal
                    title="Vertically centered modal dialog"
                    centered
                    open={open}
                    onOk={closeClipModal}
                    onCancel={closeClipModal}
                    className="edit-clip-modal"
                >
                    <div className="edit-clip-wrapper">
                        <div className="clip-header">
                            <div className="row">
                                <div className="col-10 align-self-center ">
                                    <h3 className="title">
                                        {fileName}
                                    </h3>
                                </div>
                                <div className="col-2 align-self-center text-end">
                                    <a href="#" className="cross-icon" onClick={(e) => {
                                        e.preventDefault();
                                        closeClipModal()
                                    }}><img src={crossBlackIcon} alt="icon"/></a>
                                </div>
                            </div>
                        </div>
                        <div className="clip-body">
                            <div className="video-wrapper">
                                {isLoadingVideo &&
                                    <div className="loading-wrapper">
                                        <div className="lds-roller">
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                        </div>
                                    </div>
                                }
                                <video
                                    id="video-clip"
                                    className="video"
                                    controls={false}
                                    ref={videoPlayer}
                                    src={fileURL}
                                    onLoadStart={handleLoadStart}
                                    onLoadedData={handleLoadedData}
                                ></video>

                                <div id="range-container">
                                    <input type="range" id="start-slider" step={1} value={startClip} onChange={(e) => {
                                        // Prevent start slider from exceeding end slider

                                        if (parseFloat(e.target.value) >= parseFloat(endClip) - 5) {
                                            setStartClip(parseFloat(endClip) - 5)
                                        } else {
                                            if (parseFloat(endClip) - parseFloat(e.target.value) <= 600) {
                                                setStartClip(e.target.value)
                                            }
                                        }
                                        updateSlider()

                                    }} ref={startSlider} min="0"/>
                                    <input type="range" className="end" id="end-slider" value={endClip} step={1} onChange={(e) => {
                                        // Prevent end slider from going below start slider
                                        if (parseFloat(e.target.value) <= parseFloat(startClip) + 5) {
                                            setEndClip(parseFloat(startClip) + 5)
                                        } else {
                                            if (parseFloat(e.target.value) - parseFloat(startClip) <= 600) {
                                                setEndClip(e.target.value)
                                            }
                                        }
                                        updateSlider()
                                    }} ref={endSlider} min="0"/>

                                    <div className="slider-track" id="slider-track" ref={sliderTrack}></div>
                                    <div className="slider-track-gray" id="slider-track-gray" ref={sliderTrackGray}></div>
                                </div>
                                <div className="video-tool-wrapper">
                                    <span className="progress-line" onClick={handleProgressClick}>
                                    <span className="watch-line" id="watch-line-line-clip"></span>
                                  </span>
                                    <div className="video-tools">
                                        <div className="row">
                                            <div className="col-8 align-self-center">
                                                <ul className="tools">
                                                    <li>
                                                        <a href="#" onClick={togglePlayPause}>
                                                            <img src={isPlaying ? pauseIcon : playWhiteIcon} className={isPlaying ? 'w-18' : ''} alt="icon"/>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="#" onClick={() => skipTime(10)}>
                                                            <img src={audioNextIcon} alt="icon"/>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="#" onClick={() => skipTime(-10)}>
                                                            <img src={audioPreviousIcon} alt="icon"/>
                                                        </a>
                                                    </li>
                                                    {videoPosition !== "" &&
                                                        <li>
                                                            <p>{formatTime(videoPosition)} / {formatTime(videoPlayer?.current?.duration)}</p>
                                                        </li>
                                                    }
                                                </ul>
                                            </div>
                                            <div className="col-4 align-self-center">
                                                <ul className="tools right">
                                                    <li>
                                                        <a href="#" onClick={toggleMute}>
                                                            <img src={isMuted ? muteIcon : speakerIcon} className={isMuted ? 'w-18' : ''} alt="icon"/>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="clip-footer">
                            <div className="row">
                                <div className="col-md-9 col-12 align-self-center">
                                    <div className="clip-time">
                                        <p>Clip Time: {formatTime(clipDuration)} </p>
                                        <ul className="time-count">
                                            <li>
                                                <p className="small">From</p>
                                                <span className="count">{formatTime(startClip)}</span>
                                                {/*
                                                <input type="text" value={formatTime(startClip)}/>*/}
                                            </li>
                                            <li>
                                                <p className="small">To</p>
                                                <span className="count">{formatTime(endClip)}</span>
                                                {/*
                                                <input type="text" value={formatTime(endClip)}/>*/}
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-md-3 col-12 align-self-center">
                                    <div className="btn-wrapper">
                                        <Button loading={loading} className="btn-style" onClick={(e) => {
                                            e.preventDefault();
                                            saveClip();
                                            console.log(`Clip created from ${formatTime(startClip)} to ${formatTime(endClip)}`);
                                        }}>Save</Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>
            }
        </>
    );
};

export default CreateClip;



