import React, {useEffect, useState, useRef} from "react";
import {Link, useNavigate} from "react-router-dom";
import {Popover, Checkbox, DatePicker, Select, Tooltip, Drawer, Modal, notification} from 'antd';
import {useAuth} from "../../context/AuthContext";
import config from "../../config";
import searchIcon from "../../assets/images/ai-search/search-icon.svg";
import crossBlackIcon from "../../assets/images/ai-search/cross-black-icon.svg";
import infoWhiteIcon from "../../assets/images/ai-search/info-white-icon.svg";
import fileIcon from "../../assets/images/ai-search/file-icon.svg";
import backArrowIcon from "../../assets/images/icons/back-arrow-black-icon.svg";
import aiCopyIcon from "../../assets/images/icons/ai-copy-icon.svg";
import clipsIcon from "../../assets/images/ai-search/clips-icon.svg";
import taggingIcon from "../../assets/images/ai-search/tagging-icon.svg";
import fileInfoIcon from "../../assets/images/ai-search/file-info-icon.svg";
import commnetIcon from "../../assets/images/ai-search/commnet-icon.svg";
import videoImg from "../../assets/images/ai-search/video-plyer-img.png";
import playIcon from "../../assets/images/ai-search/play-circle-icon.svg";
import globeIcon from "../../assets/images/ai-search/globe-gray-icon.svg";
import rightDrawerIcon from "../../assets/images/ai-search/open-drawer-right-icon.svg";
import plusWhiteIcon from "../../assets/images/ai-search/plus-white-icon.svg";
import playBlackIcon from "../../assets/images/ai-search/play-black-icon.svg";
import trashBlackIcon from "../../assets/images/ai-search/trash-black-icon.svg";
import downloadBlackIcon from "../../assets/images/ai-search/download-black-icon.svg";
import editBlackIcon from "../../assets/images/ai-search/edit-black-icon.svg";
import identifiedImg1 from "../../assets/images/ai-search/identified-object-img-1.png";
import identifiedImg2 from "../../assets/images/ai-search/identified-object-img-2.png";
import identifiedImg3 from "../../assets/images/ai-search/identified-object-img-3.png";
import identifiedImg4 from "../../assets/images/ai-search/identified-object-img-4.png";
import identifiedImg5 from "../../assets/images/ai-search/identified-object-img-5.png";
import identifiedImg6 from "../../assets/images/ai-search/identified-object-img-6.png";
import identifiedImg7 from "../../assets/images/ai-search/identified-object-img-7.png";
import identifiedImg8 from "../../assets/images/ai-search/identified-object-img-8.png";
import identifiedImg9 from "../../assets/images/ai-search/identified-object-img-9.png";
import identifiedImg10 from "../../assets/images/ai-search/identified-object-img-10.png";
import identifiedImg11 from "../../assets/images/ai-search/identified-object-img-11.png";
import identifiedImg12 from "../../assets/images/ai-search/image 45.jpg";
import crossRedCircleIcon from "../../assets/images/ai-search/cross-red-circle-icon.svg";
import userImg1 from "../../assets/images/ai-search/comment-user-img.png";
import zoomIn from "../../assets/images/ai-search/Zoomin.svg";
import zoomOut from "../../assets/images/ai-search/ZoomOut.svg";
import download from "../../assets/images/ai-search/Download.svg";
import fullscreen from "../../assets/images/ai-search/Full.svg";
import minimized from "../../assets/images/ai-search/Minimazed.svg";

import Viewer from 'react-viewer';
import {tr} from "react-date-range/dist/locale";
import toastIcon from "../../assets/images/icons/toast-icon.svg";
import Comments from "./CommentsSection";

const {RangePicker} = DatePicker;
const VideoDetail = ({open, callback, previewItem}) => {
    const {
        postDataValere,
        getDataValere,
        logout,
        setPopup500,
        setError499,
        setPopup499,
        user,
        getFormatedDate,
        setSearchResults,
        searchResults,
        getCloudAccountName,
        getFormatedDateFiles,
    } = useAuth();
    const viewerContainer = useRef(null); // Create a ref to the container
    const [fileSidebar, setFileSidebar] = useState(false);
    const [fileInfo, setFileInfo] = useState(false);
    const [aiComment, setAiComment] = useState(false);
    const [aiTagging, setAiTagging] = useState(false);
    const [containerReady, setContainerReady] = useState(false);

    const [isRightSidebar, setisRightSidebar] = useState(false);
    const [editClipModal, setEditClipModal] = useState(false);
    const [showImagePreview, setShowImagePreview] = useState(false);
    const [tags, setTags] = useState([]);
    const [autoTags, setAutoTags] = useState([]);
    const [speakers, setSpeakers] = useState([]);
    const [keywords, setKeywords] = useState("");
    const [transcript, setTranscript] = useState([]);
    const [comments, setComments] = useState([]);
    const [clips, setClips] = useState([]);
    const [commentsOnVideo, setCommentsOnVideo] = useState([]);
    const [showAddCommentsOnVideo, setShowAddCommentsOnVideo] = useState(false);
    const [currentCommentPosition, setCurrentCommentPosition] = useState("");
    const [fileId, setFileId] = useState("");
    const [fileName, setFileName] = useState("");
    const [fileURL, setFileURL] = useState("");
    const [fileDuration, setFileDuration] = useState("");
    const [fileSize, setFileSize] = useState("");
    const [fileUpdated_at, setFileUpdated_at] = useState("");
    const [fileSummary, setFileSummary] = useState("");
    const [fileResolution, setFileResolution] = useState("");
    const [fileCreated_at, setFileCreated_at] = useState("");
    const [fileModifiedBy, setFileModifiedBy] = useState("");
    const [fileType, setFileType] = useState("");
    const [fileBucketName, setFileBucketName] = useState("");
    const [fileCloudName, setFileCloudName] = useState("");
    const [fileMetaDate, setFileMetaDate] = useState([]);
    const [getMetaDataDone, setGetMetaDataDone] = useState(false);
    const [api, contextHolder] = notification.useNotification();

    useEffect(() => {
        if (fileSidebar) {
            document.body.classList.add('file-sidebar-open');
        } else {
            document.body.classList.remove('file-sidebar-open');
        }
    }, [fileSidebar]);

    useEffect(() => {
        getDataValere(`/file?file_id=${previewItem.id}`)
            .then((res) => Promise.all([res.status, res.json()]))
            .then(([status, data]) => {
                console.log(data)
                if (status === 401) {
                    logout()
                    return;
                }
                if (status === 500) {
                    setPopup500(true)
                    return;
                }
                if (status === 499) {
                    var dataNew = JSON.parse(data)
                    var errorType = dataNew.type;
                    setError499(dataNew.errors[errorType][0])
                    setPopup499(true)
                    return;
                }

                setKeywords(data.keywords)
                setTranscript(data.transcriptions)
                setFileId(data.id)
                setFileName(data.path)
                setFileURL(data.url)
                setFileDuration(data.duration)
                setFileSize(data.size)
                setFileSummary(data.summary)
                setFileUpdated_at(data.updated_at)
                setFileResolution(data.resolution)
                setFileCreated_at(data.created_at)
                setFileModifiedBy(data.modify_by)
                setFileType(data.type)
                setFileBucketName(data.bucket_name)
                setFileCloudName(getCloudAccountName(data.cloud_account_id))

            })
        getDataValere(`/tags?file_id=${previewItem.id}`)
            .then((res) => Promise.all([res.status, res.json()]))
            .then(([status, data]) => {
                console.log(data)
                if (status === 401) {
                    logout()
                    return;
                }
                if (status === 500) {
                    setPopup500(true)
                    return;
                }
                if (status === 499) {
                    var dataNew = JSON.parse(data)
                    var errorType = dataNew.type;
                    setError499(dataNew.errors[errorType][0])
                    setPopup499(true)
                    return;
                }
                setAutoTags(data)
            })
        getDataValere(`/metadata?file_id=${previewItem.id}`)
            .then((res) => Promise.all([res.status, res.json()]))
            .then(([status, data]) => {
                console.log("This is meta data")
                console.log(data)
                if (status === 401) {
                    logout()
                    return;
                }
                if (status === 500) {
                    setPopup500(true)
                    return;
                }
                if (status === 499) {
                    var dataNew = JSON.parse(data)
                    var errorType = dataNew.type;
                    setError499(dataNew.errors[errorType][0])
                    setPopup499(true)
                    return;
                }
                setFileMetaDate(data)
                setGetMetaDataDone(true)
            })
        /*getComments();
        getClips();*/
    }, []);
    const onCloseDrawer = (e) => {
        e.preventDefault();
        callback()
    };
    const onEditClipClose = (e) => {
        e.preventDefault();
        setFileInfo(false);
        setisRightSidebar(false);
        setAiComment(false);
    };

    const onAiTagging = (e) => {
        e.preventDefault()
        setAiTagging(true);
        setFileInfo(false);
        setAiComment(false);
        setisRightSidebar(false)
    };

    const onFileInfo = (e) => {
        e.preventDefault();
        setFileInfo(true);
        setAiTagging(false);
        setAiComment(false);
        setisRightSidebar(true);
    };
    const onAiComment = (e) => {
        e.preventDefault();
        setAiComment(true);
        setFileInfo(false);
        setAiTagging(false);
        setisRightSidebar(true);
    };
    const toggleFullscreen = () => {
        if (document.fullscreenElement) {
            document.exitFullscreen();
        } else if (viewerContainer.current) {
            viewerContainer.current.requestFullscreen();
        }
    };
    const customToolbar = (config) => {
        return config.concat([
            {
                key: 'customDownload',
                render: <img src={download} alt="img"/>,
                onClick: () => {

                    downloadURI(fileURL, fileName)
                }
            },
            {
                key: 'fullscreen',
                render: <img src={document.fullscreenElement ? minimized : fullscreen} alt="img"/>,
                onClick: toggleFullscreen
            }
        ]).map((item) => {
            if (item.key === 'zoomIn') {
                return {...item, render: <img src={zoomIn} alt="img"/>};
            }
            if (item.key === 'zoomOut') {
                return {...item, render: <img src={zoomOut} alt="img"/>};
            }
            return item;
        });
    };

    function downloadURI(uri, name)
    {
        var link = document.createElement("a");
        // If you don't know the name or want to use
        // the webserver default set name = ''
        link.setAttribute('download', name);
        link.setAttribute('target', "_blank");
        link.href = uri;
        document.body.appendChild(link);
        link.click();
        link.remove();
    }

    const [isDesktop, setIsDesktop] = useState(false);
    useEffect(() => {
        // Function to check screen size
        const checkScreenSize = () => {
            setIsDesktop(window.innerWidth >= 1024);
        };

        // Initial check
        checkScreenSize();

        // Add event listener to handle screen resize
        window.addEventListener('resize', checkScreenSize);

        // Cleanup event listener on component unmount
        return () => window.removeEventListener('resize', checkScreenSize);
    }, []);


    return (
        <>
            <Drawer
                title="Basic Drawer"
                placement={'bottom'}
                closable={false}
                onClose={onCloseDrawer}
                open={open}
                afterOpenChange={(o) => {
                    setShowImagePreview(o)
                }}
            >
                <div className="ai-drawer-main">
                    <div className={`ai-left-sidebar ${!isRightSidebar && isDesktop ? 'active' : ''}`}>
                        <a href="#" className="open-drawer" onClick={(e) => {
                            e.preventDefault();
                            setisRightSidebar(false)
                            setIsDesktop(true);
                        }}>
                            <img src={rightDrawerIcon} alt="icon"/>
                        </a>
                        <div className="ai-sidebar-wrapper">
                            <div className="title-wrapper-main">
                                <div className="row">
                                    <div className="col-md-12 d-block d-md-block col-10 align-self-center">
                                        <div className="title-wrapper">
                                            <a href="#" className="back-icon" onClick={onCloseDrawer}><img src={backArrowIcon} alt="icon"/></a>
                                            <h3>{fileName}</h3>
                                        </div>
                                    </div>
                                    <div className="col-md-12 d-block d-md-none col-2 align-self-center">
                                        <div className="cross-icon">
                                            <a href="#" onClick={(e) => {
                                                e.preventDefault();
                                                setIsDesktop(false);
                                            }}><i className="fa-solid fa-x"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="form ai-search">
                                {/*<div className="form-group">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter your search"
                                    />
                                    <img src={searchIcon} alt="icon" className="icon"/>
                                </div>*/}
                            </div>
                            {autoTags?.length > 0 &&
                                <div className="ai-tags-wrapper">
                                    <p className="title">Auto-tags</p>
                                    <ul className="ai-tags">
                                        {autoTags.map((item) => (
                                            <li><a href="#" id={item.id}>{item.name}</a></li>
                                        ))}
                                    </ul>
                                </div>
                            }
                            {speakers?.length > 0 &&
                                <div className="ai-tags-wrapper">
                                    <p className="title">Speakers</p>
                                    <ul className="ai-tags">
                                        {speakers.map((item) => (
                                            <li><a href="#" id={item.id}>{item.name}</a></li>
                                        ))}
                                    </ul>
                                </div>
                            }
                            {keywords !== "" &&
                                <div className="ai-copy-wrapper">
                                    <div className="copy-title">
                                        <p className="title">Keywords</p>
                                        <a href="#" onClick={(e) => {
                                            e.preventDefault();
                                            navigator.clipboard.writeText(keywords);
                                            api.open({
                                                message: 'Keywords Copied.',
                                                icon: <img src={toastIcon} alt="toastIcon"/>,
                                                duration: 3,
                                            });
                                        }} className="copy-icon"><img src={aiCopyIcon} alt="icon"/></a>
                                    </div>
                                    <div className="copy-body">
                                        <p>{keywords}</p>
                                    </div>
                                </div>
                            }
                            {fileSummary !== "" &&
                                <div className="ai-copy-wrapper mb-0">
                                    <div className="copy-title">
                                        <p className="title">Summary</p>
                                        <a href="#" onClick={(e) => {
                                            e.preventDefault();
                                            navigator.clipboard.writeText(fileSummary);
                                            api.open({
                                                message: 'Summary Copied.',
                                                icon: <img src={toastIcon} alt="toastIcon"/>,
                                                duration: 3,
                                            });
                                        }} className="copy-icon"><img src={aiCopyIcon} alt="icon"/></a>
                                    </div>
                                    <div className="copy-body">
                                        <p>{fileSummary}</p>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                    <div className={`ai-right-sidebar ${isRightSidebar ? 'active' : ''}`}>
                        {/*{fileInfo && (
                            <div className="ai-sidebar-wrapper">
                                <div className="file-name-wrapper">
                                    <div className="title-wrapper">
                                        <h3 className="h3">File Name</h3>
                                        <a href="#" className="cross-icon" onClick={onEditClipClose}><img src={crossBlackIcon} alt="icon"/></a>
                                    </div>
                                    <div className="file-name-body">
                                        <ul className="btn-list">
                                            <li><a href="#" className="btn-style icon"><img src={infoWhiteIcon} alt="img"/>About the file</a></li>
                                            <li><a href="#" className="btn-style icon text"><img src={fileIcon} alt="img"/>All files</a></li>
                                        </ul>
                                        <div className="information-wrapper">
                                            <p className="p bold title">General information</p>
                                            <ul className="info">
                                                <li>
                                                    <p className="small semi">Type:</p>
                                                    <p className="small">MP4 video</p>
                                                </li>
                                                <li>
                                                    <p className="small semi">Size:</p>
                                                    <p className="small">500 bytes</p>
                                                </li>
                                                <li>
                                                    <p className="small semi">Cloud:</p>
                                                    <p className="small">AWS</p>
                                                </li>
                                                <li>
                                                    <p className="small semi">Bucket:</p>
                                                    <p className="small">Bucket name</p>
                                                </li>
                                                <li>
                                                    <p className="small semi">Created:</p>
                                                    <p className="small">may 11th, 2023 at 09:14</p>
                                                </li>
                                                <li>
                                                    <p className="small semi">Modified:</p>
                                                    <p className="small">24 feb 2024 at 13:45 by Barry Allen</p>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="tags-wrapper">
                                            <p className="p bold title">Auto-tags</p>
                                            <ul className="tags">
                                                <li><a href="#">Quam</a></li>
                                                <li><a href="#">Quam</a></li>
                                                <li><a href="#">Quam</a></li>
                                                <li><a href="#">Quam</a></li>
                                                <li><a href="#">Quam</a></li>
                                                <li><a href="#">Quam</a></li>
                                                <li><a href="#">Quam</a></li>
                                                <li><a href="#">Quam</a></li>
                                                <li><a href="#">Quam</a></li>
                                                <li><a href="#">Quam</a></li>
                                            </ul>
                                        </div>
                                        <div className="tags-wrapper">
                                            <p className="p bold title">Comments</p>
                                            <div className="comment-wrapper">
                                                <div className="comment-card">
                                                    <div className="img-wrapper">
                                                        <img src={userImg1} alt="icon"/>
                                                    </div>
                                                    <div className="content">
                                                        <p><strong>Isaac F.</strong> commented</p>
                                                        <ul className="option">
                                                            <li><span className="count">05:13</span></li>
                                                            <li><p>Good shot, I love it!</p></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="comment-card">
                                                    <div className="img-wrapper">
                                                        <img src={userImg1} alt="icon"/>
                                                    </div>
                                                    <div className="content">
                                                        <p><strong>Isaac F.</strong> commented</p>
                                                        <ul className="option">
                                                            <li><span className="count">05:13</span></li>
                                                            <li><p>Good shot, I love it!</p></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="comment-card">
                                                    <div className="img-wrapper">
                                                        <img src={userImg1} alt="icon"/>
                                                    </div>
                                                    <div className="content">
                                                        <p><strong>Isaac F.</strong> commented</p>
                                                        <ul className="option">
                                                            <li><span className="count">05:13</span></li>
                                                            <li><p>Good shot, I love it!</p></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}*/}

                        {fileInfo && (
                            <div className="ai-sidebar-wrapper">
                                <div className="file-name-wrapper">
                                    <div className="title-wrapper">
                                        <h3 className="h3">{fileName}</h3>
                                        <a href="#" className="cross-icon" onClick={onEditClipClose}><img src={crossBlackIcon} alt="icon"/></a>
                                    </div>
                                    <div className="file-name-body">
                                        <ul className="btn-list">
                                            <li><a href="#" className="btn-style icon"><img src={infoWhiteIcon} alt="img"/>About the file</a></li>
                                            {/*<li><a href="#" className="btn-style icon text"><img src={fileIcon} alt="img"/>All files</a></li>*/}
                                        </ul>
                                        <div className="information-wrapper">
                                            <p className="p bold title">General information</p>
                                            <ul className="info">
                                                <li>
                                                    <p className="small semi">Type:</p>
                                                    <p className="small">{fileType}</p>
                                                </li>
                                                <li>
                                                    <p className="small semi">Size:</p>
                                                    <p className="small">{fileSize}</p>
                                                </li>
                                                <li>
                                                    <p className="small semi">Resolution:</p>
                                                    <p className="small">{fileResolution}</p>
                                                </li>
                                                <li>
                                                    <p className="small semi">Cloud:</p>
                                                    <p className="small">{fileCloudName}</p>
                                                </li>
                                                <li>
                                                    <p className="small semi">Bucket:</p>
                                                    <p className="small">{fileBucketName}</p>
                                                </li>
                                                <li>
                                                    <p className="small semi">Created:</p>
                                                    <p className="small">{getFormatedDateFiles(fileCreated_at)}</p>
                                                </li>
                                                <li>
                                                    <p className="small semi">Modified:</p>
                                                    <p className="small">{getFormatedDateFiles(fileUpdated_at)} {fileModifiedBy !== "" ? ` by ${fileModifiedBy}` : ""}</p>
                                                </li>
                                            </ul>
                                        </div>
                                        {autoTags?.length > 0 &&
                                            <div className="tags-wrapper">
                                                <p className="p bold title">Tags</p>
                                                <ul className="tags">
                                                    {autoTags.map((item) => (
                                                        <li><a href="#" id={item.id}>{item.name}</a></li>
                                                    ))}
                                                </ul>
                                            </div>
                                        }
                                        <Comments data={comments}/>
                                    </div>
                                </div>
                            </div>
                        )}
                        {aiComment && (
                            <div className="ai-sidebar-wrapper">
                                <div className="file-name-wrapper">
                                    <div className="title-wrapper">
                                        <h3 className="h3">Comments</h3>
                                        <a href="#" className="cross-icon" onClick={onEditClipClose}><img src={crossBlackIcon} alt="icon"/></a>
                                    </div>
                                    <div className="file-name-body">
                                        <div className="tags-wrapper">
                                            <div className="select-wrapper">
                                                <Select
                                                    defaultValue="Frame Time"
                                                    options={[
                                                        {value: 'All', label: 'All'},
                                                        {value: 'Start (0:00 to 2:00)', label: 'Start (0:00 to 2:00)'},
                                                        {value: 'Quarter (2:01 to 4:00)', label: 'Quarter (2:01 to 4:00)'},
                                                        {value: 'Middle (4:01 to 6:00)', label: 'Middle (4:01 to 6:00)'},
                                                        {value: 'Three-quarters (6:01 to 8:00)', label: 'Three-quarters (6:01 to 8:00)'},
                                                        {value: 'End (8:01: to 10:00)', label: 'End (8:01: to 10:00)'},
                                                    ]}
                                                />
                                            </div>
                                            <div className="comment-wrapper">
                                                <div className="comment-card">
                                                    <div className="img-wrapper">
                                                        <img src={userImg1} alt="icon"/>
                                                    </div>
                                                    <div className="content">
                                                        <p><strong>Isaac F.</strong> commented</p>
                                                        <ul className="option">
                                                            <li><span className="count">05:13</span></li>
                                                            <li><p>Good shot, I love it!</p></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="comment-card">
                                                    <div className="img-wrapper">
                                                        <img src={userImg1} alt="icon"/>
                                                    </div>
                                                    <div className="content">
                                                        <p><strong>Isaac F.</strong> commented</p>
                                                        <ul className="option">
                                                            <li><span className="count">05:13</span></li>
                                                            <li><p>Good shot, I love it!</p></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="comment-card">
                                                    <div className="img-wrapper">
                                                        <img src={userImg1} alt="icon"/>
                                                    </div>
                                                    <div className="content">
                                                        <p><strong>Isaac F.</strong> commented</p>
                                                        <ul className="option">
                                                            <li><span className="count">05:13</span></li>
                                                            <li><p>Good shot, I love it!</p></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                    <div id="scrollable" className={`ai-main-content padding-bottom-50  ${isRightSidebar ? 'right-active' : 'left-active'}`}>
                        <ul className="tool-menu">
                            <li><a href="#" onClick={onAiTagging} className={`${aiTagging ? 'active' : ''} d-none`}><img src={taggingIcon} alt="icon"/>AI tagging</a></li>
                            <li><a href="#" onClick={onFileInfo} className={`${fileInfo ? 'active' : ''}`}><img src={fileInfoIcon} alt="icon"/>File information</a></li>
                            <li><a href="#" onClick={onAiComment} className={`${aiComment ? 'active' : ''} d-none`}><img src={commnetIcon} alt="icon"/>Comments</a></li>
                        </ul>
                        <div className="photo-preview-page" id="viewerContainer" ref={viewerContainer}>
                            <Viewer
                                visible={showImagePreview}
                                // rotatable={false}
                                showTotal={false}
                                loop={false}
                                changeable={false}
                                //noFooter={true}
                                noClose={true}
                                noNavbar={true}
                                minScale={1.5}
                                defaultScale={2}
                                customToolbar={customToolbar}
                                container={document.getElementById('viewerContainer')}
                                images={[{src: fileURL, alt: fileName}]}
                            />
                        </div>
                        <div className="transcript-wrapper d-none" id="TranscriptTop">
                            <div className="transcript-header">
                                {!aiTagging && (
                                    <div className="row">
                                        <div className="col-6 align-self-center">
                                            <div className="content">
                                                <p className="title">Your related images</p>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {aiTagging && (
                                    <div className="row">
                                        <div className="col-6 align-self-center">
                                            <div className="content">
                                                <p className="title">Identified</p>
                                            </div>
                                        </div>
                                        <div className="col-6 align-self-center text-end">
                                            <a href="#" className="edit-icon"><img src={editBlackIcon} alt="icon"/></a>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className="transcript-body">
                                {!aiTagging && (
                                    <ul className="identified">
                                        <li>
                                            <div className="identified-card">
                                                <div className="img-wrapper">
                                                    <img src={identifiedImg12} alt="img"/>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="identified-card">
                                                <div className="img-wrapper">
                                                    <img src={identifiedImg12} alt="img"/>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="identified-card">
                                                <div className="img-wrapper">
                                                    <img src={identifiedImg12} alt="img"/>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                )}
                                {aiTagging && (
                                    <ul className="identified">
                                        <li>
                                            <div className="identified-card">
                                                <a href="#" className="cross-icon"><img src={crossRedCircleIcon} alt="icon"/></a>
                                                <div className="img-wrapper">
                                                    <img src={identifiedImg1} alt="img"/>
                                                </div>
                                                <div className="content">
                                                    <p>Face</p>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="identified-card">
                                                <a href="#" className="cross-icon"><img src={crossRedCircleIcon} alt="icon"/></a>
                                                <div className="img-wrapper">
                                                    <img src={identifiedImg2} alt="img"/>
                                                </div>
                                                <div className="content">
                                                    <p>Bird</p>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="identified-card">
                                                <a href="#" className="cross-icon"><img src={crossRedCircleIcon} alt="icon"/></a>
                                                <div className="img-wrapper">
                                                    <img src={identifiedImg3} alt="img"/>
                                                </div>
                                                <div className="content">
                                                    <p>Bottle</p>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="identified-card">
                                                <a href="#" className="cross-icon"><img src={crossRedCircleIcon} alt="icon"/></a>
                                                <div className="img-wrapper">
                                                    <img src={identifiedImg4} alt="img"/>
                                                </div>
                                                <div className="content">
                                                    <p>Bottle</p>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="identified-card">
                                                <a href="#" className="cross-icon"><img src={crossRedCircleIcon} alt="icon"/></a>
                                                <div className="img-wrapper">
                                                    <img src={identifiedImg5} alt="img"/>
                                                </div>
                                                <div className="content">
                                                    <p>Traffic Light</p>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="identified-card">
                                                <a href="#" className="cross-icon"><img src={crossRedCircleIcon} alt="icon"/></a>
                                                <div className="img-wrapper">
                                                    <img src={identifiedImg6} alt="img"/>
                                                </div>
                                                <div className="content">
                                                    <p>Face</p>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="identified-card">
                                                <a href="#" className="cross-icon"><img src={crossRedCircleIcon} alt="icon"/></a>
                                                <div className="img-wrapper">
                                                    <img src={identifiedImg7} alt="img"/>
                                                </div>
                                                <div className="content">
                                                    <p>Person</p>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="identified-card">
                                                <a href="#" className="cross-icon"><img src={crossRedCircleIcon} alt="icon"/></a>
                                                <div className="img-wrapper">
                                                    <img src={identifiedImg8} alt="img"/>
                                                </div>
                                                <div className="content">
                                                    <p>Person</p>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="identified-card">
                                                <a href="#" className="cross-icon"><img src={crossRedCircleIcon} alt="icon"/></a>
                                                <div className="img-wrapper">
                                                    <img src={identifiedImg9} alt="img"/>
                                                </div>
                                                <div className="content">
                                                    <p>Person</p>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="identified-card">
                                                <a href="#" className="cross-icon"><img src={crossRedCircleIcon} alt="icon"/></a>
                                                <div className="img-wrapper">
                                                    <img src={identifiedImg10} alt="img"/>
                                                </div>
                                                <div className="content">
                                                    <p>Car</p>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="identified-card">
                                                <a href="#" className="cross-icon"><img src={crossRedCircleIcon} alt="icon"/></a>
                                                <div className="img-wrapper">
                                                    <img src={identifiedImg11} alt="img"/>
                                                </div>
                                                <div className="content">
                                                    <p>Car</p>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </Drawer>
        </>
    );
};

export default VideoDetail;



