import React, {useEffect, useState, useRef} from "react";
import {Link, NavLink, useLocation, useNavigate} from "react-router-dom";
import {Popover, Checkbox, DatePicker, Button} from 'antd';
import {useAuth} from "../../context/AuthContext";
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import {DateRange} from 'react-date-range';


import downArrowIcon from "../../assets/images/ai-search/angle-arrow-down-icon.svg";
import searchIcon from "../../assets/images/ai-search/search-icon.svg";
import calendarIcon from "../../assets/images/ai-search/calendar-icon.svg";
import filterIcon from "../../assets/images/ai-search/filter-icon.svg";
import aiStarIcon from "../../assets/images/ai-search/ai-star-icon.svg";
import fileIcon1 from "../../assets/images/ai-search/file-icon-1.svg";
import fileIcon2 from "../../assets/images/ai-search/file-icon-2.svg";
import fileIcon3 from "../../assets/images/ai-search/file-icon-3.svg";
import fileIcon4 from "../../assets/images/ai-search/file-icon-4.svg";
import fileIcon5 from "../../assets/images/ai-search/file-icon-5.svg";
import timeIcon from "../../assets/images/ai-search/time-icon.svg";
import guideIcon1 from "../../assets/images/ai-search/guide-icon-1.svg";
import guideIcon2 from "../../assets/images/ai-search/guide-icon-2.svg";
import guideIcon3 from "../../assets/images/ai-search/guide-icon-3.svg";
import guideIcon4 from "../../assets/images/ai-search/guide-icon-4.svg";
import crossIcon from "../../assets/images/ai-search/cross-orange-icon.svg";
import searchOrangeIcon from "../../assets/images/ai-search/orange-search-icon.svg";
import universalSearchIcon from "../../assets/images/ai-search/userversal-search-icon.svg";

const AiSearch = ({location}) => {
    const {
        postDataValere,
        getDataValere,
        deleteDataValere,
        logout,
        setPopup500,
        setError499,
        setPopup499,
        user,
        getFormatedDate,
        setSearchResults,
        checkedValues,
        setCheckedValues,
        checkedAll,
        setCheckedAll,
        searchValue,
        setSearchValue,
        filterDate,
        setFilterDate,
        dateChanged,
        setDateChanged,
        setSearchedBucketName,
        setSearchedCloudName,
        selectedBucketsForSearch,
        setSelectedBucketsForSearch,
        groupByValue,
        setGroupByValue,
        orderByValue,
        setOrderByValue,
        fileIdToOpen,
        setFileIdToOpen
    } = useAuth();
    const navigate = useNavigate();
    const locationURL = useLocation();
    const dataFetchedRef = useRef(false);
    const [selectFile, setSelectFile] = useState(false);
    const [isCalendarOpen, setIsCalendarOpen] = useState(false);
    const [cloudFilterOpen, setCloudFilterOpen] = useState(false);
    const [metaFocused, setMetaFocused] = useState(false);
    const searchInputRef = useRef(null);
    const metaSearchRef = useRef(null);
    const [loading, setLoading] = useState(false);
    const [clouds, setClouds] = useState([]);
    const [selectedClouds, setSelectedClouds] = useState([]);
    const [buckets, setBuckets] = useState([]);
    const [searchHistory, setSearchHistory] = useState([]);
    const options = ["doc", "image", "video", "code", "audio"]; // Docs, Images, Videos, Code, Audio
    const [selectedIndex, setSelectedIndex] = useState(0); // Index for the selected search query
    const [innerSearchValue, setInnerSearchValue] = useState('');

    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const pageReloaded = useRef(false);

    useEffect(() => {
        if (Object.keys(user).length > 0) {
            console.log(Object.keys(user).length)
            console.log(user)
            console.log(user.userId)
            getBucketsForSearch()
            getDataValere("/history/" + user.userId)
                .then((res) => Promise.all([res.status, res.json()]))
                .then(([status, data]) => {
                    console.log(data)
                    if (status === 401) {
                        logout()
                        return;
                    }
                    if (status === 500) {
                        setPopup500(true)
                        return;
                    }
                    if (status === 499) {
                        var dataNew = JSON.parse(data)
                        var errorType = dataNew.type;
                        setError499(dataNew.errors[errorType][0])
                        setPopup499(true)
                        return;
                    }
                    setSearchHistory(data)
                })
        }
    }, [user]);


    useEffect(() => {
        const performanceEntries = window.performance.getEntriesByType('navigation');
        if (performanceEntries.length > 0) {
            const navigationEntry = performanceEntries[0];

            if (navigationEntry.type === 'reload') {
                // Parse the query string to get data
                const queryParams = new URLSearchParams(window.location.search);
                console.log('Page was reloaded');
                console.log(queryParams);
                const page = queryParams.get("page");
                const searchVal = queryParams.get("search");
                const buckets = queryParams.get("buckets");
                const groupBy = queryParams.get("groupBy");
                const orderBy = queryParams.get("orderBy");
                const pageSize = queryParams.get("pageSize");
                const file_types = queryParams.get("file_types");
                const created_at_max = queryParams.get("created_at_max");
                const created_at_min = queryParams.get("created_at_min");
                const updated_at_max = queryParams.get("updated_at_max");
                const updated_at_min = queryParams.get("updated_at_min");
                const fileId = queryParams.get("fileId");
                if (fileId !== null) {
                    setFileIdToOpen(fileId)
                }

                if (page !== null &&
                    searchVal !== null &&
                    groupBy !== null &&
                    orderBy !== null &&
                    pageSize !== null &&
                    file_types !== null
                ) {
                    setPageNumber(parseInt(page))
                    setPageSize(parseInt(pageSize))
                    setSearchValue(searchVal)
                    if(buckets?.length > 0){
                        const intArray = buckets.split(',').map(str => parseInt(str, 10));

                        console.log(intArray); // Output: [1, 2, 3, 4, 5]

                        setSelectedBucketsForSearch(intArray)
                    }

                    setCheckedValues(file_types.split(','))
                    if(created_at_min !== null && created_at_max !== null){
                        setFilterDate([
                            {
                                startDate: new Date(created_at_min),
                                endDate: new Date(created_at_max),
                                key: 'selection'
                            }
                        ])
                        setDateChanged(true)
                    }
                    setGroupByValue(groupBy !== "" ? groupBy : "none")
                    setOrderByValue(orderBy !== "" ? orderBy : "none")
                    pageReloaded.current = true;

                    search(false, true);

                }


                console.log(page);
                console.log(searchVal);
                console.log(page);
                console.log(buckets);
                console.log(groupBy);
                console.log(orderBy);
                console.log(pageSize);
                console.log(file_types);
                console.log(created_at_max);
                console.log(created_at_min);
                console.log(updated_at_max);
                console.log(updated_at_min);

            } else {
                console.log('Route changed to:', window.location.pathname);
            }
        }
    }, [location]);


    const handleArrowDownClick = (e) => {
        e.preventDefault();
        console.log(selectedIndex, 'setSelectedIndex')
        /*if (metaFocused) {
            setSelectedIndex((prevIndex) => (prevIndex + 1) % searchHistory.length); // Loop through the list items
        }*/
        setSelectedIndex((prevIndex) => (prevIndex + 1) % searchHistory.length); // Loop through the list items
    };

    const handleArrowUpClick = (e) => {
        e.preventDefault();
        /*if (metaFocused) {
            setSelectedIndex((prevIndex) => (prevIndex - 1 + searchHistory.length) % searchHistory.length); // Loop through the list items in reverse
        }*/
        setSelectedIndex((prevIndex) => (prevIndex - 1 + searchHistory.length) % searchHistory.length); // Loop through the list items in reverse
    };

    const listRef = useRef(null);
    const handleKeyDown = (e) => {
        /* if (e.key === 'ArrowDown') {
             handleArrowDownClick();
         } else if (e.key === 'ArrowUp') {
             handleArrowUpClick();
         } else if (e.key === 'Escape') {
             handleEscapeClick();
         } else if (e.key === 'Enter') {
             handleSelectClick();
         }*/
    };

    useEffect(() => {
        // Attach the keydown listener to the document
        document.addEventListener('keydown', handleKeyDown);
        return () => {
            // Clean up the listener on component unmount
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [selectedIndex, searchHistory.length]);

    useEffect(() => {
        if (locationURL.pathname === '/search-result') {
            dataFetchedRef.current = false;
            search(false);
        }
    }, [groupByValue, orderByValue]);
    useEffect(() => {
        // Scroll the selected item into view
        if (listRef.current) {
            const selectedItem = listRef.current.children[selectedIndex];
            if (selectedItem) {
                selectedItem.scrollIntoView({
                    behavior: 'smooth',
                    block: 'nearest',
                });
            }
        }
    }, [selectedIndex]);
    useEffect(() => {
        // Scroll the selected item into view
        if (buckets.length > 0) {
            if (pageReloaded.current) {
                pageReloaded.current = false
                search(false, true);
            }
        }
    }, [buckets]);


    const handleSelectClick = (e) => {
        e.preventDefault();
        setMetaFocused(false);
        const listItems = document.querySelectorAll('.searched-queries li');
        if (listItems[selectedIndex]) {
            setSearchValue(listItems[selectedIndex].textContent.trim());
            searchInputRef.current.focus();
        }
    };

    const handleEscapeClick = (e) => {
        e.preventDefault();
        setMetaFocused(false);
    };


    const handleSearchFocus = () => {
        setIsCalendarOpen(false);
        setCloudFilterOpen(false);
        setMetaFocused(true);
    };


    const handleClickOutside = (event) => {
        if (
            metaSearchRef.current &&
            !metaSearchRef.current.contains(event.target) &&
            searchInputRef.current &&
            !searchInputRef.current.contains(event.target)
        ) {
            setMetaFocused(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [selectFile]);

    const onSelectAllFile = (value) => {
        setCheckedAll(value.target.checked)
        if (value.target.checked) {
            setCheckedValues([...options]);
        } else {
            setCheckedValues([]);
        }
    }
    const onSelectFile = (values) => {
        setCheckedValues(values);
        console.log(values.length)
        console.log(options.length)
        if (values.length < options.length) {
            setCheckedAll(false)
        } else {
            setCheckedAll(true)
        }
    };

    useEffect(() => {
        console.log(location)
        console.log(checkedValues)
        if (location === "results") {
            //dataFetchedRef.current = false;
            // search();
        }
    }, [checkedValues]);
    const onSelectCloud = (values) => {
        console.log(values)
        setSelectedClouds(values);
        getBucketsForSearch(values)
    };
    const onSelectBucket = (values) => {
        console.log(values)
        setSelectedBucketsForSearch(values)
    };


    const getBucketName = (id) => {
        console.log(id)
        console.log(buckets)
        if (buckets.length > 0) {
            console.log(buckets.find(bucket => bucket.id === id))
            return buckets.find(bucket => bucket.id === id)?.bucket_name;
        }
    };


    const getBucketsForSearch = () => {
        if (user !== undefined) {
            console.log(user.userId)
            getDataValere(`/buckets?user_id=${user.userId}`)
                .then((res) => Promise.all([res.status, res.json()]))
                .then(([status, data]) => {
                    console.log(data)
                    if (status === 401) {
                        logout()
                        return;
                    }
                    if (status === 500) {
                        setPopup500(true)
                        return;
                    }
                    if (status === 499) {
                        var dataNew = JSON.parse(data)
                        var errorType = dataNew.type;
                        setError499(dataNew.errors[errorType][0])
                        setPopup499(true)
                        return;
                    }
                    console.log(data)
                    setBuckets(data)
                })
        }
    };


    const onCalendarOpen = (e) => {
        setCloudFilterOpen(false);
        // setIsCalendarOpen(true);
        e.preventDefault();
        setIsCalendarOpen(!isCalendarOpen);
    };
    const onCloudFilterOpen = (e) => {
        e.preventDefault();
        setIsCalendarOpen(false);
        // setCloudFilterOpen(true);
        setCloudFilterOpen(!cloudFilterOpen);
    };

    // Function to format date to 'YYYY-MM-DDTHH:mm:ssZ'
    function formatToISO(date) {
        let yyyy = date.getUTCFullYear();
        let mm = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months are zero-based
        let dd = String(date.getUTCDate()).padStart(2, '0');
        let hh = String(date.getUTCHours()).padStart(2, '0');
        let min = String(date.getUTCMinutes()).padStart(2, '0');
        let ss = String(date.getUTCSeconds()).padStart(2, '0');
        return `${yyyy}-${mm}-${dd}T${hh}:${min}:${ss}Z`;
    }


    const handleSearchKeyDown = (event) => {
        if (event.key === 'Enter' && searchValue.length > 2) {
            if (document.activeElement.id === 'ai-search-input') {
                dataFetchedRef.current = false;
                search();
            }
        }
    };

    useEffect(() => {
        window.addEventListener('keydown', handleSearchKeyDown);
        return () => {
            window.removeEventListener('keydown', handleSearchKeyDown);
        };
    }, [searchValue]);
    const search = (loading = true, pageReload = false) => {
        if (!pageReload) {
            console.log(dataFetchedRef.current)
            if (dataFetchedRef.current) return;
            dataFetchedRef.current = true;
        }
        var bucketsToPost = []
        if (selectedBucketsForSearch.length > 0) {
            let newArr = [...selectedBucketsForSearch];
            for (var i = 0; i < newArr.length; i++) {
                var item = buckets.filter((item) => item.id === newArr[i])
                if (item.length > 0) {
                    bucketsToPost.push(item[0].id);
                }
            }
        } else {
            for (var j = 0; j < buckets.length; j++) {
                bucketsToPost.push(buckets[j].id);
            }
        }
        var bucketsToPostLength = selectedBucketsForSearch.length;
        console.log(selectedBucketsForSearch)
        console.log(bucketsToPost)
        var created_at_max = "";
        var created_at_min = "";
        if (dateChanged) {
            created_at_max = formatToISO(new Date(filterDate[0].endDate))
            created_at_min = formatToISO(new Date(filterDate[0].startDate))
        }
        var gby = groupByValue
        var oby = orderByValue

        if (groupByValue === "none") {
            gby = "";
        }

        if (orderByValue === "none") {
            oby = "";
        }
        console.log(gby)
        console.log(oby)
        var file_types = checkedValues;

        if (checkedValues.length === 0) {
            file_types = options;
        }


        var dataToPost = {
            "page": pageNumber,
            "search": searchValue,
            "buckets": bucketsToPost,
            "groupBy": gby,
            "orderBy": oby,
            "pageSize": pageSize,
            "file_types": file_types,
        }

        if (created_at_max !== "") {
            dataToPost.created_at_max = created_at_max
        }
        if (created_at_min !== "") {
            dataToPost.created_at_min = created_at_min
        }
        if (created_at_max !== "") {
            dataToPost.updated_at_max = created_at_max
        }
        if (created_at_min !== "") {
            dataToPost.updated_at_min = created_at_min
        }

        console.log(dataToPost)
        if (bucketsToPost.length > 0) {
            if (loading) {
                setLoading(true)
            }
            postDataValere("/search", dataToPost)
                .then((res) => Promise.all([res.status, res.json()]))
                .then(([status, data]) => {
                    setLoading(false)
                    console.log(data)
                    if (status === 401) {
                        logout()
                        return;
                    }
                    if (status === 500) {
                        setPopup500(true)
                        return;
                    }
                    if (status === 499) {
                        var dataNew = JSON.parse(data)
                        var errorType = dataNew.type;
                        setError499(dataNew.errors[errorType][0])
                        setPopup499(true)
                        return;
                    }

                    setSearchResults(data)
                    dataFetchedRef.current = false;
                    if (data.data.length > 0) {
                        // if(!pageReload){
                        if(bucketsToPostLength === 0){
                            delete dataToPost.buckets;
                        }
                        console.log(bucketsToPostLength)
                        console.log(dataToPost)

                        const queryString = new URLSearchParams(dataToPost).toString();
                        navigate(`/search-result?${queryString}`)
                        // }
                    }
                }).catch(err => {
                console.error(err);
                setLoading(false);
                dataFetchedRef.current = false;
            })
        }
    };

    const removeCloudTag = (itemToRemove) => {
        const updatedClouds = selectedClouds.filter(item => item !== itemToRemove);
        setSelectedClouds(updatedClouds);
    };
    const removeBucketTag = (itemToRemove) => {
        const updatedBucket = selectedBucketsForSearch.filter(item => item !== itemToRemove);
        setSelectedBucketsForSearch(updatedBucket);
    };


    const calendarRef = useRef(null);
    const searchFilterRef = useRef(null);
    const iconCalendarRef = useRef(null);
    const iconFilterRef = useRef(null);
    const popupClickOutside = (event) => {
        // Check if the click is outside the calendar and not on the calendar icon
        if (calendarRef.current &&
            !calendarRef.current.contains(event.target) &&
            iconCalendarRef.current &&
            !iconCalendarRef.current.contains(event.target)
        ) {
            setIsCalendarOpen(false);
        }

        // Check if the click is outside the search filter
        if (searchFilterRef.current &&
            !searchFilterRef.current.contains(event.target) &&
            iconFilterRef.current &&
            !iconFilterRef.current.contains(event.target)
        ) {
            setCloudFilterOpen(false);
        }
    };

    useEffect(() => {
        if (isCalendarOpen || cloudFilterOpen) {
            document.addEventListener('mousedown', popupClickOutside);
        } else {
            document.removeEventListener('mousedown', popupClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', popupClickOutside);
        };
    }, [isCalendarOpen, cloudFilterOpen]);

    const handleClearClick = () => {
        deleteDataValere("/history/" + user.userId)
            .then((res) => Promise.all([res.status, res.text()]))
            .then(([status, data]) => {
                console.log(data)
                if (status === 401) {
                    logout()
                    return;
                }
                if (status === 500) {
                    setPopup500(true)
                    return;
                }
                if (status === 499) {
                    var dataNew = JSON.parse(data)
                    var errorType = dataNew.type;
                    setError499(dataNew.errors[errorType][0])
                    setPopup499(true)
                    return;
                }
                setSearchHistory(data)
            })
    }

    return (
        <>
            <div className="ai-search-main">
                <div className={`ai-search-wrapper ${location === "dashboard" || location === "results" ? "no-padding" : ""}`}>
                    <div className={`ai-search-card ${location === "results" ? "no-margin" : ""}`}>
                        {location !== "dashboard" && location !== "results" &&
                            <h3 className="h3 title">Search <strong>everything</strong>, search <strong>smart</strong></h3>
                        }
                        <div className="filter-form">
                            <div className="filter-item">
                                <div className="ai-search-form">
                                    {location !== "dashboard" &&
                                        <div className="files-wrapper">
                                            <Popover
                                                content={<div className="files-popup">
                                                    <div className="files">
                                                        <p>Format</p>
                                                        <Checkbox onChange={onSelectAllFile} checked={checkedAll}>All Files</Checkbox>
                                                        <Checkbox.Group value={checkedValues} onChange={onSelectFile}>
                                                            <Checkbox value="doc">Docs</Checkbox>
                                                            <Checkbox value="image">Images</Checkbox>
                                                            <Checkbox value="video">Videos</Checkbox>
                                                            <Checkbox value="code">Code</Checkbox>
                                                            <Checkbox value="audio">Audio</Checkbox>
                                                        </Checkbox.Group>
                                                    </div>
                                                </div>}
                                                trigger="click"
                                                placement="bottom"
                                                getPopupContainer={trigger => trigger.parentNode}
                                            >
                                                <a href="#" className="file-btn">
                                                    {(checkedValues.length > 0) ? (
                                                        <ul className="selected-files">
                                                            {checkedValues.slice(0, 3).map((value, index) => (
                                                                <li key={index}>
                                                                    <a href="#">
                                                                        {value === "doc" && <img src={fileIcon5} alt="Docs icon"/>}
                                                                        {value === "image" && <img src={fileIcon2} alt="Images icon"/>}
                                                                        {value === "video" && <img src={fileIcon1} alt="Videos icon"/>}
                                                                        {value === "code" && <img src={fileIcon4} alt="Code icon"/>}
                                                                        {value === "audio" && <img src={fileIcon3} alt="Audio icon"/>}
                                                                    </a>
                                                                </li>
                                                            ))}

                                                            {/* Show the "more" if more than 3 files are selected */}
                                                            {checkedValues.length > 3 && (
                                                                <li>
                                                                    <a href="#"><span className="more">+{checkedValues.length - 3}</span></a>
                                                                </li>
                                                            )}
                                                        </ul>
                                                    ) : (
                                                        <p>All files</p>
                                                    )}
                                                    <img src={downArrowIcon} alt="Down arrow icon"/>
                                                </a>
                                            </Popover>
                                        </div>
                                    }
                                    <div className="form">
                                        <div className="form-group">
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Enter your search"
                                                onFocus={handleSearchFocus}
                                                onKeyUp={(e) => {
                                                    if (e.key === "ArrowDown") {
                                                        handleSearchFocus()
                                                    }
                                                }}
                                                autoComplete="off"
                                                ref={searchInputRef}
                                                value={searchValue}
                                                onChange={(e) => setSearchValue(e.target.value)}
                                                id="ai-search-input"
                                            />
                                            <img src={searchIcon} alt="icon" className="icon"/>
                                        </div>
                                    </div>
                                    <div className="calendar-wrapper">

                                        {location === "dashboard" ? (
                                            <a href="#" className="filter-icon" ref={iconFilterRef} onClick={onCloudFilterOpen}>
                                                <img src={filterIcon} alt="icon"/>
                                            </a>
                                        ) : (
                                            <a href="#" onClick={onCalendarOpen} ref={iconCalendarRef}><img src={calendarIcon} alt="icon"/>
                                                {dateChanged &&
                                                    <p>{getFormatedDate(filterDate[0].startDate, false)} to {getFormatedDate(filterDate[0].endDate, false)}</p>
                                                }
                                            </a>
                                        )
                                        }
                                    </div>
                                    {metaFocused && (
                                        <div className="meta-search" ref={metaSearchRef} tabIndex="-1">
                                            <div className="meta-search-wrapper">
                                                <p className="title">Recent <a href="#" onClick={handleClearClick}><span>Clear</span></a></p>
                                                <ul className="searched-queries" ref={listRef}>
                                                    {searchHistory.length > 0 && searchHistory.map((item, index) => (
                                                        <li className={selectedIndex === index ? 'selected' : ''}>
                                                            <a href="#" onClick={(e) => {
                                                                e.preventDefault();
                                                                setSearchValue(item);
                                                                setSelectedIndex(index);
                                                                setMetaFocused(false)
                                                            }}><img src={timeIcon} alt="icon"/>{item}</a>
                                                        </li>
                                                    ))
                                                    }
                                                </ul>
                                                <div className={`guide-options-wrapper ${location === "dashboard" ? "space-between" : ""} `}>
                                                    <ul className="guide-options">
                                                        <li><a href="#" onClick={handleSelectClick}><img src={guideIcon1} alt="To Select"/></a></li>
                                                        <li><a href="#"><span>To select</span></a></li>
                                                        <li><a href="#" onClick={handleArrowUpClick}><img src={guideIcon2} alt="Arrow Up"/></a></li>
                                                        <li><a href="#" onClick={handleArrowDownClick}><img src={guideIcon3} alt="Arrow Down"/></a></li>
                                                        <li><a href="#"><span>To navigate</span></a></li>
                                                        <li><a href="#" onClick={handleEscapeClick}><img src={guideIcon4} alt="To Escape"/></a></li>
                                                        <li><a href="#"><span>To close</span></a></li>
                                                    </ul>
                                                    {location === "dashboard" &&
                                                        <div className="universal-search">
                                                            <NavLink to="/ai-search" className="search-link">Open Universal Search <img src={universalSearchIcon} alt="icon"/></NavLink>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    {isCalendarOpen && (
                                        <div className="select-date" ref={calendarRef}>
                                            <div className="select-date-wrapper">
                                                <DateRange
                                                    editableDateInputs={false}
                                                    onChange={item => {
                                                        console.log(item)
                                                        setFilterDate([item.selection]);
                                                    }}
                                                    moveRangeOnFirstSelection={false}
                                                    ranges={filterDate}
                                                />
                                                <div className="btn-wrapper">
                                                    <a href="#" className="btn-style" onClick={(e) => {
                                                        e.preventDefault()
                                                        setDateChanged(false)
                                                        setIsCalendarOpen(false)
                                                    }}>
                                                        Cancel
                                                    </a>
                                                    <a href="#" className="btn-style orange-btn" onClick={(e) => {
                                                        e.preventDefault()
                                                        setDateChanged(true)
                                                        setIsCalendarOpen(false)
                                                    }}>
                                                        Continue
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            {location !== "dashboard" && location !== "results" &&
                                <div className="filter-item filter-icon">
                                    <a href="#" className="filter-icon" ref={iconFilterRef} onClick={onCloudFilterOpen}>
                                        <img src={filterIcon} alt="icon"/>
                                    </a>
                                </div>
                            }
                            {/*{location !== "results" &&*/}
                            <div className="filter-item">
                                <div className={`filter-btn ${searchValue.length < 3 ? 'disabled' : ''}`}>
                                    {location === "dashboard" ? (
                                        <div>
                                            <Button loading={loading} className="search-btn desktop" onClick={() => {
                                                dataFetchedRef.current = false;
                                                search()
                                            }}><img src={searchOrangeIcon} alt="icon"/>Search</Button>
                                            <Button loading={loading} className="search-btn mobile" onClick={() => {
                                                dataFetchedRef.current = false;
                                                search() }}>Search</Button>
                                        </div>
                                    ) : (
                                        <Button loading={loading} className="btn-style icon" onClick={() => {
                                            dataFetchedRef.current = false;
                                            search()
                                        }}>Search <img src={aiStarIcon} alt="icon"/></Button>
                                    )
                                    }
                                </div>
                            </div>
                            {/*  }*/}

                            {cloudFilterOpen && (
                                <div className="filter-cloud" ref={searchFilterRef}>
                                    <div className="filter-cloud-wrapper">
                                        <div className="form">
                                            <div className="form-group">
                                                {/* placeholder="Search your Cloud or Bucket"*/}
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Search your Bucket"
                                                    value={innerSearchValue}
                                                    onChange={(e) => setInnerSearchValue(e.target.value)}
                                                />
                                                <img src={searchIcon} alt="icon" className="icon"/>
                                            </div>
                                        </div>
                                        {/*   {clouds.length > 0 &&
                                            <div className="cloud-card">
                                                <p className="title">Clouds</p>
                                                <Checkbox.Group onChange={onSelectCloud} value={selectedClouds}>
                                                    {clouds.filter((item) => {
                                                        if (innerSearchValue !== "") {
                                                            return item.toLowerCase().includes(innerSearchValue.toLowerCase());
                                                        } else {
                                                            return true;
                                                        }
                                                    }).map((item) => (
                                                        <Checkbox value={item}>{item}</Checkbox>))}
                                                </Checkbox.Group>
                                            </div>
                                        }*/}
                                        {buckets.length > 0 &&
                                            <div className="cloud-card border-bottom-0 mb-0">
                                                <p className="title">Buckets</p>
                                                <Checkbox.Group onChange={onSelectBucket} value={selectedBucketsForSearch}>
                                                    {buckets.filter((item) => {
                                                        if (innerSearchValue !== "") {
                                                            return item.bucket_name.toLowerCase().includes(innerSearchValue.toLowerCase());
                                                        } else {
                                                            return true;
                                                        }
                                                    }).map((item) => (
                                                        <Checkbox value={item.id}>{item.bucket_name}</Checkbox>))}
                                                </Checkbox.Group>
                                            </div>
                                        }
                                        <div className="btn-wrapper">
                                            <a href="#" className="btn-style btn-30" onClick={(e) => {
                                                e.preventDefault()
                                                setCloudFilterOpen(false)
                                            }}>
                                                Cancel
                                            </a>
                                            <a href="#" className={`btn-style btn-30 orange-btn ${selectedBucketsForSearch.length === 0 ? "disabled" : ""}`} onClick={(e) => {
                                                e.preventDefault()
                                                setCloudFilterOpen(false)
                                            }}>
                                                Continue
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                        {selectedBucketsForSearch.length > 0 && location !== "results" &&
                            <ul className="searched-tags">
                                {selectedBucketsForSearch.length > 0 && selectedBucketsForSearch.map((item) => (
                                    <li>
                                        <a href="#">
                                            {getBucketName(item)}
                                            <span
                                                className="cross-icon"
                                                onClick={() => removeBucketTag(item)}
                                            >
                            <img src={crossIcon} alt="icon"/>
                        </span>
                                        </a>
                                    </li>
                                ))}
                            </ul>
                        }
                    </div>
                </div>
            </div>
        </>
    );
};

export default AiSearch;



