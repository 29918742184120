import React, {useState, useEffect} from "react";
import {Button, Modal, Select, notification, Popconfirm} from "antd";
import {useAuth} from "../context/AuthContext";
import config from "../config";

const ChooseFolder = ({open, handleCancel, handleOk, title}) => {
    const {
        logout,
        isWindows,
        sendDataToMixPanel,
        user,
        postDataNewFolder,
        setPopup500,
        getDataMethodGet,
        setError499,
        setPopup499,
    } = useAuth();
    const [localDrives, setLocalDrives] = useState([]);
    const [localDir, setLocalDir] = useState([]);
    const [previousPath, setPreviousPath] = useState("");
    const [selectedPath, setSelectedPath] = useState("");

    const [folderName, setFolderName] = useState("");

    const confirm = (e) => {
        if (folderName !== '') {
            postDataNewFolder('/Management/make_directory', {
                "directoryName": folderName,
                "path": previousPath
            })
                .then((res) => Promise.all([res.status, res.text()]))
                .then(([status, response]) => {
                    if (status === 401) {
                        logout()
                        return;
                    }
                    if (status === 500) {
                        setPopup500(true)
                        return;
                    }
                    if (status === 499) {
                        var data = JSON.parse(response)
                        var errorType = data.type;
                        setError499(data.errors[errorType][0])
                        setPopup499(true)
                        return;
                    }
                    sendDataToMixPanel('New Folder Created.', user.username, {
                        'App': 'Web App',
                        'Folder Name':folderName
                    })
                    getDataMethodGet('/Management/list_directories?path=' + encodeURIComponent(previousPath))
                        .then((res) => Promise.all([res.status, res.json()]))
                        .then(([status, response]) => {
                            if (status === 401) {
                                logout()
                                return;
                            }
                            if (status === 500) {
                                setPopup500(true)
                                return;
                            }
                            if (status === 499) {
                                var errorType = response.type;
                                setError499(response.errors[errorType][0])
                                setPopup499(true)
                                return;
                            }
                            setPreviousPath(previousPath)
                            setLocalDir(response)
                            const elements = document.querySelectorAll('.directory-list.pre-cache .form-group')
                            elements.forEach((element) => {
                                element.classList.remove('active');
                            });

                        })
                });
            setFolderName('');
        }
    };

    useEffect(() => {

        if (isWindows) {
            getdrives();
        } else {
            getDirectoriesMac()
        }
    }, []);

    async function getdrives() {
        getDataMethodGet('/Management/list_drives')
            .then((res) => Promise.all([res.status, res.json()]))
            .then(([status, response]) => {
                if (status === 401) {
                    logout()
                    return;
                }
                if (status === 500) {
                    setPopup500(true);
                    return;
                }
                if (status === 499) {
                    var errorType = response.type;
                    setError499(response.errors[errorType][0])
                    setPopup499(true)
                    return;
                }
                setLocalDrives(response)
            })
    }

    function getDirectories(e) {
        setSelectedPath(e.target.value)
        getDataMethodGet('/Management/list_directories?path=' + encodeURIComponent(e.target.value))
            .then((res) => Promise.all([res.status, res.json()]))
            .then(([status, response]) => {
                if (status === 401) {
                    logout()
                    return;
                }
                if (status === 500) {
                    setPopup500(true);
                    return;
                }
                if (status === 499) {
                    var errorType = response.type;
                    setError499(response.errors[errorType][0])
                    setPopup499(true)
                    return;
                }
                setPreviousPath(e.target.value)
                setLocalDir(response)
            })
    }

    async function getDirectoriesMac() {
        getDataMethodGet('/Management/list_directories?path=/')
            .then((res) => Promise.all([res.status, res.json()]))
            .then(([status, response]) => {
                if (status === 401) {
                    logout()
                    return;
                }
                if (status === 500) {
                    setPopup500(true);
                    return;
                }
                if (status === 499) {
                    var errorType = response.type;
                    setError499(response.errors[errorType][0])
                    setPopup499(true)
                    return;
                }
                setLocalDir(response)
            })
    }

    function changeDir(e) {
        const val = e.target.getAttribute('data-value');
        const elements = document.querySelectorAll('.directory-list .form-group')
        elements.forEach((element) => {
            element.classList.remove('active');
        });
        e.target.parentNode.classList.add('active');
        setSelectedPath(val)
        if (e.detail == 2) {
            getDataMethodGet('/Management/list_directories?path=' + encodeURIComponent(val))
                .then((res) => Promise.all([res.status, res.json()]))
                .then(([status, response]) => {
                    if (status === 401) {
                        logout()
                        return;
                    }
                    if (status === 500) {
                        setPopup500(true)
                        return;
                    }
                    if (status === 499) {
                        var errorType = response.type;
                        setError499(response.errors[errorType][0])
                        setPopup499(true)
                        return;
                    }
                    setPreviousPath(val)
                    setLocalDir(response)
                    const elements = document.querySelectorAll('.directory-list .form-group')
                    elements.forEach((element) => {
                        element.classList.remove('active');
                    });
                })
        }
    }



    function goBack() {
        var val = ""
        if (isWindows) {
            if (previousPath.lastIndexOf("\\") > 2) {
                val = previousPath.substring(0, previousPath.lastIndexOf("\\"));
            } else {
                val = previousPath.substring(0, previousPath.lastIndexOf("\\") + 1);
            }
        } else {
            if (previousPath.lastIndexOf("/") > 2) {
                val = previousPath.substring(0, previousPath.lastIndexOf("/"));
            } else {
                val = previousPath.substring(0, previousPath.lastIndexOf("/") + 1);
            }
        }

        const elements = document.querySelectorAll('.directory-list .form-group')

        elements.forEach((element) => {
            element.classList.remove('active');
        });
        setPreviousPath(val)
        getDataMethodGet('/Management/list_directories?path=' + encodeURIComponent(val))
            .then((res) => Promise.all([res.status, res.json()]))
            .then(([status, response]) => {
                if (status === 401) {
                    logout()
                    return;
                }
                if (status === 500) {
                    setPopup500(true);
                    return;
                }
                if (status === 499) {
                    var errorType = response.type;
                    setError499(response.errors[errorType][0])
                    setPopup499(true)
                    return;
                }
                setLocalDir(response)
            })
    }

    const showModal = () => {
        if (selectedPath !== '') {
            var p = selectedPath.substring(0, selectedPath.lastIndexOf("\\"));
            if (!isWindows) {
                p = selectedPath.substring(0, selectedPath.lastIndexOf("/"))
            }
            setPreviousPath(p)
            getDataMethodGet('/Management/list_directories?path=' + encodeURIComponent(p))
                .then((res) => Promise.all([res.status, res.json()]))
                .then(([status, response]) => {
                    if (status === 401) {
                        logout()
                        return;
                    }
                    if (status === 500) {
                        setPopup500(true)
                        return;
                    }
                    if (status === 499) {
                        var errorType = response.type;
                        setError499(response.errors[errorType][0])
                        setPopup499(true)
                        return;
                    }
                    setLocalDir(response);
                    setTimeout(function () {
                        const elements = document.querySelectorAll('.directory-list .form-group')
                        elements.forEach((element) => {
                            element.classList.remove('active');
                            var fName = '';
                            if (isWindows) {
                                fName = selectedPath.substring(selectedPath.lastIndexOf("\\") + 1, selectedPath.length)
                            } else {
                                fName = selectedPath.substring(selectedPath.lastIndexOf("/") + 1, selectedPath.length)
                            }
                            if (element.innerText === fName) {
                                element.classList.add('active');
                            }
                        });
                    }, 200)
                })
        } else {
            var dirListPath = '/';
            if (isWindows) {
                setPreviousPath(localDrives[0])
                dirListPath = encodeURIComponent(localDrives[0]);
            } else {
                setPreviousPath('/');
            }
            getDataMethodGet('/Management/list_directories?path=' + dirListPath)
                .then((res) => Promise.all([res.status, res.json()]))
                .then(([status, response]) => {
                    if (status === 401) {
                        logout()
                        return;
                    }
                    if (status === 500) {
                        setPopup500(true)
                        return;
                    }
                    if (status === 499) {
                        var errorType = response.type;
                        setError499(response.errors[errorType][0])
                        setPopup499(true)
                        return;
                    }
                    setLocalDir(response);
                    if (isWindows) {
                        document.getElementById('select-drive-0').checked = true;
                    }
                })
        }
    };

    const newFolderName = (event) => {
        const folderName = event.target.value;
        setFolderName(folderName);
    }

    async function postData(url = '', data = {}) {
        const response = await fetch(config.apiURL + url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'accept': 'text/plain',
                "X-App-Origin": "WebApp"
            },
            body: JSON.stringify(data)
        });
        return response;
    }

    return (
        <>
            <Modal
                open={open}
                title="Title"
                onOk={()=>{
                    handleOk(selectedPath)
                }}
                onCancel={handleCancel}
                className={`file-path-modal file-path-modal-wrapper ${!isWindows ? "mac-os" : ""}`}
                footer={[
                    <Popconfirm
                        title="Create a folder"
                        description={<input type='text' placeholder='Enter your folder name' value={folderName} className='form-control' onChange={newFolderName}/>}
                        onConfirm={confirm}
                        cancelButtonProps={false}
                        icon={false}
                        okText="Create"
                        placement="topLeft"
                        getPopupContainer={trigger => trigger.parentNode}
                        className={`new-folder-wrapper ${previousPath !== '' && previousPath !== '/' ? "" : "d-none"}`}
                    >
                        <button className={`btn-style float-start  ${previousPath !== '' && previousPath !== '/' ? "" : "d-none"}`}>
                            New Folder
                        </button>
                    </Popconfirm>,
                    <button className="btn-style" onClick={()=>{
                        handleOk(selectedPath)
                    }}>
                        Select Folder
                    </button>,
                    <button className="btn-style margin-left" onClick={handleCancel}>
                        Cancel
                    </button>
                ]}
            >
                <div className="form dir-path-wrapper">
                    <div className="form-group choose-your-backup-title">
                        <h3>
                            <button className={`btn-style  ${selectedPath.length < 4 ? "disabled" : ""}`} type="button" onClick={goBack}><i className="fa fa-arrow-left"></i></button>
                            {title}
                        </h3>
                    </div>

                </div>
                <div className="modal-body-wrapper">
                    <div className={`select-drive-wrapper  ${!isWindows ? "mac-os" : ""}`}>
                        <div className="row">
                            {isWindows &&
                                <div className="col-md-3">
                                    <p className="medium">Drive</p>
                                    <div className="form">

                                        {localDrives.length > 0 &&
                                            localDrives.map((item, i) => (
                                                <div className="form-group" key={i}>
                                                    <input type="radio" id={`select-drive-` + i} value={item} name="select-drive" onChange={getDirectories}/>
                                                    <label htmlFor={`select-drive-` + i}>
                                                        <svg width="22" height="18" viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M1.5 9H20.5M5 13H9M7.96656 1H14.0334C15.1103 1 15.6487 1 16.1241 1.16396C16.5445 1.30896 16.9274 1.5456 17.2451 1.85675C17.6043 2.2086 17.8451 2.6902 18.3267 3.65337L20.4932 7.9865C20.6822 8.36449 20.7767 8.55348 20.8434 8.75155C20.9026 8.92745 20.9453 9.10847 20.971 9.29226C21 9.49923 21 9.71053 21 10.1331V12.2C21 13.8802 21 14.7202 20.673 15.362C20.3854 15.9265 19.9265 16.3854 19.362 16.673C18.7202 17 17.8802 17 16.2 17H5.8C4.11984 17 3.27976 17 2.63803 16.673C2.07354 16.3854 1.6146 15.9265 1.32698 15.362C1 14.7202 1 13.8802 1 12.2V10.1331C1 9.71053 1 9.49923 1.02897 9.29226C1.05471 9.10847 1.09744 8.92745 1.15662 8.75155C1.22326 8.55348 1.31776 8.36448 1.50675 7.9865L3.67331 3.65337C4.1549 2.69019 4.3957 2.2086 4.75495 1.85675C5.07263 1.5456 5.45551 1.30896 5.87589 1.16396C6.35125 1 6.88969 1 7.96656 1Z" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                                        </svg>
                                                        <p className="medium">{item.replace(":\\", "")}</p></label>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                            }
                            <div className="col-md-9">
                                <div className="form">
                                    <div className={`account-detail radio radio-button  ${isWindows ? "windows-dir-wrapper" : ""}`}>
                                        <div className={`final-selected-cloud directory-list  ${isWindows ? "windows-dir-list-wrapper" : ""}`}>
                                            {localDir.length > 0 &&
                                                localDir.map((item, i) => (
                                                    <div className="form-group">
                                                        <label data-value={item} title={item.substring(item.lastIndexOf("\\") + 1, item.length)} className="account-card" key={i} onClick={changeDir}>
                                                            {isWindows &&
                                                                item.substring(item.lastIndexOf("\\") + 1, item.length)
                                                            }
                                                            {!isWindows &&
                                                                item.substring(item.lastIndexOf("/") + 1, item.length)
                                                            }
                                                            <i key={i} className='d-none'></i>
                                                        </label>
                                                    </div>
                                                ))
                                            }
                                            {localDir.length < 1 && selectedPath !== "" &&
                                                <h3>There is no sub directory</h3>
                                            }
                                        </div>
                                    </div>

                                    <div className={`folder-path-wrapper  ${isWindows ? "windows-folder-wrapper" : ""}`}>
                                        <div className="row">
                                            <div className="col-3">Path:</div>
                                            <div className="col-9">
                                                <div className="folder-name" title={selectedPath}>
                                                    {selectedPath}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={`selected-folder-wrapper  ${isWindows ? "windows-folder-wrapper" : ""}`}>
                                        <div className="row">
                                            <div className="col-3">Folder:</div>
                                            <div className="col-9">
                                                <div className="folder-name">
                                                    {isWindows &&
                                                        selectedPath.substring(selectedPath.lastIndexOf("\\") + 1, selectedPath.length)
                                                    }
                                                    {!isWindows &&
                                                        selectedPath.substring(selectedPath.lastIndexOf("/") + 1, selectedPath.length)
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default ChooseFolder;

