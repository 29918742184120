import React, {useEffect, useRef, useState} from "react";
import {useNavigate} from "react-router-dom";
import {Button, Collapse, Modal, notification, Popconfirm, Radio, Select, Tooltip} from 'antd';
import crossIcon from '../assets/images/icons/cross-black-icon.svg'
import verticleDotsIcon from '../assets/images/icons/verticle-dots-black-icon.svg'
import config from "../config";
import toastIcon from "../assets/images/icons/toast-icon.svg";
import cloudDriveIcon from "../assets/images/icons/cloud-hard-drive.svg";
import cloudTeamIcon from "../assets/images/icons/team-black-icon.svg";
import projectWhiteIcon from "../assets/images/icons/white-project-icon.svg";
import projectDarkIcon from "../assets/images/icons/dark-project-icon.svg";
import {useAuth} from "../context/AuthContext";
import {DeleteModal} from "./DeleteModal";
import pageLogoWhite from "../assets/images/logo-dark.svg";
import pageLogo from "../assets/images/logo.svg";


const {Panel} = Collapse;

const ProjectsAccordion = () => {
    const {
        token,
        getDataWithToken,
        updateDataWithToken,
        logout,
        cloudAccounts,
        getIcon,
        userStatus,
        user,
        sendDataToWebsocket,
        reloadProjects,
        setReloadProjects,
        postDataWebNoResponse,
        deleteDataWeb,
        getDataWithTokenPOST,
        setPopup500,
        sendDataToMixPanel,
        setError499,
        setPopup499,
        isDark
    } = useAuth();
    const dataFetchedRef = useRef(false);
    const navigate = useNavigate();
    const [api, contextHolder] = notification.useNotification();
    const [addUser, setAddUser] = useState(false);
    const [sharePath, setSharePath] = useState(false);
    const [addGroup, setAddGroup] = useState(false);
    const [projectName, setProjectName] = useState("");
    const [projectDescription, setProjectDescription] = useState("");
    const [selectedProject, setSelectedProject] = useState("");
    const [loading, setLoading] = useState(false);
    const [projectsData, setProjectsData] = useState([]);

    const [isSelectUser, setSelectUser] = useState(false);
    const [isOktaUser, setOktaUser] = useState(false);
    const [isManualUser, setManualUser] = useState(false);
    const [isAssignShare, setAssignShare] = useState(false);
    const [isAssignGroup, setAssignGroup] = useState(false);
    const [isAssignDrive, setAssignDrive] = useState(false);
    const [isAssignDelete, setAssignDelete] = useState(false);
    const [isEditUser, showEditUser] = useState(false);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [selectedGroup, setSelectedGroup] = useState([]);
    const [selectedDrive, setSelectedDrive] = useState([]);
    const [usersData, setUsersData] = useState([]);
    const [groupsData, setGroupsData] = useState([]);
    const [drivesData, setDrivesData] = useState([]);
    const [searchValForSearch, setSearchValForSearch] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [descending, setDescending] = useState(false);
    const [totalResult, setTotalResult] = useState("");
    const [perPage, setPerPage] = useState(20);
    const [value, setValue] = useState(1);
    const [newUsersList, setNewUsersList] = useState([]);
    const [usersList, setUsersList] = useState([]);
    const [newDrivesList, setNewDrivesList] = useState([]);
    const [drivesList, setDrivesList] = useState([]);
    const [newDrivePermissionValue, setNewDrivePermissionValue] = useState(1);
    const [newGroupList, setNewGroupList] = useState([]);
    const [groupList, setGroupList] = useState([]);
    const [newGroupPermissionValue, setNewGroupPermissionValue] = useState(1);
    const [dataLoaded, setDataLoaded] = useState(false);
    const [activeKey, setActiveKey] = useState(0);


    useEffect(() => {
        if (userStatus.status === "trial" || userStatus.status === "active") {
            if (reloadProjects) {
                setReloadProjects(false)
                getAllProjects()
            }
        }
    }, [reloadProjects]);
    var allProjectData = [];
    var assignedUsersCount = 0;
    var projectsCount = 0;

    const selectManualOk = () => {
        setManualUser(false);
    };

    const selectManualCancel = () => {
        setManualUser(false);
    };


    // Assign Share Modal
    const showAssignUsers = (project) => {
        setAssignShare(true);
        setSelectedProject(project);
        setUsersList(project.usersData);
    };

    // Assign Group Modal
    const showAssignGroup = (project) => {
        setAssignGroup(true);
        setSelectedProject(project);
        setGroupList(project.groupsData);
    };

    const AssignGroupCancel = () => {
        setAssignGroup(false);
        setSelectedProject("");
        setSelectedGroup("")
        setNewGroupList([])
        setGroupList([])
    };
    const AssignDriveCancel = () => {
        setAssignDrive(false);
        setSelectedProject("")
        setDrivesList([])
        setNewDrivesList([])
    };


    // Assign Projects Modal
    const showAssignDrive = (project) => {
        setAssignDrive(true);
        setSelectedProject(project);
        setDrivesList(project.drivesData);
    };


    const AssignProjectsCancel = () => {
        setAssignShare(false);
        setSelectedProject("")
        setUsersList([])
        setNewUsersList([])
    };


    // Delete User Modal
    const showAssignDelete = (project) => {
        setAssignDelete(true);
        setSelectedProject(project)
    };

    const AssignDeleteCancel = () => {
        setAssignDelete(false);
        setSelectedProject("")
    };


    // Edit User Modal
    const showEditProject = (project) => {
        setProjectName(project.name)
        setProjectDescription(project.description)
        setSelectedProject(project)
        showEditUser(true);
    };


    const EditUserCancel = () => {
        showEditUser(false);
    };

    const createProject = () => {
        setLoading(true)
        var dataToPost = {
            "name": projectName,
            "description": projectDescription,
            "active": true,
            "deleted": false
        }

        postDataWebNoResponse("/api/project/insert", dataToPost)
            .then((res) => Promise.all([res.status, res.json()]))
            .then(([status, response]) => {
                setLoading(false);
                if (status === 401) {
                    logout()
                    return;
                }
                if (status === 500) {
                    setPopup500(true);
                    return;
                }
                if (status === 499) {
                    var errorType = response.type;
                    setError499(response.errors[errorType][0])
                    setPopup499(true)
                    return;
                }
                setLoading(false)
                setAddGroup(false)
                setAddUser(false)
                setSharePath(false)
                setManualUser(false)
                setProjectName("")
                setProjectDescription("")
                api.open({
                    message: projectName + ' Project has been successfully created.',
                    icon: <img src={toastIcon} alt="toastIcon"/>,
                    duration: 5,
                });
                getAllProjects();
            })
    };

    const updateProject = () => {
        setLoading(true)
        var dataToPost = {
            "id": selectedProject.id,
            "name": projectName,
            "description": projectDescription,
            "active": true,
            "deleted": false
        }

        updateDataWithToken("/api/project/update", dataToPost)
            .then((res) => Promise.all([res.status, res.text()]))
            .then(([status, res]) => {
                setLoading(false);
                if (status === 401) {
                    logout()
                    return;
                }
                if (status === 500) {
                    setPopup500(true);
                    return;
                }

                if (status === 499) {
                    var response = JSON.parse(res)
                    var errorType = response.type;
                    setError499(response.errors[errorType][0])
                    setPopup499(true)
                    return;
                }
                api.open({
                    message: projectName + ' Project has been successfully updated.',
                    icon: <img src={toastIcon} alt="toastIcon"/>,
                    duration: 5,
                });
                sendDataToMixPanel('Project has been successfully updated.', user.username, {
                    'App': 'Web App',
                    'Project Name': projectName
                })
                setProjectName("")
                setProjectDescription("")
                setLoading(false)
                showEditUser(false);
                getAllProjects();
            })
    };


    const getAllProjects = () => {
        setDataLoaded(false)
        getDataWithToken(`/api/project/get_all?page=${currentPage}&pagesize=${perPage}&sortfield=Name&descending=${descending}&deleted=false&name=${searchValForSearch}`)
            .then((res) => Promise.all([res.status, res.json()]))
            .then(([status, response]) => {
                if (status === 401) {
                    logout()
                    return;
                }
                if (status === 500) {
                    setPopup500(true);
                    setLoading(false);
                    return;
                }
                if (status === 499) {
                    var errorType = response.type;
                    setError499(response.errors[errorType][0])
                    setPopup499(true)
                    return;
                }
                var data = response.data;
                for (var i = 0; i < data.length; i++) {
                    data[i].usersData = [];
                    data[i].groupsData = [];
                    data[i].drivesData = [];
                }
                let newArr = [...projectsData]; // copying the old datas array
                while (newArr.length > 0) {
                    newArr.pop();
                }
                for (var i = 0; i < data.length; i++) {
                    newArr.push(data[i]); // replace e.target.value with whatever you want to change it to
                }
                console.log(newArr)
                setProjectsData(newArr)
                setTotalResult(response.total)
                if (data.length < 1) {
                    setDataLoaded(true)
                } else {
                    allProjectData = data;
                    getAssignedDrives(0);
                }
            })
    };

    const getAssignedUsers = (count, single = false) => {
        var id = "";
        if (single) {
            setDataLoaded(false);
            id = count;
        } else {
            id = allProjectData[count].id;
        }
        getDataWithToken(`/api/permission/get_users_assigned_to_project?projectId=${id}&page=1&pagesize=10000000&sortfield=User.Username&descending=false`)
            .then((res) => Promise.all([res.status, res.text()]))
            .then(([status, response]) => {
                if (status === 401) {
                    logout()
                    return;
                }
                if (status === 500) {
                    setPopup500(true);
                    setLoading(false);
                    return;
                }
                if (status === 499) {
                    var resp = JSON.parse(response)
                    var errorType = resp.type;
                    setError499(resp.errors[errorType][0])
                    setPopup499(true)
                    return;
                }
                var res = JSON.parse(response);

                if (single) {
                    let newArr = [...projectsData]; // copying the old datas array
                    for (var i = 0; i < newArr.length; i++) {
                        if (newArr[i].id === count) {
                            newArr[i].usersData = res.data;
                        }
                    }
                    setProjectsData(newArr)
                    setDataLoaded(true);
                } else {
                    allProjectData[count].usersData = res.data;
                    assignedUsersCount++;
                    if (assignedUsersCount < allProjectData.length) {
                        getAssignedUsers(assignedUsersCount, single);
                    } else {
                        let newArr = [...projectsData]; // copying the old datas array
                        while (newArr.length > 0) {
                            newArr.pop();
                        }
                        for (var i = 0; i < allProjectData.length; i++) {
                            newArr[i] = allProjectData[i]; // replace e.target.value with whatever you want to change it to
                        }
                        setProjectsData(newArr);
                        allProjectData = newArr;
                        assignedUsersCount = 0;
                        getAssignedGroups(0);
                    }
                }

            })
    };

    const getAssignedDrives = (count, single = false) => {
        var id = "";
        if (single) {
            setDataLoaded(false);
            id = count;
        } else {
            id = allProjectData[count].id;
        }
        getDataWithToken(`/api/project/get_assigned_sharedclouddrives?projectId=${id}&page=1&pagesize=10000000&&sortfield=SharedCloudDrive.Name&descending=true`)
            .then((res) => Promise.all([res.status, res.text()]))
            .then(([status, response]) => {
                setLoading(false);
                if (status === 401) {
                    logout()
                    return;
                }
                if (status === 500) {
                    setPopup500(true);
                    return;
                }
                if (status === 499) {
                    var resp = JSON.parse(response)
                    var errorType = resp.type;
                    setError499(resp.errors[errorType][0])
                    setPopup499(true)
                    return;
                }
                var res = JSON.parse(response);
                if (single) {
                    let newArr = [...projectsData]; // copying the old datas array
                    for (var i = 0; i < newArr.length; i++) {
                        if (newArr[i].id === count) {
                            newArr[i].drivesData = res.data;
                        }
                    }
                    setProjectsData(newArr)
                    setDataLoaded(true);
                } else {
                    allProjectData[count].drivesData = res.data;
                    assignedUsersCount++;
                    if (assignedUsersCount < allProjectData.length) {
                        getAssignedDrives(assignedUsersCount, single);
                    } else {
                        let newArr = [...projectsData]; // copying the old datas array
                        while (newArr.length > 0) {
                            newArr.pop();
                        }
                        for (var i = 0; i < allProjectData.length; i++) {
                            newArr[i] = allProjectData[i]; // replace e.target.value with whatever you want to change it to
                        }
                        setProjectsData(newArr);
                        allProjectData = newArr;
                        assignedUsersCount = 0;
                        getAssignedGroups(0);
                    }
                }
            })
    };
    const getAssignedGroups = (count, single = false) => {
        var id = "";
        if (single) {
            setDataLoaded(false);
            id = count;
        } else {
            id = allProjectData[count].id;
        }
        getDataWithToken(`/api/permission/get_usergroups_assigned_to_project?projectId=${id}&page=1&pagesize=10000000&sortfield=UserGroup.Name&descending=false`)
            .then((res) => Promise.all([res.status, res.text()]))
            .then(([status, response]) => {
                setLoading(false);
                if (status === 401) {
                    logout()
                    return;
                }
                if (status === 500) {
                    setPopup500(true);
                    return;
                }
                if (status === 499) {
                    var resp = JSON.parse(response)
                    var errorType = resp.type;
                    setError499(resp.errors[errorType][0])
                    setPopup499(true)
                    return;
                }
                var res = JSON.parse(response);

                if (single) {
                    let newArr = [...projectsData]; // copying the old datas array
                    for (var i = 0; i < newArr.length; i++) {
                        if (newArr[i].id === count) {
                            newArr[i].groupsData = res.data;
                        }
                    }
                    setProjectsData(newArr)
                    setDataLoaded(true);
                } else {
                    allProjectData[count].groupsData = res.data;
                    assignedUsersCount++;
                    if (assignedUsersCount < allProjectData.length) {
                        getAssignedGroups(assignedUsersCount, single);
                    } else {
                        let newArr = [...projectsData]; // copying the old datas array
                        while (newArr.length > 0) {
                            newArr.pop();
                        }
                        for (var i = 0; i < allProjectData.length; i++) {
                            newArr[i] = allProjectData[i]; // replace e.target.value with whatever you want to change it to
                        }
                        setProjectsData(newArr);
                        allProjectData = newArr;
                        assignedUsersCount = 0;
                        projectsCount = 0;
                        getAssignedUsersForGroups(0, 0)
                    }
                }
            })
    };

    const getAssignedUsersForGroups = (projectCount, groupCount) => {
        if (projectCount < allProjectData.length) {
            if (allProjectData[projectCount].groupsData.length > 0) {
                var id = allProjectData[projectCount].groupsData[groupCount].userGroup.id;

                getDataWithToken(`/api/usergroup/get_assigned_users?userGroupId=${id}&page=1&pagesize=10000000&sortfield=User.Username&descending=true`)
                    .then((res) => Promise.all([res.status, res.text()]))
                    .then(([status, response]) => {
                        setLoading(false);
                        if (status === 401) {
                            logout()
                            return;
                        }
                        if (status === 500) {
                            setPopup500(true)
                            return;
                        }
                        if (status === 499) {
                            var resp = JSON.parse(response)
                            var errorType = resp.type;
                            setError499(resp.errors[errorType][0])
                            setPopup499(true)
                            return;
                        }
                        var res = JSON.parse(response);

                        allProjectData[projectCount].groupsData[groupCount].userGroup.usersData = res.data;
                        assignedUsersCount++;

                        if (assignedUsersCount < allProjectData[projectCount].groupsData.length) {
                            getAssignedUsersForGroups(projectCount, assignedUsersCount);
                        } else {
                            assignedUsersCount = 0;
                            projectsCount++;
                            getAssignedUsersForGroups(projectsCount, assignedUsersCount);
                        }
                    })
            } else {
                assignedUsersCount = 0;
                projectsCount++;
                getAssignedUsersForGroups(projectsCount, assignedUsersCount);
            }
        } else {

            let newArr = [...projectsData]; // copying the old datas array
            while (newArr.length > 0) {
                newArr.pop();
            }
            for (var i = 0; i < allProjectData.length; i++) {
                newArr[i] = allProjectData[i]; // replace e.target.value with whatever you want to change it to
            }
            setProjectsData(newArr);
            allProjectData = newArr;

            setDataLoaded(true)
        }
    }

    const getAllUsers = () => {
        getDataWithToken("/api/user/get_all_users?page=1&pagesize=10000000&sortfield=CreateDate&descending=true&deleted=false")
            .then((res) => Promise.all([res.status, res.json()]))
            .then(([status, response]) => {
                setLoading(false);
                if (status === 401) {
                    logout()
                    return;
                }
                if (status === 500) {
                    setPopup500(true);
                    return;
                }
                if (status === 499) {
                    var errorType = response.type;
                    setError499(response.errors[errorType][0])
                    setPopup499(true)
                    return;
                }

                setUsersData(response.data)
            })
    };
    const getAllGroups = () => {
        getDataWithToken("/api/usergroup/get_all?page=1&pagesize=10000000&sortfield=Name&descending=true&deleted=false")
            .then((res) => Promise.all([res.status, res.json()]))
            .then(([status, response]) => {
                setLoading(false);
                if (status === 401) {
                    logout()
                    return;
                }
                if (status === 500) {
                    setPopup500(true);
                    return;
                }
                if (status === 499) {
                    var errorType = response.type;
                    setError499(response.errors[errorType][0])
                    setPopup499(true)
                    return;
                }
                setGroupsData(response.data)
            })
    };
    const getAllDrives = () => {
        if (user.userType === 16) {
            console.log("/api/sharedclouddrive/get_all")
            console.log(user)
        getDataWithToken(`/api/sharedclouddrive/get_all?page=1&pagesize=10000000&sortfield=Name&descending=true&deleted=false`)
            .then((res) => Promise.all([res.status, res.json()]))
            .then(([status, response]) => {
                setLoading(false);
                if (status === 401) {
                        //logout()
                    return;
                }
                if (status === 500) {
                    setPopup500(true);
                    return;
                }
                if (status === 499) {
                    var errorType = response.type;
                    setError499(response.errors[errorType][0])
                    setPopup499(true)
                    return;
                }
                setDrivesData(response.data)
            })
        }
    };
    useEffect(() => {
        if (dataFetchedRef.current) return;
        dataFetchedRef.current = true;
        getAllProjects();
        if (user.userType === 16) {
            getAllUsers();
            getAllGroups();
            getAllDrives();
        }
    }, [user]);

    function deleteProject() {
        var id = selectedProject.id;
        setLoading(true)
        deleteDataWeb('/api/project/delete?id=' + id)
            .then((res) => Promise.all([res.status, res.text()]))
            .then(([status, response]) => {
                setLoading(false);
                if (status === 401) {
                    logout()
                    return;
                }
                if (status === 500) {
                    setPopup500(true);
                    return;
                }
                if (status === 499) {
                    var resp = JSON.parse(response)
                    var errorType = resp.type;
                    setError499(resp.errors[errorType][0])
                    setPopup499(true)
                    return;
                }
                if (status === 200) {
                    setProjectsData((current) =>
                        current.filter(
                            (item) => item.id !== id
                        )
                    );
                    setSelectedProject("")
                    setLoading(false)
                    setAssignDelete(false);
                    api.open({
                        message: 'Project removed successfully.',
                        description: `Your project has been removed.`,
                        icon: <img src={toastIcon} alt="toastIcon"/>,
                        duration: 3
                    });
                    sendDataToMixPanel('Project removed successfully.', user.username, {
                        'App': 'Web App'
                    })
                }
            });
    }

    const handleChangeUser = (value) => {
        let newArr = [...newUsersList];
        for (var i = 0; i < usersData.length; i++) {
            if (usersData[i].id === value) {
                newArr.push({userName: usersData[i].email, userId: value, permissionType: newDrivePermissionValue})
            }
        }
        setNewUsersList(newArr);
    };
    const removeUserFromList = (index, permissionId, newUser = false) => {
        if (newUser) {
            let newArr = [...newUsersList];
            newArr.splice(index, 1);
            setNewUsersList(newArr)
        } else {
            let newArr = [...usersList];
            newArr.splice(index, 1);
            setUsersList(newArr)
            deleteDataWeb('/api/permission/users_project_permission?id=' + permissionId)
                .then((res) => Promise.all([res.status, res.text()]))
                .then(([status, response]) => {
                    setLoading(false)
                    if (status === 401) {
                        logout()
                        return;
                    }
                    if (status === 500) {
                        setPopup500(true)
                        return;
                    }
                    if (status === 499) {
                        var resp = JSON.parse(response)
                        var errorType = resp.type;
                        setError499(resp.errors[errorType][0])
                        setPopup499(true)
                        return;
                    }
                    if (status === 200) {
                        api.open({
                            message: 'User removed successfully.',
                            description: `User has been removed from project.`,
                            icon: <img src={toastIcon} alt="toastIcon"/>,
                            duration: 3
                        });
                        sendDataToMixPanel('User removed successfully.', user.username, {
                            'App': 'Web App'
                        })
                        let newArrG = [...projectsData];
                        for (var i = 0; i < newArrG.length; i++) {
                            if (newArrG[i].id === selectedProject.id) {
                                newArrG[i].usersData.splice(index, 1);
                                break;
                            }
                        }
                        setProjectsData(newArrG);
                    }
                });
        }

    };

    const AssignUserToProject = () => {
        setLoading(true)
        if (newUsersList.length > 0) {
            var dataToPost = [];
            var newArray = [...newUsersList];
            for (var i = 0; i < newArray.length; i++) {
                dataToPost.push({
                    "userId": newArray[i].userId,
                    "projectId": selectedProject.id,
                    "permissionType": newArray[i].permissionType
                })
            }
            getDataWithTokenPOST("/api/permission/users_project_permission", dataToPost)
                .then((res) => Promise.all([res.status, res.text()]))
                .then(([status, response]) => {
                    setLoading(false)
                    if (status === 401) {
                        logout()
                        return;
                    }
                    if (status === 500) {
                        setPopup500(true)
                        return;
                    }
                    if (status === 499) {
                        var resp = JSON.parse(response)
                        var errorType = resp.type;
                        setError499(resp.errors[errorType][0])
                        setPopup499(true)
                        return;
                    }

                    setAssignShare(false);
                    api.open({
                        message: 'User successfully assigned.',
                        icon: <img src={toastIcon} alt="toastIcon"/>,
                        duration: 5,
                    });
                    sendDataToMixPanel('User successfully assigned.', user.username, {
                        'App': 'Web App'
                    })
                    setNewUsersList([]);
                    setSelectedProject("")
                    if (usersList.length < 1) {
                        allProjectData = projectsData;
                        assignedUsersCount = 0;
                        getAssignedUsers(selectedProject.id, true);
                    }
                })
        }
        if (usersList.length > 0) {
            dataToPost = [];
            newArray = [...usersList];
            for (var i = 0; i < newArray.length; i++) {
                dataToPost.push({
                    "id": newArray[i].permission.id,
                    "userId": newArray[i].user.id,
                    "projectId": selectedProject.id,
                    "permissionType": newArray[i].permission.permissionType
                })
            }
            setLoading(false)
            updateDataWithToken("/api/permission/users_project_permission", dataToPost)
                .then((res) => Promise.all([res.status, res.text()]))
                .then(([status, response]) => {
                    setLoading(false)
                    if (status === 401) {
                        logout()
                        return;
                    }
                    if (status === 500) {
                        setPopup500(true)
                        return;
                    }
                    if (status === 499) {
                        var resp = JSON.parse(response)
                        var errorType = resp.type;
                        setError499(resp.errors[errorType][0])
                        setPopup499(true)
                        return;
                    }
                    setAssignShare(false);
                    api.open({
                        message: 'User successfully updated.',
                        icon: <img src={toastIcon} alt="toastIcon"/>,
                        duration: 5,
                    });
                    sendDataToMixPanel('User successfully updated.', user.username, {
                        'App': 'Web App'
                    })
                    setUsersList([]);
                    setSelectedProject("");
                    allProjectData = projectsData;
                    assignedUsersCount = 0;
                    getAssignedUsers(selectedProject.id, true);
                })
        }

    };
    const onChangePermission = (e, id, newProject = false) => {
        if (newProject) {
            let newArr = [...newUsersList];
            for (var i = 0; i < newArr.length; i++) {
                if (newArr[i].userId === id) {
                    newArr[i].permissionType = e.target.value;
                }
            }
            setNewUsersList(newArr)
        } else {
            let newArr = [...usersList];
            for (var i = 0; i < newArr.length; i++) {
                if (newArr[i].user.id === id) {
                    newArr[i].permission.permissionType = e.target.value;
                }
            }
            setUsersList(newArr)
        }
    };


    const handleChangeDrive = (value) => {
        let newArr = [...newDrivesList];
        for (var i = 0; i < drivesData.length; i++) {
            if (drivesData[i].id === value) {
                newArr.push({name: drivesData[i].name, id: value})
            }
        }
        setNewDrivesList(newArr);
    };

    const removeGroupFromList = (index, permissionId, newUser = false) => {
        if (newUser) {
            let newArr = [...newGroupList];
            newArr.splice(index, 1);
            setNewGroupList(newArr)
        } else {
            let newArr = [...groupList];
            newArr.splice(index, 1);
            setGroupList(newArr);
            deleteDataWeb('/api/permission/usergroups_project_permission?id=' + permissionId)
                .then((res) => Promise.all([res.status, res.text()]))
                .then(([status, response]) => {
                    setLoading(false)
                    if (status === 401) {
                        logout()
                        return;
                    }
                    if (status === 500) {
                        setPopup500(true)
                        return;
                    }
                    if (status === 499) {
                        var resp = JSON.parse(response)
                        var errorType = resp.type;
                        setError499(resp.errors[errorType][0])
                        setPopup499(true)
                        return;
                    }
                    if (status === 200) {
                        api.open({
                            message: 'Team removed successfully.',
                            description: `Team has been removed from project.`,
                            icon: <img src={toastIcon} alt="toastIcon"/>,
                            duration: 3
                        });
                        sendDataToMixPanel('Team removed successfully.', user.username, {
                            'App': 'Web App'
                        })
                        let newArrG = [...projectsData];
                        for (var i = 0; i < newArrG.length; i++) {
                            if (newArrG[i].id === selectedProject.id) {
                                newArrG[i].groupsData.splice(index, 1);
                                break;
                            }
                        }
                        setProjectsData(newArrG);
                    }
                });
        }

    };
    const removeDriveFromList = (index, permissionId, newUser = false) => {
        if (newUser) {
            let newArr = [...newDrivesList];
            newArr.splice(index, 1);
            setNewDrivesList(newArr)
        } else {
            let newArr = [...drivesList];
            newArr.splice(index, 1);
            setDrivesList(newArr);
            deleteDataWeb('/api/project/unassign_sharedclouddrive?id=' + permissionId)
                .then((res) => Promise.all([res.status, res.text()]))
                .then(([status, response]) => {
                    setLoading(false)
                    if (status === 401) {
                        logout()
                        return;
                    }
                    if (status === 500) {
                        setPopup500(true)
                        return;
                    }
                    if (status === 499) {
                        var resp = JSON.parse(response)
                        var errorType = resp.type;
                        setError499(resp.errors[errorType][0])
                        setPopup499(true)
                        return;
                    }
                    if (status === 200) {
                        api.open({
                            message: 'Drive removed successfully.',
                            description: `Drive has been removed from project.`,
                            icon: <img src={toastIcon} alt="toastIcon"/>,
                            duration: 3
                        });
                        sendDataToMixPanel('Drive removed successfully.', user.username, {
                            'App': 'Web App'
                        })
                        let newArrG = [...projectsData];
                        for (var i = 0; i < newArrG.length; i++) {
                            if (newArrG[i].id === selectedProject.id) {
                                newArrG[i].drivesData.splice(index, 1);
                                break;
                            }
                        }
                        setProjectsData(newArrG);
                    }
                });
        }

    };
    const onChangePermissionGroup = (e, id, newProject = false) => {
        if (newProject) {
            let newArr = [...newGroupList];
            for (var i = 0; i < newArr.length; i++) {
                if (newArr[i].userGroupId === id) {
                    newArr[i].permissionType = e.target.value;
                }
            }
            setNewGroupList(newArr)
        } else {
            let newArr = [...groupList];
            for (var i = 0; i < newArr.length; i++) {
                if (newArr[i].userGroup.id === id) {
                    newArr[i].permission.permissionType = e.target.value;
                }
            }
            setGroupList(newArr)
        }
    };
    const handleChangeGroup = (value) => {
        let newArr = [...newGroupList];
        for (var i = 0; i < groupsData.length; i++) {
            if (groupsData[i].id === value) {
                newArr.push({userGroupId: groupsData[i].id, name: groupsData[i].name, permissionType: newGroupPermissionValue})
            }
        }
        setNewGroupList(newArr);
    };
    const AssignGroupToProject = () => {
        setLoading(true)
        if (newGroupList.length > 0) {
            var dataToPost = [];
            var newArray = [...newGroupList];
            for (var i = 0; i < newArray.length; i++) {
                dataToPost.push({
                    "userGroupId": newArray[i].userGroupId,
                    "projectId": selectedProject.id,
                    "permissionType": newArray[i].permissionType
                })
            }

            getDataWithTokenPOST("/api/permission/usergroups_project_permission", dataToPost)
                .then((res) => Promise.all([res.status, res.text()]))
                .then(([status, response]) => {
                    setLoading(false)
                    if (status === 401) {
                        logout()
                        return;
                    }
                    if (status === 500) {
                        setPopup500(true)
                        return;
                    }
                    if (status === 499) {
                        var resp = JSON.parse(response)
                        var errorType = resp.type;
                        setError499(resp.errors[errorType][0])
                        setPopup499(true)
                        return;
                    }
                    if (status === 200) {
                        setAssignGroup(false);
                        api.open({
                            message: 'Team successfully assigned.',
                            icon: <img src={toastIcon} alt="toastIcon"/>,
                            duration: 5,
                        });
                        sendDataToMixPanel('Team successfully assigned.', user.username, {
                            'App': 'Web App'
                        })
                        setNewGroupList([]);
                        setSelectedProject("")
                        if (groupList.length < 1) {
                            allProjectData = projectsData;
                            assignedUsersCount = 0;
                            getAssignedGroups(selectedProject.id, true);
                        }
                    }
                })
        }
        if (groupList.length > 0) {
            dataToPost = [];
            newArray = [...groupList];
            for (var i = 0; i < newArray.length; i++) {
                dataToPost.push({
                    "id": newArray[i].permission.id,
                    "userGroupId": newArray[i].userGroup.id,
                    "projectId": selectedProject.id,
                    "permissionType": newArray[i].permission.permissionType
                })
            }
            setLoading(false)
            updateDataWithToken("/api/permission/usergroups_project_permission", dataToPost)
                .then((res) => Promise.all([res.status, res.text()]))
                .then(([status, response]) => {
                    setLoading(false)
                    if (status === 401) {
                        logout()
                        return;
                    }
                    if (status === 500) {
                        setPopup500(true)
                        return;
                    }
                    if (status === 499) {
                        var resp = JSON.parse(response)
                        var errorType = resp.type;
                        setError499(resp.errors[errorType][0])
                        setPopup499(true)
                        return;
                    }
                    setAssignGroup(false);
                    api.open({
                        message: 'Team successfully updated.',
                        icon: <img src={toastIcon} alt="toastIcon"/>,
                        duration: 5,
                    });
                    sendDataToMixPanel('Team successfully updated.', user.username, {
                        'App': 'Web App'
                    })
                    setGroupList([]);
                    setSelectedProject("");
                    allProjectData = projectsData;
                    assignedUsersCount = 0;
                    getAssignedGroups(selectedProject.id, true);
                })
        }

    };
    const AssignDriveToProject = () => {
        setLoading(true)
        if (newDrivesList.length > 0) {
            var dataToPost = [];
            var newArray = [...newDrivesList];
            for (var i = 0; i < newArray.length; i++) {
                dataToPost.push({
                    "sharedCloudDriveId": newArray[i].id,
                    "projectId": selectedProject.id,
                })
            }
            getDataWithTokenPOST("/api/project/assign_sharedclouddrive", dataToPost)
                .then((res) => Promise.all([res.status, res.text()]))
                .then(([status, response]) => {
                    setLoading(false)
                    if (status === 401) {
                        logout()
                        return;
                    }
                    if (status === 500) {
                        setPopup500(true)
                        return;
                    }
                    if (status === 499) {
                        var resp = JSON.parse(response)
                        var errorType = resp.type;
                        setError499(resp.errors[errorType][0])
                        setPopup499(true)
                        return;
                    }
                    setAssignDrive(false);
                    api.open({
                        message: 'Drive successfully assigned.',
                        icon: <img src={toastIcon} alt="toastIcon"/>,
                        duration: 5,
                    });
                    sendDataToMixPanel('Drive successfully assigned.', user.username, {
                        'App': 'Web App'
                    })
                    setNewDrivesList([]);
                    setSelectedProject("")
                    allProjectData = projectsData;
                    assignedUsersCount = 0;
                    getAssignedDrives(selectedProject.id, true);
                })
        } else {
            setLoading(false)
            setAssignDrive(false);
        }
    };
    const onChange = (key) => {
        if(projectsData.length > 0){
            setActiveKey(key)
        }
    };
    const projects = (
        <div className={`connected-accounts-header`}>
            <h3>Projects</h3>
            {projectsData.length > 0 && (
                <ul className="login-accounts">
                    {projectsData.map((project, index) => (
                        <li className="show" key={index}>
                            <img src={isDark ? projectWhiteIcon  : projectDarkIcon} alt="icon"/>
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
    return (
        <>  {contextHolder}
            {projectsData.length > 0 &&
                <>
                    <div className={`connected-accounts`}>
                        {/*<Collapse defaultActiveKey={[open]} onChange={onChange}>*/}
                        <Collapse onChange={onChange} activeKey={activeKey}>
                            <Panel header={projects} key="1">
                                <div className="connected-accounts-body">
                                    <div className="project-new-page">
                                        <div className="project-main-wrapper">
                                            <div className="project-card-wrapper">
                                                {projectsData.map(project => (
                                                    <div className="project-card">
                                                        <div className="card-header">
                                                            <div className="row">
                                                                <div className="col-md-12">
                                                                    <h3 className="text-center">{project.name}</h3>
                                                                    {user.userType === 16 &&
                                                                        <div className={`tooltip-btn ${!dataLoaded ? "disabled" : ""}`}>
                                                                            <Tooltip
                                                                                getPopupContainer={trigger => trigger.parentNode}
                                                                                trigger="click"
                                                                                placement="bottomLeft"
                                                                                title={
                                                                                    <ul className="view-list">
                                                                                        <li><a href="#" onClick={(e) => {
                                                                                            e.preventDefault();
                                                                                            showEditProject(project)
                                                                                        }}>Edit project</a></li>
                                                                                        <li><a href="#" onClick={(e) => {
                                                                                            e.preventDefault();
                                                                                            showAssignUsers(project)
                                                                                        }}>Manage users</a></li>
                                                                                        <li><a href="#" onClick={(e) => {
                                                                                            e.preventDefault();
                                                                                            showAssignGroup(project)
                                                                                        }}>Manage Teams</a></li>
                                                                                        <li><a href="#" onClick={(e) => {
                                                                                            e.preventDefault();
                                                                                            showAssignDrive(project)
                                                                                        }}>Manage Drive</a></li>
                                                                                        <li><a href="#" className="red-color" onClick={(e) => {
                                                                                            e.preventDefault();
                                                                                            showAssignDelete(project)
                                                                                        }}>Delete project</a></li>
                                                                                    </ul>
                                                                                }
                                                                            >
                                                                                <a href="#">
                                                                                    <img src={verticleDotsIcon} alt="icon"/>
                                                                                </a>
                                                                            </Tooltip>
                                                                        </div>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="card-body">
                                                            <div className="row row-eq-height">
                                                                <div className="col-md-6">
                                                                    <p className="text-center title">Drives</p>
                                                                    <div className="projects-icons">{project.drivesData.length > 0 &&
                                                                        <>
                                                                            {project.drivesData?.map((item, index) => (
                                                                                <>
                                                                                    <Tooltip
                                                                                        getPopupContainer={trigger => trigger.parentNode}
                                                                                        trigger="hover"
                                                                                        placement="bottomLeft"
                                                                                        title={
                                                                                            <span>{item.sharedCloudDrive.name} </span>
                                                                                        }
                                                                                    >
                                                                                        <img src={cloudDriveIcon} className="static-icon" alt="icon"/>
                                                                                        {/*{cloudAccounts.filter(account => (account.id === item.sharedCloudDrive.cloudAccountId)).map(account => (
                                                                        getIcon(account.cloudType, account.cloudIconType)
                                                                    ))}*/}
                                                                                    </Tooltip>
                                                                                </>
                                                                            ))
                                                                            }
                                                                        </>
                                                                    }
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <p className="text-center title">Teams</p>
                                                                    <p>{project.groupsData.length > 0 &&
                                                                        <div className="projects-icons">
                                                                            {project.groupsData?.map((item, index) => (
                                                                                <>
                                                                                    {item.userGroup.usersData?.length > 0 &&
                                                                                        <Tooltip
                                                                                            getPopupContainer={trigger => trigger.parentNode}
                                                                                            trigger="hover"
                                                                                            placement="bottomLeft"
                                                                                            title={
                                                                                                <span> {item.userGroup.name} <br/>
                                                                                                    {
                                                                                                        item.userGroup.usersData.map(user => (
                                                                                                            <span>
                                                                                {user.user.email} <br/>
                                                                            </span>))
                                                                                                    }
                                                                        </span>
                                                                                            }
                                                                                        >
                                                                                            <img src={cloudTeamIcon} className="static-icon team-icon" alt="icon"/>
                                                                                            {/*<span className="tags cursor-pointer">{item.userGroup.name}</span>*/}
                                                                                        </Tooltip>
                                                                                    }
                                                                                    {/*{(item.userGroup.usersData?.length < 1 || item.userGroup.usersData === undefined) &&
                                                                    <span className="tags">{item.userGroup.name}</span>
                                                                }*/}
                                                                                </>
                                                                            ))
                                                                            }
                                                                        </div>
                                                                    }
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Panel>
                        </Collapse>
                    </div>
                </>
            }


            <Modal title="Add New project" className="manual-user-type manual-project-type" centered open={isManualUser} onOk={selectManualOk} onCancel={selectManualOk}>
                <div className="manual-user-modal-wrapper">
                    <div className="header">
                        <h3>Add new project</h3>
                    </div>
                    <div className="body">
                        <div className="search-wrapper">
                            <div className="form">
                                <div className="form-group">
                                    <p className="label">Project name</p>
                                    <input type="text" className="form-control" onChange={(e) => {
                                        setProjectName(e.target.value)
                                    }} value={projectName} placeholder="Enter project name"/>
                                </div>
                                <div className="form-group">
                                    <p className="label">Project description</p>
                                    <textarea className="form-control" onChange={(e) => {
                                        setProjectDescription(e.target.value)
                                    }} value={projectDescription} placeholder="Enter project description"/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="footer">
                        <a href="#" className="btn-style transparent" onClick={selectManualCancel}>Cancel</a>
                        <Button className="btn-style blue" loading={loading} onClick={createProject}>Add Project</Button>
                    </div>
                </div>
            </Modal>

            <Modal title="Manage users" className="manual-user-type" centered open={isAssignShare} onOk={AssignProjectsCancel} onCancel={AssignProjectsCancel}>
                <div className="manual-user-modal-wrapper">
                    <div className="header">
                        <h3>Manage users</h3>
                    </div>
                    <div className="body">
                        <div className="search-wrapper">
                            <div className="form">
                                <div className="form-group">
                                    <p className="label">Users</p>
                                    <div className="add-share">
                                        <Select
                                            showSearch
                                            placeholder="Select a person"
                                            optionFilterProp="children"
                                            onChange={handleChangeUser}
                                            value={selectedUsers}
                                            options={usersData.filter((item) => {
                                                if (usersList.filter((a) => a.user.id === item.id).length < 1 && newUsersList.filter((a) => a.userId === item.id).length < 1) {
                                                    return item
                                                }
                                            }).map((item) => ({
                                                label: item.email,
                                                value: item.id,
                                            }))}
                                        />
                                    </div>
                                    {(usersList.length > 0 || newUsersList.length > 0) &&
                                        <div className="added-users">
                                            {usersList.length > 0 &&
                                                usersList.map((user, i) => (
                                                    <div className="added-users-card">
                                                        <div className="row">
                                                            <div className="col-md-6 align-self-center">
                                                                <div className="tag-wrapper">
                                                               <span className="tag">{user.user.email} <a href="#" onClick={(e) => {
                                                                   e.preventDefault();
                                                                   removeUserFromList(i, user.permission.id)
                                                               }}><img src={crossIcon} alt="icon"/></a></span>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6 align-self-center">
                                                                <div className="read-write-radio">
                                                                    <Radio.Group onChange={(e) => {
                                                                        onChangePermission(e, user.user.id)
                                                                    }} value={user.permission.permissionType}>
                                                                        <Radio value={1}>
                                                                            Read
                                                                        </Radio>
                                                                        <Radio value={2}>
                                                                            Read, Write
                                                                        </Radio>
                                                                    </Radio.Group>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                            {newUsersList.length > 0 &&
                                                newUsersList.map((user, i) => (
                                                    <div className="added-users-card">
                                                        <div className="row">
                                                            <div className="col-md-6 align-self-center">
                                                                <div className="tag-wrapper">
                                                                <span className="tag">{user.userName}<a href="#" onClick={(e) => {
                                                                    e.preventDefault();
                                                                    removeUserFromList(i, user, true)
                                                                }}><img src={crossIcon} alt="icon"/></a></span>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6 align-self-center">
                                                                <div className="read-write-radio">
                                                                    <Radio.Group onChange={(e) => {
                                                                        onChangePermission(e, user.userId, true)
                                                                    }} value={user.permissionType}>
                                                                        <Radio value={1}>
                                                                            Read
                                                                        </Radio>
                                                                        <Radio value={2}>
                                                                            Read, Write
                                                                        </Radio>
                                                                    </Radio.Group>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="footer">
                        <a href="#" className="btn-style transparent btn-30" onClick={AssignProjectsCancel}>Cancel</a>
                        <Button className={`btn-style blue btn-30 ${newUsersList.length > 0 || usersList.length > 0 ? "" : "disabled"}`} loading={loading} onClick={AssignUserToProject}>Assign</Button>
                    </div>
                </div>
            </Modal>


            <Modal title="Manage teams" className="manual-user-type" centered open={isAssignGroup} onOk={AssignGroupCancel} onCancel={AssignGroupCancel}>
                <div className="manual-user-modal-wrapper">
                    <div className="header">
                        <h3>Manage teams</h3>
                    </div>
                    <div className="body">
                        <div className="search-wrapper">
                            <div className="form">
                                <div className="form-group">
                                    <p className="label">Teams</p>
                                    <div className="add-share">
                                        <Select
                                            showSearch
                                            placeholder="Select a team"
                                            optionFilterProp="children"
                                            onChange={handleChangeGroup}
                                            value={selectedGroup}
                                            options={groupsData.filter((item) => {
                                                if (groupList.filter((a) => a.userGroup.id === item.id).length < 1 && newGroupList.filter((a) => a.userGroupId === item.id).length < 1) {
                                                    return item
                                                }
                                            }).map((item) => ({
                                                label: item.name,
                                                value: item.id,
                                            }))}
                                        />
                                    </div>
                                    {(groupList.length > 0 || newGroupList.length > 0) &&
                                        <div className="added-users">
                                            {groupList.length > 0 &&
                                                groupList.map((group, i) => (
                                                    <div className="added-users-card">
                                                        <div className="row">
                                                            <div className="col-md-6 align-self-center">
                                                                <div className="tag-wrapper">
                                                               <span className="tag">{group.userGroup.name} <a href="#" onClick={(e) => {
                                                                   e.preventDefault();
                                                                   removeGroupFromList(i, group.permission.id)
                                                               }}><img src={crossIcon} alt="icon"/></a></span>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6 align-self-center">
                                                                <div className="read-write-radio">
                                                                    <Radio.Group onChange={(e) => {
                                                                        onChangePermissionGroup(e, group.userGroup.id)
                                                                    }} value={group.permission.permissionType}>
                                                                        <Radio value={1}>
                                                                            Read
                                                                        </Radio>
                                                                        <Radio value={2}>
                                                                            Read, Write
                                                                        </Radio>
                                                                    </Radio.Group>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                            {newGroupList.length > 0 &&
                                                newGroupList.map((group, i) => (
                                                    <div className="added-users-card">
                                                        <div className="row">
                                                            <div className="col-md-6 align-self-center">
                                                                <div className="tag-wrapper">
                                                                <span className="tag">{group.name} <a href="#" onClick={(e) => {
                                                                    e.preventDefault();
                                                                    removeGroupFromList(i, group, true)
                                                                }}><img src={crossIcon} alt="icon"/></a></span>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6 align-self-center">
                                                                <div className="read-write-radio">
                                                                    <Radio.Group onChange={(e) => {
                                                                        onChangePermissionGroup(e, group.userGroupId, true)
                                                                    }} value={group.permissionType}>
                                                                        <Radio value={1}>
                                                                            Read
                                                                        </Radio>
                                                                        <Radio value={2}>
                                                                            Read, Write
                                                                        </Radio>
                                                                    </Radio.Group>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="footer">
                        <a href="#" className="btn-style transparent btn-30" onClick={AssignGroupCancel}>Cancel</a>
                        <Button className={`btn-style blue btn-30 ${newGroupList.length > 0 || groupList.length > 0 ? "" : "disabled"}`} loading={loading} onClick={AssignGroupToProject}>Assign</Button>
                    </div>
                </div>
            </Modal>

            <Modal title="Manage drive" className="manual-user-type" centered open={isAssignDrive} onOk={AssignDriveCancel} onCancel={AssignDriveCancel}>
                <div className="manual-user-modal-wrapper">
                    <div className="header">
                        <h3>Manage drive</h3>
                    </div>
                    <div className="body">
                        <div className="search-wrapper">
                            <div className="form">
                                <div className="form-group">
                                    <p className="label">Drive</p>
                                    <div className="add-share">
                                        <Select
                                            showSearch
                                            placeholder="Select a drive"
                                            optionFilterProp="children"
                                            onChange={handleChangeDrive}
                                            value={selectedDrive}
                                            options={drivesData.filter((item) => {
                                                if (drivesList.filter((a) => a.sharedCloudDrive.id === item.id).length < 1 && newDrivesList.filter((a) => a.id === item.id).length < 1) {
                                                    return item
                                                }
                                            }).map((item) => ({
                                                label: item.name,
                                                value: item.id,
                                            }))}
                                        />
                                    </div>
                                    {(drivesList.length > 0 || newDrivesList.length > 0) &&
                                        <div className="added-users">
                                            {drivesList.length > 0 &&
                                                drivesList.map((drive, i) => (
                                                    <div className="added-users-card">
                                                        <div className="row">
                                                            <div className="col-md-6 align-self-center">
                                                                <div className="tag-wrapper">
                                                               <span className="tag">{drive.sharedCloudDrive.name} <a href="#" onClick={(e) => {
                                                                   e.preventDefault();
                                                                   removeDriveFromList(i, drive.projectSharedCloudDrive.id)
                                                               }}><img src={crossIcon} alt="icon"/></a></span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                            {newDrivesList.length > 0 &&
                                                newDrivesList.map((drive, i) => (
                                                    <div className="added-users-card">
                                                        <div className="row">
                                                            <div className="col-md-6 align-self-center">
                                                                <div className="tag-wrapper">
                                                                <span className="tag">{drive.name} <a href="#" onClick={(e) => {
                                                                    e.preventDefault();
                                                                    removeDriveFromList(i, drive.id, true)
                                                                }}><img src={crossIcon} alt="icon"/></a></span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="footer">
                        <a href="#" className="btn-style transparent btn-30" onClick={AssignDriveCancel}>Cancel</a>
                        <Button className={`btn-style blue btn-30 ${newDrivesList.length > 0 || drivesList.length > 0 ? "" : "disabled"}`} loading={loading} onClick={AssignDriveToProject}>Assign</Button>
                    </div>
                </div>
            </Modal>

            <DeleteModal title="Delete Project" text="Are you sure you want to delete this project?" deleteOpen={isAssignDelete} deleteCancel={AssignDeleteCancel} deleteSuccess={deleteProject} loading={loading}/>

            <Modal title="Edit project" className="manual-user-type manual-project-type" centered open={isEditUser} onOk={EditUserCancel} onCancel={EditUserCancel}>
                <div className="manual-user-modal-wrapper">
                    <div className="header">
                        <h3>Edit project</h3>
                    </div>
                    <div className="body">
                        <div className="search-wrapper">
                            <div className="form">
                                <div className="form-group">
                                    <p className="label">Project name</p>
                                    <input type="text" className="form-control" onChange={(e) => {
                                        setProjectName(e.target.value)
                                    }} value={projectName} placeholder="Enter project name"/>
                                </div>
                                <div className="form-group">
                                    <p className="label">Project description</p>
                                    <textarea className="form-control" onChange={(e) => {
                                        setProjectDescription(e.target.value)
                                    }} value={projectDescription} placeholder="Enter project description"/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="footer">
                        <a href="#" className="btn-style transparent btn-30" onClick={EditUserCancel}>Cancel</a>
                        <Button className="btn-style blue btn-30" loading={loading} onClick={updateProject}>Save Changes</Button>
                    </div>
                </div>
            </Modal>

        </>
    )
}

export {ProjectsAccordion}


