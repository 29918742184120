import React, {createContext, useContext, useEffect, useRef, useState,} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import config from "../config";
import awsCloud from "../assets/images/clouds/aws-cloud-brand-logo.svg";
import awsCloudWhite from "../assets/images/clouds/aws-cloud-brand-logo-white.svg";
import wasabiCloud from "../assets/images/clouds/wasabi.svg";
import azureCloud from "../assets/images/clouds/azure.svg";
import storjCloud from "../assets/images/storj-icon.svg";
import ibmCloud from "../assets/images/IBM-icon-new.svg";
import ovhCloud from "../assets/images/OVH-icon-new.svg";
import otionCloud from "../assets/images/digital-otion.svg";
import blackBlazCloud from "../assets/images/blackblaz.svg";
import oracleCloud from "../assets/images/oracle-corporation-logo.svg";
import periferyCloud from "../assets/images/perifery-logo-icon.svg";
import seagateCloud from "../assets/images/seagate.svg";
import s3Cloud from "../assets/images/clouds/s3.png";
import frameIoIcon from "../assets/images/frame.io-icon.svg";
import cloudflare from "../assets/images/cloudflare-icon.png";
import dropbox from "../assets/images/dropbox-icon.svg";
import googleCloud from "../assets/images/clouds/conected-google-cloud-icon.svg";
import amoveIcon from "../assets/images/icons/logo-icon.svg";
import idriveIcon from "../assets/images/idrive-icon.svg";
import boxIcon from "../assets/images/box.svg";
import onedriveIcon from "../assets/images/onedrive.svg";
import googledriveIcon from "../assets/images/google-drive-icon.png";
import performIcon from "../assets/images/perform.svg";
import symplyIcon from "../assets/images/symply-small-icon.png";
import globalIcon from "../assets/images/global.svg";
import scaleIcon from "../assets/images/scale.svg";
import * as signalR from "@microsoft/signalr";

//Import Mixpanel SDK
import mixpanel from 'mixpanel-browser';

export const AuthContext = createContext();

const connectionUrl = "ws://localhost:29123/notification";

const cache = new Map();

const records = {
    drives: [],
    backups: [],
    directs: []
};


function AuthContextProvider({children}) {
    const navigate = useNavigate();
    const location = useLocation();
    const dataFetchedRef = useRef(false);
    const dataFetchedRef1 = useRef(false);
    const dataFetchedRefSignalR = useRef(false);
    const dataLogout = useRef(false);

    const [isAuthenticated, setIsAuthenticated] = useState();
    const [showHeaderAndSidebar, setShowHeaderAndSidebar] = useState(false);
    const [showHeader, setShowHeader] = useState(false);
    const [showSidebar, setShowSidebar] = useState(false);
    const [token, setToken] = useState();
    const [updateCloudAccounts, setUpdateCloudAccounts] = useState(false);
    const [signUpWithGoogle, setSignUpWithGoogle] = useState(false);
    const [session, setSession] = useState();
    const [cloudAccounts, setCloudAccounts] = useState([]);
    const [cloudAccountsDone, setCloudAccountsDone] = useState(false);
    const [cloudAccountsLoaded, setCloudAccountsLoaded] = useState(false);
    const [cloudAccountsLoadedFirstTime, setCloudAccountsLoadedFirstTime] = useState(false);
    const [cloudCounts, setcloudCounts] = useState([]);
    const [activityDataDrives, setactivityDrives] = useState([]);
    const [activityDataBackups, setactivityBackups] = useState([]);
    const [activityDataDirects, setactivityDirects] = useState([]);
    const [notifications, setNotifications] = useState([]);
    const notificationsRef = useRef([]);

    const [progress, setProgress] = useState([]);
    const [isWindows, setIsWindows] = useState(true);
    const [isCMDDone, setIsCMDDone] = useState(false);
    const [isCalledAgent, setIsCalledAgent] = useState(false);
    const [isCMDError, setIsCMDError] = useState(false);
    const [user, setUser] = useState({});
    const [userStatus, setUserStatus] = useState({});
    const [loading, setLoading] = useState(false);
    const [redirect, setRedirect] = useState(false);
    const [appLoaded, setAppLoaded] = useState(false);
    const [agentIsRunning, setAgentIsRunning] = useState(false);
    const controller = useRef(null);
    const [refreshData, setRefreshData] = useState(false);
    const [firstLoad, setFirstLoad] = useState(true);
    const [saveIndex, setSaveIndex] = useState(null);
    const [defaultOpenCloudDrive, setDefaultOpenCloudDrive] = useState(false);
    const [defaultOpenBackup, setDefaultOpenBackup] = useState(false);
    const [defaultOpenConnection, setDefaultOpenConnection] = useState(false);
    const [appLoading, setAppLoading] = useState(false);
    const [reloadDrivesFromAccounts, setReloadDrivesFromAccounts] = useState(false);
    const [reloadBackups, setReloadBackups] = useState(false);
    const [reloadFiles, setReloadFiles] = useState(false);
    const [reloadSSO, setReloadSSO] = useState(false);
    const [reloadDeleteSSO, setReloadDeleteSSO] = useState(false);
    const [reloadLog, setReloadLog] = useState(false);
    const [deleteLog, setDeleteLog] = useState(false);
    const [reloadUsers, setReloadUsers] = useState(false);
    const [reloadTeams, setReloadTeams] = useState(false);
    const [reloadProjects, setReloadProjects] = useState(false);
    const [reloadSharedDrives, setReloadSharedDrives] = useState(false);
    const [reloadSMB, setReloadSMB] = useState(false);
    const [reloadNFS, setReloadNFS] = useState(false);
    const [reloadBilling, setReloadBilling] = useState(false);
    const [isDark, setIsDark] = useState(false);
    const [agentPopupOpen, setAgentPopupOpen] = useState(false);
    const [billingPopupOpen, setBillingPopupOpen] = useState(false);
    const [checkingSizeLimit, setCheckingSizeLimit] = useState(false);
    const [limitExceeded, setLimitExceeded] = useState(false);
    const [amAccountId, setAmAccountId] = useState("");
    const [drivesPopup, setDrivesPopup] = useState(false);
    const [amovePkg, setAmovePkg] = useState(false);
    const [hideBTNs, setHideBTNs] = useState(false);
    const [preSelectedAccountID, setPreSelectedAccountID] = useState("");
    const [preSelectedbucket, setPreSelectedbucket] = useState("");
    const [updatePopup, setUpdatePopup] = useState(false);
    const [updateNotPopup, setUpdateNotPopup] = useState(false);
    const [updatePopupValues, setUpdatePopupValues] = useState(false);
    const [openCloudDriveAccordion, setOpenCloudDriveAccordion] = useState(false);
    const [getCloudInfo, setGetCloudInfo] = useState(false);
    const [showAgentOnce, setShowAgentOnce] = useState(false);
    const [cloudAccountsHasAmoveStorage, setCloudAccountsHasAmoveStorage] = useState(false);
    const [preSelectedNFSSMB, setPreSelectedNFSSMB] = useState("");
    const [selectedDriveToInviteUser, setSelectedDriveToInviteUser] = useState("");
    const [lastAddedCloudAccount, setLastAddedCloudAccount] = useState("");

    const amAccountIdRef = useRef(amAccountId);
    const [isProgressPopup, setIsProgressPopup] = useState(false);
    const [openInviteUserPopup, setOpenInviteUserPopup] = useState(false);
    const [reloadCloudAccounts, setReloadCloudAccounts] = useState(false);
    const [reloadGetUserData, setReloadGetUserData] = useState(false);
    const [reloadCheckUserStatus, setReloadCheckUserStatus] = useState(false);
    const [reloadLogOut, setReloadLogOut] = useState(false);
    const [reloadLogIn, setReloadLogIn] = useState(false);
    const [tokenFromWebSocket, setTokenFromWebSocket] = useState(false);
    const [delayOnLogin, setDelayOnLogin] = useState(false);
    const [popup500, setPopup500] = useState(false);
    const [popup499, setPopup499] = useState(false);
    const [error499, setError499] = useState(false);
    const [popupSupport, setPopupSupport] = useState(false);
    const [showPopupSupport, setShowPopupSupport] = useState(false);
    const [endpoint, setEndpoint] = useState("");
    const [showTourDashboard, setShowTourDashboard] = useState(false);
    const [showTourConnections, setShowTourConnections] = useState(false);
    const [totalDrives, setTotalDrives] = useState(null);
    const [tourStep, setTourStep] = useState(0);
    const [showStartedBtn, setShowStartedBtn] = useState(false);
    const [showInvitePopup, setShowInvitePopup] = useState(false);
    const [showDrivePopup, setShowDrivePopup] = useState(false);
    const [showDrivePopup2, setShowDrivePopup2] = useState(false);
    const [loadDrivesOnLogin, setLoadDrivesOnLogin] = useState(false);
    const [loadSyncOnLogin, setLoadSyncOnLogin] = useState(false);
    const [showLaunchPopup, setShowLaunchPopup] = useState(false);
    const [regions, setRegions] = useState([]);
    const [speed_stat, set_speed_stat] = useState(0);
    const [transferred_stat, set_transferred_stat] = useState(0);
    const [remaining_stat, set_remaining_stat] = useState(0);
    const [speeds, set_speeds] = useState([]);
    const [reloadMigrationStatus, setReloadMigrationStatus] = useState(false);
    const [backButton, setBackButton] = useState(false);
    const [searchResults, setSearchResults] = useState([]);

    const [checkedValues, setCheckedValues] = useState([]);
    const [checkedAll, setCheckedAll] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const [searchedBucketName, setSearchedBucketName] = useState('');
    const [searchedCloudName, setSearchedCloudName] = useState('');
    const [dateChanged, setDateChanged] = useState(false);
    const [selectedBucketsForSearch, setSelectedBucketsForSearch] = useState([]);
    const [filterDate, setFilterDate] = useState([
        {
            startDate: new Date(),
            endDate: new Date(),
            key: 'selection'
        }
    ]);
    const [groupByValue, setGroupByValue] = useState("none");
    const [orderByValue, setOrderByValue] = useState("none");
    const [fileIdToOpen, setFileIdToOpen] = useState(null);
    const [calcDrives, setCalcDrives] = useState(false);
    const [cloudDrives, setCloudDrives] = useState([]);

    const month = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const weekday = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

    amAccountIdRef.current = amAccountId;
    var cloudAccountsData = [];
    var bucketsCount = 0;

    const [migrationsProcessing, setMigrationsProcessing] = useState([]);

    const migrationsRef = useRef([]);

    useEffect(() => {
        let letSignalRconnect = true;
        let connection;

        if (!isAuthenticated) {
            letSignalRconnect = false;
            if (connection) {
                connection.stop();
                connection = null;
            }
            return;
        }

        if (isAuthenticated && token !== undefined && token !== null && letSignalRconnect) {
            if (dataFetchedRefSignalR.current) return;
            dataFetchedRefSignalR.current = true;
            connection = new signalR.HubConnectionBuilder()
                .withUrl(config.apiURLWeb + "/api/rm/?access_token=Bearer " + token)
                .configureLogging(signalR.LogLevel.Information)
                .withAutomaticReconnect()
                .build();

            let retryAttempts = 0;
            const maxRetryAttempts = 5;
            const retryDelay = 5000; // 5 seconds
            const cooldownDelay = 5 * 60 * 1000; // 5 minutes
            let totalAttempts = 0;
            const totalMaxAttempts = 5 * maxRetryAttempts; // Total of 25 attempts

            function isNotConnected() {
                return connection.state !== signalR.HubConnectionState.Connected;
            }

            async function start() {
                if (isNotConnected() && letSignalRconnect) {
                    if (totalAttempts < totalMaxAttempts) {
                        if (retryAttempts < maxRetryAttempts) {
                            try {
                                await connection.start();
                                console.log('SignalR Connected.');
                                retryAttempts = 0; // Reset retry attempts after successful connection
                                totalAttempts = 0; // Reset total attempts as well
                            } catch (err) {
                                retryAttempts++;
                                totalAttempts++;
                                console.error(`Error connecting to SignalR (Attempt ${totalAttempts}):`, err);
                                setTimeout(start, retryDelay); // Retry connection after delay
                            }
                        } else {
                            console.log('Maximum retry attempts reached. Waiting for cooldown period.');
                            retryAttempts = 0; // Reset retry attempts after cooldown
                            setTimeout(start, cooldownDelay); // Retry connection after cooldown period
                        }
                    } else {
                        console.log('Total maximum attempts reached. No more retries will be made.');
                    }
                }
            }

            start();

            console.log('Initializing LandingPage component...');

            console.log('Setting up SignalR connection...');
            connection.on('ReceiveMessage', (message) => {
                console.log('Received message:', message);
            });

            connection.on('rm', (msg) => {
                try {
                    console.log('Received rm message:', msg);
                    // Example of handling the message, ensuring no undefined properties
                    if (msg && typeof msg === 'object') {
                        var updated = false;

                        if (msg.payload.type === 4 || msg.payload.type === 5) {
                            setReloadMigrationStatus(true)
                        }
                        var migrations = migrationsRef.current;
                        migrations = migrations.filter((item) => {
                            return (item.type === 0 || item.type === 3);
                        })
                        for (let i = 0; i < migrations.length; i++) {
                            if (migrations[i].transferID === msg.payload.transferID) {
                                migrations[i] = msg.payload;
                                updated = true;
                            }
                        }
                        if (!updated) {
                            migrations.push(msg.payload);
                        }

                        migrationsRef.current = migrations;
                        if (migrations.length > 0) {
                            let total_percent = 0
                            var val_speed_stat = 0
                            var val_transferred_stat = 0
                            var val_remaining_stat = 0
                            for (var i = 0; i < migrations.length; i++) {
                                if (migrations[i].type === 3 || migrations[i].type === 0) {
                                    total_percent += migrations[i].percent
                                    val_speed_stat += migrations[i].averageSpeed;
                                    val_transferred_stat += (migrations[i].totalFileTransferedSize + migrations[i].skippedSize)
                                    val_remaining_stat += (migrations[i].totalFileSize - (migrations[i].totalFileTransferedSize + migrations[i].skippedSize))
                                }
                            }

                            if (total_percent === 100) {
                                total_percent = 0
                                val_speed_stat = 0
                                val_transferred_stat = 0
                                val_remaining_stat = 0
                            }

                            let newArr = [...speeds];
                            newArr.push({time: Date.now(), cumulative: val_speed_stat * 8});
                            if (newArr.length > 1000) newArr.splice(0, 100)

                            set_speeds(newArr)
                            set_speed_stat(val_speed_stat > 0 ? toTitleCase(prettySize(val_speed_stat * 8) + 'ps') : '0')
                            set_transferred_stat(prettySize(val_transferred_stat))
                            set_remaining_stat(prettySize(val_remaining_stat))
                        }
                        setMigrationsProcessing(migrationsRef.current)
                    } else {
                        console.warn('Message is not an object or is undefined');
                    }

                    // Add your custom handling logic here
                } catch (error) {
                    console.error('Error processing rm message:', error);
                }
            });

            connection.onreconnecting((error) => {
                console.warn('SignalR reconnecting...', error);
            });

            connection.onreconnected((connectionId) => {
                console.log('SignalR reconnected:', connectionId);
            });

            connection.onclose((error) => {
                console.error('SignalR connection closed:', error);
                if (letSignalRconnect) {
                    start(); // Restart the connection if it closes
                }
            });

            // Cleanup on unmount
            return () => {
                console.log('Cleaning up SignalR connection...');
                if (connection) {
                    connection.off("ReceiveMessage");
                    connection.off("rm");
                    connection.stop();
                    connection = null;
                }
            };
        }
    }, [token, isAuthenticated]);


    useEffect(() => {
        if (reloadCloudAccounts) {
            setReloadCloudAccounts(false)
            getCloudAccounts(true, true)
        }
    }, [reloadCloudAccounts]);

    useEffect(() => {
        if (reloadGetUserData) {
            setReloadGetUserData(false)
            getUserData()
        }
    }, [reloadGetUserData]);

    useEffect(() => {
        if (reloadCheckUserStatus) {
            setReloadCheckUserStatus(false)
            checkUserStatus()
        }
    }, [reloadCheckUserStatus]);


    useEffect(() => {
        if (reloadLogOut) {
            setReloadLogOut(false)
            let local = window.localStorage.getItem("isLoggedIn");
            if (local !== null) {
                logout();
            }
        }
    }, [reloadLogOut]);

    useEffect(() => {
        if (reloadLogIn) {
            setReloadLogIn(false)
            let local = window.localStorage.getItem("isLoggedIn");
            if (local !== null) {
                logout(tokenFromWebSocket);
            } else {
                setToken(tokenFromWebSocket);
                setIsAuthenticated(true);
                setShowHeaderAndSidebar(true);
                window.localStorage.setItem("token", tokenFromWebSocket);
                window.localStorage.setItem("isLoggedIn", true);
                localStorage.setItem("isLoggedInTimer", true);
            }
        }
    }, [reloadLogIn]);


    let socket; // Declare socket globally to reference it for closing and reconnecting
    let interval;
    let count = 1;

    function connectToWebSocket() {

        const connectionUrl = "ws://localhost:29123/notification";

        if (socket && (socket.readyState === WebSocket.CONNECTING || socket.readyState === WebSocket.OPEN)) {
            return; // If already connecting or connected, do nothing
        }

        if (socket && socket.readyState === WebSocket.CLOSING) {
            return setTimeout(connectToWebSocket, 500); // If closing, wait and retry
        }

        if (socket) {
            // Ensure the previous connection is terminated before reconnecting
            socket.onclose = null; // Remove previous onclose handler to avoid unnecessary reconnection attempt
            socket.close();
        }


        socket = new WebSocket(connectionUrl);

        socket.onopen = (event) => {
            if (config.environment !== 'Prod') {
                console.log('WebSocket Connected');
            }
            // Start the keep-alive mechanism once connected
            interval = setInterval(keepAlive, 30000);
        };

        socket.onclose = (event) => {
            clearInterval(interval);
            if (config.environment !== 'Prod') {
                console.log('WebSocket Disconnected. Attempting to reconnect...');
            }
            // Retry connection after a delay
            count++;
            if (count < 4) {

                setTimeout(() => {
                    socket.close();
                    setTimeout(() => {
                        connectToWebSocket()
                    }, 3000);
                }, 2000);
            }
        };

        socket.onerror = (event) => {
            if (config.environment !== 'Prod') {
                console.log('WebSocket Error:', event);
            }
            socket.close();
            // Handle WebSocket connection errors
            clearInterval(interval);
        };

        socket.onmessage = function (event) {
            var msgType = JSON.parse(event.data);

            if (msgType.Type == 1 && msgType.Data.TransferMode !== 8 && msgType.Data.TransferMode !== 16) {
                process(JSON.parse(event.data));
            } else if (msgType.Type == 2) {
                processUserNotification(event.data);
            } else if (msgType.Type == 4 && msgType.Data.Operation == 2) {
                processUpdates(event.data);
            }

            //type 4 and operation 2 it should be
        };

    }

    function keepAlive() {
        if (socket && socket.readyState === WebSocket.OPEN) {
            socket.send(JSON.stringify({type: 'ping'})); // Send a ping message or any small message
        }
    }

    function clearActivityData() {
        // Clear the records arrays
        records.backups.length = 0;
        records.drives.length = 0;
        records.directs.length = 0;

        // Clear the cache
        cache.clear();

        // Update state after clearing the arrays
        setactivityDrives([]);
        setactivityBackups([]);
        setactivityDirects([]);
    }

    const onDrivePopup = (id) => {
        setSaveIndex(id);
        if (saveIndex === id) {
            setSaveIndex(null);
        }
    };

    async function sendEmailDataToZapier(data) {
        try {
            const params = new URLSearchParams(data);
            var requestOptions = {
                method: 'POST',
                redirect: 'follow',
                headers: {
                    "X-App-Origin": "WebApp"
                },
                mode: "no-cors"
            };
            const response = await fetch("https://hooks.zapier.com/hooks/catch/14342253/3v2obt8/?" + params.toString(), requestOptions);
            return response;
        } catch (error) {
            console.error(`Could not sent: ${error}`);
        }
    }

    useEffect(() => {
        if (dataFetchedRef.current) return;
        dataFetchedRef.current = true;
        connectToWebSocket()
        var isCheckDark = localStorage.getItem('darkMode');
        if (isCheckDark === null) {
            document.body.classList.add('dark-theme-active');
            localStorage.setItem('darkMode', "true");
            setIsDark(true);
            /*if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
                document.body.classList.add('dark-theme-active');
                localStorage.setItem('darkMode', true);
                setIsDark(true);
            } else {
                document.body.classList.remove('dark-theme-active');
                localStorage.setItem('darkMode', false);
                setIsDark(false);
            }*/
        } else {
            if (isCheckDark === 'true') {
                document.body.classList.add('dark-theme-active');
                setIsDark(true);
            } else {
                document.body.classList.remove('dark-theme-active');
                setIsDark(false);
            }
        }
        mixpanel.init("d9904285f92927adf99423bc6928a173", {
            debug: true,
            track_pageview: true,
            persistence: "localStorage",
        });

    }, []);
    useEffect(() => {
        mixpanel.identify(user.username);
        mixpanel.track(location.pathname.replace('/', '') + ' page', {
            'App': 'Web App'
        });
        setTotalDrives(null)
        setShowDrivePopup2(false);
        if (isAuthenticated && location.pathname !== '/' && location.pathname !== '/dashboard') {
            setShowInvitePopup(false);
            setShowDrivePopup(false);
            localStorage.setItem('shorNextTour', "true");
        }
        if (location.pathname !== '/file-manager-new') {
            setBackButton(false);
        }
        if (location.pathname !== '/' && location.pathname !== '/dashboard' && location.pathname !== '/ai-search' && location.pathname !== '/search-result') {
            resetAISearch()
        }
        if(isAuthenticated){
            getRegions()
        }
    }, [location]);

    function sendDataToMixPanel(
        msg,
        userName = user.username,
        data = {
            'App': 'Web App'
        }
    ) {
        mixpanel.identify(userName);
        mixpanel.people.set({
            '$distinct_id': userName,
            '$name': userName,
            '$email': userName
        });
        mixpanel.track(msg, data);
    }

    useEffect(() => {
        // Keep ref in sync with state
        notificationsRef.current = notifications;
    }, [notifications]);

    const processUserNotification = (msg) => {
        try {
            const json = JSON.parse(msg);
            json.Data.msgStatus = 'unread';

            // Check if the key indicates a postlab bundle
            if (json.Data.Key.includes('.postlabteam')) {
                // Check if there is already a notification for this bundle
                const bundleKey = json.Data.Key.split('.postlabteam')[0]; // Get the unique bundle identifier
                const existingNotificationIndex = notificationsRef.current.findIndex(
                    notification => notification.Key.includes(bundleKey)
                );

                if (existingNotificationIndex === -1) {
                    // If no notification exists for this bundle, add the new one
                    setNotifications([...notificationsRef.current, json.Data].reverse());
                }
            } else {
                // For other notifications, just add them
                setNotifications([...notificationsRef.current, json.Data].reverse());
            }
        } catch (error) {
            console.error('Error processing notification:', error);
        }
    };


    const removeAllNotification = (sendToWebsocket = true) => {
        let notificationsData = [...notifications];
        while (notificationsData.length > 0) {
            notificationsData.pop();
        }
        notificationsRef.current = [];
        setNotifications(notificationsData);
        if (sendToWebsocket) {
            sendDataToWebsocket("removeAllNotification", "POST")
        }
    }

    const removeNotification = (i, sendToWebsocket = true) => {
        let notificationsData = [...notificationsRef.current];
        notificationsData.splice(i, 1);
        setNotifications(notificationsData);
        if (sendToWebsocket) {
            sendDataToWebsocket("remSingleNotification", "POST", `${i}`)
        }
    }

    function processUpdates(msg) {
        var json = JSON.parse(msg)
        if ((json.Data.HttpMethod === "POST" || json.Data.HttpMethod === "DELETE" || json.Data.HttpMethod === "PUT") && json.Data.Origion === "DesktopApp") {
            var request = json.Data.EndpointAddress;

            if (request.includes("removeAllNotification")) {
                removeAllNotification(false)
            }
            if (request.includes("remSingleNotification")) {
                removeNotification(parseInt(json.Data.Data), false)
            }


            if (request.includes("/desktopLogin")) {
                setTokenFromWebSocket(json.Data.Data)
                setReloadLogIn(true)
            }

            if (request.includes("/Management/clouddrive")) {
                setTimeout(function () {
                    setReloadDrivesFromAccounts(true);
                }, 3000)
            }

            if (request.includes("/Authentication/logout")) {
                setReloadLogOut(true)
            }

            if (
                request.includes("/Cloud/insert_account") ||
                request.includes("/Cloud/delete") ||
                request.includes("/Cloud/add_storage") ||
                request.includes("/Cloud/delete_storage") ||
                request.includes("/Cloud/create_bucket") ||
                request.includes("/Cloud/delete_bucket")

            ) {
                setReloadCloudAccounts(true)
            }
            if (
                request.includes("/Management/cloudbackup") &&
                !request.includes("/Management/cloudbackup_open")

            ) {
                setReloadBackups(true)
            }
            if (
                request.includes("/Cloud/delete_object") ||
                request.includes("/Cloud/create_folder") ||
                request.includes("/Cloud/upload_object") ||
                request.includes("/Cloud/upload_object_from_form")
            ) {
                setReloadFiles(true)
            }

            if (
                request.includes("/Authentication/update_user")
            ) {
                setReloadGetUserData(true);
            }

            if (
                request.includes("/SSO/setup_okta") ||
                request.includes("/SSO/update_okta") ||
                request.includes("/SSO/setup_entraId") ||
                request.includes("/SSO/update_entraId") ||
                request.includes("/SSO/setup_saml") ||
                request.includes("/SSO/update_saml")
            ) {
                setReloadSSO(true)
            }
            if (
                request.includes("/SSO/delete_okta") ||
                request.includes("/SSO/delete_entraId") ||
                request.includes("/SSO/delete_saml")
            ) {
                setReloadDeleteSSO(true)
            }

            if (
                request.includes("/AccountLogSetting/setup") ||
                request.includes("/AccountLogSetting/update")
            ) {
                setReloadLog(true)
            }
            if (
                request.includes("/AccountLogSetting/delete")
            ) {
                setDeleteLog(true)
            }

            if (
                request.includes("/User/insert_user") ||
                request.includes("/User/edit_user") ||
                request.includes("/User/delete_user") ||
                request.includes("/UserGroup/assign_users") ||
                request.includes("/UserGroup/unassign_user") ||
                request.includes("/UsersPermission/users_project_permission") ||
                request.includes("/UsersPermission/users_sharedclouddrive_permision")
            ) {
                setReloadUsers(true)
            }

            if (
                request.includes("/UserGroup/insert") ||
                request.includes("/UserGroup/update") ||
                request.includes("/UserGroup/delete") ||
                request.includes("/UserGroup/assign_users") ||
                request.includes("/UserGroup/unassign_user") ||
                request.includes("/UsersPermission/usergroups_project_permission") ||
                request.includes("/UsersPermission/usergroup_sharedclouddrive_permission")
            ) {
                setReloadTeams(true)
            }


            if (
                request.includes("/Projects/insert") ||
                request.includes("/Projects/update") ||
                request.includes("/Projects/delete") ||
                request.includes("/UsersPermission/users_project_permission") ||
                request.includes("/UsersPermission/usergroups_project_permission") ||
                request.includes("/Projects/assign_sharedclouddrive")
            ) {
                setReloadProjects(true)
            }

            if (
                request.includes("/SharedClodDiver/insert") ||
                request.includes("/SharedClodDiver/update") ||
                request.includes("/SharedClodDiver/delete") ||
                request.includes("/UsersPermission/users_sharedclouddrive_permision") ||
                request.includes("/Projects/assign_sharedclouddrive") ||
                request.includes("/UsersPermission/usergroup_sharedclouddrive_permission") ||
                request.includes("/Management/command/0")

            ) {
                setTimeout(function () {
                    setReloadSharedDrives(true)
                    setReloadDrivesFromAccounts(true);
                }, 3000)
            }

            if (
                request.includes("/Management/smbclient")
            ) {
                setReloadSMB(true)
            }
            if (
                request.includes("/Management/nfsclient")
            ) {
                setReloadNFS(true)
            }
            if (
                request.includes("/Billing/billing_subscribe") ||
                request.includes("/Billing/billing_unsubscribe")
            ) {
                setReloadCheckUserStatus(true)
            }

            if (
                request.includes("/Authentication/update_subscription")
            ) {
                setReloadGetUserData(true);
            }

        }
    }

    function process(msg) {
        let target;
        switch (msg.Data.StorageType) {
            case 1:
                target = records.drives;
                break;
            case 2:
                target = records.backups;
                break;
            case 4:
                target = records.directs;
                break;
            default:
                console.error("Invalid storage type");
                return; // Ensure no further processing occurs if storage type is invalid
        }

        const storage = createOrGet(msg.Data.Name, target, {
            name: msg.Data.Name,
            storageName: msg.Data.StorageName,
            opened: false,
            files: [],
        });

        // Ensure the message is processed only if it's not already in the cache or if the status is started (1)
        if (!cache.has(msg.Data.Path) && msg.Data.Status !== 1) {
            return;
        }

        const file = createOrGet(msg.Data.Path, storage.files, {
            transferMode: msg.Data.TransferMode,
            status: msg.Data.Status,
            storageType: msg.Data.StorageType,
            path: msg.Data.Path,
            bytes: msg.Data.Bytes,
            size: msg.Data.Size,
            error: msg.Data.Error,
        });

        file.status = msg.Data.Status;
        file.bytes = msg.Data.Bytes;
        file.error = msg.Data.Error;

        // Remove from cache if completed or failed
        if (file.status >= 3) {
            cache.delete(file.path);
        }

        // Limit to 1000 files per storage
        if (storage.files.length > 1000) {
            storage.files.length = 1000;
        }

        // Update state
        setactivityDrives([...records.drives]);
        setactivityBackups([...records.backups]);
        setactivityDirects([...records.directs]);
    }


    function createOrGet(key, array, value) {
        const val = cache.get(key);

        if (!val) {
            array.unshift(value);
            cache.set(key, value);
            return value;
        }

        return val;
    }


    async function postDataWithToken(url = "", data = {}) {
        controller.current = new AbortController();
        const response = await fetch(config.apiURLWeb + url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                'Authorization': 'Bearer ' + token,
                "X-App-Origin": "WebApp"
            },
            body: JSON.stringify(data),
            signal: controller.current.signal
        });
        sendDataToWebsocket(config.apiURLWeb + url, "POST")
        return response;
    }

    function cloudWithCounts(data) {
        var items = [];
        for (const [key, value] of Object.entries(data)) {
            var obj = {};
            obj.cloudType = key;
            obj.count = value;
            items.push(obj);
        }
        setcloudCounts(items);
    }

    // Example Get method implementation:
    async function getToken(url) {
        return await fetch(url, {
            method: "POST"
        });
    }

    async function postDataLogout(url = "", data = {}) {
        return await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "X-App-Origin": "WebApp"
            },
            body: JSON.stringify(data)
        });
    }

    async function logout(newJwtToken = "") {
        cancelRequests();
        resetAISearch()
        if (dataLogout.current) return;
        dataLogout.current = true;
        localStorage.removeItem('isLoggedInTimer');
        if (agentIsRunning) {
            try {
                const res = await postBeforeLogout("/Management/command/1");
                const [status, data] = await Promise.all([res.status, res.text()]);
                logoutRequest(newJwtToken);
            } catch (error) {
                console.error("Error during logout:", error);
            }
        } else {
            logoutRequest(newJwtToken);
        }
    }

    /*
        function logout(newJwtToken = "") {
            cancelRequests();
            if (dataLogout.current) return;
            dataLogout.current = true;
            localStorage.removeItem('isLoggedInTimer')
            //logoutRequest(newJwtToken);
            postBeforeLogout("/Management/command/1")
                .then((res) => Promise.all([res.status, res.text()]))
                .then(([status, data]) => {
                     logoutRequest(newJwtToken);
                })
        }
    */

    function logoutRequest(newJwtToken) {
        getToken(config.auth + "/api/authentication/request_token")
            .then((res) => Promise.all([res.status, res.text()]))
            .then(([status, requestToken]) => {
                if (status === 500) {
                    setPopup500(true);
                    setLoading(false);
                    return;
                }
                if (status === 499) {
                    var response = JSON.parse(requestToken)
                    var errorType = response.type;
                    setError499(response.errors[errorType][0])
                    setPopup499(true)
                    return;
                }
                postDataLogout(config.auth + "/api/authentication/logout", {
                    requestToken: requestToken,
                    jwtToken: token
                }).then((res) => Promise.all([res.status, res.text()]))
                    .then(([status, data]) => {
                        setCloudAccountsDone(false);
                        let activityBackups = [...records.backups];
                        while (activityBackups.length > 0) {
                            activityBackups.pop();
                        }
                        let activityDrives = [...records.drives];
                        while (activityDrives.length > 0) {
                            activityDrives.pop();
                        }
                        let activityDirects = [...records.directs];
                        while (activityDirects.length > 0) {
                            activityDirects.pop();
                        }

                        let notificationsData = [...notifications];
                        while (notificationsData.length > 0) {
                            notificationsData.pop();
                        }
                        setactivityDrives(activityDrives);
                        setactivityBackups(activityBackups);
                        setactivityDirects(activityDirects);
                        setNotifications(notificationsData);
                        cloudAccountsData = [];
                        let newArr = [...cloudAccounts];
                        while (newArr.length > 0) {
                            newArr.pop();
                        }

                        let newArr1 = [...cloudCounts];
                        while (newArr1.length > 0) {
                            newArr1.pop();
                        }
                        setCloudAccounts(newArr);
                        setCloudAccountsDone(false);
                        setDefaultOpenCloudDrive(false);
                        setCheckingSizeLimit(false);
                        setcloudCounts(newArr1);
                        localStorage.clear();
                        setUser({});
                        setToken(null);
                        setHideBTNs(false);
                        setShowHeaderAndSidebar(false);
                        setIsAuthenticated(false);
                        setIsCMDDone(false);
                        setAgentIsRunning(false);
                        setLoadDrivesOnLogin(false)
                        setLoadSyncOnLogin(false)
                        dataFetchedRef1.current = false;
                        dataFetchedRef1.current = false;
                        dataLogout.current = false;
                        if (newJwtToken === "") {
                            sendDataToWebsocket(config.auth + "/api/authentication/logout", "POST")
                            setDelayOnLogin(true)
                            navigate("/");
                            setHideBTNs(false);
                        } else {
                            setTimeout(function () {
                                setToken(newJwtToken);
                                setIsAuthenticated(true);
                                setShowHeaderAndSidebar(true);
                                window.localStorage.setItem("token", newJwtToken);
                                window.localStorage.setItem("isLoggedIn", true);
                                localStorage.setItem("isLoggedInTimer", true);
                            }, 1000)
                        }
                    });
            });
    }

    function logoutONSignup(t = "") {
        cancelRequests();
        setIsAuthenticated(false);
        setCheckingSizeLimit(false);
        setIsCMDDone(false);
        cloudAccountsData = [];
        let newArr = [...cloudAccounts];
        while (newArr.length > 0) {
            newArr.pop();
        }
        let newArr1 = [...cloudCounts];
        while (newArr1.length > 0) {
            newArr1.pop();
        }
        setCloudAccounts(newArr);
        setcloudCounts(newArr1);
        localStorage.clear();
        setShowHeaderAndSidebar(false);
        setCloudAccountsDone(false);
        if (t !== "") {
            setJWTToken(t);
            setIsAuthenticated(true);
        }
    }

    function setJWTToken(token, reload = false) {
        setToken(token);
        setIsAuthenticated(true);
        setShowHeaderAndSidebar(true);
        window.localStorage.setItem("token", token);
        window.localStorage.setItem("isLoggedIn", true);
        localStorage.setItem("isLoggedInTimer", true);
    }

    function setLoginSession(session) {
        setSession(session);
        window.localStorage.setItem("session", session);
    }

    function clearLoginSession() {
        setSession(false);
        window.localStorage.removeItem("session");
    }

    // Example POST method implementation:
    async function postData(url = "", data = {}) {
        controller.current = new AbortController();
        sendDataToWebsocket(config.apiURL + url, "POST")
        const response = await fetch(config.apiURL + url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "X-App-Origin": "WebApp"
            },
            body: JSON.stringify(data),
            signal: controller.current.signal,
        });
        return response.json();
    }


    async function postDataNoResponse(url = "", data = {}) {
        controller.current = new AbortController();
        sendDataToWebsocket(config.apiURL + url, "POST")
        return await fetch(config.apiURL + url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                'accept': 'text/plain',
                "X-App-Origin": "WebApp"
            },
            body: JSON.stringify(data),
            signal: controller.current.signal,
        });
    }

    async function postDataReturnText(url = "", data = {}, t = false) {
        controller.current = new AbortController();
        var link = config.apiURL + url;
        if (t) {
            link = config.apiURL + url + "/?token=" + token;
        }
        const response = await fetch(link, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "X-App-Origin": "WebApp"
            },
            body: JSON.stringify(data),
            signal: controller.current.signal,
        });
        return response.text();
    }

    async function onlyPostDataNoResponse(url = "") {
        controller.current = new AbortController();
        return await fetch(config.apiURL + url, {
            method: "POST",
            headers: {
                "accept": "text/plain",
                "X-App-Origin": "WebApp"
            },
            signal: controller.current.signal
        });
    }

    async function postBeforeLogin(url = "") {

        controller.current = new AbortController();
        const response = await fetch(config.apiURL + url, {
            method: "POST",
            headers: {
                "X-App-Origin": "WebApp"
            },
            signal: controller.current.signal
        });
        return response;
    }

    async function postBeforeLogout(url = "") {
        return await fetch(config.apiURL + url, {
            method: "POST",
            headers: {
                "X-App-Origin": "WebApp"
            }
        });
    }

    const cancelRequests = () => {
        controller.current && controller.current.abort();
    };

    const sendDataToWebsocket = (url, method, Data) => {
        var dataToPost = {
            "operation": 2,
            "Data": Data,
            "endpointAddress": url,
            "httpMethod": method,
            "origion": "WebApp"
        }
        if (agentIsRunning) {
            postDataReturnText("/Management/notify", dataToPost)
        }
    };

    const sendDataToWebsocketLogin = (url, method, Data) => {
        var dataToPost = {
            "operation": 2,
            "Data": Data,
            "endpointAddress": url,
            "httpMethod": method,
            "origion": "WebApp"
        }
        postDataReturnText("/Management/notify", dataToPost)

    };

    // Example Get method implementation:
    async function getData(url) {
        controller.current = new AbortController();
        return await fetch(config.apiURL + url, {
            signal: controller.current.signal
        });
    }

    async function getDataMethodGet(url = '') {
        controller.current = new AbortController();
        return await fetch(config.apiURL + url, {
            method: 'GET',
            signal: controller.current.signal
        });
    }

    async function getDataWithTokenSSO(url) {
        controller.current = new AbortController();
        const requestOptions = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
                "X-App-Origin": "WebApp"
            },
            signal: controller.current.signal
        };
        return await fetch(config.apiURLWeb + url, requestOptions);
    }


    async function getDataWithToken(url) {
        controller.current = new AbortController();
        const requestOptions = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
                "X-App-Origin": "WebApp"
            },
            signal: controller.current.signal,
        };
        return await fetch(config.apiURLWeb + url, requestOptions);
    }


    async function getDataWithTokenPOST(url, data) {
        controller.current = new AbortController();
        const response = await fetch(config.apiURLWeb + url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
                "X-App-Origin": "WebApp"
            },
            body: JSON.stringify(data),
            signal: controller.current.signal
        });
        sendDataToWebsocket(config.apiURLWeb + url, "POST")
        return response;
    }

    async function postDataNewFolder(url = '', data = {}) {
        controller.current = new AbortController();
        // Default options are marked with *
        return await fetch(config.apiURL + url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'accept': 'text/plain',
                "X-App-Origin": "WebApp"
            },
            body: JSON.stringify(data),
            signal: controller.current.signal
        });
    }

    async function postDataNewFolderWeb(url = '', data = {}) {
        controller.current = new AbortController();
        // Default options are marked with *
        return await fetch(config.apiURLWeb + url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: "Bearer " + token,
                "X-App-Origin": "WebApp"
            },
            body: JSON.stringify(data),
            signal: controller.current.signal
        });
    }


    // Example Delete method implementation:
    async function deleteData(url) {
        controller.current = new AbortController();
        return await fetch(config.apiURL + url, {
            method: "DELETE",
            headers: {
                "X-App-Origin": "WebApp"
            },
            signal: controller.current.signal
        });
    }


    async function deleteDataWeb(url) {
        controller.current = new AbortController();
        const response = await fetch(config.apiURLWeb + url, {
            method: "DELETE",
            headers: {
                'Authorization': 'Bearer ' + token,
                "X-App-Origin": "WebApp"
            },
            signal: controller.current.signal
        });
        sendDataToWebsocket(config.apiURLWeb + url, "DELETE")
        return response;
    }

    // Example Update method implementation:
    async function updateData(url, data) {
        controller.current = new AbortController();
        const requestOptions = {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                "X-App-Origin": "WebApp"
            },
            body: JSON.stringify(data),
            signal: controller.current.signal
        };

        return await fetch(config.apiURL + url, requestOptions);
    }

    // Example Update method implementation:
    async function updateDataWithToken(url, data) {
        controller.current = new AbortController();
        const requestOptions = {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                'Authorization': 'Bearer ' + token,
                "X-App-Origin": "WebApp"
            },
            body: JSON.stringify(data),
            signal: controller.current.signal
        };
        const response = await fetch(config.apiURLWeb + url, requestOptions);
        sendDataToWebsocket(config.apiURLWeb + url, "PUT")
        return response;
    }

    function getUniqueDataCount(objArr, propName) {
        var data = [];
        if (Array.isArray(propName)) {
            propName.forEach((prop) => {
                objArr.forEach(function (d, index) {
                    if (d[prop]) {
                        data.push(d[prop]);
                    }
                });
            });
        } else {
            objArr.forEach(function (d, index) {
                if (d[propName]) {
                    data.push(d[propName]);
                }
            });
        }

        var uniqueList = [...new Set(data)];

        var dataSet = {};
        for (var i = 0; i < uniqueList.length; i++) {
            dataSet[uniqueList[i]] = data.filter((x) => x == uniqueList[i]).length;
        }

        return dataSet;
    }

    useEffect(() => {
        if (isAuthenticated && location.pathname !== '/subscribe' && location.pathname !== '/billing-status') {
            checkUserStatus()
        }
    }, [location]);

    const checkSizeEvery10Minutes = () => {
        if (token !== undefined && token !== null) {
            if (userStatus?.status !== "active") {
                getDataWithToken("/api/cloudaccount/idrive_get_usage")
                    .then((res) => Promise.all([res.status, res.text()]))
                    .then(([status, data]) => {
                        if (status === 401) {
                            setEndpoint("/api/cloudaccount/idrive_get_usage")
                            setShowPopupSupport(true)
                            // logout()
                            return;
                        }
                        if (status === 500) {
                            setPopup500(true)
                            return;
                        }
                        if (data >= (config.sizeLimitForAmoveDrive * 1024 * 1024 * 1024)) {
                            if (userStatus.status === "trial" || userStatus.status === "unpaid") {
                                setLimitExceeded(true);
                                navigate("/subscribe");
                            } else {
                                setLimitExceeded(false);
                            }
                        } else {
                            if(userStatus.status === "trial"){
                                setTimeout(() => {
                                    checkSizeEvery10Minutes()
                                    setLimitExceeded(false);
                                }, config.reTryCheckLimitForAmoveDrive)
                            }
                        }
                    });
            }
        }
    }

    const checkUserStatus = () => {

        if (token !== undefined && token !== null) {
            getDataWithToken('/api/desktop/billing_status')
                .then((res) => Promise.all([res.status, res.json()]))
                .then(([status, response]) => {
                    if (status === 401) {
                        logout();
                        return;
                    }
                    if (status === 499) {
                        var errorType = response.type;
                        setError499(response.errors[errorType][0])
                        setPopup499(true)
                        return;
                    }
                    if (status === 499 || status === 500) {
                        setUserStatus(
                            {
                                "status": "unpaid",
                                "currentPeriodStart": "",
                                "currentPeriodEnd": ""
                            });
                        navigate("/billing-status");
                        return;
                    }
                    if (status === 200) {
                        if (response.status === "trial") {
                            setUserStatus(response);
                            checkAgent();
                            if (!checkingSizeLimit) {
                                setTimeout(function (){
                                    checkSizeEvery10Minutes()
                                },10000)
                                setCheckingSizeLimit(true);
                            }
                        } else if (response.status === "active") {

                            setLimitExceeded(false);
                            setUserStatus(response);
                            checkAgent();
                        } else if (response.status === "unpaid") {
                            setUserStatus(response);
                            navigate("/billing-status");
                        } else {
                            setUserStatus(
                                {
                                    "status": "unpaid",
                                    "currentPeriodStart": "",
                                    "currentPeriodEnd": ""
                                });
                            navigate("/billing-status");
                        }
                    }


                })
        }
    }
    const checkAgent = (checkAgain = false) => {
        if (token !== undefined && token !== null) {
            getData("/Management/agent_status")
                .then((res) => Promise.all([res.status, res.json()]))
                .then(([status, data]) => {
                    if (status === 401) {
                        logout()
                        return;
                    }
                    if (status === 500) {
                        setPopup500(true)
                        return;
                    }
                    if (status === 499) {
                        var errorType = data.type;
                        setError499(data.errors[errorType][0])
                        setPopup499(true)
                        return;
                    }
                    if (data === 1) {
                        setAgentIsRunning(true)
                        setShowLaunchPopup(false)
                        setAgentPopupOpen(false)
                        checkAgain = false
                    }
                    if (checkAgain) {
                        setTimeout(() => {
                            checkAgent(true)
                        }, 1000)
                    }
                })
                .catch(() => {
                    setIsCMDDone(false);
                    setAgentIsRunning(false)
                    if (checkAgain) {
                        setTimeout(() => {
                            checkAgent(true)
                        }, 1000)
                    }
                });
        }
    }


// Start the connection
    useEffect(() => {
        let local = window.localStorage.getItem("isLoggedIn");
        let token = window.localStorage.getItem("token");
        if (local !== null) {
            if (token !== null) {
                setToken(token);
                setIsAuthenticated(true);
                setShowHeaderAndSidebar(true);
                checkUserStatus();
                getUserData();
            }
        }
        setAppLoaded(true);
        checkOS();
    }, [token]);

    useEffect(() => {
        if (token !== undefined && token !== null) {
            if (userStatus.status === "trial" || userStatus.status === "active") {
                if (!dataFetchedRef1.current) {
                    if (location.pathname === '/' || location.pathname === '/dashboard') {
                        getCloudAccounts();
                    }
                }
            }
        }
    }, [token, updateCloudAccounts, userStatus, limitExceeded, user]);


    function getCloudAccounts(refresh = false, useData = false, getBucketsAgain = true) {
        if (token !== undefined && token !== null && (userStatus.status === "trial" || userStatus.status === "active")) {
            if (dataFetchedRef1.current) return;
            dataFetchedRef1.current = true;
            setCloudAccountsDone(false);
            setCloudAccountsLoaded(false)
            if (window.localStorage.getItem("token") === null) {
                window.localStorage.setItem("token", token);
                window.localStorage.setItem("isLoggedIn", true);
                localStorage.setItem("isLoggedInTimer", true);
            }
            getDataWithToken("/api/desktop/get_all")
                .then((res) => Promise.all([res.status, res.json()]))
                .then(([status, response]) => {
                    if (status === 401) {
                        logout()
                        return;
                    }
                    if (status === 500) {
                        setPopup500(true)
                        return;
                    }
                    if (status === 499) {
                        var errorType = response.type;
                        setError499(response.errors[errorType][0])
                        setPopup499(true)
                        return;
                    }
                    var data = response.data;
                    var amoveAccountID = ""
                    if (data.length > 0) {
                        localStorage.setItem('onboarding', true);
                        setHideBTNs(false)
                        var amoveDriveOpened = false
                        for (var j = 0; j < data.length; j++) {
                            data[j]["buckets"] = [];
                            data[j]["showBucket"] = false;
                            data[j]["cloudIconType"] = data[j].cloudType;
                            data[j]["disabled"] = false;
                            if (data[j].internalStorage) {
                                if(data[j].storageTier === 1){
                                    data[j]["cloudIconType"] = "Perform";
                                }
                                if(data[j].storageTier === 2){
                                    data[j]["cloudIconType"] = "Global";
                                }
                                if(data[j].storageTier === 4){
                                    data[j]["cloudIconType"] = "Scale";
                                }
                                if (limitExceeded) {
                                    data[j]["disabled"] = true;
                                }
                                amoveAccountID = data[j].id;
                                setCloudAccountsHasAmoveStorage(true)
                                const urlParams = new URLSearchParams(window.location.search);
                                const amoveDrive = urlParams.get('amove')
                                if (amoveDrive === "true") {
                                    if (!amoveDriveOpened) {
                                        data[j].showBucket = true;
                                        amoveDriveOpened = true;
                                    }
                                }
                            } else {
                                if (data[j].serviceUrl !== null && data[j].serviceUrl !== undefined) {
                                    if (data[j].serviceUrl.indexOf("storjshare.io") > -1) {
                                        data[j]["cloudIconType"] = "storj";
                                    }
                                    if (data[j].serviceUrl.indexOf("appdomain.cloud") > -1) {
                                        data[j]["cloudIconType"] = "IBM";
                                    }
                                    if (data[j].serviceUrl.indexOf("ovh.us") > -1) {
                                        data[j]["cloudIconType"] = "OVH";
                                    }
                                    if (data[j].serviceUrl.indexOf("seagate.com") > -1) {
                                        data[j]["cloudIconType"] = "Live";
                                    }
                                    if (data[j].serviceUrl.indexOf("oraclecloud.com") > -1) {
                                        data[j]["cloudIconType"] = "Oracle";
                                    }
                                    if (data[j].serviceUrl.indexOf("digitaloceanspaces.com") > -1) {
                                        data[j]["cloudIconType"] = "Ocean";
                                    }
                                    if (data[j].serviceUrl.indexOf("backblazeb2.com") > -1) {
                                        data[j]["cloudIconType"] = "Backblaze";
                                    }
                                    if (data[j].serviceUrl.indexOf("object-matrix.com") > -1) {
                                        data[j]["cloudIconType"] = "Perifery";
                                    }
                                    if (data[j].serviceUrl.indexOf("idrivee2") > -1) {
                                        data[j]["cloudIconType"] = "IDrive";
                                    }
                                    if (data[j].serviceUrl.indexOf("symply") > -1) {
                                        data[j]["cloudIconType"] = "symply";
                                    }
                                }
                            }
                        }

                        cloudAccountsData = data;

                        setCloudAccounts(data);

                        setCloudAccountsDone(true);

                        updateCloudCounts(data);
                        if(getBucketsAgain){
                            if (useData) {
                                getBuckets(0, data, true);
                            } else {
                                getBuckets(0);
                            }
                        }
                        var url = window.location.href;
                        if (url.substring(url.lastIndexOf("/") + 1, url.length) !== "add-cloud-account") {
                            setShowHeaderAndSidebar(true);
                        }
                        setRefreshData(false)
                        setAmAccountId(amoveAccountID)
                    } else {
                        setShowHeaderAndSidebar(true);
                        setCloudAccountsDone(true);
                        setcloudCounts([])
                        localStorage.removeItem('onboarding');
                    }

                    if (!refresh) {
                        setCloudAccountsLoadedFirstTime(true)
                    }
                    var onboarding = localStorage.getItem('onboarding');

                    /*if ((user.userType === 32 || user.userType === 64) && onboarding === null) {
                        localStorage.setItem('onboarding', true);
                        console.log("dashboard_1")
                        navigate("/dashboard");
                        return;
                    }*/

                    if (onboarding === null) {
                        if (data.length < 1 && user.userType !== undefined && (user.owner && user.userType === 16)) {
                            localStorage.setItem('onboarding', true);
                            if (location.pathname !== '/onboarding' && location.pathname !== '/add-cloud-account') {
                                navigate("/onboarding");
                            }
                        } else {
                            localStorage.setItem('onboarding', true);
                            //navigate("/dashboard");
                        }
                    } else {
                        if (location.pathname === "/sign-in") {
                            navigate("/dashboard");
                        }
                    }
                    dataFetchedRef1.current = false;
                });
        }
    }

    function getCloudAccountName(id){
        if(cloudAccounts.length > 0){
            return cloudAccounts.filter(item => item.id)[0]?.name;
        }
    }


    function getUserData(reload = false) {
        if (token !== undefined && token !== null) {
            getDataWithToken("/api/user/userinfo")
                .then((res) => Promise.all([res.status, res.json()]))
                .then(([status, data]) => {
                    if (status === 401) {
                        logout()
                        return;
                    }
                    if (status === 500) {
                        setPopup500(true)
                        return;
                    }
                    if (status === 499) {
                        var errorType = data.type;
                        setError499(data.errors[errorType][0])
                        setPopup499(true)
                        return;
                    }
                    //data.userType = 64;
                    setUser(data);
                    if (!reload) {
                        getCloudAccounts();
                    }
                });
        }
    }


    async function postDataWeb(url = "", data = {}) {
        controller.current = new AbortController();
        const response = await fetch(config.apiURLWeb + url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                'Authorization': 'Bearer ' + token,
                "X-App-Origin": "WebApp"
            },
            body: JSON.stringify(data),
            signal: controller.current.signal
        });
        sendDataToWebsocket(config.apiURLWeb + url, "POST")
        return response.json();
    }

    async function postDataWebNoResponse(url = "", data = {}) {
        controller.current = new AbortController();
        const response = await fetch(config.apiURLWeb + url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                'Authorization': 'Bearer ' + token,
                "X-App-Origin": "WebApp"
            },
            body: JSON.stringify(data),
            signal: controller.current.signal
        });
        sendDataToWebsocket(config.apiURLWeb + url, "POST")
        return response;
    }

    async function postDataWebResponseText(url, data) {
        controller.current = new AbortController();
        const requestOptions = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
                "X-App-Origin": "WebApp"
            },
            body: JSON.stringify(data),
            signal: controller.current.signal
        };
        const response = await fetch(config.apiURLWeb + url, requestOptions);
        sendDataToWebsocket(config.apiURLWeb + url, "POST")
        return response.text();
    }


    async function sync(url) {
        controller.current = new AbortController();
        return await fetch(config.apiURL + url, {
            method: "POST",
            signal: controller.current.signal
        });
    }

    async function omnisend(email) {
        const options = {
            method: 'POST',
            headers: {
                accept: 'application/json',
                'content-type': 'application/json',
                'X-API-KEY': '6658af81639f6b4910d9b0ca-dNtGOdp7zX0i38YunEhkPIuCrvb29vfI7XS7NJLObN9mfD0JNY'
            },
            body: JSON.stringify({
                identifiers: [
                    {
                        type: 'email',
                        channels: {email: {status: 'subscribed'}},
                        id: email
                    }
                ],
                sendWelcomeEmail: true
            })
        };

        fetch('https://api.omnisend.com/v3/contacts', options)
            .then(response => response.json())
            .then(response => console.log(response))
            .catch(err => console.error(err));
    }

    function updateCloudCounts(data) {
        cloudWithCounts(getUniqueDataCount(data, ["cloudIconType"]));
    }

    function getIcon(cloudType, iconType = "") {
        if (
            cloudType !== "storj" &&
            cloudType !== "Backblaze" &&
            cloudType !== "Ocean" &&
            cloudType !== "Oracle" &&
            cloudType !== "Live" &&
            cloudType !== "OVH" &&
            cloudType !== "IBM" &&
            cloudType !== "Amove" &&
            cloudType !== "Perifery" &&
            cloudType !== "IDrive" &&
            cloudType !== "symply"
        ) {
            cloudType = parseInt(cloudType);
        }
        if (iconType === "Perform" || cloudType === "Perform") {
            return <img src={performIcon} className="tier-icon perform-icon" alt="Amove Perform"/>;
        }
        if (iconType === "Global" || cloudType === "Global") {
            return <img src={globalIcon} className="tier-icon global-icon" alt="Amove Global"/>;
        }
        if (iconType === "Scale" || cloudType === "Scale") {
            return <img src={scaleIcon} className="tier-icon scale-icon" alt="Amove Scale"/>;
        }
        if (cloudType === 1) {
            return <img src={isDark ? awsCloudWhite : awsCloud} className="larger" alt="AWS"/>;
        }
        if (cloudType === 2) {
            return <img src={wasabiCloud} className="wasabi-icons" alt="Wasabi"/>;
        }
        if (cloudType === 4) {
            return <img src={azureCloud} alt="Azure"/>;
        }
        if (cloudType === 16) {
            return <img src={googleCloud} alt="Google"/>;
        }
        if (iconType === "storj" || cloudType === "storj") {
            return <img src={storjCloud} className="storj-icon" alt="Storj"/>;
        }
        if (iconType === "symply" || cloudType === "symply") {
            return <img src={symplyIcon} className="symply-icon" alt="symply"/>;
        }
        if (iconType === "IBM" || cloudType === "IBM") {
            return <img src={ibmCloud} className="ibm-icon" alt="IBM"/>;
        }
        if (iconType === "OVH" || cloudType === "OVH") {
            return <img src={ovhCloud} className="storj-icon" alt="OVH"/>;
        }
        if (iconType === "Ocean" || cloudType === "Ocean") {
            return <img src={otionCloud} className="ocean-icon" alt="Ocean"/>;
        }
        if (iconType === "Backblaze" || cloudType === "Backblaze") {
            return (
                <img src={blackBlazCloud} className="backblaze-icon" alt="Backblaze"/>
            );
        }
        if (iconType === "Live" || cloudType === "Live") {
            return <img src={seagateCloud} className="storj-icon" alt="seagate"/>;
        }
        if (iconType === "Oracle" || cloudType === "Oracle") {
            return <img src={oracleCloud} className="oracle-icon" alt="Oracle"/>;
        }
        if (iconType === "Perifery" || cloudType === "Perifery") {
            return <img src={periferyCloud} className="perifery-icon" alt="Perifery"/>;
        }
        if (iconType === "IDrive" || cloudType === "IDrive") {
            return <img src={idriveIcon} className="idrive-icon" alt="idrive-icon"/>;
        }
        if (cloudType === 8) {
            return <img src={s3Cloud} alt="S3"/>;
        }
        if (cloudType === 32) {
            return <img src={frameIoIcon} className="frameio-icon" alt="FrameIO"/>;
        }
        if (cloudType === 64) {
            return <img src={cloudflare} className="cloudflare-icon" alt="cloudflare"/>;
        }
        if (cloudType === 128) {
            return <img src={dropbox} className="dropbox" alt="dropbox"/>;
        }
        if (cloudType === 256) {
            return <img src={boxIcon} className="boxIcon" alt="boxIcon"/>;
        }
        if (cloudType === 512) {
            return <img src={onedriveIcon} className="onedriveIcon" alt="onedriveIcon"/>;
        }
        if (cloudType === 1024) {
            return <img src={googledriveIcon} className="googledriveIcon" alt="googledriveIcon"/>;
        }
    }
    function getTypeName(cloudType, iconType = "") {
        if (
            cloudType !== "storj" &&
            cloudType !== "Backblaze" &&
            cloudType !== "Ocean" &&
            cloudType !== "Oracle" &&
            cloudType !== "Live" &&
            cloudType !== "OVH" &&
            cloudType !== "IBM" &&
            cloudType !== "Amove" &&
            cloudType !== "Perifery" &&
            cloudType !== "IDrive"
        ) {
            cloudType = parseInt(cloudType);
        }

        if (iconType === "Perform" || cloudType === "Perform") {
            return "perform"
        }
        if (iconType === "Global" || cloudType === "Global") {
            return "global"
        }
        if (iconType === "Scale" || cloudType === "Scale") {
            return "scale"
        }
        if (cloudType === 1) {
            return "aws"
        }
        if (cloudType === 2) {
            return "wasabi"
        }
        if (cloudType === 4) {
            return "azure"
        }
        if (cloudType === 16) {
            return "google"
        }
        if (iconType === "storj" || cloudType === "storj") {
            return "storj"
        }
        if (iconType === "symply" || cloudType === "symply") {
            return "symply"
        }
        if (iconType === "IBM" || cloudType === "IBM") {
            return "ibm"
        }
        if (iconType === "OVH" || cloudType === "OVH") {
            return "ovh"
        }
        if (iconType === "Ocean" || cloudType === "Ocean") {
            return "ocean"
        }
        if (iconType === "Backblaze" || cloudType === "Backblaze") {
            return "backblaze"
        }
        if (iconType === "Live" || cloudType === "Live") {
            return "live"
        }
        if (iconType === "Oracle" || cloudType === "Oracle") {
            return "oracle"
        }
        if (iconType === "Perifery" || cloudType === "Perifery") {
            return "perifery"
        }
        if (iconType === "IDrive" || cloudType === "IDrive") {
            return "idrive"
        }
        if (cloudType === 8) {
            return "S3"
        }
        if (cloudType === 32) {
            return "frameio"
        }
        if (cloudType === 64) {
            return "cloudflare"
        }
        if (cloudType === 128) {
            return "dropbox"
        }
        if (cloudType === 256) {
            return "box"
        }
        if (cloudType === 512) {
            return "onedrive"
        }
        if (cloudType === 1024) {
            return "google-drive"
        }
    }

    function getBuckets(count, data = [], useData = false) {

        if (isAuthenticated && (userStatus.status === "trial" || userStatus.status === "active")) {
            getDataWithTokenPOST("/api/desktop/list_buckets", {
                "cloudAccountId": cloudAccountsData[count].id,
                "includeRegion": true
            }).then((res) => Promise.all([res.status, res.json()]))
                .then(([status, bucket]) => {
                        if (status === 401) {
                            //logout()
                            return;
                        }
                        if (status === 500) {
                            setPopup500(true)
                            return;
                        }
                        if (status === 499) {
                            var errorType = bucket.type;
                            setError499(bucket.errors[errorType][0])
                            setPopup499(true)
                            return;
                        }
                        cloudAccountsData[count].buckets = bucket;
                        bucketsCount++;
                        if (bucketsCount < cloudAccountsData.length) {
                            if (useData) {
                                getBuckets(bucketsCount, data, true);
                            } else {
                                getBuckets(bucketsCount);
                            }

                        } else {

                            var get_cloud_info = false;
                            let newArr = [];
                            if (useData) {
                                newArr = [...data];
                            } else {
                                newArr = [...cloudAccounts]; // copying the old datas array
                            }
                            // a deep copy is not needed as we are overriding the whole object below, and not setting a property of it. this does not mutate the state.
                            for (var i = 0; i < cloudAccountsData.length; i++) {
                                newArr[i] = cloudAccountsData[i]; // replace e.target.value with whatever you want to change it to
                                if (cloudAccountsData[i].internalStorage) {
                                    get_cloud_info = true;
                                    setGetCloudInfo(true);
                                }
                            }


                            setCloudAccounts(newArr);
                            setCloudAccountsLoaded(true)

                            /*if (!get_cloud_info) {
                                setCloudAccounts(newArr);
                                setCloudAccountsLoaded(true)
                            } else {
                                getDataWithToken("/api/cloudaccount/get_cloud_info")
                                    .then((res) => Promise.all([res.status, res.json()]))
                                    .then(([status, data]) => {
                                        if (status === 401) {
                                            setEndpoint("/api/cloudaccount/get_cloud_info")
                                            setShowPopupSupport(true)
                                            // logout()
                                            return;
                                        }
                                        if (status === 500) {
                                            setPopup500(true)
                                            return;
                                        }
                                        if (data.status !== 499) {
                                            for (var i = 0; i < newArr.length; i++) {
                                                if (newArr[i].internalStorage) {
                                                    newArr[i].totalUsedSpaceSize = data.totalUsedSpaceSize
                                                    newArr[i].totalObjectCount = data.totalObjectCount
                                                    for (var j = 0; j < newArr[i].buckets.length; j++) {
                                                        for (var k = 0; k < data.bucketsInfo.length; k++) {
                                                            if (newArr[i].buckets[j].name === data.bucketsInfo[k].bucketName) {
                                                                newArr[i].buckets[j].usedSpaceSize = data.bucketsInfo[k].usedSpaceSize
                                                                newArr[i].buckets[j].objectCount = data.bucketsInfo[k].objectCount
                                                                newArr[i].buckets[j].createDrive = false
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                        setCloudAccounts(newArr);
                                        setCloudAccountsLoaded(true)
                                        checkSizeEvery10Minutes()
                                    });
                            }*/
                        }
                    }
                );
        }
    }

    function getRegions() {
        if(regions.length === 0){
            getDataWithToken('/api/cloudaccount/idrive_regions')
                .then((res) => Promise.all([res.status, res.json()]))
                .then(([status, response]) => {
                    setLoading(false);
                    if (status === 401) {
                        logout()
                        return;
                    }
                    if (status === 500) {
                        setPopup500(true);
                        setLoading(false);
                        return;
                    }
                    if (status === 499) {
                        var errorType = response.type;
                        setError499(response.errors[errorType][0])
                        setPopup499(true)
                        return;
                    }
                    setRegions(response)
                });
        }
    }


    function checkOS() {
        let userAgent = window.navigator.userAgent.toLowerCase(),
            macosPlatforms = /(macintosh|macintel|macppc|mac68k|macos)/i,
            windowsPlatforms = /(win32|win64|windows|wince)/i,
            iosPlatforms = /(iphone|ipad|ipod)/i,
            os = null;
        if (macosPlatforms.test(userAgent)) {
            os = "macos";
        } else if (iosPlatforms.test(userAgent)) {
            os = "ios";
        } else if (windowsPlatforms.test(userAgent)) {
            os = "windows";
        } else if (/android/.test(userAgent)) {
            os = "android";
        } else if (!os && /linux/.test(userAgent)) {
            os = "linux";
        }
        if (os === "macos" || os === "ios" || os === "linux") {
            setIsWindows(false);
        }
    }

    function getFormatedDate(date, includeTime = true) {
        const d = new Date(date);

        var hours = d.getHours();
        var minutes = d.getMinutes();
        var ampm = hours >= 12 ? 'pm' : 'am';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? '0' + minutes : minutes;
        var strTime = hours + ':' + minutes + ' ' + ampm;
        if (includeTime) {
            return d.getDate() + "/" + (d.getMonth() + 1) + "/" + d.getFullYear() + " " + strTime;
        } else {
            return d.getDate() + "/" + (d.getMonth() + 1) + "/" + d.getFullYear();
        }
    }

    function getFormatedDateFiles(date) {
        const d = new Date(date);

        var hours = d.getHours();
        var minutes = d.getMinutes();
        var ampm = hours >= 12 ? 'pm' : 'am';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? '0' + minutes : minutes;
        var strTime = hours + ':' + minutes + ' ' + ampm;


        return d.getDate() + " " + month[d.getMonth()] + " " + d.getFullYear() + " at " + strTime;

    }

    function prettySize(size, nospace, one, places) {
        const sizes = [
            'Bytes', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB'
        ]

        /**
         Pretty print a size from bytes
         @method pretty
         @param {Number} size The number to pretty print
         @param {Boolean} [nospace=false] Don't print a space
         @param {Boolean} [one=false] Only print one character
         @param {Number} [places=1] Number of decimal places to return
         */

        if (typeof nospace === 'object') {
            const opts = nospace
            nospace = opts.nospace
            one = opts.one
            places = opts.places || 1
        } else {
            places = places || 1
        }

        let mysize

        sizes.forEach((unit, id) => {
            if (one) {
                unit = unit.slice(0, 1)
            }
            const s = Math.pow(1024, id)
            let fixed
            if (size >= s) {
                fixed = String((size / s).toFixed(places))
                if (fixed.indexOf('.0') === fixed.length - 2) {
                    fixed = fixed.slice(0, -2)
                }
                mysize = fixed + (nospace ? '' : ' ') + unit
            }
        })
        if (!mysize) {
            let unit = (one ? sizes[0].slice(0, 1) : sizes[0])
            mysize = '0' + (nospace ? '' : ' ') + unit
        }
        return mysize
    }

    const validBucketName = (bucket) => {
        var regularExp = /^(?!^(\d{1,3}\.){3}\d{1,3}$)(^[a-z0-9]([a-z0-9\-]*[a-z0-9])?(?:\.[a-z0-9]([a-z0-9\-]*[a-z0-9])?)*$)/;
        return regularExp.test(bucket);
    }

    const checkLength = (s, l) => {
        return s.length <= l;
    }

    function toTitleCase(str) {
        return str.replace(
            /\w\S*/g,
            text => text.charAt(0).toUpperCase() + text.substring(1).toLowerCase()
        );
    }

    /* Update State Immediately

    const [sentMessages, setSentMessages] = useState([
    // some initial state here
]);

const sentMessagesRef = useRef([
    // some initial state here
]);

useEffect(() => {
  // Keep ref in sync with state
  sentMessagesRef.current = sentMessages;
}, [sentMessages]);

useEffect(
  () => {
    websocket.current.onmessage = (event) => {
      // Use up-to-date value of sentMessages here,
      // not the value at the time of declaring this function.
      setSentMessages([...sentMessagesRef.current, JSON.parse(event.data)]);
    }

    setSentMessages([]);
  },
  [curMessage, curUserName]
);

    * */



    const resetAISearch = () => {
        setCheckedValues([])
        setCheckedAll(false)
        setSearchValue("")
        setDateChanged(false)
        setFilterDate([
            {
                startDate: new Date(),
                endDate: new Date(),
                key: 'selection'
            }
        ])
    }
    function formatTime(seconds) {
        const hours = Math.floor(seconds / 3600); // Get the hours
        const minutes = Math.floor((seconds % 3600) / 60); // Get the remaining minutes
        const secs = Math.floor(seconds % 60); // Get the remaining seconds

        // Format the output to always show two digits
        const hoursStr = hours > 0 ? `${hours}:` : ''; // Show hours only if greater than 0
        const minutesStr = `${minutes.toString().padStart(2, '0')}:`; // Always show two digits for minutes
        const secondsStr = secs.toString().padStart(2, '0'); // Always show two digits for seconds

        return `${hoursStr}${minutesStr}${secondsStr}`;
    }
    function formatTimeHours(seconds) {
        const hours = Math.floor(seconds / 3600); // Get the hours
        const minutes = Math.floor((seconds % 3600) / 60); // Get the remaining minutes
        const secs = Math.floor(seconds % 60); // Get the remaining seconds

        // Format the output to always show two digits
        const hoursStr = `${hours.toString().padStart(2, '0')}:`; // Show hours only if greater than 0
        const minutesStr = `${minutes.toString().padStart(2, '0')}:`; // Always show two digits for minutes
        const secondsStr = secs.toString().padStart(2, '0'); // Always show two digits for seconds

        return `${hoursStr}${minutesStr}${secondsStr}`;
    }

    // AI search Valere Implementation
    async function postDataValere(url = "", data = {}) {
        controller.current = new AbortController();
        sendDataToWebsocket(config.aiSearchBaseURL + url, "POST")
        const response = await fetch(config.aiSearchBaseURL + url, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                Authorization: "Bearer " + token,
                Accept: 'application/json'
            },
            body: JSON.stringify(data),
            signal: controller.current.signal,
        });
        return response;
    }
    async function updateDataValere(url = "", data = {}) {
        controller.current = new AbortController();
        sendDataToWebsocket(config.aiSearchBaseURL + url, "POST")
        const response = await fetch(config.aiSearchBaseURL + url, {
            method: "PUT",
            headers: {
                'Content-Type': 'application/json',
                Authorization: "Bearer " + token,
                Accept: 'application/json'
            },
            body: JSON.stringify(data),
            signal: controller.current.signal,
        });
        return response;
    }
    async function getDataValere(url = "") {
        controller.current = new AbortController();
        sendDataToWebsocket(config.aiSearchBaseURL + url, "POST")
        const response = await fetch(config.aiSearchBaseURL + url, {
            method: "GET",
            headers: {
                Accept: 'application/json',
                Authorization: "Bearer " + token,
            },
            signal: controller.current.signal,
        });
        return response;
    }
   /* async function deleteDataValere(url = "") {
        controller.current = new AbortController();
        sendDataToWebsocket(config.aiSearchBaseURL + url, "POST")
        const response = await fetch(config.aiSearchBaseURL + url, {
            method: 'DELETE',
            headers: {
                Accept: 'application/json',
            signal: controller.current.signal,
                Authorization: "Bearer " + token,
            },
        });
        return response;
    }*/
    async function deleteDataValere(url = "") {
        try {
            controller.current = new AbortController();
            sendDataToWebsocket(config.aiSearchBaseURL + url, "POST");

            const response = await fetch(config.aiSearchBaseURL + url, {
                method: 'DELETE',
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                signal: controller.current.signal,
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            return response;
        } catch (error) {
            console.error("Error deleting data:", error);
        }
    }

    return (
        <AuthContext.Provider
            value={{
                isAuthenticated,
                showHeaderAndSidebar,
                showSidebar,
                setShowSidebar,
                setShowHeaderAndSidebar,
                setIsAuthenticated,
                token,
                setJWTToken,
                setLoginSession,
                session,
                postData,
                postDataNoResponse,
                getData,
                getDataMethodGet,
                deleteData,
                updateData,
                logout,
                clearLoginSession,
                cloudAccounts,
                setCloudAccounts,
                updateCloudAccounts,
                setUpdateCloudAccounts,
                cloudCounts,
                setcloudCounts,
                activityDataDrives,
                activityDataBackups,
                activityDataDirects,
                setactivityDrives,
                setactivityBackups,
                setactivityDirects,
                isWindows,
                getIcon,
                user,
                setUser,
                postBeforeLogin,
                isCMDDone,
                checkUserStatus,
                userStatus,
                setUserStatus,
                loading,
                setLoading,
                redirect,
                setRedirect,
                showHeader,
                setShowHeader,
                setSignUpWithGoogle,
                logoutONSignup,
                postDataWebResponseText,
                agentIsRunning,
                appLoaded,
                getUserData,
                postDataReturnText,
                updateDataWithToken,
                getCloudAccounts,
                refreshData,
                setRefreshData,
                onDrivePopup,
                saveIndex,
                checkAgent,
                getFormatedDate,
                prettySize,
                defaultOpenCloudDrive,
                setDefaultOpenCloudDrive,
                defaultOpenBackup,
                setDefaultOpenBackup,
                sendDataToMixPanel,
                setDefaultOpenConnection,
                defaultOpenConnection,
                cancelRequests,
                notifications,
                setNotifications,
                reloadDrivesFromAccounts,
                setReloadDrivesFromAccounts,
                isDark,
                setIsDark,
                agentPopupOpen,
                setAgentPopupOpen,
                billingPopupOpen,
                setBillingPopupOpen,
                cloudAccountsDone,
                limitExceeded,
                drivesPopup,
                setDrivesPopup,
                amovePkg,
                setAmovePkg,
                getToken,
                hideBTNs,
                setHideBTNs,
                preSelectedAccountID,
                setPreSelectedAccountID,
                preSelectedbucket,
                setPreSelectedbucket,
                preSelectedNFSSMB,
                setPreSelectedNFSSMB,
                openCloudDriveAccordion,
                setOpenCloudDriveAccordion,
                showAgentOnce,
                setShowAgentOnce,
                getDataWithTokenPOST,
                reloadBackups,
                setReloadBackups,
                reloadFiles,
                setReloadFiles,
                reloadSSO,
                setReloadSSO,
                reloadDeleteSSO,
                setReloadDeleteSSO,
                reloadLog,
                setReloadLog,
                deleteLog,
                setDeleteLog,
                reloadUsers,
                setReloadUsers,
                reloadTeams,
                setReloadTeams,
                reloadProjects,
                setReloadProjects,
                reloadSharedDrives,
                setReloadSharedDrives,
                reloadSMB,
                setReloadSMB,
                reloadNFS,
                setReloadNFS,
                reloadBilling,
                setReloadBilling,
                sendDataToWebsocket,
                sendDataToWebsocketLogin,
                cloudAccountsHasAmoveStorage,
                postDataWithToken,
                getDataWithTokenSSO,
                selectedDriveToInviteUser,
                setSelectedDriveToInviteUser,
                openInviteUserPopup,
                setOpenInviteUserPopup,
                lastAddedCloudAccount,
                setLastAddedCloudAccount,
                cloudAccountsLoaded,
                setCloudAccountsLoaded,
                delayOnLogin,
                setDelayOnLogin,
                popup500,
                setPopup500,
                getUniqueDataCount,
                deleteDataWeb,
                postDataWeb,
                postDataWebNoResponse,
                sync,
                onlyPostDataNoResponse,
                getDataWithToken,
                postDataNewFolder,
                popupSupport,
                setPopupSupport,
                sendEmailDataToZapier,
                endpoint,
                setEndpoint,
                showPopupSupport,
                setShowPopupSupport,
                postDataNewFolderWeb,
                removeAllNotification,
                removeNotification,
                notificationsRef,
                showTourDashboard,
                setShowTourDashboard,
                showTourConnections,
                setShowTourConnections,
                totalDrives,
                setTotalDrives,
                tourStep,
                setTourStep,
                omnisend,
                showStartedBtn,
                setShowStartedBtn,
                showInvitePopup,
                setShowInvitePopup,
                showDrivePopup,
                setShowDrivePopup,
                popup499,
                setPopup499,
                error499,
                setError499,
                loadDrivesOnLogin,
                setLoadDrivesOnLogin,
                loadSyncOnLogin,
                setLoadSyncOnLogin,
                showDrivePopup2,
                setShowDrivePopup2,
                showLaunchPopup,
                setShowLaunchPopup,
                getRegions,
                regions,
                setRegions,
                validBucketName,
                checkLength,
                speed_stat,
                transferred_stat,
                remaining_stat,
                toTitleCase,
                migrationsProcessing,
                reloadMigrationStatus,
                setReloadMigrationStatus,
                backButton,
                setBackButton,
                clearActivityData,
                postDataValere,
                updateDataValere,
                getDataValere,
                setSearchResults,
                searchResults,
                deleteDataValere,
                getFormatedDateFiles,
                checkedValues,
                setCheckedValues,
                checkedAll,
                setCheckedAll,
                searchValue,
                setSearchValue,
                filterDate,
                setFilterDate,
                dateChanged,
                setDateChanged,
                searchedBucketName,
                setSearchedBucketName,
                searchedCloudName,
                setSearchedCloudName,
                selectedBucketsForSearch,
                setSelectedBucketsForSearch,
                getTypeName,
                formatTime,
                formatTimeHours,
                getCloudAccountName,

                groupByValue,
                setGroupByValue,
                orderByValue,
                setOrderByValue,
                fileIdToOpen,
                setFileIdToOpen,
                calcDrives,
                setCalcDrives,
                cloudDrives,
                setCloudDrives
        }}
        >
            {children}
        </AuthContext.Provider>
    );
}

export default AuthContextProvider;

export const useAuth = () => useContext(AuthContext);
//
