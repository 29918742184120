import React, {useEffect, useState, useRef} from 'react';
import {useLocation, useNavigate} from "react-router-dom";
import {Link} from "react-router-dom";
import trashIcon from "../assets/images/icons/trash-icon.svg";
// import editIcon from "../assets/images/icons/edit-icon.svg";
import googleCloud from "../assets/images/clouds/conected-google-cloud-icon.svg";
import awsCloud from "../assets/images/clouds/aws-cloud-brand-logo.svg";
import wasabiCloud from "../assets/images/clouds/wasabi.svg";
import azureCloud from "../assets/images/clouds/azure.svg";
import s3Cloud from "../assets/images/clouds/s3.png";
import {NavLink} from "react-router-dom";
import editIcon from "../assets/images/icons/edit-red-icon.svg";
import syncIcon from "../assets/images/icons/sync-icon-lighter.svg";
import settings from "../assets/images/settings-icon.svg";
import info from "../assets/images/info.svg";
import {Collapse, notification, Popconfirm, Radio, Slider, Tooltip, Switch, Select, InputNumber, Input} from 'antd';
import {useAuth} from "../context/AuthContext";
import warning from "../assets/images/icons/warning-sign.svg";
import toastIcon from "../assets/images/icons/toast-icon.svg";
import {Button, Modal, Alert} from 'antd';
import config from '../config';
import {LoadingOutlined} from '@ant-design/icons';
import {Spin} from 'antd';

import {Tabs} from 'antd';
import crossIcon from "../assets/images/icons/cross-black-icon.svg";
import userIcon from "../assets/images/icons/team/user-icon.svg";
import crossBalckIcon from "../assets/images/icons/tour-cross-black-icon.svg";
import performIcon from "../assets/images/perform.svg";
import globalIcon from "../assets/images/global.svg";
import scaleIcon from "../assets/images/scale.svg";
import ChooseFolder from "./ChooseFolder";

const {TabPane} = Tabs;

const {Panel} = Collapse;
const CloudDrives = ({props, callback}) => {
    const {
        getData,
        deleteData,
        updateData,
        logout,
        getIcon,
        isWindows,
        isCMDDone,
        defaultOpenCloudDrive,
        setDefaultOpenCloudDrive,
        token,
        postBeforeLogin,
        setIsCMDDone,
        reloadDrivesFromAccounts,
        setReloadDrivesFromAccounts,
        userStatus,
        getDataWithToken,
        prettySize,
        limitExceeded,
        setDrivesPopup,
        agentIsRunning,
        reloadDrive,
        setReloadDrive,
        cloudAccounts,
        openCloudDriveAccordion,
        setOpenCloudDriveAccordion,
        user,
        sendDataToWebsocket,
        postDataWithToken,
        selectedDriveToInviteUser,
        setSelectedDriveToInviteUser,
        deleteDataWeb,
        getDataWithTokenPOST,
        setPopup500,
        onlyPostDataNoResponse,
        totalDrives,
        setTotalDrives,
        showStartedBtn,
        setShowStartedBtn,
        showDrivePopup,
        setShowInvitePopup,
        setShowDrivePopup,
        sendDataToMixPanel,
        setError499,
        setPopup499,
        setOpenInviteUserPopup,
        loadDrivesOnLogin,
        setLoadDrivesOnLogin,
        isAuthenticated,
        showDrivePopup2,
        setShowDrivePopup2,
        checkAgent,
        showLaunchPopup,
        setShowLaunchPopup,
        checkLength,
        updateDataWithToken,
        postDataReturnText,
        setCalcDrives,
        cloudDrives,
        setCloudDrives
    } = useAuth();
    const navigate = useNavigate();
    const [cacheSettings, setcacheSettings] = useState('stream');
    const [cacheSettingsGBTB, setcacheSettingsGBTB] = useState('GB');
    const [api, contextHolder] = notification.useNotification();
    const [cloudCounts, setcloudCounts] = useState([])
    const [backupInstance, setBackupInstance] = useState([]);
    const [modalData, setModalData] = useState({})
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isChecked, setisChecked] = useState(false);
    const [showTooltips, setshowTooltips] = useState(false);
    const [allowDelete, setAllowDelete] = useState(false);
    const [reloading, setReloading] = useState(false);
    const [cache, setCache] = useState(50);
    const [Objects, setObjects] = useState(10000);
    const [syncInterval, setSyncInterval] = useState(21600);
    const [speedNetwork, setSpeedNetwork] = useState('Home/Office');
    const [GBMB, setGBMB] = useState('MB');
    const [Threads, setThreads] = useState(5);
    const [selectedTab, setSelectedTab] = useState('1');
    const [chunkSize, setChunkSize] = useState();
    const [chunkObjects, setChunkObjects] = useState();
    const [descending, setDescending] = useState(false);
    const [searchValForSearch, setSearchValForSearch] = useState("");
    const [cloudCountState, setCloudCountState] = useState(false);
    const [editDriveName, setEditDriveName] = useState(false);
    const [updatedDriveName, setUpdatedDriveName] = useState(false);
    const [showCloudDriveNameError, setShowCloudDriveNameError] = useState(false);
    const [showCloudDriveNameLengthError, setShowCloudDriveNameLengthError] = useState(false);
    const [refreshData, setRefreshData] = useState(false);
    const [defaultActive, setDefaultActive] = useState(0)
    const [limitExceed, setLimitExceed] = useState(false);
    const [rotationalCache, setRotationalCache] = useState(false);
    const [keepFileAfterUpload, setKeepFileAfterUploade] = useState(false);
    const [encryption, setEncryption] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loadingDel, setLoadingDel] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(20);
    const [isEditUser, setEditUser] = useState(false);
    const [newUserPermissionValue, setNewUserPermissionValue] = useState(2);
    const [newUsersList, setNewUsersList] = useState([]);
    const [usersList, setUsersList] = useState([]);
    const [usersData, setUsersData] = useState([]);
    const [allUsersData, setAllUsersData] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const urlParams = new URLSearchParams(window.location.search);
    const autologin = urlParams.get('autologin');
    const dataFetchedRef = useRef(false);
    const dataFetchedRef1 = useRef(false);
    const checkDrivesIntervalCalledFirstTime = useRef(false);
    const [chooseFolder, setChooseFolder] = useState(false);
    const [inputsDisabled, setInputsDisabled] = useState(false);

    const onChangeCache = (newValue) => {
        setCache(newValue);
    };
    const onChangeObjects = (newValue) => {
        setObjects(newValue);
    };
    const manageUserCancel = () => {
        setEditUser(false);
        setNewUsersList([])
    };

    const getAllUsers = () => {
        //&userStatus=1
        getDataWithToken("/api/user/get_all_users?page=1&pagesize=10000000&sortfield=CreateDate&descending=true&deleted=false")
            .then((res) => Promise.all([res.status, res.json()]))
            .then(([status, response]) => {
                if (status === 401) {
                    logout()
                    return;
                }
                if (status === 500) {
                    setPopup500(true);
                    setLoading(false);
                    return;
                }

                if (status === 499) {
                    var errorType = response.type;
                    setError499(response.errors[errorType][0])
                    setPopup499(true)
                    return;
                }
                setAllUsersData(response.data)
            })
    };
    const handleChangeUser = (value) => {
        let newArr = [...newUsersList];
        for (var i = 0; i < usersData.length; i++) {
            if (usersData[i].id === value) {
                newArr.push({name: usersData[i].email, userId: value, permissionType: newUserPermissionValue})
            }
        }
        setNewUsersList(newArr);
    };

    const onChangePermissionUsers = (e, id, newProject = false) => {
        if (newProject) {
            let newArr = [...newUsersList];
            for (var i = 0; i < newArr.length; i++) {
                if (newArr[i].userId === id) {
                    newArr[i].permissionType = e.target.value;
                }
            }
            setNewUsersList(newArr)
        } else {
            let newArr = [...usersList];
            for (var i = 0; i < newArr.length; i++) {
                if (newArr[i].user.id === id) {
                    newArr[i].permission.permissionType = e.target.value;
                }
            }
            setUsersList(newArr)
        }
    };
    const AssignUserToDrive = () => {
        setLoading(true)
        var selectedDriveID = selectedDriveToInviteUser.sharedDriveId === undefined ? selectedDriveToInviteUser.id : selectedDriveToInviteUser.sharedDriveId;
        if (newUsersList.length > 0) {
            var dataToPost = [];
            var newArray = [...newUsersList];
            var assignedHimSelf = false;

            for (var i = 0; i < newArray.length; i++) {
                if (newArray[i].userId === user.userId) {
                    assignedHimSelf = true;
                }
                dataToPost.push({
                    "userId": newArray[i].userId,
                    "sharedCloudDriveId": selectedDriveID,
                    "permissionType": newArray[i].permissionType
                })
            }
            postDataWithToken("/api/permission/users_sharedclouddrive_permision", dataToPost)
                .then((res) => Promise.all([res.status, res.text()]))
                .then(([status, data]) => {
                    if (status === 401) {
                        logout()
                        return;
                    }
                    if (status === 500) {
                        setPopup500(true)
                        return;
                    }
                    if (status === 499) {
                        var response = JSON.parse(data)
                        var errorType = response.type;
                        setError499(response.errors[errorType][0])
                        setPopup499(true)
                        return;
                    }
                    setLoading(false)
                    setEditUser(false);
                    api.open({
                        message: 'User successfully assigned.',
                        icon: <img src={toastIcon} alt="toastIcon"/>,
                        duration: 5,
                    });
                    sendDataToMixPanel('User successfully assigned to Drive.', user.username, {
                        'App': 'Web App'
                    })
                    if (agentIsRunning) {
                        if (assignedHimSelf) {
                            getDataWithTokenPOST('/Management/sync_shareddrive')
                                .then((res) => Promise.all([res.status, res.text()]))
                                .then(([status, data]) => {
                                    if (status === 401) {
                                        logout()
                                        return;
                                    }
                                    if (status === 500) {
                                        setPopup500(true)
                                        return;
                                    }
                                    if (status === 499) {
                                        var response = JSON.parse(data)
                                        var errorType = response.type;
                                        setError499(response.errors[errorType][0])
                                        setPopup499(true)
                                        return;
                                    }
                                })
                        }
                    }
                    setNewUsersList([]);
                    setSelectedDriveToInviteUser("")
                })
        }

        if (usersList.length > 0) {
            dataToPost = [];
            newArray = [...usersList];
            for (var i = 0; i < newArray.length; i++) {
                dataToPost.push({
                    "id": newArray[i].permission.id,
                    "userId": newArray[i].user.id,
                    "sharedCloudDriveId": selectedDriveID,
                    "permissionType": newArray[i].permission.permissionType
                })
            }
            setLoading(false)
            updateDataWithToken("/api/permission/users_sharedclouddrive_permision", dataToPost)
                .then((res) => Promise.all([res.status, res.text()]))
                .then(([status, bucket]) => {
                    if (status === 401) {
                        logout()
                        return;
                    }
                    if (status === 500) {
                        setPopup500(true)
                        return;
                    }
                    if (status === 499) {
                        var data = JSON.parse(bucket)
                        var errorType = data.type;
                        setError499(data.errors[errorType][0])
                        setPopup499(true)
                        return;
                    }

                    setLoading(false)
                    setEditUser(false);
                    api.open({
                        message: 'User successfully updated.',
                        icon: <img src={toastIcon} alt="toastIcon"/>,
                        duration: 5,
                    });
                    sendDataToMixPanel('User successfully updated.', user.username, {
                        'App': 'Web App'
                    })
                    setUsersList([]);
                    setSelectedDriveToInviteUser("")
                })
        }
    };
    const removeUserFromList = (index, permissionId, newUser = false) => {
        if (newUser) {
            let newArr = [...newUsersList];
            newArr.splice(index, 1);
            setNewUsersList(newArr)
        } else {
            let newArr = [...usersList];
            newArr.splice(index, 1);
            setUsersList(newArr)
            deleteDataWeb('/api/permission/users_sharedclouddrive_permision?id=' + permissionId).then((res) => Promise.all([res.status, res.text()]))
                .then(([status, data]) => {
                    if (status === 401) {
                        logout()
                        return;
                    }
                    if (status === 500) {
                        setPopup500(true)
                        return;
                    }
                    if (status === 499) {
                        var dataNew = JSON.parse(data)
                        var errorType = dataNew.type;
                        setError499(dataNew.errors[errorType][0])
                        setPopup499(true)
                        return;
                    }
                    if (status === 200) {
                        api.open({
                            message: 'User removed successfully.',
                            description: `User has been removed from project.`,
                            icon: <img src={toastIcon} alt="toastIcon"/>,
                            duration: 3
                        });
                        getUsersForAssign(selectedDriveToInviteUser.sharedDriveId === undefined ? selectedDriveToInviteUser.id : selectedDriveToInviteUser.sharedDriveId)
                        sendDataToMixPanel('User has been removed from project.', user.username, {
                            'App': 'Web App'
                        })
                        if (agentIsRunning) {
                            postDataReturnText('/Management/sync_shareddrive')
                        }
                    }
                });
        }
    };

    useEffect(() => {
        if (userStatus.status === "trial" || userStatus.status === "active") {
            getAllUsers()
            if (backupInstance.length === 0) {
                getData('/Management/cloudbackup')
                    .then((res) => Promise.all([res.status, res.json()]))
                    .then(([status, data]) => {
                        if (status === 401) {
                            logout()
                            return;
                        }
                        if (status === 500) {
                            setPopup500(true)
                            return;
                        }
                        if (status === 499) {
                            var errorType = data.type;
                            setError499(data.errors[errorType][0])
                            setPopup499(true)
                            return;
                        }
                        setBackupInstance(data);
                    });
            }
            if (defaultOpenCloudDrive) {
                setDefaultActive(1);
                setDefaultOpenCloudDrive(false);
            }
        }
        if (autologin === "1") {
            setShowLaunchPopup(true)
        }
    }, []);
    useEffect(() => {
        if (userStatus.status === "trial" || userStatus.status === "active") {
            if (reloadDrivesFromAccounts) {
                setReloadDrivesFromAccounts(false)
                setRefreshData(true)
                loadCloudDrives(true)
            }
        }
    }, [reloadDrivesFromAccounts]);
    useEffect(() => {
        setTotalDrives(cloudDrives.length)
    }, [cloudDrives]);
    const onChangeDriveName = (e) => {
        var name = e.target.value;
        setUpdatedDriveName(e.target.value)

        setShowCloudDriveNameError(false)
        setShowCloudDriveNameLengthError(false)
        if (name.length > 1) {
            var result1 = cloudDrives.filter(item => item.name.toLowerCase() === name.toLowerCase());
            var result2 = backupInstance.filter(item => item.name.toLowerCase() === name.toLowerCase());

            if (result1.length > 0 || result2.length > 0) {
                setShowCloudDriveNameError(true)
            } else {
                if (!checkLength(e.target.value, 32)) {
                    setShowCloudDriveNameLengthError(true)
                } else {
                    setShowCloudDriveNameLengthError(false)
                    setModalData((lastValue) => {
                        return {
                            ...lastValue,
                            ['name']: e.target.value,
                        };
                    });
                }
            }
        }
    };

    const onChangeChunkObjects = (newValue) => {
        setChunkObjects(newValue);
    };
    const onChangeThreads = (newValue) => {
        setThreads(newValue);
    };
    const handleChange = (value) => {
        setSyncInterval(value)
    };
    const onChangeNetwork = (value) => {
        setSpeedNetwork(value);
        if (value === '1') { // Home/Office
            setThreads(5);
            setChunkObjects(5);
            setChunkSize(5);
            setInputsDisabled(false);
        } else if (value === '2') { // Mobile
            setThreads(1);
            setChunkObjects(1);
            setChunkSize(1);
            setInputsDisabled(false);
        } else if (value === '3') { // Public Wifi
            setThreads(2);
            setChunkObjects(2);
            setChunkSize(5);
            setInputsDisabled(false);
        } else if (value === '4') { // Advance
            setThreads(5);
            setChunkObjects(5);
            setChunkSize(5);
            setInputsDisabled(true);
        }
    };
    const changeGBMB = (e) => {
        setGBMB(e.target.value)
        if (e.target.value === "GB") {
            setChunkSize(1)
        } else {
            setChunkSize(5)
        }
    };
    const onChangeAllowDelete = (e) => {
        setAllowDelete(false)
    };

    function cloudWithCounts(data) {
        var items = [];
        for (const [key, value] of Object.entries(data)) {
            var obj = {};
            obj.cloudType = key;
            obj.count = value;
            items.push(obj)
        }
        setcloudCounts(items);
    }

    const onChange = (key) => {
        if (cloudDrives.length > 0) {
            callback({"drives": key.length > 1})
            setDefaultActive(key);
        }
    };

    function getUniqueDataCount(objArr, propName) {
        var data = [];
        if (Array.isArray(propName)) {
            propName.forEach(prop => {
                objArr.forEach(function (d, index) {
                    if (d[prop]) {
                        data.push(d[prop]);
                    }
                });
            });
        } else {
            objArr.forEach(function (d, index) {
                if (d[propName]) {
                    data.push(d[propName]);
                }
            });
        }

        var uniqueList = [...new Set(data)];

        var dataSet = {};
        for (var i = 0; i < uniqueList.length; i++) {
            dataSet[uniqueList[i]] = data.filter(x => x == uniqueList[i]).length;
        }

        return dataSet;
    }

    var time = 2000; // dynamic interval
    var timer; // current timeout id to clear
    function checkDrivesInterval() {
        checkDrivesIntervalCalledFirstTime.current = true
        time *= 2;
        timer = setTimeout(() => {
            checkDrivesInterval()
        }, time);

        if (localStorage.getItem('isLoggedInTimer') === null || time === 64000) {
            time = 2000;
            clearTimeout(timer);
        } else {
            checkAgent()
            loadCloudDrives();
        }
    }


    useEffect(() => {
        if (userStatus.status === "trial" || userStatus.status === "active") {
            //if (cloudDrives.length === 0) {
            if (user.userType !== undefined) {
                loadCloudDrives();
                if (!checkDrivesIntervalCalledFirstTime.current || agentIsRunning) {
                    clearTimeout(timer);
                    checkDrivesInterval()
                }
            }
            //   }
        }
    }, [isCMDDone, agentIsRunning, user]);


    useEffect(() => {
        if (limitExceeded) {
            loadCloudDrives()
        }
    }, [limitExceeded]);
    useEffect(() => {
        if (openCloudDriveAccordion) {
            setOpenCloudDriveAccordion(false)
            setDefaultActive(1);
        }
    }, [openCloudDriveAccordion]);

    function getUsersForAssign(id) {
        getDataWithToken(`/api/permission/get_users_assigned_to_sharedclouddrive?sharedCloudDriveId=${id}&page=1&pagesize=1000000&sortfield=User.Username&descending=false`)
            .then((res) => Promise.all([res.status, res.text()]))
            .then(([status, response]) => {
                if (status === 401) {
                    logout()
                    return;
                }
                if (status === 500) {
                    setPopup500(true);
                    setLoading(false);
                    return;
                }
                if (status === 499) {
                    var data = JSON.parse(response)
                    var errorType = data.type;
                    setError499(data.errors[errorType][0])
                    setPopup499(true)
                    return;
                }
                var res = JSON.parse(response);
                var filteredDate = allUsersData.filter((item) => {
                    if (res.data.filter((a) => a.user.id === item.id).length < 1) {
                        return item
                    }
                })
                setUsersList(res.data)
                setUsersData(filteredDate)
            })

    }

    function loadCloudDrives(refresh = false) {
        if (agentIsRunning) {
            if (dataFetchedRef1.current) return;
            dataFetchedRef1.current = true;
            getData('/Management/clouddrive')
                .then((res) => Promise.all([res.status, res.json()]))
                .then(([status, response]) => {
                    if (status === 401) {
                        logout()
                        return;
                    }
                    if (status === 500) {
                        setPopup500(true);
                        setLoading(false);
                        return;
                    }

                    if (status === 499) {
                        var errorType = response.type;
                        setError499(response.errors[errorType][0])
                        setPopup499(true)
                        return;
                    }

                    var clouddrives = response;
                    var data = clouddrives;
                    if (user.userType === 16) {
                        getDataWithToken(`/api/sharedclouddrive/get_all?page=${currentPage}&pagesize=${perPage}&sortfield=Name&descending=${descending}&deleted=false&name=${searchValForSearch}`)
                            .then((res) => Promise.all([res.status, res.json()]))
                            .then(([status, response]) => {
                                if (status === 401) {
                                    //logout()
                                    return;
                                }
                                if (status === 500) {
                                    setPopup500(true)
                                    return;
                                }
                                if (status === 499) {
                                    var errorType = response.type;
                                    setError499(response.errors[errorType][0])
                                    setPopup499(true)
                                    return;
                                }
                                var sharedDrives = response.data;
                                let sharedDriveIds = new Set(clouddrives.map(d => d.sharedDriveId));
                                let notExistsDrives = sharedDrives.filter(drive => !sharedDriveIds.has(drive.id));
                                if (notExistsDrives.length > 0) {
                                    for (var i = 0; i < notExistsDrives.length; i++) {
                                        notExistsDrives[i]["account"] = notExistsDrives[i]["cloudAccount"];
                                        notExistsDrives[i]["notassigned"] = true;
                                        notExistsDrives[i]["sharedDriveId"] = notExistsDrives[i]["id"];
                                        data.push(notExistsDrives[i])
                                    }
                                    if (!checkDrivesIntervalCalledFirstTime.current) {
                                        checkDrivesInterval()
                                    }
                                }
                                if (data.length > 0) {
                                    setShowStartedBtn(false)
                                    var shorNextTour = localStorage.getItem('shorNextTour')
                                    if (shorNextTour === null || shorNextTour === "false") {
                                        localStorage.setItem('shorNextTour', "false")
                                        setShowInvitePopup(true)
                                        setShowDrivePopup(true)
                                        setDefaultActive(1);
                                        setDefaultOpenCloudDrive(true)
                                    }
                                } else {
                                    setShowStartedBtn(true)
                                }
                                updateCloudDrivesData(data)
                            })
                    } else {
                        updateCloudDrivesData(data)
                        if (data.length > 0) {
                            setShowStartedBtn(false)
                            var shorNextTour = localStorage.getItem('shorNextTour')
                            if (shorNextTour === null || shorNextTour === "false") {
                                localStorage.setItem('shorNextTour', "false")
                                setShowInvitePopup(true)
                                setShowDrivePopup(true)
                                setDefaultActive(1);
                                setDefaultOpenCloudDrive(true)
                            }
                        } else {
                            setShowStartedBtn(true)
                        }
                    }
                }).catch((error) => {
                setShowStartedBtn(true)
            })
        } else {
            if (user.userType === 16) {
                if (dataFetchedRef.current) return;
                dataFetchedRef.current = true;
                getDataWithToken(`/api/sharedclouddrive/get_all?page=${currentPage}&pagesize=${perPage}&sortfield=Name&descending=${descending}&deleted=false&name=${searchValForSearch}`)
                    .then((res) => Promise.all([res.status, res.json()]))
                    .then(([status, response]) => {
                        if (status === 401) {
                            //logout()
                            return;
                        }
                        if (status === 500) {
                            setPopup500(true)
                            return;
                        }
                        if (status === 499) {
                            var errorType = response.type;
                            setError499(response.errors[errorType][0])
                            setPopup499(true)
                            return;
                        }
                        var data = response.data;

                        for (var j = 0; j < data.length; j++) {
                            data[j]["account"] = data[j].cloudAccount;
                        }

                        updateCloudDrivesData(data)
                    })
            }
            if (user.userType !== 32 || user.userType !== 64) {
                if (dataFetchedRef.current) return;
                dataFetchedRef.current = true;

                getDataWithToken(`/api/sharedclouddrive/get_all?page=${currentPage}&pagesize=${perPage}&sortfield=Name&descending=${descending}&deleted=false&name=${searchValForSearch}`)
                    .then((res) => Promise.all([res.status, res.json()]))
                    .then(([status, response]) => {
                        if (status === 401) {
                            //logout()
                            return;
                        }
                        if (status === 500) {
                            setPopup500(true)
                            return;
                        }
                        if (status === 499) {
                            var errorType = response.type;
                            setError499(response.errors[errorType][0])
                            setPopup499(true)
                            return;
                        }
                        var data = response.data;

                        for (var j = 0; j < data.length; j++) {
                            data[j]["account"] = data[j].cloudAccount;
                        }

                        var AllSharedDrives = data;

                        getDataWithToken(`/api/permission/get_sharedclouddrive_assigned_to_user?userId=${user.userId}&page=1&pagesize=50&sortfield=SharedClouDrive.Name&descending=false`)
                            .then((res) => Promise.all([res.status, res.json()]))
                            .then(([status, response]) => {
                                if (status === 401) {
                                    logout()
                                    return;
                                }
                                if (status === 500) {
                                    setPopup500(true);
                                    setLoading(false);
                                    return;
                                }
                                updateCloudDrivesData(AllSharedDrives.filter((item) => (item.id === response?.data[0]?.sharedClouDrive?.id)))
                            })
                    })
            }
        }
    }


    useEffect(() => {
        if (userStatus.status === "trial" || userStatus.status === "active") {
            if (cloudCounts) {
                setCloudCountState(true);
            }
        }
    }, [cloudCounts])

    const cloudAccount = (
        <div className="connected-accounts-header">
            <h3>Drives</h3>
            {cloudCounts.length > 0 && (
                <ul className="login-accounts">
                    {cloudCounts.map((item, i) => {
                        return (
                            <li key={i}
                                className={` ${item.count > 0 ? "show" : ""}`}>{getIcon(item.cloudType, item.cloudType)}<span
                                className={` ${item.count > 1 ? "show" : ""}`}>{item.count}</span></li>
                        )
                    })}
                </ul>
            )}
        </div>
    );


    const updateCloudDrivesData = (data, disabled = false) => {

        if (data.length > 0) {
            setDrivesPopup("false")
            for (var j = 0; j < data.length; j++) {
                data[j]["cloudIconType"] = data[j].account?.cloudType;
                data[j]["cloudType"] = data[j].account?.cloudType;
                data[j]["disabled"] = disabled;
                if (limitExceeded) {
                    data[j]["disabled"] = true;
                    data[j]["active"] = false;
                    actionSettingUpdate(false, data[j], false)
                }
                if (data[j].account.internalStorage) {
                    if (data[j].account.storageTier === 1) {
                        data[j]["cloudIconType"] = "Perform";
                    }
                    if (data[j].account.storageTier === 2) {
                        data[j]["cloudIconType"] = "Global";
                    }
                    if (data[j].account.storageTier === 4) {
                        data[j]["cloudIconType"] = "Scale";
                    }
                } else {
                    if (data[j].account.serviceUrl !== null && data[j].account.serviceUrl !== undefined) {
                        if (data[j].account.serviceUrl.indexOf('storjshare.io') > -1) {
                            data[j]["cloudIconType"] = 'storj';
                        }
                        if (data[j].account.serviceUrl.indexOf('appdomain.cloud') > -1) {
                            data[j]["cloudIconType"] = 'IBM';
                        }
                        if (data[j].account.serviceUrl.indexOf('ovh.us') > -1) {
                            data[j]["cloudIconType"] = 'OVH';
                        }
                        if (data[j].account.serviceUrl.indexOf('seagate.com') > -1) {
                            data[j]["cloudIconType"] = 'Live';
                        }
                        if (data[j].account.serviceUrl.indexOf('oraclecloud.com') > -1) {
                            data[j]["cloudIconType"] = 'Oracle';
                        }
                        if (data[j].account.serviceUrl.indexOf('digitaloceanspaces.com') > -1) {
                            data[j]["cloudIconType"] = 'Ocean';
                        }
                        if (data[j].account.serviceUrl.indexOf('backblazeb2.com') > -1) {
                            data[j]["cloudIconType"] = 'Backblaze';
                        }
                        if (data[j].account.serviceUrl.indexOf("object-matrix.com") > -1) {
                            data[j]["cloudIconType"] = "Perifery";
                        }
                        if (data[j].account.serviceUrl.indexOf("idrivee2") > -1) {
                            data[j]["cloudIconType"] = "IDrive";
                        }
                        if (data[j].account.serviceUrl.indexOf("symply") > -1) {
                            data[j]["cloudIconType"] = "symply";
                        }
                    }
                }
            }
            dataFetchedRef1.current = false;
            setCloudDrives(data);
        } else {
            setCloudDrives([]);
            setDefaultActive(0);
            setcloudCounts([]);
            setDrivesPopup("true");
            dataFetchedRef1.current = false;
            var onboarding = localStorage.getItem('onboarding');
            if (onboarding === null) {
                if (user.userType !== 32 && user.userType !== 64 && user.userType === 16 && user.userType !== undefined && !user.owner && cloudAccounts.length < 1) {
                    localStorage.setItem('onboarding', true);
                    navigate("/onboarding");
                }
            }
        }
        var dataToCheck = [];
        for (var i = 0; i < data.length; i++) {
            dataToCheck.push(data[i])
        }
        cloudWithCounts(getUniqueDataCount(dataToCheck, ['cloudIconType']))
    }

    const showModal = (e, data) => {
        setIsModalOpen(true);
        setshowTooltips(true);
        setSelectedTab('1');
        setModalData(data)
        setObjects(data.objectsPerFolder)
        setThreads(data?.transferOptions?.concurrentFiles)
        setcacheSettingsGBTB('GB')
        setAllowDelete(data.allowDelete)
        setChunkObjects(data?.transferOptions?.concurrentChunks)
        setRotationalCache(data.rotationalCache)
        setKeepFileAfterUploade(data.keepFileAfterUpload)
        setEncryption(data.localCacheEncrypted)

        setGBMB("MB")
        if (data.maximumCacheSize > 0) {
            setCache(((data.maximumCacheSize / 1024) / 1024) / 1024)
        } else {
            setCache(data.maximumCacheSize)
        }

        setChunkSize((data.transferOptions.chunkSize / 1024) / 1024)
        setSyncInterval((data.syncIntervalSeconds / 60) / 60 + " hour")

        if (data.syncType === 1) {
            setcacheSettings('stream')
        }
        if (data.syncType === 2) {
            setcacheSettings('mirror')
        }
        e.preventDefault();
    };
    const reload = (item) => {
        setReloading(false)
        getData(`/Management/clouddrive/sync/${item.id}`)
            .then((res) => Promise.all([res.status, res.text()]))
            .then(([status, data]) => {
                if (status === 401) {
                    logout()
                    return;
                }
                if (status === 500) {
                    setPopup500(true);
                    setLoading(false);
                    return;
                }
                if (status === 499) {
                    var response = JSON.parse(data)
                    var errorType = response.type;
                    setError499(response.errors[errorType][0])
                    setPopup499(true)
                    return;
                }
                if (status === 200) {
                    api.open({
                        message: 'Your ' + item.name + ' Drive has been updated.',
                        icon: <img src={toastIcon} alt="toastIcon"/>,
                        duration: 5
                    });
                }
            })
            .catch((error) => {
                console.error(error)
            });
    };

    function saveSettings(url, localpath = "") {
        setLoading(true)
        var finalChunkSize = chunkSize * 1024 * 1024;
        var ThreadsVal = Threads;
        var chunkObjectsVal = chunkObjects;
        var cSize = cache;
        if (GBMB === "GB") {
            finalChunkSize = 1024 * 1024 * 1024;
        }
        var cSetting = 1;
        if (cacheSettings === "stream") {
            cSetting = 1;
        }
        if (cacheSettings === "mirror") {
            cSetting = 2;
        }
        if (ThreadsVal === '' || ThreadsVal === undefined || ThreadsVal === null) {
            ThreadsVal = 1;
        }
        if (chunkObjectsVal === '' || chunkObjectsVal === undefined || chunkObjectsVal === null) {
            chunkObjectsVal = 1;
        }

        if (cSize === '' || cSize === undefined || cSize === null) {
            cSize = 15;

            if (cacheSettingsGBTB === "TB") {
                cSize = 1;
            }
        }

        cSize = (cSize * 1024 * 1024 * 1024);

        if (cacheSettingsGBTB === "TB") {
            cSize = cSize * 1024;
        }

        var dataToPost = {
            "id": modalData.id,
            "active": modalData.active,
            "name": modalData.name,
            "localPath": localpath,
            "permanentLocalPath": modalData.permanentLocalPath,
            "prefix": modalData.prefix,
            "storageName": modalData.storageName,
            "driveName": modalData.driveName,
            "syncIntervalSeconds": (parseInt(syncInterval) * 60 * 60),
            "objectsPerFolder": Objects,
            "maximumCacheSize": cSize,
            "allowDelete": allowDelete,
            "driveType": modalData.driveType,
            "rotationalCache": rotationalCache,
            "keepFileAfterUpload": keepFileAfterUpload,
            "syncType": cSetting,
            "permissionType": modalData.permissionType,
            "sharedDrive": modalData.sharedDrive,
            "cloudType": modalData.cloudType,
            "cloudIconType": modalData.cloudIconType,
            "transferOptions": {
                "chunkSize": finalChunkSize,
                "retryCount": modalData.transferOptions.retryCount,
                "concurrentChunks": chunkObjectsVal,
                "concurrentFiles": ThreadsVal
            },
            "account": modalData.account
        }

        updateData(url + modalData.id, dataToPost)
            .then((res) => Promise.all([res.status, res.json()]))
            .then(([status, data]) => {
                setLoading(false)
                if (status === 401) {
                    logout()
                    return;
                }
                if (status === 500) {
                    setPopup500(true);
                    setLoading(false);
                    return;
                }
                if (status === 499) {
                    var errorType = data.type;
                    setError499(data.errors[errorType][0])
                    setPopup499(true)
                    return;
                }

                setLoading(false)
                if (status === 200) {
                    let newArr = [...cloudDrives];
                    dataToPost.cloudType = modalData.cloudType;
                    dataToPost.cloudIconType = modalData.cloudIconType;
                    for (var i = 0; i < newArr.length; i++) {
                        if (newArr[i].id === modalData.id) {
                            newArr[i] = dataToPost;
                        }
                    }
                    setCloudDrives(newArr)
                    api.open({
                        message: 'Your ' + modalData.name + ' Drive has been updated.',
                        icon: <img src={toastIcon} alt="toastIcon"/>,
                        duration: 5
                    });
                    setIsModalOpen(false);
                    setModalData({})

                    setShowCloudDriveNameError(false)
                    setEditDriveName(false);
                    setUpdatedDriveName('')
                    if(localpath !== ""){
                        loadCloudDrives(true)
                    }
                }
            });
    }

    function actionSettingUpdate(e, data, updateCData = true) {

        var msg = 'Your ' + data.name + ' Drive has been deactivated.'
        if (e) {
            msg = 'Your ' + data.name + ' Drive has been activated.'
        }
        var dataToPost = {
            "id": data.id,
            "active": e,
            "name": data.name,
            "permanentLocalPath": data.permanentLocalPath,
            "prefix": data.prefix,
            "storageName": data.storageName,
            "driveName": data.driveName,
            "syncIntervalSeconds": data.syncIntervalSeconds,
            "objectsPerFolder": data.objectsPerFolder,
            "maximumCacheSize": data.maximumCacheSize,
            "allowDelete": data.allowDelete,
            "rotationalCache": data.rotationalCache,
            "keepFileAfterUpload": data.keepFileAfterUpload,
            "driveType": data.driveType,
            "syncType": data.syncType,
            "permissionType": data.permissionType,
            "sharedDrive": data.sharedDrive,
            "cloudType": data.cloudType,
            "cloudIconType": data.cloudIconType,
            "transferOptions": {
                "chunkSize": data.transferOptions.chunkSize,
                "retryCount": data.transferOptions.retryCount,
                "concurrentChunks": data.transferOptions.concurrentChunks,
                "concurrentFiles": data.transferOptions.concurrentFiles
            },
            "account": data.account
        }

        updateData('/Management/clouddrive/' + data.id, dataToPost)
            .then((res) => Promise.all([res.status, res.json()]))
            .then(([status, response]) => {
                if (status === 401) {
                    logout()
                    return;
                }
                if (status === 500) {
                    setPopup500(true);
                    setLoading(false);
                    return;
                }
                if (status === 499) {
                    var errorType = response.type;
                    setError499(response.errors[errorType][0])
                    setPopup499(true)
                    return;
                }
                if (status === 200) {
                    if (updateCData) {
                        let newArr = [...cloudDrives];
                        dataToPost.cloudType = data.cloudType;
                        dataToPost.cloudIconType = data.cloudIconType;

                        for (var i = 0; i < newArr.length; i++) {
                            if (newArr[i].id === dataToPost.id) {
                                newArr[i].active = e;
                            }
                        }
                        setCloudDrives(newArr);

                        api.open({
                            message: msg,
                            icon: <img src={toastIcon} alt="toastIcon"/>,
                            duration: 5
                        });
                        setIsModalOpen(false);
                        setModalData({})
                    }
                }
            });
    }

    function deleteCloudDrive(id, type, name, sharedDriveId) {
        setLoadingDel(true)
        if (user.userType !== 32 && user.userType !== 64) {
            deleteDataWeb('/api/sharedclouddrive/delete?id=' + sharedDriveId)
                .then((res) => Promise.all([res.status, res.text()]))
                .then(([status, data]) => {
                    if (status === 401) {
                        logout()
                        return;
                    }
                    if (status === 500) {
                        setPopup500(true)
                        return;
                    }
                    if (status === 499) {
                        var response = JSON.parse(data)
                        var errorType = response.type;
                        setError499(response.errors[errorType][0])
                        setPopup499(true)
                        return;
                    }
                    if (status === 200) {
                        deleteCloudDriveFromAgent(id, type, name)
                    }
                });
        } else {
            deleteCloudDriveFromAgent(id, type, name)
        }
        sendDataToMixPanel('Drive Deleted.', user.username, {
            'App': 'Web App',
            'Drive Name': name
        })
    }

    function deleteCloudDriveFromAgent(id, type, name) {
        deleteData('/Management/clouddrive/' + id)
            .then((res) => Promise.all([res.status, res.text()]))
            .then(([status, data]) => {
                if (status === 401) {
                    logout()
                    return;
                }
                if (status === 500) {
                    setPopup500(true);
                    setLoading(false);
                    return;
                }
                if (status === 499) {
                    var response = JSON.parse(data)
                    var errorType = response.type;
                    setError499(response.errors[errorType][0])
                    setPopup499(true)
                    return;
                }
                if (status === 200) {
                    let cDrives = [...cloudDrives];
                    if (cDrives.length < 2) {
                        loadCloudDrives()
                        setDefaultActive(0)
                    }


                    setCloudDrives((current) =>
                        current.filter(
                            (item) => item.id !== id
                        )
                    );

                    setIsModalOpen(false);
                    api.open({
                        message: 'Your ' + name + ' Drive has been deleted.',
                        icon: <img src={toastIcon} alt="toastIcon"/>,
                        duration: 3
                    });

                    let newArr = [...cloudCounts];
                    for (var i = 0; i < newArr.length; i++) {
                        if (newArr[i].cloudType == type) {
                            newArr[i].count--;
                        }
                    }
                    setcloudCounts(newArr)
                }
                setCalcDrives(true)
                setLoadingDel(false)
            });
    }

    const onChangeCacheSettings = (e) => {
        setcacheSettings(e.target.value)
    };
    const onChangeCacheSettingsGBTB = (e) => {
        setcacheSettingsGBTB(e.target.value)
        if (e.target.value === 'GB') {
            setCache(15)
        } else {
            setCache(1)
        }
    };
    const chunkSizeChange = (value) => {
        setChunkSize(value)
    };
    const handleOk = () => {
        setIsModalOpen(false);
        setIsModalOpen(false);
        setEditDriveName(false);
        setUpdatedDriveName('')
    };

    const handleCancel = () => {
        setIsModalOpen(false);
        setEditDriveName(false);
        setUpdatedDriveName('')
    };
    const confirm = (e) => {
        setisChecked(true)
        setAllowDelete(true)
    };
    const cancel = (e) => {
        setisChecked(false)
    };
    const onChangeTab = (key) => {
        setSelectedTab(key);
    };
    const onEditDriveName = (key) => {
        setEditDriveName(true);
        setUpdatedDriveName(modalData.name)
    };

    function openCloudDrive(id) {
        onlyPostDataNoResponse('/Management/clouddrive_open/' + id)
            .then((res) => Promise.all([res.status, res.text()]))
            .then(([status, data]) => {
                if (status === 401) {
                    logout()
                    return;
                }
                if (status === 500) {
                    setPopup500(true)
                }
                if (status === 499) {
                    var response = JSON.parse(data)
                    var errorType = response.type;
                    setError499(response.errors[errorType][0])
                    setPopup499(true)
                    return;
                }
            });
    }

    return (

        <> {contextHolder}
            <div className={`tour-tooltip tour-show-drive-2 d-none-mobile ${showDrivePopup2 ? "active" : ""}`}>
                <div className="tour-tooltip-wrapper">
                    <a href="#" className="cross" onClick={(e) => {
                        e.preventDefault();
                        setShowDrivePopup2(false)
                        localStorage.setItem('shorNextTour', "true")
                        window.history.pushState({}, null, "/");
                    }}>
                        <img src={crossBalckIcon} alt="icon"/>
                    </a>
                    <h3>Install the App</h3>
                    <p className="p mb-0">Once you have completed installation of the Amove desktop app,
                        you will gain instant access to your Drive.</p>
                </div>
            </div>
            <div className={`tour-tooltip tour-show-drive-2 d-none-mobile ${showLaunchPopup ? "active" : ""}`}>
                <div className="tour-tooltip-wrapper">
                    <a href="#" className="cross" onClick={(e) => {
                        e.preventDefault();
                        setShowLaunchPopup(false);
                        setShowStartedBtn(false)
                        setShowInvitePopup(true)
                        setShowDrivePopup(true)
                        setDefaultActive(1);
                        setDefaultOpenCloudDrive(true)
                        localStorage.setItem('shorNextTour', "true")
                        window.history.pushState({}, null, "/");
                    }}>
                        <img src={crossBalckIcon} alt="icon"/>
                    </a>
                    <h3>Ready to Rock</h3>
                    <p>Once the app is installed, there's no looking back.
                        Click below to launch it or start inviting team members.</p>
                    <div className="btn-wrapper">
                        <a href="#" className="btn-tour" onClick={(e) => {
                            e.preventDefault();
                            sendDataToMixPanel('Launch the Amove Desktop App', user.username, {
                                'App': 'Web App'
                            })
                            checkAgent(true)
                            window.location.href = `amoveapp://open?token=${token}`
                            window.history.pushState({}, null, "/");
                            setShowStartedBtn(false)
                            setShowInvitePopup(true)
                            setShowDrivePopup(true)
                            setDefaultActive(1);
                            setDefaultOpenCloudDrive(true)
                            localStorage.setItem('shorNextTour', "true")
                        }}>Launch Amove</a>
                    </div>
                </div>
            </div>
            <div className="connected-accounts d-none-mobile">
                <Collapse defaultActiveKey={[defaultActive]}
                          className={`connected-accounts-body-collapse ${cloudDrives.length < 1 ? "hide-arrow-icon-panel" : ""}`}
                          activeKey={defaultActive} onChange={onChange}>
                    <Panel header={cloudAccount} key="1">
                        {cloudDrives.length > 0 && (
                            <div className="connected-accounts-body">
                                <div className="table-header">
                                    <div className="row">
                                        <div className="col-2 ">
                                            <p className="open-drive-btn-title">Drive</p>
                                        </div>
                                        <div className="col-2 text-center">
                                            <p>Cloud Account</p>
                                        </div>
                                        <div className="col-2 text-center">
                                            Bucket
                                        </div>
                                        <div className="col-1 text-center">
                                            Prefix
                                        </div>
                                        <div className="col-2 text-center">
                                            {user.userType === 16 &&
                                                <>
                                                    Assign Users
                                                </>
                                            }
                                        </div>
                                        <div className="col-1 text-center">
                                            Settings
                                        </div>
                                        <div className="col-1 text-center">
                                            Re-Sync
                                        </div>
                                        <div className="col-1 text-end last-item-padding">
                                            Off/On
                                        </div>
                                    </div>
                                </div>
                                <div className="table-body">
                                    <ul>
                                        {cloudDrives.map(item => (
                                            <div className="table-body-card" key={item.id}>
                                                <div className="row">
                                                    <div
                                                        className={`col-2 align-self-center  ${item.disabled || !agentIsRunning ? "disabled" : ""}`}>
                                                        <a href="#" onClick={(e) => {
                                                            e.preventDefault();
                                                            if (!limitExceed) {
                                                                openCloudDrive(item.id)
                                                            }
                                                        }}
                                                           className={`btn-style invert open-drive-btn smaller ellipses ${item.notassigned !== undefined ? "not-clickable" : ""}`}
                                                           title={item.name.length > 10 ? item.name : ""}>
                                                            {item.name}

                                                        </a>
                                                        {cloudDrives.length === 1 &&
                                                            <div className={`tour-tooltip tour-show-drive ${showDrivePopup && agentIsRunning ? "active" : ""}`}>
                                                                <div className="tour-tooltip-wrapper">
                                                                    <a href="#" className="cross" onClick={(e) => {
                                                                        e.preventDefault();
                                                                        setShowDrivePopup(false)
                                                                        localStorage.setItem('shorNextTour', "true")
                                                                        window.history.pushState({}, null, "/");
                                                                    }}>
                                                                        <img src={crossBalckIcon} alt="icon"/>
                                                                    </a>
                                                                    <h3>Access Drives</h3>
                                                                    <p className="p mb-0">Now that your drive is ready, you
                                                                        can access
                                                                        unlimited content in the cloud from your
                                                                        desktop.</p>
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>
                                                    <div className="col-2 align-self-center text-center">
                                                        <div className="account-detail centered">
                                                            <div className="img-wrapper">
                                                                {getIcon(item.cloudType, item.cloudIconType)}
                                                            </div>
                                                            <div className="content-wrapper">
                                                                <p className="medium">{item.account.name}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-2 align-self-center text-center">
                                                        <div className="no-wrappe ellipses"
                                                             title={item.storageName.length > 30 ? item.storageName : ""}>
                                                            {item.storageName}
                                                        </div>
                                                    </div>
                                                    <div className="col-1 align-self-center text-center">
                                                        <div className="no-wrappe ellipses">
                                                            {item.prefix}
                                                        </div>
                                                    </div>
                                                    <div className={`col-2 align-self-center text-center`}>
                                                        {user.userType === 16 &&

                                                            <p className="medium assign-btn-wrapper"><a
                                                                className={`btn-style small`} href="#" onClick={(e) => {
                                                                e.preventDefault();

                                                                getUsersForAssign(item.sharedDriveId === undefined ? item.id : item.sharedDriveId)

                                                                setSelectedDriveToInviteUser(item);
                                                                setEditUser(true)

                                                            }}>Assign</a>
                                                            </p>
                                                        }
                                                    </div>
                                                    <div
                                                        className={`col-1 align-self-center text-center ${item.disabled || !agentIsRunning ? "disabled" : ""}`}>
                                                        <a href="#" className="settings-icon" onClick={(e) => {
                                                            if (!limitExceed) {
                                                                showModal(e, item)
                                                            }
                                                        }}>
                                                            <img src={settings} alt="Settings"/>
                                                        </a>
                                                    </div>
                                                    <div
                                                        className={`col-1 align-self-center text-center ${item.disabled || !agentIsRunning ? "disabled" : ""}`}>
                                                        {item.notassigned === undefined &&
                                                            <div className="refresh-bucket">
                                                                <Tooltip placement="topLeft"
                                                                         title='Refresh your drive to assure all content is instantly accessible.'
                                                                         getPopupContainer={trigger => trigger.parentNode}>
                                                                    <img src={syncIcon} alt="icon" onClick={(e) => {
                                                                        if (!limitExceed) {
                                                                            reload(item)
                                                                        }
                                                                    }}/>
                                                                </Tooltip>
                                                            </div>
                                                        }
                                                    </div>
                                                    <div
                                                        className={`col-1 align-self-center action-wrapper ${item.disabled || !agentIsRunning ? "disabled" : ""}`}>
                                                        {item.notassigned === undefined &&
                                                            <ul className="icons">
                                                                <li>
                                                                    {item.active &&
                                                                        <Popconfirm className="switch-confirm"
                                                                                    placement="topLeft"
                                                                                    title="Are you sure you want to deactivate this drive?"
                                                                                    okText="Yes"
                                                                                    cancelText="No"
                                                                                    getPopupContainer={trigger => trigger.parentNode}
                                                                                    onConfirm={() => {
                                                                                        if (!limitExceed) {
                                                                                            actionSettingUpdate(false, item)
                                                                                        }
                                                                                    }}
                                                                        >
                                                                            <Switch checked={item.active}/>
                                                                        </Popconfirm>
                                                                    }
                                                                    {!item.active &&
                                                                        <Switch onChange={(e) => {
                                                                            if (!limitExceed) {
                                                                                actionSettingUpdate(e, item)
                                                                            }
                                                                        }} defaultChecked={item.active}/>
                                                                    }
                                                                </li>
                                                            </ul>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        )}
                    </Panel>
                </Collapse>
            </div>
            <Modal title="Basic Modal" centered className={`cloud-drive-modal d-none-mobile ${!isWindows ? "mac-os" : ""}`}
                   open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                <div className="cloud-drive-modal-wrapper">
                    <h3>{modalData.name} | Drive Settings</h3>
                    <Tabs type="card" onChange={onChangeTab} activeKey={selectedTab}>
                        <TabPane tab="General" key="1" className={modalData.notassigned ? "not-assigned" : ""}>
                            <div className="cloud-account-popup-detail">
                                <ul>
                                    <li>
                                        <strong>Cloud Account:</strong>
                                        {modalData.account !== undefined &&
                                            <p>{modalData.account.name}</p>
                                        }
                                    </li>
                                    <li>
                                        <strong>Drive:</strong>
                                        {(editDriveName === false) &&
                                            <div className="flex-gap">
                                                <p>{modalData.name}</p>
                                                {!modalData.notassigned && !modalData.sharedDrive &&
                                                    <div className="edit-drive-name" onClick={onEditDriveName}>
                                                        <img src={editIcon} alt="icon"/>
                                                    </div>
                                                }
                                            </div>
                                        }
                                        {(editDriveName === true) &&
                                            <div className="form edit-cloud-drive-name">
                                                <div className="form-group">
                                                    <Input
                                                        className="form-control"
                                                        value={updatedDriveName}
                                                        onChange={onChangeDriveName}/>
                                                </div>
                                                {showCloudDriveNameError &&
                                                    <span className="red-error cloud-name">
                                                                That drive name has already been used.
                                                            </span>
                                                }

                                                {showCloudDriveNameLengthError &&
                                                    <span className="red-error cloud-name length-error">
                                                                32 characters max allowed
                                                            </span>}
                                            </div>
                                        }
                                    </li>
                                    <li>
                                        <strong>Bucket Name:</strong>
                                        <p>{modalData.storageName}</p>
                                    </li>

                                    {!modalData.notassigned &&
                                        <>
                                            <li className='path-text'>
                                                <strong>Local Cache Folder:</strong>
                                                <p title={modalData.permanentLocalPath}>{modalData.permanentLocalPath}</p>
                                               {/* {agentIsRunning &&
                                                    <a href="#" onClick={(e) => {
                                                        e.preventDefault()
                                                        setChooseFolder(true)
                                                    }} className="change-btn">Change</a>
                                                }*/}
                                            </li>
                                        </>
                                    }
                                    {modalData.prefix !== "" && modalData.prefix !== null &&
                                        <>
                                            <li className='path-text'>
                                                <strong>Prefix:</strong>
                                                <p title={modalData.prefix}>{modalData.prefix}</p>
                                            </li>
                                        </>
                                    }
                                    {modalData.notassigned && isWindows &&
                                        <>
                                            <li className='path-text'>
                                                <strong>Number of files per folder:</strong>
                                                <p>{Objects}</p>
                                            </li>
                                            <li className='path-text'>
                                                <strong>File deletion in cloud account:</strong>
                                                <p>{allowDelete ? "Enabled" : "Disabled"}</p>
                                            </li>
                                            <li className='path-text'>
                                                <strong>Encryption:</strong>
                                                <p className="switch-wrapper info-wrapper">
                                                    {encryption ? "Enabled" : "Disabled"}
                                                </p>
                                            </li>
                                        </>
                                    }
                                </ul>
                            </div>
                        </TabPane>

                        {!modalData.notassigned &&
                            <TabPane tab="Control" key="2">
                                <div className="form form-spacing-fixed">
                                    <div className="row">
                                        <div className="col-md-7 align-self-center">
                                            <div className="form-group info-wrapper stream-file-popup-wrapper">
                                                <label>Drive Type
                                                    <Tooltip
                                                        getPopupContainer={trigger => trigger.parentNode}
                                                        placement="topLeft" title={<div className="stream-detail-text">
                                                        <span>Stream files</span>
                                                        <ul>
                                                            <li><p>Store all files in the cloud</p></li>
                                                            <li><p>Access files from the Amove Drive on your desktop</p>
                                                            </li>
                                                            <li><p>Choose files and folders to make available
                                                                offline</p></li>
                                                        </ul>
                                                        <span>Mirror files</span>
                                                        <ul>
                                                            <li><p>Access files in the cloud and from the desktop</p>
                                                            </li>
                                                            <li><p>All files are available offline</p></li>
                                                            <li><p>See thumbnails of your files from the Amove Drive</p>
                                                            </li>
                                                        </ul>
                                                    </div>}
                                                    >
                                                        <img src={info} alt="Info"/>
                                                    </Tooltip>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-5 align-self-center">
                                            <div
                                                className={`form-group cache-radio-wrapper radio-btn radio-info-wrapper cloud-drive-type-wrapper ${modalData?.account?.internalStorage && modalData?.account?.storageTier === 4 ? "disabled" : ""}`}>
                                                <Radio.Group onChange={onChangeCacheSettings} value={cacheSettings}>
                                                    <Radio value="stream">
                                                        <Tooltip placement="top"
                                                                 title="Stream Cloud files to a virtual drive on your computer.">
                                                            Stream
                                                        </Tooltip>
                                                    </Radio>
                                                    <Radio value="mirror">
                                                        <Tooltip placement="top"
                                                                 title="Mirror automatically downloads from your cloud bucket to this Amove drive on your computer.">
                                                            Mirror
                                                        </Tooltip>
                                                    </Radio>
                                                </Radio.Group>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-7 align-self-center">
                                            <div className="form-group info-wrapper">
                                                <label htmlFor="threads">Cloud to Local Sync
                                                    <Tooltip
                                                        getPopupContainer={trigger => trigger.parentNode}
                                                        placement="topLeft"
                                                        title="If there are any changes to a file in the cloud bucket (new version) or if there's a new file, it will sync to the drive in the saved intervals. Learn more.">
                                                        <img src={info} alt="Info"/>
                                                    </Tooltip></label>
                                            </div>
                                        </div>
                                        <div className="col-md-5 align-self-center">
                                            <div className="form-group">
                                                <Select
                                                    defaultValue={syncInterval}
                                                    value={syncInterval}
                                                    onChange={handleChange}
                                                    style={{width: 90}}
                                                    options={[
                                                        {
                                                            value: '1',
                                                            label: '1 hour',
                                                        },
                                                        {
                                                            value: '3',
                                                            label: '3 hour',
                                                        },
                                                        {
                                                            value: '4',
                                                            label: '4 hour',
                                                        },
                                                        {
                                                            value: '5',
                                                            label: '5 hour',
                                                        },
                                                        {
                                                            value: '6',
                                                            label: '6 hour',
                                                        },
                                                        {
                                                            value: '12',
                                                            label: '12 hour',
                                                        },
                                                        {
                                                            value: '18',
                                                            label: '18 hour',
                                                        },
                                                        {
                                                            value: '24',
                                                            label: '24 hour',
                                                        }
                                                    ]}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-7 align-self-center">
                                            <div className="form-group">
                                                <label htmlFor="cacheSize">Cache Size</label>
                                            </div>
                                        </div>
                                        <div className="col-md-5 align-self-center">
                                            <div className="form-group slider">
                                                <div className="slider-wrapper">
                                                    <InputNumber
                                                        className="form-control"
                                                        min={cacheSettingsGBTB === 'GB' ? 15 : 1}
                                                        style={{width: 90}}
                                                        value={cache}
                                                        onChange={onChangeCache}/>
                                                </div>
                                                <div className="form-group no-margin-b">
                                                    <Radio.Group onChange={onChangeCacheSettingsGBTB}
                                                                 value={cacheSettingsGBTB}>
                                                        <Radio value="GB">
                                                            GB
                                                        </Radio>
                                                        <Radio value="TB">
                                                            TB
                                                        </Radio>
                                                    </Radio.Group>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={`row ${modalData.sharedDrive ? "disabled" : ""}`}>
                                        <div className="col-md-7 align-self-center">
                                            <div className="form-group">
                                                <label htmlFor="filesPerFolder">Number of files per folder</label>
                                            </div>
                                        </div>
                                        <div className="col-md-5 align-self-center">
                                            <div className="form-group slider">
                                                <div className="slider-wrapper">
                                                    <InputNumber
                                                        className="form-control"
                                                        min={5000}
                                                        max={100000}
                                                        style={{width: 90}}
                                                        value={Objects}
                                                        onChange={onChangeObjects}/>
                                                </div>
                                                <strong>Max: 100K</strong>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={`row`}>
                                        <div className="col-md-7 align-self-center">
                                            <div className="form-group  info-wrapper">
                                                <label htmlFor="deletion"><span
                                                    className={modalData.sharedDrive ? "disabled" : ""}>Enable file deletion in cloud account</span>
                                                    <Tooltip
                                                        getPopupContainer={trigger => trigger.parentNode}
                                                        placement="topLeft"
                                                        title="Enabling file deletion cannot be changed after the Drive has been set up. You can set up a new Drive at anytime.">
                                                        <img src={info} alt="Info"/>
                                                    </Tooltip>
                                                </label>
                                            </div>
                                        </div>
                                        <div
                                            className={`col-md-5 align-self-center ${modalData.sharedDrive ? "disabled" : ""}`}>
                                            <div className="form-group switch-btn allow-delete-wrapper">
                                                <div className="switch-wrapper">
                                                    {!allowDelete &&
                                                        <Popconfirm className="switch-confirm"
                                                                    placement="topLeft"
                                                                    title={<div>IMPORTANT: If you enable File Deletion
                                                                        for <br/> this local drive,
                                                                        any objects deleted <br/> will also be deleted
                                                                        in
                                                                        your corresponding cloud bucket. <br/>
                                                                        As agreed in the Amove Terms.</div>}
                                                                    okText="Yes"
                                                                    cancelText="No"
                                                                    getPopupContainer={trigger => trigger.parentNode}
                                                                    onConfirm={confirm}
                                                                    onCancel={cancel}
                                                        >
                                                            <Switch checked={allowDelete}/>
                                                        </Popconfirm>
                                                    }
                                                    {allowDelete &&
                                                        <Switch checked={allowDelete} onChange={onChangeAllowDelete}/>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={`row`}>
                                        <div className="col-md-7">
                                            <div className="form-group">
                                                <label htmlFor="deletion">Rotational Cache</label>
                                            </div>
                                        </div>
                                        <div className="col-md-5 align-self-center">
                                            <div className="form-group switch-btn allow-delete-wrapper">
                                                <div className="switch-wrapper">
                                                    <Tooltip placement="topLeft"
                                                             title={`${rotationalCache ? "Disable" : "Enable"}, Rotational Cache.`}
                                                             getPopupContainer={trigger => trigger.parentNode}>
                                                        <Switch checked={rotationalCache}
                                                                onChange={(e) => {
                                                                    setRotationalCache(e)
                                                                }}/>
                                                    </Tooltip>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={`row`}>
                                        <div className="col-md-7">
                                            <div className="form-group">
                                                <label htmlFor="deletion">Keep file in local cache after upload</label>
                                            </div>
                                        </div>
                                        <div className="col-md-5 align-self-center">
                                            <div className="form-group switch-btn allow-delete-wrapper">
                                                <div className="switch-wrapper">
                                                    <Switch checked={keepFileAfterUpload}
                                                            onChange={(e) => {
                                                                setKeepFileAfterUploade(e)
                                                            }}/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={`row`}>
                                        <div className="col-md-7">
                                            <div className="form-group">
                                                <label htmlFor="deletion">Encryption</label>
                                            </div>
                                        </div>
                                        <div className="col-md-5 align-self-center">
                                            <div className="form-group switch-btn allow-delete-wrapper">
                                                <div className="switch-wrapper info-wrapper">
                                                    {encryption ? "Enabled" : "Disabled"}
                                                    <Tooltip
                                                        getPopupContainer={trigger => trigger.parentNode}
                                                        placement="topLeft"
                                                        title={<div>Encryption setting can't be <br/> changed after the
                                                            Drive is set up.</div>}>
                                                        <img src={info} alt="Info"/>
                                                    </Tooltip>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </TabPane>
                        }

                        {!modalData.notassigned &&
                            <TabPane tab="Speed" key="3">
                                <div className="form form-spacing-fixed">
                                    <div className="row">
                                        <div className="col-md-7 align-self-center">
                                            <div className="form-group info-wrapper">
                                                <label htmlFor="network">Network</label>
                                            </div>
                                        </div>
                                        <div className="col-md-5 align-self-center">
                                            <div className="form-group">
                                                <Select
                                                    defaultValue={speedNetwork}
                                                    value={speedNetwork}
                                                    onChange={onChangeNetwork}
                                                    style={{width: 120}}
                                                    options={[
                                                        {
                                                            value: '1',
                                                            label: 'Home/Office',
                                                        },
                                                        {
                                                            value: '2',
                                                            label: 'Mobile',
                                                        },
                                                        {
                                                            value: '3',
                                                            label: 'Public Wifi',
                                                        },
                                                        {
                                                            value: '4',
                                                            label: 'Advance',
                                                        },
                                                    ]}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-7 align-self-center">
                                            <div className="form-group info-wrapper">
                                                <label htmlFor="threads">Number of concurrent threads
                                                    <Tooltip
                                                        getPopupContainer={trigger => trigger.parentNode}
                                                        placement="topLeft"
                                                        title="The number of objects that are transferred in parallel across the network simultaneously.">
                                                        <img src={info} alt="Info"/>
                                                    </Tooltip></label>
                                            </div>
                                        </div>
                                        <div className="col-md-5 align-self-center">
                                            <div className={`form-group slider concurrent-threads-slider ${inputsDisabled ? '':'disabled'}`}>
                                                <div className="slider-wrapper">
                                                    <InputNumber
                                                        className="form-control"
                                                        min={1}
                                                        max={10}
                                                        style={{width: 90}}
                                                        value={Threads}
                                                        onChange={onChangeThreads}/>
                                                </div>
                                                <strong>Max: 10</strong>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-7 align-self-center">
                                            <div className="form-group info-wrapper">
                                                <label htmlFor="threads">Number of chunks per object
                                                    <Tooltip
                                                        getPopupContainer={(trigger) => trigger.parentNode}
                                                        placement="topLeft"
                                                        title="The number of parts divided for each object that are transferred in parallel across the network.">
                                                        <img src={info} alt="Info"/>
                                                    </Tooltip>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-5 align-self-center">
                                            <div className={`form-group slider concurrent-threads-slider ${inputsDisabled ? '':'disabled'}`}>
                                                <div className="slider-wrapper">
                                                    <InputNumber
                                                        className="form-control"
                                                        min={1}
                                                        max={modalData.cloudIconType === 'storj' ? 20 : 5}
                                                        style={{width: 90}}
                                                        value={chunkObjects}
                                                        onChange={onChangeChunkObjects}/>
                                                </div>
                                                <strong>Max: {modalData.cloudIconType === 'storj' && 20}{modalData.cloudIconType !== 'storj' && 5}</strong>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-7 align-self-center">
                                            <div className="form-group info-wrapper">
                                                <label htmlFor="chunkSize">
                                                    Chunk Size
                                                </label>
                                                <Tooltip
                                                    getPopupContainer={(trigger) => trigger.parentNode}
                                                    placement="topLeft"
                                                    title={<div>For most providers, chunk size <br/> is regulated to a
                                                        minimum of <br/> 5MB up to 4GB.</div>}>
                                                    <img src={info} alt="Info"/>
                                                </Tooltip>
                                            </div>
                                        </div>
                                        <div className="col-md-5 align-self-center">

                                            <div className={`form-group slider ${inputsDisabled ? '':'disabled'}`}>
                                                <div className="slider-wrapper">
                                                    <div
                                                        className={`form-group no-margin-b ${chunkSize === '' ? "hide-input-arrows" : ""}`}>
                                                        {modalData.cloudIconType !== 'storj' &&
                                                            <InputNumber placeholder="5MB to 4GB"
                                                                         style={{width: 90}} className="form-control"
                                                                         min={GBMB === 'GB' ? 1 : 5}
                                                                         max={GBMB === 'GB' ? 4 : 4096}
                                                                         value={chunkSize}
                                                                         onChange={chunkSizeChange}/>

                                                        }
                                                        {modalData.cloudIconType === 'storj' &&
                                                            <Select
                                                                defaultValue="64"
                                                                style={{width: 90}}
                                                                onChange={chunkSizeChange}
                                                                value={chunkSize}
                                                                options={[
                                                                    {
                                                                        value: '64',
                                                                        label: '64',
                                                                    },
                                                                    {
                                                                        value: '128',
                                                                        label: '128',
                                                                    },
                                                                    {
                                                                        value: '192',
                                                                        label: '192',
                                                                    },
                                                                    {
                                                                        value: '256',
                                                                        label: '256'
                                                                    },
                                                                    {
                                                                        value: '320',
                                                                        label: '320'
                                                                    },
                                                                    {
                                                                        value: '384',
                                                                        label: '384'
                                                                    },
                                                                    {
                                                                        value: '448',
                                                                        label: '448'
                                                                    },
                                                                    {
                                                                        value: '512',
                                                                        label: '512'
                                                                    },
                                                                    {
                                                                        value: '576',
                                                                        label: '576'
                                                                    },
                                                                    {
                                                                        value: '640',
                                                                        label: '640'
                                                                    },
                                                                    {
                                                                        value: '704',
                                                                        label: '704'
                                                                    },
                                                                    {
                                                                        value: '768',
                                                                        label: '768'
                                                                    },
                                                                    {
                                                                        value: '832',
                                                                        label: '832'
                                                                    },
                                                                    {
                                                                        value: '896',
                                                                        label: '896'
                                                                    },
                                                                    {
                                                                        value: '960',
                                                                        label: '960'
                                                                    }
                                                                ]}
                                                            />
                                                        }
                                                    </div>
                                                </div>
                                                <div className="form-group no-margin-b">
                                                    <Radio.Group onChange={changeGBMB} value={GBMB}>
                                                        <Radio value="MB">
                                                            MB
                                                        </Radio>
                                                        {modalData.cloudIconType !== 'storj' &&
                                                            <Radio value="GB">
                                                                GB
                                                            </Radio>
                                                        }
                                                    </Radio.Group>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </TabPane>
                        }
                    </Tabs>
                    <div className="form form-footer">
                        <div className="row">
                            <div className="col-md-7 align-self-end delete-wrapper">
                                <div className="form-group submit text-start">
                                    {(selectedTab == 2 || modalData.notassigned) &&
                                        <>

                                            <Popconfirm
                                                placement="topLeft"
                                                title="Are you sure you want to delete this drive?"
                                                okText="Yes"
                                                cancelText="No"
                                                getPopupContainer={trigger => trigger.parentNode}
                                                onConfirm={() => deleteCloudDrive(modalData.id, modalData.cloudIconType, modalData.name, modalData.sharedDriveId === undefined ? modalData.id : modalData.sharedDriveId)}
                                            >
                                                <Button loading={loadingDel} className="btn-style small-btn del-btn">Delete
                                                    Drive</Button>
                                            </Popconfirm>
                                            <label>
                                                {/*{modalData.name} Drive*/}
                                            </label>
                                        </>
                                    }
                                </div>
                            </div>
                            <div className="col-md-5">
                                {!modalData.notassigned &&
                                    <div className="form-group submit">
                                        <Button className="btn-style small-btn cancel-btn btn-30"
                                                onClick={handleCancel}>Cancel</Button>
                                        <Button loading={loading}
                                                className={`btn-style small-btn btn-30 ${showCloudDriveNameError || showCloudDriveNameLengthError ? "disabled" : ""}`}
                                                onClick={() => {
                                                    saveSettings('/Management/clouddrive/')
                                                }}>Save</Button>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>


            <Modal title="Assign Users" className="manual-user-type d-none-mobile" centered open={isEditUser} onOk={manageUserCancel}
                   onCancel={manageUserCancel}>
                <div className="manual-user-modal-wrapper">
                    <div className="header">
                        <h3>Assign Users to {selectedDriveToInviteUser.name} Drive</h3>
                    </div>
                    <div className="body">
                        <div className="search-wrapper">
                            <div className="form">
                                <div className="form-group">
                                    <p className="label">Users</p>
                                    <div className="add-share">
                                        <Select
                                            showSearch
                                            placeholder="Select a user"
                                            optionFilterProp="children"
                                            onChange={handleChangeUser}
                                            value={selectedUsers}
                                            options={usersData.filter((item) => {
                                                if (usersList.filter((a) => a.user.id === item.id).length < 1 && newUsersList.filter((a) => a.userId === item.id).length < 1) {
                                                    return item
                                                }
                                            }).map((item) => ({
                                                label: item.email, value: item.id,
                                            }))}
                                        />
                                    </div>
                                    {(usersList.length > 0 || newUsersList.length > 0) && <div className="added-users">
                                        {usersList.length > 0 && usersList.map((user, i) => (
                                            <div className="added-users-card">
                                                <div className="row">
                                                    <div className="col-md-6 align-self-center">
                                                        <div className="tag-wrapper">
                                                               <span className="tag">{user.user.email} <a href="#"
                                                                                                          onClick={(e) => {
                                                                                                              e.preventDefault();
                                                                                                              removeUserFromList(i, user.permission.id)
                                                                                                          }}><img
                                                                   src={crossIcon} alt="icon"/></a></span>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 align-self-center">
                                                        <div className="read-write-radio">
                                                            <Radio.Group onChange={(e) => {
                                                                onChangePermissionUsers(e, user.user.id)
                                                            }} value={user.permission.permissionType}>
                                                                <Radio value={1}>
                                                                    Read
                                                                </Radio>
                                                                <Radio value={2}>
                                                                    Read, Write
                                                                </Radio>
                                                            </Radio.Group>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>))}
                                        {newUsersList.length > 0 && newUsersList.map((user, i) => (
                                            <div className="added-users-card">
                                                <div className="row">
                                                    <div className="col-md-6 align-self-center">
                                                        <div className="tag-wrapper">
                                                                <span className="tag">{user.name}<a href="#"
                                                                                                    onClick={(e) => {
                                                                                                        e.preventDefault();
                                                                                                        removeUserFromList(i, user.userId, true)
                                                                                                    }}><img
                                                                    src={crossIcon} alt="icon"/></a></span>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 align-self-center">
                                                        <div className="read-write-radio">
                                                            <Radio.Group onChange={(e) => {
                                                                onChangePermissionUsers(e, user.userId, true)
                                                            }} value={user.permissionType}>
                                                                <Radio value={1}>
                                                                    Read
                                                                </Radio>
                                                                <Radio value={2}>
                                                                    Read, Write
                                                                </Radio>
                                                            </Radio.Group>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>))}
                                    </div>}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="footer">
                        <a href="#"
                           className={`btn-style left-btn btn-small oright-btn btn-30`} onClick={(e) => {
                            e.preventDefault();
                            setOpenInviteUserPopup(true)
                            navigate("/users");
                        }}>
                            <span>Invite people</span></a>
                        <a href="#" className="btn-style btn-small transparent btn-30" onClick={manageUserCancel}>Cancel</a>
                        <Button
                            className={`btn-style btn-small blue btn-30 ${newUsersList.length > 0 || usersList.length > 0 ? "" : "disabled"}`}
                            loading={loading} onClick={AssignUserToDrive}>Assign</Button>
                    </div>
                </div>
            </Modal>
            {chooseFolder &&
                <ChooseFolder open={chooseFolder} handleCancel={() => {
                    setChooseFolder(false)
                }} handleOk={(value) => {
                    saveSettings('/Management/clouddrive_change_cache/', value)
                    setChooseFolder(false)
                }} title={"Choose your cache Folder"}/>
            }

        </>
    )
}

export {CloudDrives}
