import React, {useEffect, useRef, useState} from "react";
import {Button, Modal, notification, Pagination, Radio, Select, Tooltip} from 'antd';
import plusIcon from '../assets/images/icons/add-user-plus-icon.svg'
import crossIcon from '../assets/images/icons/cross-black-icon.svg'
import searchIcon from '../assets/images/icons/search-gray-icon.svg'
import verticleDotsIcon from '../assets/images/icons/verticle-dots-black-icon.svg'
import downArrowIcon from '../assets/images/icons/down-arrow-icon.svg'
import config from "../config";
import toastIcon from "../assets/images/icons/toast-icon.svg";
import {useAuth} from "../context/AuthContext";
import {ConfigurationsMenu} from "../components/ConfigurationsMenu";
import {DeleteModal} from "../components/DeleteModal";
import {useNavigate} from "react-router-dom";
import {BillingPopup} from "../components/BillingPopup";

const Project = () => {
    const {
        token,
        getDataWithToken,
        updateDataWithToken,
        logout,
        user,
        userStatus,
        setBillingPopupOpen,
        reloadProjects,
        setReloadProjects,
        sendDataToWebsocket,
        setPopup500,
        deleteDataWeb,
        sendDataToMixPanel,
        setError499,
        setPopup499
    } = useAuth();
    const dataFetchedRef = useRef(false);
    const navigate = useNavigate();
    const [api, contextHolder] = notification.useNotification();
    const [addUser, setAddUser] = useState(false);
    const [sharePath, setSharePath] = useState(false);
    const [addGroup, setAddGroup] = useState(false);
    const [projectName, setProjectName] = useState("");
    const [projectDescription, setProjectDescription] = useState("");
    const [selectedProject, setSelectedProject] = useState("");
    const [loading, setLoading] = useState(false);
    const [projectsData, setProjectsData] = useState([]);

    const [isSelectUser, setSelectUser] = useState(false);
    const [isOktaUser, setOktaUser] = useState(false);
    const [isManualUser, setManualUser] = useState(false);
    const [isAssignShare, setAssignShare] = useState(false);
    const [isAssignGroup, setAssignGroup] = useState(false);
    const [isAssignDrive, setAssignDrive] = useState(false);
    const [isAssignDelete, setAssignDelete] = useState(false);
    const [isEditUser, showEditUser] = useState(false);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [selectedGroup, setSelectedGroup] = useState([]);
    const [selectedDrive, setSelectedDrive] = useState([]);
    const [usersData, setUsersData] = useState([]);
    const [groupsData, setGroupsData] = useState([]);
    const [drivesData, setDrivesData] = useState([]);


    const [addEmail, setAddEmail] = useState(false);
    const [isModalOpenEdit, setIsModalOpenEdit] = useState(false);
    const [addEmailEdit, setAddEmailEdit] = useState(false);


    const [searchValForSearch, setSearchValForSearch] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [descending, setDescending] = useState(false);
    const [searchVal, setSearchVal] = useState("");
    const [totalResult, setTotalResult] = useState("");
    const [perPage, setPerPage] = useState(20);

    const [value, setValue] = useState(1);
    const [newUsersList, setNewUsersList] = useState([]);
    const [usersList, setUsersList] = useState([]);
    const [newDrivesList, setNewDrivesList] = useState([]);
    const [drivesList, setDrivesList] = useState([]);
    const [newDrivePermissionValue, setNewDrivePermissionValue] = useState(2);
    const [newUserPermissionValue, setNewUserPermissionValue] = useState(2);
    const [newGroupList, setNewGroupList] = useState([]);
    const [groupList, setGroupList] = useState([]);
    const [newGroupPermissionValue, setNewGroupPermissionValue] = useState(2);
    const [dataLoaded, setDataLoaded] = useState(false);


    useEffect(() => {
        if (userStatus.status === "trial" || userStatus.status === "active") {
            if (reloadProjects) {
                setReloadProjects(false)
                getAllProjects()
            }
        }
    }, [reloadProjects]);

    var allProjectData = [];
    var assignedUsersCount = 0;


    // Manual User Modal
    const showManualUser = () => {
        setManualUser(true);
        setSelectUser(false)
    };

    const selectManualOk = () => {
        setManualUser(false);
    };

    const selectManualCancel = () => {
        setManualUser(false);
    };


    // Assign Share Modal
    const showAssignUsers = (project) => {
        setAssignShare(true);
        setSelectedProject(project);
        setUsersList(project.usersData);
    };

    // Assign Group Modal
    const showAssignGroup = (project) => {
        setAssignGroup(true);
        setSelectedProject(project);
        setGroupList(project.groupsData);
    };

    const AssignGroupCancel = () => {
        setAssignGroup(false);
        setSelectedProject("");
        setSelectedGroup("")
        setNewGroupList([])
        setGroupList([])
    };
    const AssignDriveCancel = () => {
        setAssignDrive(false);
        setSelectedProject("")
        setDrivesList([])
        setNewDrivesList([])
    };


    // Assign Projects Modal
    const showAssignDrive = (project) => {
        setAssignDrive(true);
        setSelectedProject(project);
        setDrivesList(project.drivesData);
    };


    const AssignProjectsCancel = () => {
        setAssignShare(false);
        setSelectedProject("")
        setUsersList([])
        setNewUsersList([])
    };


    // Delete User Modal
    const showAssignDelete = (project) => {
        setAssignDelete(true);
        setSelectedProject(project)
    };

    const AssignDeleteCancel = () => {
        setAssignDelete(false);
        setSelectedProject("")
    };


    // Edit User Modal
    const showEditProject = (project) => {
        setProjectName(project.name)
        setProjectDescription(project.description)
        setSelectedProject(project)
        showEditUser(true);
    };


    const EditUserCancel = () => {
        showEditUser(false);
    };


    async function postData(url = "", data = {}) {
        const response = await fetch(config.apiURLWeb + url, {
            method: "POST", headers: {
                "Content-Type": "application/json", 'Authorization': 'Bearer ' + token,
                "X-App-Origin": "WebApp"
            }, body: JSON.stringify(data),
        });

        sendDataToWebsocket(config.apiURLWeb + url, "POST")
        return response;
    }

    const createProject = () => {
        setLoading(true)
        var dataToPost = {
            "name": projectName.trim(), "description": projectDescription, "active": true, "deleted": false
        }
        postData("/api/project/insert", dataToPost)
            .then((res) => Promise.all([res.status, res.json()]))
            .then(([status, response]) => {
                if (status === 401) {
                    logout()
                    return;
                }
                if (status === 500) {
                    setPopup500(true);
                setLoading(false);
                    return;
                }
                if (status === 499) {
                    var errorType = response.type;
                    setError499(response.errors[errorType][0])
                    setPopup499(true)
                    return;
                }
                setLoading(false)
                setAddGroup(false)
                setAddUser(false)
                setSharePath(false)
                setManualUser(false)
                setProjectName("")
                setProjectDescription("")
                sendDataToMixPanel('Project has been successfully created.', user.username, {
                    'App': 'Web App',
                    'Project Name': projectName.trim()
                })

                api.open({
                    message: projectName.trim() + ' Project has been successfully created.',
                    icon: <img src={toastIcon} alt="toastIcon"/>,
                    duration: 5,
                });
                getAllProjects();
            })
    };

    const updateProject = () => {
        setLoading(true)
        var dataToPost = {
            "id": selectedProject.id,
            "name": projectName.trim(),
            "description": projectDescription,
            "active": true,
            "deleted": false
        }

        updateDataWithToken("/api/project/update", dataToPost)
            .then((res) => Promise.all([res.status, res.text()]))
            .then(([status, response]) => {
                if (status === 401) {
                    logout()
                    return;
                }
                if (status === 500) {
                    setPopup500(true);
                setLoading(false);
                    return;
                }
                if (status === 499) {
                    var data = JSON.parse(response)
                    var errorType = data.type;
                    setError499(data.errors[errorType][0])
                    setPopup499(true)
                    return;
                }

                sendDataToMixPanel('Project has been successfully updated.', user.username, {
                    'App': 'Web App',
                    'Project Name': projectName.trim()
                })

                api.open({
                    message: projectName.trim() + ' Project has been successfully updated.',
                    icon: <img src={toastIcon} alt="toastIcon"/>,
                    duration: 5,
                });
                setProjectName("")
                setProjectDescription("")
                setLoading(false)
                showEditUser(false);
                getAllProjects();
            })
    };


    const getAllProjects = () => {
        setDataLoaded(false)
        getDataWithToken(`/api/project/get_all?page=${currentPage}&pagesize=${perPage}&sortfield=Name&descending=${descending}&deleted=false&name=${searchValForSearch}`)
            .then((res) => Promise.all([res.status, res.json()]))
            .then(([status, response]) => {
                if (status === 401) {
                    logout()
                    return;
                }
                if (status === 500) {
                    setPopup500(true);
                setLoading(false);
                    return;
                }
                if (status === 499) {
                    var errorType = response.type;
                    setError499(response.errors[errorType][0])
                    setPopup499(true)
                    return;
                }
                var data = response.data;
                for (var i = 0; i < data.length; i++) {
                    data[i].usersData = [];
                    data[i].groupsData = [];
                    data[i].drivesData = [];
                }
                let newArr = [...projectsData]; // copying the old datas array
                while (newArr.length > 0) {
                    newArr.pop();
                }
                for (var i = 0; i < data.length; i++) {
                    newArr.push(data[i]); // replace e.target.value with whatever you want to change it to
                }
                setProjectsData(newArr)
                setTotalResult(response.total)
                if (data.length < 1) {
                    setDataLoaded(true)
                } else {
                    allProjectData = data;
                    getAssignedUsers(0);
                }
            })
    };

    const getAssignedUsers = (count, single = false) => {
        var id = "";
        if (single) {
            setDataLoaded(false);
            id = count;
        } else {
            id = allProjectData[count].id;
        }
        getDataWithToken(`/api/permission/get_users_assigned_to_project?projectId=${id}&page=1&pagesize=10000000&sortfield=User.Username&descending=false`)
            .then((res) => Promise.all([res.status, res.text()]))
            .then(([status, response]) => {
                if (status === 401) {
                    logout()
                    return;
                }
                if (status === 500) {
                    setPopup500(true);
                setLoading(false);
                    return;
                }
                if (status === 499) {
                    var data = JSON.parse(response)
                    var errorType = data.type;
                    setError499(data.errors[errorType][0])
                    setPopup499(true)
                    return;
                }
                var res = JSON.parse(response);

                if (single) {
                    let newArr = [...projectsData]; // copying the old datas array
                    for (var i = 0; i < newArr.length; i++) {
                        if (newArr[i].id === count) {
                            newArr[i].usersData = res.data;
                        }
                    }
                    setProjectsData(newArr)
                    setDataLoaded(true);
                } else {
                    allProjectData[count].usersData = res.data;
                    assignedUsersCount++;
                    if (assignedUsersCount < allProjectData.length) {
                        getAssignedUsers(assignedUsersCount, single);
                    } else {
                        let newArr = [...projectsData]; // copying the old datas array
                        while (newArr.length > 0) {
                            newArr.pop();
                        }
                        for (var i = 0; i < allProjectData.length; i++) {
                            newArr[i] = allProjectData[i]; // replace e.target.value with whatever you want to change it to
                        }
                        setProjectsData(newArr);
                        allProjectData = newArr;
                        assignedUsersCount = 0;
                        getAssignedGroups(0);
                    }
                }

            })
    };

    const getAssignedGroups = (count, single = false) => {
        var id = "";
        if (single) {
            setDataLoaded(false);
            id = count;
        } else {
            id = allProjectData[count].id;
        }
        getDataWithToken(`/api/permission/get_usergroups_assigned_to_project?projectId=${id}&page=1&pagesize=10000000&sortfield=UserGroup.Name&descending=false`).then((res) => Promise.all([res.status, res.text()]))
            .then(([status, response]) => {
                if (status === 401) {
                    logout()
                    return;
                }
                if (status === 500) {
                    setPopup500(true);
                setLoading(false);
                    return;
                }
                if (status === 499) {
                    var data = JSON.parse(response)
                    var errorType = data.type;
                    setError499(data.errors[errorType][0])
                    setPopup499(true)
                    return;
                }
                var res = JSON.parse(response);

                if (single) {
                    let newArr = [...projectsData]; // copying the old datas array
                    for (var i = 0; i < newArr.length; i++) {
                        if (newArr[i].id === count) {
                            newArr[i].groupsData = res.data;
                        }
                    }
                    setProjectsData(newArr)
                    setDataLoaded(true);
                } else {
                    allProjectData[count].groupsData = res.data;
                    assignedUsersCount++;
                    if (assignedUsersCount < allProjectData.length) {
                        getAssignedGroups(assignedUsersCount, single);
                    } else {
                        let newArr = [...projectsData]; // copying the old datas array
                        while (newArr.length > 0) {
                            newArr.pop();
                        }
                        for (var i = 0; i < allProjectData.length; i++) {
                            newArr[i] = allProjectData[i]; // replace e.target.value with whatever you want to change it to
                        }
                        setProjectsData(newArr);
                        allProjectData = newArr;
                        assignedUsersCount = 0;
                        getAssignedDrives(0);
                    }
                }
            })
    };
    const getAssignedDrives = (count, single = false) => {
        var id = "";
        if (single) {
            setDataLoaded(false);
            id = count;
        } else {
            id = allProjectData[count].id;
        }
        getDataWithToken(`/api/project/get_assigned_sharedclouddrives?projectId=${id}&page=1&pagesize=10000000&&sortfield=SharedCloudDrive.Name&descending=true`)
            .then((res) => Promise.all([res.status, res.text()]))
            .then(([status, response]) => {
                if (status === 401) {
                    logout()
                    return;
                }
                if (status === 500) {
                    setPopup500(true);
                setLoading(false);
                    return;
                }
                if (status === 499) {
                    var data = JSON.parse(response)
                    var errorType = data.type;
                    setError499(data.errors[errorType][0])
                    setPopup499(true)
                    return;
                }
                var res = JSON.parse(response);
                if (single) {
                    let newArr = [...projectsData]; // copying the old datas array
                    for (var i = 0; i < newArr.length; i++) {
                        if (newArr[i].id === count) {
                            newArr[i].drivesData = res.data;
                        }
                    }
                    setProjectsData(newArr)
                    setDataLoaded(true);
                } else {
                    allProjectData[count].drivesData = res.data;
                    assignedUsersCount++;
                    if (assignedUsersCount < allProjectData.length) {
                        getAssignedDrives(assignedUsersCount, single);
                    } else {
                        let newArr = [...projectsData]; // copying the old datas array
                        while (newArr.length > 0) {
                            newArr.pop();
                        }
                        for (var i = 0; i < allProjectData.length; i++) {
                            newArr[i] = allProjectData[i]; // replace e.target.value with whatever you want to change it to
                        }
                        setProjectsData(newArr);
                        allProjectData = newArr;
                        setDataLoaded(true)
                    }
                }
            })
    };

    const getAllUsers = () => {
        getDataWithToken("/api/user/get_all_users?page=1&pagesize=10000000&sortfield=CreateDate&descending=true&deleted=false")
            .then((res) => Promise.all([res.status, res.json()]))
            .then(([status, response]) => {
                if (status === 401) {
                    logout()
                    return;
                }
                if (status === 500) {
                    setPopup500(true);
                setLoading(false);
                    return;
                }
                if (status === 499) {
                    var errorType = response.type;
                    setError499(response.errors[errorType][0])
                    setPopup499(true)
                    return;
                }
                setUsersData(response.data)
            })
    };
    const getAllGroups = () => {
        getDataWithToken("/api/usergroup/get_all?page=1&pagesize=10000000&sortfield=Name&descending=true&deleted=false")
            .then((res) => Promise.all([res.status, res.json()]))
            .then(([status, response]) => {
                if (status === 401) {
                    logout()
                    return;
                }
                if (status === 500) {
                    setPopup500(true);
                setLoading(false);
                    return;
                }
                if (status === 499) {
                    var errorType = response.type;
                    setError499(response.errors[errorType][0])
                    setPopup499(true)
                    return;
                }
                setGroupsData(response.data)
            })
    };
    const getAllDrives = () => {
        if (user.userType === 16) {
            console.log("/api/sharedclouddrive/get_all")
            console.log(user)
        getDataWithToken(`/api/sharedclouddrive/get_all?page=1&pagesize=10000000&sortfield=Name&descending=true&deleted=false`)
            .then((res) => Promise.all([res.status, res.json()]))
            .then(([status, response]) => {
                if (status === 401) {
                    logout()
                    return;
                }
                if (status === 500) {
                    setPopup500(true);
                setLoading(false);
                    return;
                }
                if (status === 499) {
                    var errorType = response.type;
                    setError499(response.errors[errorType][0])
                    setPopup499(true)
                    return;
                }
                setDrivesData(response.data)
            })
        }
    };
    useEffect(() => {
        if (user.userType === 32 || user.userType === 64) {
            navigate("/dashboard");
            return;
        }
    }, [user]);
    useEffect(() => {
        if (user.userType === 16) {
            if (dataFetchedRef.current) return;
            dataFetchedRef.current = true;
            getAllProjects();
            getAllUsers();
            getAllGroups();
            getAllDrives();
        }
    }, [user]);

    function deleteProject() {
        var id = selectedProject.id;
        setLoading(true)
        deleteDataWeb('/api/project/delete?id=' + id)
            .then((res) => Promise.all([res.status, res.text()]))
            .then(([status, data]) => {
                if (status === 401) {
                    logout()
                    return;
                }
                if (status === 500) {
                    setPopup500(true);
                setLoading(false);
                    return;
                }
                if (status === 499) {
                    var dataNew = JSON.parse(data)
                    var errorType = dataNew.type;
                    setError499(dataNew.errors[errorType][0])
                    setPopup499(true)
                    return;
                }
                if (status === 200) {
                    setSelectedProject("")
                    setLoading(false)
                    setAssignDelete(false);

                    var newArray = [...projectsData];
                    var projectNameRemoved = newArray.filter((item) => item.id === id);
                    setProjectsData((current) => current.filter((item) => item.id !== id));
                    sendDataToMixPanel('Project removed successfully.', user.username, {
                        'App': 'Web App',
                        'Team Name': projectNameRemoved[0].name
                    })

                    api.open({
                        message: 'Project removed successfully.',
                        icon: <img src={toastIcon} alt="toastIcon"/>,
                        duration: 3
                    });
                }
            });
    }


    // Pagination and Search
    const onChangePage = (value) => {
        setCurrentPage(value)
        setUsersData([])
        setTimeout(function () {
            getAllProjects();
        }, 800)
    };
    const onChangeSort = () => {
        if (!loading) {
            setDescending(!descending);
            setTimeout(function () {
                getAllProjects();
            }, 800)
        }
    };

    const handleChangeUser = (value) => {
        let newArr = [...newUsersList];
        for (var i = 0; i < usersData.length; i++) {
            if (usersData[i].id === value) {
                newArr.push({userName: usersData[i].email, userId: value, permissionType: newDrivePermissionValue})
            }
        }
        setNewUsersList(newArr);
    };
    const removeUserFromList = (index, permissionId, newUser = false) => {
        if (newUser) {
            let newArr = [...newUsersList];
            newArr.splice(index, 1);
            setNewUsersList(newArr)
        } else {
            let newArr = [...usersList];
            newArr.splice(index, 1);
            setUsersList(newArr)
            deleteDataWeb('/api/permission/users_project_permission?id=' + permissionId)
                .then((res) => Promise.all([res.status, res.text()]))
                .then(([status, data]) => {
                    if (status === 401) {
                        logout()
                        return;
                    }
                    if (status === 500) {
                        setPopup500(true)
                        return;
                    }
                    if (status === 200) {
                    api.open({
                        message: 'User removed successfully.',
                        icon: <img src={toastIcon} alt="toastIcon"/>,
                        duration: 3
                    });
                    let newArrG = [...projectsData];
                    for (var i = 0; i < newArrG.length; i++) {
                        if (newArrG[i].id === selectedProject.id) {
                            newArrG[i].usersData.splice(index, 1);
                            break;
                        }
                    }
                    setProjectsData(newArrG);
                }
            });
        }

    };

    async function postDataResponseText(url = "", data = {}) {
        const response = await fetch(config.apiURLWeb + url, {
            method: "POST", headers: {
                "Content-Type": "application/json", 'Authorization': 'Bearer ' + token,
                "X-App-Origin": "WebApp"
            }, body: JSON.stringify(data),
        });
        sendDataToWebsocket(config.apiURLWeb + url, "POST")
        return response;
    }

    const AssignUserToProject = () => {
        setLoading(true)
        if (newUsersList.length > 0) {
            var dataToPost = [];
            var newArray = [...newUsersList];
            for (var i = 0; i < newArray.length; i++) {
                dataToPost.push({
                    "userId": newArray[i].userId,
                    "projectId": selectedProject.id,
                    "permissionType": newArray[i].permissionType
                })
            }
            postDataResponseText("/api/permission/users_project_permission", dataToPost)
                .then((res) => Promise.all([res.status, res.text()]))
                .then(([status, data]) => {
                    if (status === 401) {
                        logout()
                        return;
                    }
                    if (status === 500) {
                        setPopup500(true)
                        return;
                    }

                setLoading(false)
                setAssignShare(false);
                api.open({
                    message: 'User successfully assigned.', icon: <img src={toastIcon} alt="toastIcon"/>, duration: 5,
                });
                setNewUsersList([]);
                setSelectedProject("")
                if (usersList.length < 1) {
                    allProjectData = projectsData;
                    assignedUsersCount = 0;
                    getAssignedUsers(selectedProject.id, true);
                }
            })
        }
        if (usersList.length > 0) {
            dataToPost = [];
            newArray = [...usersList];
            for (var i = 0; i < newArray.length; i++) {
                dataToPost.push({
                    "id": newArray[i].permission.id,
                    "userId": newArray[i].user.id,
                    "projectId": selectedProject.id,
                    "permissionType": newArray[i].permission.permissionType
                })
            }
            setLoading(false)
            updateDataWithToken("/api/permission/users_project_permission", dataToPost)
                .then((res) => Promise.all([res.status, res.text()]))
                .then(([status, response]) => {
                    if (status === 401) {
                        logout()
                        return;
                    }
                    if (status === 500) {
                        setPopup500(true)
                        return;
                    }
                    setLoading(false)
                    setAssignShare(false);
                    api.open({
                        message: 'User successfully updated.', icon: <img src={toastIcon} alt="toastIcon"/>, duration: 5,
                    });
                    setUsersList([]);
                    setSelectedProject("");
                    allProjectData = projectsData;
                    assignedUsersCount = 0;
                    getAssignedUsers(selectedProject.id, true);
                })
        }

    };
    const onChangePermission = (e, id, newProject = false) => {
        if (newProject) {
            let newArr = [...newUsersList];
            for (var i = 0; i < newArr.length; i++) {
                if (newArr[i].userId === id) {
                    newArr[i].permissionType = e.target.value;
                }
            }
            setNewUsersList(newArr)
        } else {
            let newArr = [...usersList];
            for (var i = 0; i < newArr.length; i++) {
                if (newArr[i].user.id === id) {
                    newArr[i].permission.permissionType = e.target.value;
                }
            }
            setUsersList(newArr)
        }
    };


    const handleChangeDrive = (value) => {
        let newArr = [...newDrivesList];
        for (var i = 0; i < drivesData.length; i++) {
            if (drivesData[i].id === value) {
                newArr.push({name: drivesData[i].name, id: value})
            }
        }
        setNewDrivesList(newArr);
    };

    const removeGroupFromList = (index, permissionId, newUser = false) => {
        if (newUser) {
            let newArr = [...newGroupList];
            newArr.splice(index, 1);
            setNewGroupList(newArr)
        } else {
            let newArr = [...groupList];
            newArr.splice(index, 1);
            setGroupList(newArr);
            deleteDataWeb('/api/permission/usergroups_project_permission?id=' + permissionId)
                .then((res) => Promise.all([res.status, res.text()]))
                .then(([status, data]) => {
                    if (status === 401) {
                        logout()
                        return;
                    }
                    if (status === 500) {
                        setPopup500(true)
                        return;
                    }
                    if (status === 200) {
                    api.open({
                        message: 'Team removed successfully.',
                        icon: <img src={toastIcon} alt="toastIcon"/>,
                        duration: 3
                    });
                    let newArrG = [...projectsData];
                    for (var i = 0; i < newArrG.length; i++) {
                        if (newArrG[i].id === selectedProject.id) {
                            newArrG[i].groupsData.splice(index, 1);
                            break;
                        }
                    }
                    setProjectsData(newArrG);
                }
            });
        }

    };
    const removeDriveFromList = (index, permissionId, newUser = false) => {
        if (newUser) {
            let newArr = [...newDrivesList];
            newArr.splice(index, 1);
            setNewDrivesList(newArr)
        } else {
            let newArr = [...drivesList];
            newArr.splice(index, 1);
            setDrivesList(newArr);
            deleteDataWeb('/api/project/unassign_sharedclouddrive?id=' + permissionId)
                .then((res) => Promise.all([res.status, res.text()]))
                .then(([status, data]) => {
                    if (status === 401) {
                        logout()
                        return;
                    }
                    if (status === 500) {
                        setPopup500(true)
                        return;
                    }
                    if (status === 200) {
                    api.open({
                        message: 'Drive removed successfully.',
                        icon: <img src={toastIcon} alt="toastIcon"/>,
                        duration: 3
                    });
                    let newArrG = [...projectsData];
                    for (var i = 0; i < newArrG.length; i++) {
                        if (newArrG[i].id === selectedProject.id) {
                            newArrG[i].drivesData.splice(index, 1);
                            break;
                        }
                    }
                    setProjectsData(newArrG);
                }
            });
        }

    };
    const onChangePermissionGroup = (e, id, newProject = false) => {
        if (newProject) {
            let newArr = [...newGroupList];
            for (var i = 0; i < newArr.length; i++) {
                if (newArr[i].userGroupId === id) {
                    newArr[i].permissionType = e.target.value;
                }
            }
            setNewGroupList(newArr)
        } else {
            let newArr = [...groupList];
            for (var i = 0; i < newArr.length; i++) {
                if (newArr[i].userGroup.id === id) {
                    newArr[i].permission.permissionType = e.target.value;
                }
            }
            setGroupList(newArr)
        }
    };
    const handleChangeGroup = (value) => {
        let newArr = [...newGroupList];
        for (var i = 0; i < groupsData.length; i++) {
            if (groupsData[i].id === value) {
                newArr.push({
                    userGroupId: groupsData[i].id, name: groupsData[i].name, permissionType: newGroupPermissionValue
                })
            }
        }
        setNewGroupList(newArr);
    };
    const AssignGroupToProject = () => {
        setLoading(true)
        if (newGroupList.length > 0) {
            var dataToPost = [];
            var newArray = [...newGroupList];
            for (var i = 0; i < newArray.length; i++) {
                dataToPost.push({
                    "userGroupId": newArray[i].userGroupId,
                    "projectId": selectedProject.id,
                    "permissionType": newArray[i].permissionType
                })
            }

            postDataResponseText("/api/permission/usergroups_project_permission", dataToPost)
                .then((res) => Promise.all([res.status, res.text()]))
                .then(([status, data]) => {
                    if (status === 401) {
                        logout()
                        return;
                    }
                    if (status === 500) {
                        setPopup500(true)
                        return;
                    }
                setLoading(false)
                setAssignGroup(false);
                api.open({
                    message: 'Team successfully assigned.', icon: <img src={toastIcon} alt="toastIcon"/>, duration: 5,
                });
                setNewGroupList([]);
                setSelectedProject("")
                if (groupList.length < 1) {
                    allProjectData = projectsData;
                    assignedUsersCount = 0;
                    getAssignedGroups(selectedProject.id, true);
                }
            })
        }
        if (groupList.length > 0) {
            dataToPost = [];
            newArray = [...groupList];
            for (var i = 0; i < newArray.length; i++) {
                dataToPost.push({
                    "id": newArray[i].permission.id,
                    "userGroupId": newArray[i].userGroup.id,
                    "projectId": selectedProject.id,
                    "permissionType": newArray[i].permission.permissionType
                })
            }
            setLoading(false)
            updateDataWithToken("/api/permission/usergroups_project_permission", dataToPost)
                .then((res) => Promise.all([res.status, res.text()]))
                .then(([status, response]) => {
                    if (status === 401) {
                        logout()
                        return;
                    }
                    if (status === 500) {
                        setPopup500(true)
                        return;
                    }
                    setLoading(false)
                    setAssignGroup(false);
                    api.open({
                        message: 'Team successfully updated.', icon: <img src={toastIcon} alt="toastIcon"/>, duration: 5,
                    });
                    setGroupList([]);
                    setSelectedProject("");
                    allProjectData = projectsData;
                    assignedUsersCount = 0;
                    getAssignedGroups(selectedProject.id, true);
                })
        }

    };
    const AssignDriveToProject = () => {
        setLoading(true)
        if (newDrivesList.length > 0) {
            var dataToPost = [];
            var newArray = [...newDrivesList];
            for (var i = 0; i < newArray.length; i++) {
                dataToPost.push({
                    "sharedCloudDriveId": newArray[i].id, "projectId": selectedProject.id,
                })
            }
            postDataResponseText("/api/project/assign_sharedclouddrive", dataToPost)
                .then((res) => Promise.all([res.status, res.text()]))
                .then(([status, data]) => {
                    if (status === 401) {
                        logout()
                        return;
                    }
                    if (status === 500) {
                        setPopup500(true)
                        return;
                    }
                setLoading(false)
                setAssignDrive(false);
                api.open({
                    message: 'Drive successfully assigned.', icon: <img src={toastIcon} alt="toastIcon"/>, duration: 5,
                });
                setNewDrivesList([]);
                setSelectedProject("")
                allProjectData = projectsData;
                assignedUsersCount = 0;
                getAssignedDrives(selectedProject.id, true);
            })
        } else {
            setLoading(false)
            setAssignDrive(false);
        }
    };
    return (<>  {contextHolder}
        <div id="main-content">
            <div className="container-fluid mb-4">
                <div className="add-new-user-wrapper">
                    <div className="add-user-header">
                        <div className="row">
                            <div className="col-lg-3 col-md-12">
                                <div className="content-wrapper">
                                    <h2>Projects</h2>
                                    <p>Use Projects to give multiple users access <br/>
                                        to cloud drives and project management.</p>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-12">
                                <ConfigurationsMenu/>
                            </div>
                            <div className="col-lg-3 col-md-12">
                                <div className="btn-wrapper">
                                    <a href="#" className="btn-style btn-30 small-btn icon" onClick={() => {
                                        if (userStatus.status === "active") {
                                            showManualUser()
                                        } else {
                                            setBillingPopupOpen(true);
                                        }
                                    }}>
                                        <img src={plusIcon} alt="icon"/>
                                        Add New Project
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="add-user-body">
                        <div className="user-table-wrapper">
                            <div className="user-table-header">
                                <ul className={`filter ${!dataLoaded ? "disabled" : ""}`}>
                                    <li>
                                        <div className="form">
                                            <div className="form-group">
                                                <input type="text" className="form-control" onChange={(e) => {
                                                    e.preventDefault()
                                                    setSearchValForSearch(e.target.value)
                                                }} value={searchValForSearch} placeholder="Search by Project Name"/>
                                                <a href="#" className="icon" onClick={(e) => {
                                                    e.preventDefault();
                                                    if (userStatus.status === "active") {
                                                        setCurrentPage(1);
                                                        setDescending(false)
                                                        getAllProjects()
                                                    }
                                                }}> <img src={searchIcon} alt="icon"/></a>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div className="user-table-body">
                                <div className="table-responsive">
                                    <table className="table table-hover">
                                        <thead>
                                        <tr>
                                            <th className="flex">Project Name <a href="#" onClick={(e) => {
                                                e.preventDefault();
                                                onChangeSort();
                                            }}
                                                                                 className={`${descending ? "arrow-down" : "arrow-up"} ${!dataLoaded ? "disabled" : ""}`}><img
                                                src={downArrowIcon} alt="icon"/></a></th>
                                            <th>Users</th>
                                            <th>Teams</th>
                                            <th>Drive</th>
                                            <th></th>
                                        </tr>
                                        </thead>
                                        <tbody className={projectsData.length > 4 ? "more-rows" : ""}>

                                        {projectsData.length > 0 && projectsData.map(project => (<tr>
                                            <td className="width-300">
                                                <div className="group-title">
                                                    <p>
                                                        <span className="d-block d-md-none">Project Name: </span> {project.name}</p>
                                                    <span>{project.description}</span>
                                                </div>
                                            </td>
                                            <td className="more-tooltip width-400">
                                                <p>
                                                    <span className="d-block d-md-none">Users: </span> {project.usersData.length > 0 && <>
                                                                <span>{project.usersData[0].user.email}
                                                                </span>
                                                    {project.usersData.length > 1 && <Tooltip
                                                        getPopupContainer={trigger => trigger.parentNode}
                                                        trigger="hover"
                                                        placement="bottomRight"
                                                        title={<ul className="view-list scroll">
                                                            {project.usersData.map((item, index) => (<>
                                                                {index > 0 && <li>{item.user.email}</li>}
                                                            </>))}
                                                        </ul>}
                                                    ><a href="#">+{project.usersData.length - 1}</a>
                                                    </Tooltip>}
                                                </>

                                                }
                                                </p></td>

                                            <td className="more-tooltip width-200">
                                                <p>
                                                    <span className="d-block d-md-none">Teams: </span>{project.groupsData.length > 0 && <>
                                                                <span>{project.groupsData[0].userGroup.name}
                                                                </span>
                                                    {project.groupsData.length > 1 && <Tooltip
                                                        getPopupContainer={trigger => trigger.parentNode}
                                                        trigger="hover"
                                                        placement="bottomRight"
                                                        title={<ul className="view-list scroll">
                                                            {project.groupsData.map((item, index) => (<>
                                                                {index > 0 &&
                                                                    <li>{item.userGroup.name}</li>}
                                                            </>))}
                                                        </ul>}
                                                    ><a href="#">+{project.groupsData.length - 1}</a>
                                                    </Tooltip>}
                                                </>

                                                }
                                                </p></td>
                                            <td className="more-tooltip width-200">
                                                <p>
                                                    <span className="d-block d-md-none">Drive: </span>{project.drivesData.length > 0 && <>
                                                                <span>{project.drivesData[0].sharedCloudDrive.name}
                                                                </span>
                                                    {project.drivesData.length > 1 && <Tooltip
                                                        getPopupContainer={trigger => trigger.parentNode}
                                                        trigger="hover"
                                                        placement="bottomRight"
                                                        title={<ul className="view-list scroll">
                                                            {project.drivesData.map((item, index) => (<>
                                                                {index > 0 &&
                                                                    <li>{item.sharedCloudDrive.name}</li>}
                                                            </>))}
                                                        </ul>}
                                                    ><a href="#">+{project.drivesData.length - 1}</a>
                                                    </Tooltip>}
                                                </>

                                                }
                                                </p></td>

                                            <td className={!dataLoaded ? "disabled" : ""}>
                                                {userStatus.status === "active" && <Tooltip
                                                    getPopupContainer={trigger => trigger.parentNode}
                                                    trigger="click"
                                                    placement="bottomLeft"
                                                    title={<ul className="view-list">
                                                        <li><a href="#" onClick={(e) => {
                                                            e.preventDefault();
                                                            showEditProject(project)
                                                        }}>Edit project</a></li>
                                                        <li><a href="#" onClick={(e) => {
                                                            e.preventDefault();
                                                            showAssignUsers(project)
                                                        }}>Manage users</a></li>
                                                        <li><a href="#" onClick={(e) => {
                                                            e.preventDefault();
                                                            showAssignGroup(project)
                                                        }}>Manage teams</a></li>
                                                        <li><a href="#" onClick={(e) => {
                                                            e.preventDefault();
                                                            showAssignDrive(project)
                                                        }}>Manage drive</a></li>
                                                        <li><a href="#" className="red-color"
                                                               onClick={(e) => {
                                                                   e.preventDefault();
                                                                   showAssignDelete(project)
                                                               }}>Delete project</a></li>
                                                    </ul>}
                                                >
                                                    <a href="#" className="toltip-btn">
                                                        <img src={verticleDotsIcon} alt="icon"/>
                                                    </a>
                                                </Tooltip>}
                                            </td>
                                        </tr>))}

                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className={!dataLoaded ? "disabled" : ""}>
                                {totalResult > perPage &&
                                    <Pagination current={currentPage} total={totalResult} pageSize={perPage}
                                                onChange={onChangePage}/>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <Modal title="Add New project" className="manual-user-type manual-project-type" centered open={isManualUser}
               onOk={selectManualOk} onCancel={selectManualOk}>
            <div className="manual-user-modal-wrapper">
                <div className="header">
                    <h3>Add new project</h3>
                </div>
                <div className="body">
                    <div className="search-wrapper">
                        <div className="form">
                            <div className="form-group">
                                <p className="label">Project name</p>
                                <input type="text" className="form-control" onChange={(e) => {
                                    setProjectName(e.target.value)
                                }} value={projectName} placeholder="Enter project name"/>
                            </div>
                            <div className="form-group">
                                <p className="label">Project description</p>
                                <textarea className="form-control" onChange={(e) => {
                                    setProjectDescription(e.target.value)
                                }} value={projectDescription} placeholder="Enter project description"/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer">
                    <a href="#" className="btn-style transparent btn-30" onClick={selectManualCancel}>Cancel</a>
                    <Button className={`btn-style blue btn-30 ${projectName.length < 1 || projectName.trim() === "" ? 'disabled' : ''}`}
                            loading={loading} onClick={createProject}>Add Project</Button>
                </div>
            </div>
        </Modal>

        <Modal title="Manage users" className="manual-user-type" centered open={isAssignShare}
               onOk={AssignProjectsCancel} onCancel={AssignProjectsCancel}>
            <div className="manual-user-modal-wrapper">
                <div className="header">
                    <h3>Manage users</h3>
                </div>
                <div className="body">
                    <div className="search-wrapper">
                        <div className="form">
                            <div className="form-group">
                                <p className="label">Users</p>
                                <div className="add-share">
                                    <Select
                                        showSearch
                                        placeholder="Select a person"
                                        optionFilterProp="children"
                                        onChange={handleChangeUser}
                                        value={selectedUsers}
                                        options={usersData.filter((item) => {
                                            if (usersList.filter((a) => a.user.id === item.id).length < 1 && newUsersList.filter((a) => a.userId === item.id).length < 1) {
                                                return item
                                            }
                                        }).map((item) => ({
                                            label: item.email, value: item.id,
                                        }))}
                                    />
                                </div>
                                {(usersList.length > 0 || newUsersList.length > 0) && <div className="added-users">
                                    {usersList.length > 0 && usersList.map((user, i) => (
                                        <div className="added-users-card">
                                            <div className="row">
                                                <div className="col-md-6 align-self-center">
                                                    <div className="tag-wrapper">
                                                               <span className="tag">{user.user.email} <a href="#"
                                                                                                          onClick={(e) => {
                                                                                                              e.preventDefault();
                                                                                                              removeUserFromList(i, user.permission.id)
                                                                                                          }}><img
                                                                   src={crossIcon} alt="icon"/></a></span>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 align-self-center">
                                                    <div className="read-write-radio">
                                                        <Radio.Group onChange={(e) => {
                                                            onChangePermission(e, user.user.id)
                                                        }} value={user.permission.permissionType}>
                                                            <Radio value={1}>
                                                                Read
                                                            </Radio>
                                                            <Radio value={2}>
                                                                Read, Write
                                                            </Radio>
                                                        </Radio.Group>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>))}
                                    {newUsersList.length > 0 && newUsersList.map((user, i) => (
                                        <div className="added-users-card">
                                            <div className="row">
                                                <div className="col-md-6 align-self-center">
                                                    <div className="tag-wrapper">
                                                                <span className="tag">{user.userName}<a href="#"
                                                                                                        onClick={(e) => {
                                                                                                            e.preventDefault();
                                                                                                            removeUserFromList(i, user, true)
                                                                                                        }}><img
                                                                    src={crossIcon} alt="icon"/></a></span>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 align-self-center">
                                                    <div className="read-write-radio">
                                                        <Radio.Group onChange={(e) => {
                                                            onChangePermission(e, user.userId, true)
                                                        }} value={user.permissionType}>
                                                            <Radio value={1}>
                                                                Read
                                                            </Radio>
                                                            <Radio value={2}>
                                                                Read, Write
                                                            </Radio>
                                                        </Radio.Group>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>))}
                                </div>}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer">
                    <a href="#" className="btn-style transparent btn-30" onClick={AssignProjectsCancel}>Cancel</a>
                    <Button
                        className={`btn-style blue btn-30 ${newUsersList.length > 0 || usersList.length > 0 ? "" : "disabled"}`}
                        loading={loading} onClick={AssignUserToProject}>Assign</Button>
                </div>
            </div>
        </Modal>


        <Modal title="Manage teams" className="manual-user-type" centered open={isAssignGroup}
               onOk={AssignGroupCancel} onCancel={AssignGroupCancel}>
            <div className="manual-user-modal-wrapper">
                <div className="header">
                    <h3>Manage teams</h3>
                </div>
                <div className="body">
                    <div className="search-wrapper">
                        <div className="form">
                            <div className="form-group">
                                <p className="label">Teams</p>
                                <div className="add-share">
                                    <Select
                                        showSearch
                                        placeholder="Select a team"
                                        optionFilterProp="children"
                                        onChange={handleChangeGroup}
                                        value={selectedGroup}
                                        options={groupsData.filter((item) => {
                                            if (groupList.filter((a) => a.userGroup.id === item.id).length < 1 && newGroupList.filter((a) => a.userGroupId === item.id).length < 1) {
                                                return item
                                            }
                                        }).map((item) => ({
                                            label: item.name, value: item.id,
                                        }))}
                                    />
                                </div>
                                {(groupList.length > 0 || newGroupList.length > 0) && <div className="added-users">
                                    {groupList.length > 0 && groupList.map((group, i) => (
                                        <div className="added-users-card">
                                            <div className="row">
                                                <div className="col-md-6 align-self-center">
                                                    <div className="tag-wrapper">
                                                               <span className="tag">{group.userGroup.name} <a href="#"
                                                                                                               onClick={(e) => {
                                                                                                                   e.preventDefault();
                                                                                                                   removeGroupFromList(i, group.permission.id)
                                                                                                               }}><img
                                                                   src={crossIcon} alt="icon"/></a></span>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 align-self-center">
                                                    <div className="read-write-radio">
                                                        <Radio.Group onChange={(e) => {
                                                            onChangePermissionGroup(e, group.userGroup.id)
                                                        }} value={group.permission.permissionType}>
                                                            <Radio value={1}>
                                                                Read
                                                            </Radio>
                                                            <Radio value={2}>
                                                                Read, Write
                                                            </Radio>
                                                        </Radio.Group>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>))}
                                    {newGroupList.length > 0 && newGroupList.map((group, i) => (
                                        <div className="added-users-card">
                                            <div className="row">
                                                <div className="col-md-6 align-self-center">
                                                    <div className="tag-wrapper">
                                                                <span className="tag">{group.name} <a href="#"
                                                                                                      onClick={(e) => {
                                                                                                          e.preventDefault();
                                                                                                          removeGroupFromList(i, group, true)
                                                                                                      }}><img
                                                                    src={crossIcon} alt="icon"/></a></span>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 align-self-center">
                                                    <div className="read-write-radio">
                                                        <Radio.Group onChange={(e) => {
                                                            onChangePermissionGroup(e, group.userGroupId, true)
                                                        }} value={group.permissionType}>
                                                            <Radio value={1}>
                                                                Read
                                                            </Radio>
                                                            <Radio value={2}>
                                                                Read, Write
                                                            </Radio>
                                                        </Radio.Group>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>))}
                                </div>}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer">
                    <a href="#" className="btn-style transparent btn-30" onClick={AssignGroupCancel}>Cancel</a>
                    <Button
                        className={`btn-style blue btn-30 ${newGroupList.length > 0 || groupList.length > 0 ? "" : "disabled"}`}
                        loading={loading} onClick={AssignGroupToProject}>Assign</Button>
                </div>
            </div>
        </Modal>

        <Modal title="Manage drive" className="manual-user-type" centered open={isAssignDrive}
               onOk={AssignDriveCancel} onCancel={AssignDriveCancel}>
            <div className="manual-user-modal-wrapper">
                <div className="header">
                    <h3>Manage drive</h3>
                </div>
                <div className="body">
                    <div className="search-wrapper">
                        <div className="form">
                            <div className="form-group">
                                <p className="label">Drive</p>
                                <div className="add-share">
                                    <Select
                                        showSearch
                                        placeholder="Select a drive"
                                        optionFilterProp="children"
                                        onChange={handleChangeDrive}
                                        value={selectedDrive}
                                        options={drivesData.filter((item) => {
                                            if (drivesList.filter((a) => a.sharedCloudDrive.id === item.id).length < 1 && newDrivesList.filter((a) => a.id === item.id).length < 1) {
                                                return item
                                            }
                                        }).map((item) => ({
                                            label: item.name, value: item.id,
                                        }))}
                                    />
                                </div>
                                {(drivesList.length > 0 || newDrivesList.length > 0) &&
                                    <div className="added-users">
                                        {drivesList.length > 0 && drivesList.map((drive, i) => (
                                            <div className="added-users-card">
                                                <div className="row">
                                                    <div className="col-md-6 align-self-center">
                                                        <div className="tag-wrapper">
                                                               <span className="tag">{drive.sharedCloudDrive.name} <a
                                                                   href="#" onClick={(e) => {
                                                                   e.preventDefault();
                                                                   removeDriveFromList(i, drive.projectSharedCloudDrive.id)
                                                               }}><img src={crossIcon} alt="icon"/></a></span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>))}
                                        {newDrivesList.length > 0 && newDrivesList.map((drive, i) => (
                                            <div className="added-users-card">
                                                <div className="row">
                                                    <div className="col-md-6 align-self-center">
                                                        <div className="tag-wrapper">
                                                                <span className="tag">{drive.name} <a href="#"
                                                                                                      onClick={(e) => {
                                                                                                          e.preventDefault();
                                                                                                          removeDriveFromList(i, drive.id, true)
                                                                                                      }}><img
                                                                    src={crossIcon} alt="icon"/></a></span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>))}
                                    </div>}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer">
                    <a href="#" className="btn-style transparent btn-30" onClick={AssignDriveCancel}>Cancel</a>
                    <Button
                        className={`btn-style blue btn-30 ${newDrivesList.length > 0 || drivesList.length > 0 ? "" : "disabled"}`}
                        loading={loading} onClick={AssignDriveToProject}>Assign</Button>
                </div>
            </div>
        </Modal>


        <DeleteModal title="Delete Project" text="Are you sure you want to delete this project?"
                     deleteOpen={isAssignDelete} deleteCancel={AssignDeleteCancel} deleteSuccess={deleteProject}
                     loading={loading}/>

        <Modal title="Edit project" className="manual-user-type manual-project-type" centered open={isEditUser}
               onOk={EditUserCancel} onCancel={EditUserCancel}>
            <div className="manual-user-modal-wrapper">
                <div className="header">
                    <h3>Edit project</h3>
                </div>
                <div className="body">
                    <div className="search-wrapper">
                        <div className="form">
                            <div className="form-group">
                                <p className="label">Project name</p>
                                <input type="text" className="form-control" onChange={(e) => {
                                    setProjectName(e.target.value)
                                }} value={projectName} placeholder="Enter project name"/>
                            </div>
                            <div className="form-group">
                                <p className="label">Project description</p>
                                <textarea className="form-control" onChange={(e) => {
                                    setProjectDescription(e.target.value)
                                }} value={projectDescription} placeholder="Enter project description"/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer">
                    <a href="#" className="btn-style transparent btn-30" onClick={EditUserCancel}>Cancel</a>
                    <Button className={`btn-style blue btn-30 ${projectName.length > 0 || projectName.trim() === "" ? '' : 'disabled'}`}
                            loading={loading} onClick={updateProject}>Save Changes</Button>
                </div>
            </div>
        </Modal>

        <BillingPopup></BillingPopup>

    </>)
}

export default Project;

