import {React, useEffect, useState, useRef} from 'react';
import {Navigate, useSearchParams, useNavigate} from "react-router-dom";
import downArrowIcon from "../assets/images/icons/down-arrow-black-icon.svg";
import bucketIcon from "../assets/images/icons/bucket-black-icon.svg";
import trashIcon from "../assets/images/icons/trash-white-icon.svg";
import settingIcon from "../assets/images/icons/setting-white-icon.svg";
import {Button, Modal, Radio, Select, Switch, Tooltip, notification} from "antd";
import bucketModalIcon from "../assets/images/icons/bucket-modal-img.svg";
import bucketModalIconBlack from "../assets/images/icons/bucket-modal-img-black.svg";
import crossIcon from "../assets/images/icons/cross-black-icon.svg";
import bucketOrange from "../assets/images/icons/object-bucket-icon.svg";
import {useLocation} from 'react-router-dom';
import {useAuth} from "../context/AuthContext";
import toastIcon from "../assets/images/icons/toast-icon.svg";
import pageLogoWhite from "../assets/images/logo-dark.svg";
import pageLogo from "../assets/images/page-logo.svg";

const Buckets = () => {
    const {
        cloudAccounts,
        setCloudAccounts,
        getCloudAccounts,
        deleteDataWeb,
        setEndpoint,
        setShowPopupSupport,
        setPopup500,
        setError499,
        setPopup499,
        user,
        logout,
        prettySize,
        getFormatedDate,
        cloudAccountsDone,
        regions,
        getRegions,
        validBucketName,
        sendDataToWebsocket,
        sendDataToMixPanel,
        getDataWithTokenPOST,
        postDataWebNoResponse,
        updateDataWithToken,
        cloudAccountsLoaded,
        limitExceeded,
        isDark,
        token,
        userStatus
    } = useAuth();
    const dataFetchedRef = useRef(false);
    const [createBucket, setCreateBucket] = useState(false);
    const [BucketOption, setBucketOption] = useState(false);
    const [deleteBucket, setDeleteBucket] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loadingPopup, setLoadingPopup] = useState(false);
    const [selectedRegions, setSelectedRegions] = useState();
    const [selectedStorage, setSelectedStorage] = useState();
    const [toggleVersioning, setToggleVersioning] = useState(false);
    const [toggleDefaultEncryption, setToggleDefaultEncryption] = useState(false);
    const [toggleObjectLocking, setToggleObjectLocking] = useState(false);
    const [showPageData, setShowPageData] = useState(false);
    const [itemToDeleteID, setItemToDeleteID] = useState(false);
    const [itemToUpdate, setItemToUpdate] = useState(false);
    const [regionName, setRegionName] = useState(false);
    const [itemToDeleteBucket, setItemToDeleteBucket] = useState(false);
    const [showBucketNameError, setBucketNameError] = useState(false);
    const [loadingCreate, setLoadingCreate] = useState(false);
    const [selectedBuckets, setSelectedBuckets] = useState([]);
    const [newBucketName, setNewBucketName] = useState("");
    const [api, contextHolder] = notification.useNotification();
    const [pageDataLoaded, setPageDataLoaded] = useState(false);
    const [options, setOptions] = useState([]);
    const [optionsRegions, setOptionsRegions] = useState([]);
    const location = useLocation();
    const navigate = useNavigate();


    useEffect(() => {
        if (user.userType === 32 || user.userType === 64) {
            navigate("/dashboard");
            return;
        }
    }, [user]);
    useEffect(() => {
        if (limitExceeded) {
            navigate("/subscribe");
        }
    }, [limitExceeded]);
    useEffect(() => {
        if (token !== undefined && token !== null && userStatus.status !== undefined) {
            if (dataFetchedRef.current) return;
            dataFetchedRef.current = true;
            getRegions();
            getCloudAccounts();
        }
    }, [token, userStatus]);


    useEffect(() => {
        const params = new URLSearchParams(location.search);
        if (params.get('create-bucket') === 'true') {
            setCreateBucket(true);
        } else {
            setCreateBucket(false);
        }
    }, []);
    useEffect(() => {
        setTimeout(function () {
            if (cloudAccountsDone) {
                setShowPageData(true)
                var optionsB = []
                cloudAccounts.filter((item) => (item.internalStorage)).map((item) => {
                    if (!optionsB.some(obj => obj.value === item.storageTier)) {
                        optionsB.push({
                            label: item.name.substring(0, item.name.indexOf("(")),
                            value: item.storageTier,
                        })
                    }
                })
                console.log(optionsB)
                setOptions(optionsB)
            }
        }, 1500)

    }, [cloudAccountsDone]);
    useEffect(() => {
        setTimeout(function () {
            if (cloudAccountsLoaded) {
                setPageDataLoaded(true)
            }
            if (!cloudAccountsLoaded) {
                setPageDataLoaded(false)
            }
        }, 1500)
    }, [cloudAccountsLoaded]);
    useEffect(() => {
        console.log("cloudAccounts")
        console.log(cloudAccounts)
    }, [cloudAccounts]);


    const onChangeRegoin = (value) => {
        setSelectedRegions(value);
        console.log(value)
    };
    const onChangeStorage = (value) => {
        setSelectedStorage(value);
        var optionsR = []
        cloudAccounts.filter((item) => (item.internalStorage)).map((item) => {
            if (item.storageTier === value) {
                if (!optionsR.some(obj => obj.label === item.name.substring(item.name.indexOf("(") + 1, (item.name.length - 1)))) {
                    optionsR.push({
                        label: item.name.substring(item.name.indexOf("(") + 1, (item.name.length - 1)),
                        value: item.id,
                    })
                }
            }
        })
        console.log(optionsR)
        setOptionsRegions(optionsR)
        console.log(value)
    };
    const manageBucketCancel = () => {
        setCreateBucket(false);
        setLoadingCreate(false);
        setToggleDefaultEncryption(false)
        setToggleObjectLocking(false)
        setToggleVersioning(false)
        setNewBucketName("")
        setSelectedRegions()
        setSelectedStorage()
        window.history.pushState({}, null, "/buckets");
    };


    const createBucketRequest = () => {

        if (validBucketName(newBucketName) && newBucketName.length > 2 && newBucketName.length < 64) {
            var versioning = toggleVersioning;
            if (toggleObjectLocking) {
                versioning = true;
            }
            var dataToPost = {
                "cloudAccountId": selectedRegions,
                "bucketName": newBucketName,
                "isPublic": false,
                "isEncrypted": toggleDefaultEncryption,
                "versioningEnabled": versioning,
                "objectLockEnabled": toggleObjectLocking
            };

            console.log(dataToPost)

            setLoadingCreate(true)
            postDataWebNoResponse("/api/storage/create_bucket", dataToPost)
                .then((res) => Promise.all([res.status, res.text()]))
                .then(([status, data]) => {
                    setLoadingCreate(false)
                    if (status === 401) {
                        setEndpoint("/api/storage/create_bucket")
                        setShowPopupSupport(true)
                        // logout()
                        return;
                    }
                    if (status === 500) {
                        setPopup500(true)
                        return;
                    }
                    if (status === 499) {
                        var dataNew = JSON.parse(data)
                        var errorType = dataNew.type;
                        setError499(dataNew.errors[errorType][0])
                        setPopup499(true)
                        return;
                    }
                    if (status === 200) {
                        manageBucketCancel()
                        getBuckets(selectedRegions)
                        api.open({
                            message: `${newBucketName} bucket has been successfully created.`,
                            icon: <img src={toastIcon} alt="toastIcon"/>,
                            duration: 3
                        });
                        sendDataToMixPanel('Bucket has been successfully created.', user.username, {
                            'App': 'Web App',
                            'Bucket Name': newBucketName
                        })
                    }
                })
        }

    };


    function getBuckets(selectedAccount) {
        getDataWithTokenPOST("/api/desktop/list_buckets", {
            "cloudAccountId": selectedAccount,
            "includeRegion": true
        }).then((res) => Promise.all([res.status, res.json()]))
            .then(([status, response]) => {
                    if (status === 401) {
                        // logout()
                        return;
                    }
                    if (status === 500) {
                        setPopup500(true)
                        return;
                    }
                    if (status === 499) {
                        var errorType = response.type;
                        setError499(response.errors[errorType][0])
                        setPopup499(true)
                        return;
                    }
                    console.log(response)
                    var newArray = [...cloudAccounts]
                    for (var i = 0; i < newArray.length; i++) {
                        if (newArray[i].id === selectedAccount) {
                            newArray[i].buckets = response
                        }
                    }
                    setCloudAccounts(newArray)
                }
            );
    }


    const manageBucketOpen = () => {
        setCreateBucket(true);
    };

    const bucketOptionCancel = () => {
        setBucketOption(false);
    };
    const bucketOptionOpen = () => {
        setLoadingPopup(true)
        setBucketOption(true);
    };

    useEffect(() => {
        if (BucketOption) {
            getDataWithTokenPOST("/api/storage/bucket_status", {
                "cloudAccountId": itemToDeleteID,
                "bucketName": itemToDeleteBucket
            }).then((res) => Promise.all([res.status, res.json()]))
                .then(([status, response]) => {
                        if (status === 401) {
                            logout()
                            return;
                        }
                        if (status === 500) {
                            setPopup500(true)
                            return;
                        }
                        if (status === 499) {
                            var errorType = response.type;
                            setError499(response.errors[errorType][0])
                            setPopup499(true)
                            return;
                        }
                        console.log(response)
                        setToggleObjectLocking(response.objectLockEnabled)
                        setToggleDefaultEncryption(response.encryptionEnabled)
                        setToggleVersioning(response.versioningEnabled)
                        setLoadingPopup(false)
                    }
                );
        }
    }, [BucketOption]);

    const bucketDeleteCancel = () => {
        setDeleteBucket(false);
    };
    const bucketDeleteOpen = () => {
        setDeleteBucket(true);
    };

    const updateBucket = () => {
        setLoading(true)
        var versioning = toggleVersioning;
        if (toggleObjectLocking) {
            versioning = true;
        }
        var dataToPost = {
            "cloudAccountId": itemToDeleteID,
            "bucketName": itemToDeleteBucket,
            "isPublic": false,
            "isEncrypted": toggleDefaultEncryption,
            "versioningEnabled": versioning
        }
        updateDataWithToken("/api/storage/update_bucket", dataToPost)
            .then((res) => Promise.all([res.status, res.text()]))
            .then(([status, response]) => {
                setLoadingPopup(false)
                setBucketOption(false)
                setLoading(false)
                if (status === 401) {
                    logout()
                    return;
                }
                if (status === 500) {
                    setPopup500(true);
                    setLoading(false);
                    return;
                }

                if (status === 499) {
                    var res = JSON.parse(response)
                    var errorType = res.type;
                    setError499(res.errors[errorType][0])
                    setPopup499(true)
                    return;
                }
                api.open({
                    message: 'Bucket settings updated.',
                    icon: <img src={toastIcon} alt="toastIcon"/>,
                    duration: 3
                });

                sendDataToMixPanel('Bucket settings updated.', user.username, {
                    'App': 'Web App'
                })
            })
    };

    function deleteBucketFromDB() {
        setLoading(true)
        deleteDataWeb(`/api/cloudaccount/idrive_delete_bucket?cloudAccountId=${itemToDeleteID}&bucketName=${itemToDeleteBucket}`)
            .then((res) => Promise.all([res.status, res.text()]))
            .then(([status, data]) => {
                setLoading(false)
                bucketDeleteCancel();
                if (status === 401) {
                    setEndpoint("/api/cloudaccount/idrive_delete_bucket")
                    setShowPopupSupport(true)
                    //logout()
                    return;
                }
                if (status === 500) {
                    setPopup500(true)
                    return;
                }
                if (status === 499) {
                    var response = JSON.parse(data)
                    var errorType = response.type;
                    setError499(response.errors[errorType][0])
                    setPopup499(true)
                    return;
                }
                if (status === 200) {
                    let newArr = [...cloudAccounts];
                    newArr = newArr.filter((array) => {
                        var res = array;
                        res.buckets = array.buckets.filter(
                            (item) => item.name !== itemToDeleteBucket
                        );
                        return res;
                    })
                    setCloudAccounts(newArr)
                    api.open({
                        message: 'Bucket removed successfully.',
                        icon: <img src={toastIcon} alt="toastIcon"/>,
                        duration: 3
                    });
                }
            });
    }

    return (
        <>
            {contextHolder}
            <div id="main-content">
                <div className="container-fluid">
                    <div className="storage-dashboard-main bucket-dashboard-main">
                        <div className="storage-region">
                            <h3>Buckets</h3>
                            <a href="#" className="btn-style btn-30 bucket-btn" onClick={(e) => {
                                e.preventDefault()
                                manageBucketOpen()
                            }}>Create a Bucket</a>
                        </div>
                        {cloudAccounts.filter((item) => (item.internalStorage)).length > 0 &&
                            <div className="enable-region-wrapper">
                                <div className="table-wrapper">
                                    <div className="table-header d-md-grid d-none grid-7">
                                        <p>Bucket name <img src={downArrowIcon} alt="icon"/></p>
                                        <p>Region <img src={downArrowIcon} alt="icon"/></p>
                                        <p>{/*Size <img src={downArrowIcon} alt="icon"/>*/}</p>
                                        <p>Create on <img src={downArrowIcon} alt="icon"/></p>
                                        <p>Actions</p>
                                        <p></p>
                                    </div>
                                    <div className="table-body position-relative">

                                        {pageDataLoaded && showPageData && cloudAccounts.filter((item) => (item.internalStorage)).map((item) => (
                                            <>
                                                {item.buckets.map((bucket) => (
                                                    <div className="table-row grid-7">
                                                        <div className="table-item">
                                                            <div className="name-wrapper">
                                                                <img src={bucketIcon} className="bucket-icon" alt="icon"/>
                                                                <p>{bucket.name}</p>
                                                            </div>
                                                        </div>
                                                        <div className="table-item">
                                                            <div className="copy-wrapper">
                                                                <p>{item.name.substring(item.name.indexOf("(") + 1, (item.name.length - 1))}</p>
                                                            </div>
                                                        </div>
                                                        <div className="table-item">
                                                            <p>{/*{prettySize(bucket.size)}*/}</p>
                                                        </div>
                                                        <div className="table-item">
                                                            <p>{getFormatedDate(bucket.creationDate)}</p>
                                                        </div>
                                                        <div className="table-item">
                                                            <ul className="options bucket-options">
                                                                <li><a href="#" onClick={(e) => {
                                                                    e.preventDefault()
                                                                    setItemToDeleteID(item.id)
                                                                    setItemToDeleteBucket(bucket.name)
                                                                    bucketDeleteOpen()
                                                                }}><img src={trashIcon} alt="icon"/></a></li>
                                                                <li><a href="#" onClick={(e) => {
                                                                    e.preventDefault()
                                                                    setItemToDeleteID(item.id)
                                                                    setItemToDeleteBucket(bucket.name)
                                                                    setItemToUpdate(bucket)
                                                                    setRegionName(item.name.substring(item.name.indexOf("(") + 1, (item.name.length - 1)))
                                                                    console.log(bucket)
                                                                    bucketOptionOpen()
                                                                }}><img src={settingIcon} alt="icon"/></a></li>
                                                            </ul>
                                                        </div>
                                                        <div className="table-item table-item-btn bucket-btn-item">
                                                            <a href="#" className="btn-style btn-30" onClick={(e) => {
                                                                e.preventDefault();
                                                                navigate(`/file-manager-new?cloud-account=${item.id}&bucket-name=${bucket.name}&bucket-id=${bucket.id}`)
                                                            }}>Browse Files</a>
                                                        </div>
                                                    </div>
                                                ))
                                                }
                                            </>
                                        ))
                                        }
                                        {(!pageDataLoaded || !showPageData) &&
                                            <>
                                                <div className="lds-roller small">
                                                    <div></div>
                                                    <div></div>
                                                    <div></div>
                                                    <div></div>
                                                    <div></div>
                                                    <div></div>
                                                    <div></div>
                                                    <div></div>
                                                </div>
                                            </>
                                        }
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
            <Modal title="Create a Bucket" className="bucket-main-modal" centered open={createBucket} onOk={manageBucketCancel}
                   onCancel={manageBucketCancel}>
                <div className="bucket-modal-header">
                    <div className="row">
                        <div className="col-9">
                            <div className="name-wrapper">
                                <img src={isDark ? bucketModalIcon : bucketModalIconBlack} alt="icon"/>
                                <h3>Create a Bucket</h3>
                            </div>
                        </div>
                        <div className="col-3">
                            <div className="cross">
                                <a href="#" onClick={(e) => {
                                    e.preventDefault();
                                    manageBucketCancel()
                                }}>
                                    <img src={crossIcon} alt="icon"/></a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bucket-modal-body">
                    <div className="form">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label htmlFor="bucket-name">Select Storage Account</label>
                                    <Select
                                        showSearch
                                        optionFilterProp="children"
                                        placeholder="Select a Storage"
                                        onChange={onChangeStorage}
                                        value={selectedStorage}
                                        filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                        options={options}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label htmlFor="bucket-name">Select a Region</label>
                                    <Select
                                        className={` ${regions !== '' && regions !== null ? "selected" : ''} ${selectedStorage === undefined ? "disabled" : ""}`}
                                        showSearch
                                        optionFilterProp="children"
                                        placeholder="Select a Region"
                                        onChange={onChangeRegoin}
                                        value={selectedRegions}
                                        filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                        options={optionsRegions}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className={`form-group ${selectedRegions === undefined ? "disabled" : ""}`}>
                                    <label htmlFor="bucket-name">Bucket Name</label>
                                    <input type="text" id="bucket-name" value={newBucketName} className={`form-control ${showBucketNameError ? "has-error" : ""}`} onChange={(e) => {
                                        if (e.target.value.length > 1) {
                                            var result1 = selectedBuckets.filter(item => item.name.toLowerCase() === e.target.value.toLowerCase());

                                            if (result1.length > 0) {
                                                setBucketNameError(true)
                                            } else {
                                                setBucketNameError(false)
                                            }
                                        } else {
                                            setBucketNameError(false)
                                        }
                                        setNewBucketName(e.target.value);

                                    }} placeholder="Introduce a Name"/>
                                    <p className="small">Bucket name can contain lowercase letters, numbers, hyphen (-), and period (.)</p>
                                </div>
                            </div>
                            <div className="col-md-6">
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <div className="switch-card">
                                        <div className="row">
                                            <div className="col-md-9">
                                                <div className="content">
                                                    <h3>Default encryption</h3>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <Switch checked={toggleDefaultEncryption} onChange={(e) => {
                                                    setToggleDefaultEncryption(e)
                                                }}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <div className="switch-card">
                                        <div className={`row`}>
                                            <div className={`col-md-9`}>
                                                <div className="content">
                                                    <h3>Versioning</h3>
                                                    <p>Retain older versions of the objects
                                                        in your bucket. These versions can be
                                                        accessed and restored.</p>
                                                </div>
                                            </div>
                                            <div className="col-md-3">

                                                {selectedStorage === 4 &&
                                                    <Tooltip
                                                        getPopupContainer={trigger => trigger.parentNode}
                                                        trigger="hover"
                                                        placement="topLeft"
                                                        title="This is supported only in Global and Perform tier"
                                                    >
                                                        <Switch checked={false}/>
                                                    </Tooltip>

                                                }
                                                {selectedStorage !== 4 &&
                                                    <Switch checked={toggleVersioning} onChange={(e) => {
                                                        if (!toggleObjectLocking) {
                                                            setToggleVersioning(e)
                                                        }
                                                    }}/>

                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <div className="switch-card">
                                        <div className={`row`}>
                                            <div className={`col-md-9`}>
                                                <div className="content">
                                                    <h3>Object Locking</h3>
                                                    <p>Prevent modification or deletion of
                                                        objects for a set time duration.</p>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                {selectedStorage === 4 &&
                                                    <Tooltip
                                                        getPopupContainer={trigger => trigger.parentNode}
                                                        trigger="hover"
                                                        placement="topLeft"
                                                        title="This is supported only in Global and Perform tier"
                                                    >
                                                        <Switch checked={false}/>
                                                    </Tooltip>

                                                }
                                                {selectedStorage !== 4 &&
                                                    <Switch checked={toggleObjectLocking} onChange={(e) => {
                                                        setToggleObjectLocking(e)
                                                        setToggleVersioning(e)
                                                    }}/>

                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bucket-modal-footer">
                    <ul>
                        <li>
                            <Button loading={loadingCreate} className={`btn-style btn-30 ${newBucketName === "" || !validBucketName(newBucketName) || newBucketName.length < 3 || newBucketName.length > 63 ? "disabled" : ""}`} onClick={createBucketRequest}>Create Bucket</Button></li>
                        <li>
                            <Button className="btn-style transparent btn-30" onClick={manageBucketCancel}>Cancel</Button>
                        </li>
                    </ul>
                </div>
            </Modal>
            <Modal title="Assign Users" className="bucket-main-modal" centered open={BucketOption} onOk={bucketOptionCancel}
                   onCancel={bucketOptionCancel}>
                <div className="bucket-modal-header">
                    <div className="row">
                        <div className="col-8">
                            <div className="name-wrapper">
                                <img src={settingIcon} alt="icon"/>
                                <h3>Bucket options</h3>
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="cross">
                                <a href="#" onClick={(e) => {
                                    e.preventDefault();
                                    bucketOptionCancel()
                                }}>
                                    <img src={crossIcon} alt="icon"/></a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bucket-modal-body">
                    <div className="form">
                        <div className="row">
                            <div className="col-md-12">
                                <ul className="bucket-options">
                                    <li>
                                        <div className="bucket-name">
                                            <div className="icon-wrapper">
                                                <img src={bucketOrange} alt="icon"/>
                                            </div>
                                            <div className="content-wrapper">
                                                <p>Bucket Name</p>
                                                <h3>{itemToUpdate.name}</h3>
                                                {/*<a href="#" className="copy">c6u5.da.idrivee2-28.com <img src={copyIcon} alt="icon"/></a>*/}
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <strong>Region: <p>{regionName}</p></strong>
                                        <strong>Created: <p>{getFormatedDate(itemToUpdate.creationDate)}</p></strong>
                                    </li>
                                    <li>
                                        {/*<strong>Objects: <p>1</p></strong>*/}
                                        {/*<strong>Size: <p>{prettySize(itemToUpdate.size)}</p></strong>*/}
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="row min-height-182 position-relative">
                            {!loadingPopup &&
                                <>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <div className="switch-card">
                                                <div className="row">
                                                    <div className="col-md-9">
                                                        <div className="content">
                                                            <h3>Default encryption</h3>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <Switch checked={toggleDefaultEncryption} onChange={(e) => {
                                                            setToggleDefaultEncryption(e)
                                                        }}/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <div className="switch-card">
                                                <div className="row">
                                                    <div className="col-md-9">
                                                        <div className="content">
                                                            <h3>Versioning</h3>
                                                            <p>Retain older versions of the objects
                                                                in your bucket. These versions can be
                                                                accessed and restored.</p>
                                                        </div>
                                                    </div>
                                                    <div className={`col-md-3 ${toggleObjectLocking ? "disabled" : ""}`}>
                                                        <Switch checked={toggleVersioning} onChange={(e) => {
                                                            setToggleVersioning(e)
                                                        }}/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <div className="switch-card">
                                                <div className="row">
                                                    <div className="col-md-9">
                                                        <div className="content">
                                                            <h3>Object Locking</h3>
                                                            <p>Prevent modification or deletion of
                                                                objects for a set time duration.</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3 disabled">
                                                        <Switch checked={toggleObjectLocking} onChange={(e) => {
                                                            setToggleObjectLocking(e)
                                                        }}/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            }
                            {loadingPopup &&
                                <>
                                    <div className="lds-roller small mb-auto">
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                    </div>
                                </>
                            }
                        </div>
                    </div>
                </div>
                <div className="bucket-modal-footer">
                    <ul>
                        <li>
                            <Button loading={loading} className={`btn-style btn-30 ${loadingPopup ? "disabled" : ""}`} onClick={updateBucket}>Save Bucket</Button></li>
                        <li>
                            <Button className="btn-style transparent btn-30" onClick={bucketOptionCancel}>Cancel</Button>
                        </li>
                    </ul>
                </div>
            </Modal>
            <Modal title="Delete Bucket" className="bucket-main-modal" centered open={deleteBucket} onOk={bucketDeleteCancel}
                   onCancel={bucketDeleteCancel}>
                <div className="bucket-modal-header">
                    <div className="row">
                        <div className="col-8">
                            <div className="name-wrapper">
                                <img src={trashIcon} alt="icon"/>
                                <h3>Delete Bucket</h3>
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="cross">
                                <a href="#" onClick={(e) => {
                                    e.preventDefault();
                                    bucketDeleteCancel()
                                }}>
                                    <img src={crossIcon} alt="icon"/></a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bucket-modal-body">
                    <div className="trash-wrapper">
                        <p>Are you sure you want to delete this bucket?
                            This action cannot be undone, and all data within
                            the bucket will be permanently lost. Please confirm
                            if you wish to proceed.</p>
                    </div>
                </div>
                <div className="bucket-modal-footer">
                    <ul>
                        <li>
                            <Button loading={loading} className="btn-style btn-30 trash" onClick={deleteBucketFromDB}>Delete Bucket</Button></li>
                        <li>
                            <Button className="btn-style transparent btn-30" onClick={bucketDeleteCancel}>Cancel</Button>
                        </li>
                    </ul>
                </div>
            </Modal>
        </>
    )
}

export default Buckets;

