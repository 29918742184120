import React, {useEffect, useState, useRef} from "react";
import {Link, NavLink, useLocation, useNavigate} from "react-router-dom";
import {LoadingOutlined} from '@ant-design/icons';
import {Popover, Checkbox, DatePicker, Select, Tooltip, Popconfirm, Modal, Button, Switch, notification, Spin} from 'antd';
import {useAuth} from "../context/AuthContext";
import searchGrayIcon from "../assets/images/ai-search/search-icon.svg";
import backArrowIcon from "../assets/images/ai-search/back-arrow-icon.svg";
import azureIcon from "../assets/images/icons/azure-small-icon.svg";
import leftArrowIcon from "../assets/images/left-arrow-orange-icon.svg";
import editIcon from "../assets/images/icons/edit-white-icon.svg";
import uploadIcon from "../assets/images/icons/upload-icon.svg";
import plusIcon from "../assets/images/plus-white-icon.svg";
import {FileUploader} from "react-drag-drop-files";
import uploadIconNew from "../assets/images/icons/upload-icon-new.svg";
import downloadActivityIcon from "../assets/images/icons/download-icon.svg";
import deleteActivityIcon from "../assets/images/icons/file-manager/bascket-icon.svg";
import threeDotsIcon from "../assets/images/icons/3-dots-icon.svg";
import selectedActivityIcon from "../assets/images/icons/file-manager/selected-minus-icon.svg";
import syncIcon from "../assets/images/icons/sync-icon.svg";
import folderIcon from "../assets/images/icons/file-icon.svg";
import folderBlackIcon from "../assets/images/icons/folder-black-icon.svg";
import fileIconFile from "../assets/images/icons/file-icon-file.svg";
import uploadNewIcon from "../assets/images/icons/upload-icon-new.svg";
import linkIcon from "../assets/images/icons/link-icon.svg";
import downloadIcon from "../assets/images/icons/download-icon.svg";
import deleteIcon from "../assets/images/icons/delete-icon.svg";
import dotsIcon from "../assets/images/icons/3-dots-icon.svg";
import {tr} from "react-date-range/dist/locale";

const {RangePicker} = DatePicker;
const AiActivate = () => {
    const {
        cloudAccounts,
        getDataValere,
        postDataValere,
        logout,
        setPopup500,
        setError499,
        setPopup499,
        user,
        getFormatedDate,
        setSearchResults,
        getIcon,
        getDataWithTokenPOST,
        limitExceeded,
        prettySize,
        isWindows,
        getDataMethodGet,
        getTypeName
    } = useAuth();
    const [buckets, setBuckets] = useState([]);
    const [activatedBuckets, setActivatedBuckets] = useState([]);
    const [filteredBuckets, setFilteredBuckets] = useState([]);
    const [filteredCloudAccounts, setFilteredCloudAccounts] = useState([]);
    const [selectedCloudAccount, setSelectedCloudAccount] = useState([]);
    const [selectedCloudAccountID, setSelectedCloudAccountID] = useState('');
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const dataFetchedRef = useRef(false);
    const [listView, setListView] = useState(true);
    const [step, setStep] = useState(1);
    const [showPrefix, setShowPrefix] = useState(false);
    const [showData, setShowData] = useState(false);
    const [loadingMore, setLoadingMore] = useState(true);
    const [prefix, setPrefix] = useState("");
    const [path, setPath] = useState("");
    const [bucket, setBucket] = useState("");
    const [continuationToken, setContinuationToken] = useState("");
    const [previousPathFiles, setPreviousPathFiles] = useState([]);
    const [previousPathIds, setPreviousPathIds] = useState([]);
    const [prevFolderID, setPrevFolderID] = useState("");
    const [result, setResult] = useState([]);
    const [localDir, setLocalDir] = useState([]);
    const [limitExceed, setLimitExceed] = useState(false);
    const [file, setFile] = useState(null);
    const [selectedBucketId, setSelectedBucketId] = useState("");
    const [selectedFolderName, setSelectedFolderName] = useState("");
    const [selectedFolderId, setSelectedFolderId] = useState("");
    const [parentID, setParentID] = useState("");

    const antIcon = <LoadingOutlined style={{fontSize: 40}} spin/>;
    useEffect(() => {
        setFilteredCloudAccounts(cloudAccounts)
    }, [cloudAccounts]);

    function getBuckets(selectedAccount,data) {
        getDataWithTokenPOST("/api/desktop/list_buckets", {
            "cloudAccountId": selectedAccount, "includeRegion": true
        }).then((res) => Promise.all([res.status, res.json()]))
            .then(([status, bucket]) => {
                if (status === 401) {
                    //logout()
                    return;
                }
                if (status === 500) {
                    setPopup500(true);
                    setLoading(false);
                    return;
                }
                if (status === 499) {
                    var errorType = bucket.type;
                    setError499(bucket.errors[errorType][0])
                    setPopup499(true)
                    return;
                }

                for (var j = 0; j < bucket.length; j++) {
                    bucket[j]["activateAi"] = false;
                    if (data.filter((item) => (bucket[j].name === item.bucket_name && item.sync)).length > 0) {
                        bucket[j]["activateAi"] = true;
                    }
                }
                console.log(bucket)
                setBuckets(bucket);
                setFilteredBuckets(bucket);
            });
    }


    function loadMore(e) {
        e.preventDefault()
        getListObjects(true)
    }

    function getListObjects(loadmore = false) {

        var pathName = '/'
        if (prefix !== '' && showPrefix) {
            pathName = prefix;
        }

        if (path !== '') {
            pathName = path;
        }
        var ct = ''
        if (continuationToken !== '' && loadmore) {
            ct = continuationToken;
            setLoadingMore(true)
        } else {
            setLoading(true);
        }
        var id = ""
        if (pathName === '/') {
            id = selectedBucketId;
        } else {
            id = selectedFolderId;
        }
        if (prevFolderID !== "" && prevFolderID !== null) {
            if (previousPathIds.length > 0) {
                id = previousPathIds[previousPathIds.length - 1];
            }
            setPrevFolderID("")
        }
        setParentID(id)
        /* if (selectedCloudAccount.cloudIconType === 32) {
             if (pathName === '/') {
                 pathName = selectedBucketId;
             }
             pathName = selectedBucketId;
             if (path !== '') {
                 pathName = path;
             }
             if (pathName === '/' && path === '/') {
                 pathName = selectedBucketId;
             }
         }*/

        if (bucket !== '') {
            var dataToPost = {
                "cloudAccountId": selectedCloudAccountID,
                "bucketName": bucket,
                "id": id,
                "path": pathName,
                "continuationToken": ct,
                "count": 50
            }
            console.log(dataToPost)
            getDataWithTokenPOST('/api/desktop/list_objects', dataToPost)
                .then((res) => Promise.all([res.status, res.json()]))
                .then(([status, data]) => {
                    setLoading(false);
                    if (status === 401) {
                        logout()
                        return;
                    }
                    if (status === 500) {
                        setPopup500(true)
                        return;
                    }
                    if (status === 499) {
                        var errorType = data.type;
                        setError499(data.errors[errorType][0])
                        setPopup499(true)
                        return;
                    }

                    console.log(activatedBuckets)
                    console.log(data.data)

                    for (var i = 0; data.data.length > i; i++) {
                        data.data[i].activateAi = false;
                    }
                    data.data.sort((a, b) => {
                        return b.type - a.type;
                    })
                    if (ct !== '' && ct !== null) {
                        setResult(old => [...old, ...data.data]);

                    } else {
                        setResult(data.data);
                    }
                    setContinuationToken(data.continuationToken);
                    setLoadingMore(false)
                    setShowData(true)
                });
        } else {
            setLoading(false);
            setLoadingMore(false)
        }
    }

    useEffect(() => {
        if (user !== undefined) {
            getActiveBuckets()
        }
    }, [user]);

    function getActiveBuckets(id = "", get = false){
        getDataValere(`/buckets?user_id=${user.userId}`)
            .then((res) => Promise.all([res.status, res.json()]))
            .then(([status, data]) => {
                if (status === 401) {
                    logout()
                    return;
                }
                if (status === 500) {
                    setPopup500(true)
                    return;
                }
                if (status === 499) {
                    var dataNew = JSON.parse(data)
                    var errorType = dataNew.type;
                    setError499(dataNew.errors[errorType][0])
                    setPopup499(true)
                    return;
                }
                console.log(data)
                setActivatedBuckets(data)
                if(get){
                    getBuckets(id,data)
                }
            })
    }

    function changeObject(e) {
        const val = e.target.getAttribute('data-value');
        const type = e.target.getAttribute('data-folder');
        const folderName = e.target.getAttribute('data-name');
        const folderId = e.target.getAttribute('data-id');

        if (e.detail == 2) {
            if (type == 2) {
                if (path === '') {
                    setPreviousPathFiles(oldArray => [...oldArray, '/']);
                } else {
                    setPreviousPathFiles(oldArray => [...oldArray, path]);
                }
                setPreviousPathIds(oldArray => [...oldArray, folderId]);
                setPath(val);
                setSelectedFolderName(folderName);
                setSelectedFolderId(folderId);
            }
        }
    }

    const confirm = (item, e, folder = false) => {
        var prefix = "/";
        var bName = item.name;
        var bId = item.id;

        if (folder) {
            prefix = item.path;
            bName = bucket;
            bId = selectedBucketId;
        }
        var cloudAccount = selectedCloudAccount[0];
        var dataToPost = {
            "account_id": cloudAccount.accountId,
            "user_id": cloudAccount.userId,
            "cloud_account_id": cloudAccount.id,
            "bucket_name": bName,
            "bucket_id": bId,
            "prefix": prefix,
            "prefix_id": "",
            "sync": e,
            "sync_interval": "1440", // in minutes
            "cloud_type": getTypeName(cloudAccount.cloudType)
        }
        postDataValere("/process/object", dataToPost)
            .then((res) => Promise.all([res.status, res.json()]))
            .then(([status, data]) => {
                console.log(data)
                if (status === 401) {
                    logout()
                    return;
                }
                if (status === 500) {
                    setPopup500(true)
                    return;
                }
                if (status === 499) {
                    var dataNew = JSON.parse(data)
                    var errorType = dataNew.type;
                    setError499(dataNew.errors[errorType][0])
                    setPopup499(true)
                    return;
                }


                if (folder) {
                    let newArr = [...result];
                    for (var i = 0; i < newArr.length; i++) {
                        if (newArr[i].path === item.path && newArr[i].name === item.name) {
                            newArr[i].activateAi = e;
                        }
                    }
                    setResult(newArr);
                    console.log(item)
                    console.log(result)
                } else {

                    let newArr = [...buckets];
                    for (var i = 0; i < newArr.length; i++) {
                        if (newArr[i].name === item.name) {
                            newArr[i].activateAi = e;
                        }
                    }
                    setBuckets(newArr);

                    let newArr1 = [...filteredBuckets];
                    for (var i = 0; i < newArr1.length; i++) {
                        if (newArr1[i].name === item.name) {
                            newArr1[i].activateAi = e;
                        }
                    }
                    setFilteredBuckets(newArr);
                }
            })
    };
    const onChangeBucket = (item) => {
        console.log(item)
        const value = item.name;
        setSelectedBucketId(item.id)
        setContinuationToken("");
        setPath('');
        setPreviousPathFiles('');
        setPreviousPathIds('');
        setBucket(value);
        setStep(3)
    };

    useEffect(() => {
        if (dataFetchedRef.current) return;
        dataFetchedRef.current = true;
        setContinuationToken("")
        if (!isWindows) {
            getDirectoriesMac()
        }
    }, [])
    useEffect(() => {
        if (path !== "") {
            getListObjects()
        }
    }, [path])

    useEffect(() => {
        if (path === "") {
            getListObjects()
        }
    }, [bucket])

    function getDirectoriesMac() {
        getDataMethodGet('/Management/list_files_directories?path=/')
            .then((res) => Promise.all([res.status, res.json()]))
            .then(([status, response]) => {
                setLoading(false);
                if (status === 401) {
                    logout()
                    return;
                }
                if (status === 500) {
                    setPopup500(true);
                    return;
                }
                if (status === 499) {
                    var errorType = response.type;
                    setError499(response.errors[errorType][0])
                    setPopup499(true)
                    return;
                }
                setLocalDir(response)
            })
    }

    const selectCloudDrive = (event) => {
        event.preventDefault();
        const id = event.target.value;
        const index = event.target.getAttribute('data-index');
        setContinuationToken("");
        setBucket('');
        setPath('');
        setPreviousPathFiles('');
        setPreviousPathIds('');
        setLoading(false);
        setLoadingMore(false)
        setShowData(false)
        setBuckets([]);
        setFilteredBuckets([]);
        setSelectedCloudAccountID(id);
        console.log(cloudAccounts[index])
        getActiveBuckets(id, true)
        setSelectedCloudAccount(cloudAccounts.filter(account => account.id === id))

        if (cloudAccounts[index].id === limitExceeded) {
            setLimitExceed(true)
        } else {
            setLimitExceed(false)
        }
        setStep(2)
    }


    const changeCloudDrive = (event) => {
        event.preventDefault();
        setBucket("")
        setBuckets("")
        setFilteredBuckets("")
        setSelectedCloudAccount([])
        setFilteredCloudAccounts(cloudAccounts)
        setStep(1)
    };
    const changeCloudBucket = (event) => {
        event.preventDefault();
        setBucket("")
        setStep(2)
    };

    const cancel = (e) => {
        console.log(e);
        // message.error('Click on No');
    };

    function goBack() {
        setLoading(true)
        setContinuationToken("")
        setPreviousPathIds((previousArr) => (previousArr.slice(0, -1)));
        setPrevFolderID(previousPathIds[previousPathIds.length - 1])
        setPath(previousPathFiles[previousPathFiles.length - 1])
        setPreviousPathFiles((previousArr) => (previousArr.slice(0, -1)));
    }

    return (
        <>
            <div id="main-content">
                <div className="container-fluid">
                    <div className="main-content-body">
                        <div className="activate-ai">
                            {/*<NavLink to="/" className="back-button"><img src={backArrowIcon} alt="icon"/>Back</NavLink>*/}
                            <ul className="ai-breadcrumb">
                                <li><NavLink to="/">Home</NavLink></li>
                                <li><NavLink to="/ai-activate" onClick={changeCloudDrive}>Activate AI</NavLink></li>
                                {selectedCloudAccount.length > 0 &&
                                    <li onClick={changeCloudBucket}><a href="#">{selectedCloudAccount[0]?.name}</a></li>
                                }
                                {bucket !== '' &&
                                    <li>{bucket}</li>
                                }
                            </ul>
                            <div className={`activate-ai-wrapper full-width ${step === 3 ? 'w-700' : ''}`}>
                                <h3 className="h3" onClick={() => {
                                    console.log(selectedCloudAccount)
                                }}>Activate AI</h3>
                                {(step === 1) &&
                                    <h3 className="choose-title">Choose a Cloud</h3>
                                }
                                {selectedCloudAccount.length > 0 &&
                                    <div className={`cloud-name-wrapper ${step === 2 || step === 3 ? 'mt-4' : ''}`}>
                                        {step === 2 &&
                                            <div className="icon">
                                                {getIcon(selectedCloudAccount[0].cloudType, selectedCloudAccount[0].cloudIconType)}
                                            </div>
                                        }
                                        {step === 2 &&
                                            <h3>{selectedCloudAccount[0].name}
                                                <a href="#" className="btn-style transparent icon"
                                                   onClick={changeCloudDrive}><img src={editIcon} alt="logo"/></a></h3>
                                        }
                                        {step === 3 &&
                                            <h3>{bucket}
                                                <a href="#" className="btn-style transparent icon"
                                                   onClick={changeCloudBucket}><img src={editIcon} alt="logo"/></a></h3>
                                        }
                                    </div>
                                }
                                {buckets.length > 0 && step === 2 &&
                                    <p className="bucket-count">{buckets.length} {buckets.length > 0 ? "Buckets" : "Bucket"}</p>
                                }


                                {step === 1 &&
                                    <div className="cloud-brands-wrapper choose-cloud-wrapper">
                                        <div className="left-content">
                                            <div className="drive-brands">


                                                <div className="form full-width">
                                                    <div className="row">
                                                        <div className="col-md-6 align-self-end">
                                                            <div className="form-group">
                                                                <input type="text" className="form-control" onKeyUp={(e) => {
                                                                    setFilteredCloudAccounts(cloudAccounts.filter((account) => account.name.toLowerCase().includes(e.target.value.toLowerCase())))
                                                                }} placeholder="search Cloud"/>
                                                                <img src={searchGrayIcon} className="icon" alt="icon"/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="choose-cloud-card-wrapper">
                                                        {filteredCloudAccounts.length > 0 &&
                                                            filteredCloudAccounts.map((item, i) => (
                                                                <div className="form-group" key={item.id}>
                                                                    <label className="choose-cloud-card">
                                                                        <input type="radio" id="choose-cloud-1" value={item.id}
                                                                               data-index={i} data-name={item.name}
                                                                               name="choose-cloud" onChange={selectCloudDrive}/>
                                                                        <div className="img-wrapper">
                                                                            {getIcon(item.cloudType, item.cloudIconType)}
                                                                            {!item.internalStorage &&
                                                                                <p className="medium">{item.name}</p>
                                                                            }
                                                                            {item.internalStorage &&
                                                                                <p className="medium">{item.name.replace("Amove-", "").replace(" (", "-").replace(")", "")}</p>
                                                                            }
                                                                        </div>
                                                                        {/*<div className="content-wrapper">
                                                                            <p>Created: 05/28/2024 <br/>
                                                                                100MB</p>
                                                                        </div>*/}
                                                                    </label>
                                                                </div>
                                                            ))
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }

                                {step === 2 &&
                                    <>

                                        <div className="form">
                                            <div className="row">
                                                <div className="col-md-6 align-self-end">
                                                    {buckets.length > 0 &&
                                                        <div className="form-group">
                                                            <input type="text" className="form-control" onKeyUp={(e) => {
                                                                setFilteredBuckets(buckets.filter((bucket) => bucket.name.toLowerCase().includes(e.target.value.toLowerCase())))
                                                            }} placeholder="Enter your search"/>
                                                            <img src={searchGrayIcon} className="icon" alt="icon"/>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className="activate-cloud-main">
                                            {buckets.length > 0 &&
                                                <div className="activate-cloud-wrapper">
                                                    <div className="cloud-item header d-none d-md-grid">
                                                        <p>Bucket</p>
                                                        <p>On/Off</p>
                                                    </div>

                                                    <div className="cloud-item-body-wrapper">
                                                        {filteredBuckets.map((item, i) => (
                                                            <div className="cloud-item">
                                                                <p><a href="#" className="name-wrapper" onClick={(e) => {
                                                                    e.preventDefault();
                                                                    onChangeBucket(item)
                                                                }}>{item.name}</a></p>
                                                                <div className="switch-wrapper">
                                                                    {!item.activateAi &&
                                                                        <Popconfirm
                                                                            title="Delete the task"
                                                                            description={
                                                                                <div className="ai-popover">
                                                                                    <h3 className="">Enable AI Processing</h3>
                                                                                    <p className="">Files will be processed with Amove AI. <br/>
                                                                                        Once activated AI features will be applied.</p>
                                                                                </div>
                                                                            }
                                                                            placement="left"
                                                                            onConfirm={() => {
                                                                                confirm(item, true)
                                                                            }}
                                                                            onCancel={cancel}
                                                                            okText="Activate AI"
                                                                            cancelText="Cancel"
                                                                            getPopupContainer={trigger => trigger.parentNode}
                                                                        >
                                                                            <Switch checked={item.activateAi}/>
                                                                        </Popconfirm>
                                                                    }
                                                                    {item.activateAi &&
                                                                        <Popconfirm
                                                                            title="Delete the task"
                                                                            description={
                                                                                <div className="ai-popover">
                                                                                    <h3 className="">Disable AI Processing</h3>
                                                                                    <p className="">AI features will no longer be available. <br/>
                                                                                        Once Disable AI features will not be applied.</p>
                                                                                </div>
                                                                            }
                                                                            placement="left"
                                                                            onConfirm={() => {
                                                                                confirm(item, false)
                                                                            }}
                                                                            onCancel={cancel}
                                                                            okText="Disable AI"
                                                                            cancelText="Cancel"
                                                                            getPopupContainer={trigger => trigger.parentNode}
                                                                        >
                                                                            <Switch checked={item.activateAi}/>
                                                                        </Popconfirm>
                                                                    }
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                        {buckets.length > 0 &&
                                            <div className="ai-footer d-none">
                                                <div className="row">
                                                    <div className="col-md-8 align-self-center">
                                                        {/* <ul className="ai-pagination">
                                    <li><a href="#"><img src={paginationLeftIcon} alt="icon"/></a></li>
                                    <li><p className="p">Page</p></li>
                                    <li>
                                        <div className="count"><p>1</p></div>
                                    </li>
                                    <li><p className="p">of</p></li>
                                    <li><p className="p">4</p></li>
                                    <li><a href="#"><img src={paginationRightIcon} alt="icon"/></a></li>
                                </ul>*/}
                                                    </div>
                                                    <div className="col-md-4 align-self-center">
                                                        <div className="btn-wrapper">
                                                            <Popover
                                                                content={
                                                                    <div className="ai-popover">
                                                                        <h3 className="h3">Changes not saved.</h3>
                                                                        <p className="p">Please save your changes before applying bulk activation.</p>
                                                                        <div className="popover-footer">
                                                                            <Button className="btn-style transparent black">Cancel</Button>
                                                                            <Button className="btn-style orange-btn">Save</Button>
                                                                        </div>
                                                                    </div>}
                                                                trigger="click"
                                                                placement="left"
                                                                getPopupContainer={trigger => trigger.parentNode}
                                                            >
                                                                <a href="#" className="btn-style orange-btn">Save Changes</a>
                                                            </Popover>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </>
                                }

                                {step === 3 &&
                                    <>
                                        <div className="activate-cloud-main">
                                            {(listView === true) &&
                                                <div>
                                                    {(previousPathFiles.length > 0 || path.length > 10) &&
                                                        <div className="sync-back">
                                                            {previousPathFiles.length > 0 &&
                                                                <button className="btn-style" type="button" onClick={goBack}><i
                                                                    className="fa fa-arrow-left"></i></button>
                                                            }
                                                            {path.length > 1 &&
                                                                <b className='searchPath'>Path: <span title={path}>{path}</span></b>
                                                            }
                                                        </div>
                                                    }
                                                    <div className="activate-cloud-wrapper">
                                                        <div className="cloud-item header d-none d-md-grid">
                                                            <p>Folder Name</p>
                                                            <p>On/Off</p>
                                                        </div>

                                                        <div className="cloud-item-body-wrapper">
                                                            {!loading && result.filter((item) => item.type === 2).map((item, index) => (
                                                                <div className="cloud-item">
                                                                    <p><a href="#" className="name-wrapper" data-value={item.path}
                                                                          data-folder={item.type} data-name={item.name} data-id={item.id}
                                                                          role="button" onClick={changeObject}>
                                                                        <div className="icon-wrapper">
                                                                            {item.type === 2 &&
                                                                                <img src={`${folderBlackIcon}`} alt="folderIcon"/>
                                                                            }
                                                                            {item.type === 1 &&
                                                                                <img src={`${fileIconFile}`} alt="fileIcon"/>
                                                                            }
                                                                        </div>
                                                                        {item.name}
                                                                    </a></p>
                                                                    <div className="switch-wrapper">
                                                                        {!item.activateAi &&
                                                                            <Popconfirm
                                                                                title="Delete the task"
                                                                                description={
                                                                                    <div className="ai-popover">
                                                                                        <h3 className="">Enable AI Processing</h3>
                                                                                        <p className="">Files will be processed with Amove AI. <br/>
                                                                                            Once activated AI features will be applied.</p>
                                                                                    </div>
                                                                                }
                                                                                placement="left"
                                                                                onConfirm={() => {
                                                                                    confirm(item, true, true)
                                                                                }}
                                                                                onCancel={cancel}
                                                                                okText="Activate AI"
                                                                                cancelText="Cancel"
                                                                                getPopupContainer={trigger => trigger.parentNode}
                                                                            >
                                                                                <Switch checked={item.activateAi}/>
                                                                            </Popconfirm>
                                                                        }
                                                                        {item.activateAi &&
                                                                            <Popconfirm
                                                                                title="Delete the task"
                                                                                description={
                                                                                    <div className="ai-popover">
                                                                                        <h3 className="">Disable AI Processing</h3>
                                                                                        <p className="">AI features will no longer be available. <br/>
                                                                                            Once Disable AI features will not be applied.</p>
                                                                                    </div>
                                                                                }
                                                                                placement="left"
                                                                                onConfirm={() => {
                                                                                    confirm(item, false, true)
                                                                                }}
                                                                                onCancel={cancel}
                                                                                okText="Disable AI"
                                                                                cancelText="Cancel"
                                                                                getPopupContainer={trigger => trigger.parentNode}
                                                                            >
                                                                                <Switch checked={item.activateAi}/>
                                                                            </Popconfirm>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            ))}
                                                            {result.filter((item) => item.type === 2).length < 1 &&
                                                                <div className="no-data-found">
                                                                    <p className="p">No folder found</p>
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AiActivate;



